import React, { useEffect, useState } from "react";
//  import NodalFile from '../../assets/templateFiles'
// import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  ModalFooter,
  Row,
} from "react-bootstrap";
import Papa from "papaparse";
import MyLoader from "../../../common/MyLoader";

import {
  FetchAllReconcialiation,
  getUploadedPdfFiles,
  uploadNodalFile,
  uploadNodalTrueOrFalse,
  uploadSwitchFile,
} from "../../../utils/ApiServices";
import swal from "sweetalert";
import { FileOpen } from "@mui/icons-material";
import axios from "axios";
import NodalTemplate from "../../../Assets/templateFiles/NodalTemplate.csv";
import SwitchTemplate from "../../../Assets/templateFiles/SwitchTemplate.csv";
import {
  FETCH_UPLOAD_HISTORY,
  GET_RECONCILIATION_DATA,
  SWIPELINC_API,
  UPLOAD_SWITCH_FILE
} from "../../../utils/constant";
import ViewDetailsForReconcialiation from "./ViewDetailsForReconcialiation";
import {
  convertStringDateToDateType,
  getMonthName,
} from "../../../utils/helper";
import { DateTime } from "luxon";
import ReconMultiFilter from "./ReconMultiFilter";
import ReconAdvanceSearch from "./ReconAdvanceSearch";
import DownloadCsv from "../../../common/DownloadCsv";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../../utils/useApiServices";
import CustomTip from "../../../common/CustomToolTip/CustomTip";
import IconWrapper from "../../../common/IconWrapper/IconWrapper";
import MiniLoader from "../../../common/MiniLoader/MiniLoader";
import Loader from "../../Loader/Loader";
// import InactiveServices from "../../InactiveServices";

const Reconciliation = ({ toggleRightBar, type1 }) => {
  const [routingFile, setRoutingFile] = useState(null);
  const [nodalFile, setNodalFile] = useState(null);
  const [reconcialiAtion, setReconcialiAtionData] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filesOpen, setFilesOpen] = useState(false);
  const [isMultiFilterOpen, setIsMultiFilterOpen] = useState(false);
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [getApi, setGetApi] = useState(false);
  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [isSubmitDisabledByApi, setIsSubmitDisabledByApi] = useState(true);
  const [id, setId] = useState("");
  const [dataTableData, setDataTableData] = useState([]); // Data for MDBDataTable
  const token = localStorage.getItem("token");
  const hasPrivilage = useCheckPrivilages(2700);
  const [lastReconcillationDate, setLastReconcillationDate] = useState(null);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [isSwitchFileDateModalOpen, setIsSwitchFileDateModalOpen] =
    useState(false);
  const [switchFileUploadDate, setSwitchFileUploadDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [isNodalFileDateModalOpen, setIsNodalFileDateModalOpen] =
    useState(false);
  const [nodalFileUploadDate, setNodalFileUploadDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [showLoader, setShowLoader] = useState(false);

  const handleRoutingFileChange = (e) => {
    const file = e.target.files[0];
    setRoutingFile(file);
  };

  const getTrueOrFalse = async (token) => {
    const resApi = await uploadNodalTrueOrFalse(token);

    if (resApi.statusCode === 200) {
      setIsSubmitDisabled(false);
    }
  };
  getTrueOrFalse(token);

  const handleViewPDF = (filename) => {
    const encodedFilename = encodeURIComponent(filename);
    const headers = {
      Authentication: `Bearer ${token}`,
    };
    console.log(filename);
    axios
      .get(
        `${SWIPELINC_API}/MerchantAdminPanel/reconciliationReport/view-pdf/${encodedFilename}`,
        {
          responseType: "arraybuffer",
          headers,
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        // setSelectedFile(pdfUrl);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error.message);
      });
  };

  const getReconcialiationData = async () => {
    const resApi = await apiCallFnforGet(GET_RECONCILIATION_DATA);
    if (resApi.statusCode === 200) {
      const modifiedData = resApi?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        switchRrn: item.switchRrn,
        amount: item.amount,
        payinStatus: item.payinStatus,
        consolidatedStatus: item.consolidatedStatus,
        date: item.date,
        ExtId: item.extId ? item.extId : "",
        switchMsg: item.switchMsg.toLowerCase(),
        action: (
          <>
            <button
              className="badge badge-rounded badge-warning"
              onClick={() => ViewDetails(item.primeryKey)}
            >
              View
            </button>
          </>
        ),
      }));
      setReconcialiAtionData(modifiedData);
    }
  };

  const ViewDetails = (id) => {
    console.log("id__", id);
    setIsModalOpen(true);
    setId(id);
  };
  // const mappedData = !reconcialiAtion?.length
  //   ? []
  //   : reconcialiAtion?.map((item) => {
  //       const mappedItem = { ...item };
  //       mappedItem.action = (
  //         <div>
  //           <button
  //             className="btn btn-primary"
  //             onClick={() => ViewDetails(item.primeryKey)}
  //           >
  //             View Details
  //           </button>
  //         </div>
  //       );
  //       mappedItem.ExtId = item.extId ? item.extId : "";

  //       return mappedItem;
  //     });
  const getUploadedPdfFilesNames = async (token) => {
    const resApi = await getUploadedPdfFiles(token);
    if (resApi.statusCode === 200) {
      setPdfFilenames(resApi?.data);
    }
  };

  const ViewPdfs = () => {
    setFilesOpen(!filesOpen);
  };
  useEffect(() => {
    getReconcialiationData();
  }, [getApi]);
  useEffect(() => {}, [reconcialiAtion]);
  useEffect(() => {
    // getReconcialiationData(token);
    getUploadedPdfFilesNames(token);
  }, [filesOpen]);

  const handleNodalFileChange = (e) => {
    const file = e.target.files[0];
    setNodalFile(file);
  };
  const handleCheckBox = () => {
    setIsSubmitDisabled(!isSubmitDisabled);
  };

  const handleRoutingUpload = async () => {
    setIsSwitchFileDateModalOpen(true);
  };

  function handleDownloadExcel(reconcialiAtion) {
    try {
      // Check if FinalDownloadData is defined and not null
      if (!reconcialiAtion) {
        console.error("Error: FinalDownloadData is undefined or null");
        return;
      }

      // Assuming you have access to the originalData
      const apiData = reconcialiAtion;

      // Check if apiData is defined and not null, and if it has at least one element
      if (!apiData || apiData.length === 0) {
        console.error("Error: apiData is undefined, null, or empty");
        return;
      }

      if (!Array.isArray(apiData)) {
        console.error("Error: apiData is not an array");
        return;
      }

      // Check if apiData is not empty
      if (apiData.length === 0) {
        console.error("Error: apiData is an empty array");
        return;
      }

      const headerheaderNames = Object.keys(apiData[0]);

      // Extract the data from the originalData
      const apiRows = apiData.map((item) => Object?.values(item));

      // Combine header with data
      const dataWithHeader = [headerheaderNames, ...apiRows];

      // Convert the data with header to CSV format using PapaParse
      const csv = Papa.unparse(dataWithHeader);

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Report.csv"; // Change the file name as needed
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const UploadSwitchFileToServer = async () => {
    handleCloseSwitchFileDateModal();
    if (routingFile) {
      // setShowLoader(true);
      setShowLoader(true);
      let formData = new FormData();
      formData.append("file", routingFile);
      let SwitchDocsRes = await uploadSwitchFile(routingFile, token);
      // let SwitchDocsRes = await apiCallFnforPost(UPLOAD_SWITCH_FILE ,formData);
      // setShowLoader(false);
      console.log(SwitchDocsRes);
      
      if (
        SwitchDocsRes?.statusCode !== 600 &&
        SwitchDocsRes?.statusCode !== 101
      ) {
        // update Date
        sendDatesAndFileType(
          switchFileUploadDate.startDate,
          switchFileUploadDate.endDate,
          "switch"
        );
        fetchUploadHistory();
        setIsSwitchFileDateModalOpen(false);
        setSwitchFileUploadDate({
          startDate: "",
          endDate: "",
        });
        setShowLoader(false);
      }

      if (SwitchDocsRes?.statusCode === 200) {
        const errorData = SwitchDocsRes?.duplicateData;
        let errorMessage = ` ${errorData} Records has been Uploaded.`;
        swal({
          title: "Success",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
        getTrueOrFalse(token);
        setShowLoader(false);
      } else if (SwitchDocsRes?.statusCode === 1001) {
        const errorData = SwitchDocsRes?.duplicateData;
        let errorMessage = `${errorData} Records has been Uploaded.`;
        swal({
          title: "Success",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
        getTrueOrFalse(token);
        setShowLoader(false);
      } else if (SwitchDocsRes?.statusCode === 422) {
        let errorMessage = "No Matching Record Found.";
        fetchUploadHistory();
        swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (SwitchDocsRes?.statusCode === 600) {
        const errorMessage =
          SwitchDocsRes?.message || "Error occurred while reading the file.";
        fetchUploadHistory();
        swal({
          title: `${SwitchDocsRes?.status}`,
          text: errorMessage,
          icon: "error",
        }).then(() => {
          setIsSwitchFileDateModalOpen(false);
          fetchUploadHistory();
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (SwitchDocsRes?.statusCode === 101) {
        const errorMessage = "Please upload a valid file.";

        swal({
          title: "Failed",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (SwitchDocsRes?.statusCode === 500) {
        swal({
          title: "Failed",
          text: "Please Upload CSV File",
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (SwitchDocsRes?.statusCode === 304) {
        swal({
          title: "Failed",
          text: "Something Went Wrong.",
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else {
        swal({
          title: "Alert!",
          text: SwitchDocsRes?.message,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      }

      setRoutingFile(null);
      document.getElementById("FileName").value = "";
    } else {
      swal({
        title: "Alert!",
        text: "Select File First",
        icon: "warning",
      }).then(() => {
        setIsSwitchFileDateModalOpen(false);
        setSwitchFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
      setShowLoader(false);
    }
  };

  const UploadNodalFileToServer = async () => {
    handleCloseNodalFileDateModal();
    if (nodalFile) {
      setShowLoader(true);
      let NodalDocsRes = await uploadNodalFile(nodalFile, token);

      if (NodalDocsRes?.statusCode !== 600 && NodalDocsRes?.statusCode !== 101) {
        // update Date
        sendDatesAndFileType(
          nodalFileUploadDate?.startDate,
          nodalFileUploadDate?.endDate,
          "nodel"
        );
        setIsNodalFileDateModalOpen(false);
        fetchUploadHistory();
        setNodalFileUploadDate({
          startDate: "",
          endDate: "",
        });
        setShowLoader(false);
      }

      // setShowLoader(false);
      console.log(NodalDocsRes);
      if (NodalDocsRes?.statusCode === 200) {
        const errorData = NodalDocsRes?.duplicateData;
        let errorMessage = `${NodalDocsRes?.duplicateData} Records has been Matched.`;
        swal({
          title: "Alert!",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
        setShowLoader(false);
      } else if (NodalDocsRes?.statusCode === 1001) {
        const errorData = NodalDocsRes?.duplicateData;
        let errorMessage = `${NodalDocsRes?.duplicateData} Records has been Matched.`;
        fetchUploadHistory();
        swal({
          title: "success",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
        setShowLoader(false);
      } else if (NodalDocsRes?.statusCode === 422) {
        let errorMessage = "No Matching Record Found.";
        fetchUploadHistory();
        swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (NodalDocsRes?.statusCode === 600) {
        const errorMessage =
          NodalDocsRes?.message || "Error occurred while reading the file.";

        swal({
          title: `${NodalDocsRes?.status}`,
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (NodalDocsRes?.statusCode === 101) {
        const errorMessage = "Please upload a valid file.";
        swal({
          title: "Failed",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else if (NodalDocsRes?.statusCode === 500) {
        swal({
          title: "Failed",
          text: "Please Upload CSV File",
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      } else {
        swal({
          title: "Alert!",
          text: NodalDocsRes?.message,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setShowLoader(false);
      }

      setNodalFile(null);
      document.getElementById("FileNameNodal").value = "";
    } else {
      setShowLoader(false);
      swal({
        title: "Alert!",
        text: "Select File First",
        icon: "warning",
      }).then(() => {
        setIsNodalFileDateModalOpen(false);
        setNodalFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
    }
  };

  const handleNodalUpload = async () => {
    setIsNodalFileDateModalOpen(true);
  };

  const handleCloseSwitchFileDateModal = () => {
    setIsSwitchFileDateModalOpen(false);
  };

  const handleCloseNodalFileDateModal = () => {
    setIsNodalFileDateModalOpen(false);
  };

  const columns = [
    {
      headerName: "Merchant Id",
      field: "mid",
      minWidth: 150,
    },

    {
      headerName: "RRN / UTR Number",
      field: "switchRrn",
      minWidth: 150,
    },

    {
      headerName: "EXT Transaction ID",
      field: "ExtId",
      minWidth: 190,
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 150,
    },
    {
      headerName: "Payin Status",
      field: "payinStatus",
      minWidth: 150,
    },
    {
      headerName: "Consolidated Status",
      field: "consolidatedStatus",
      minWidth: 150,
    },
    {
      headerName: "Switch Message",
      field: "switchMsg",
      minWidth: 150,
    },
    {
      headerName: "Date",
      field: "date",
      minWidth: 150,
      // width:"300px"
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 150,
      // width:"300px"
    },
  ];

  const handelSwithDateAndUploadFileToServer = () => {
    if (routingFile === null) {
      swal({
        title: "Alert!",
        text: "Please Select Switch File",
        icon: "error",
      }).then(() => {
        setIsSwitchFileDateModalOpen(false);
        setSwitchFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
      return;
    }

    if (switchFileUploadDate.startDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch File Start Date",
        icon: "error",
      });
      return;
    }

    if (switchFileUploadDate.endDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch File End Date",
        icon: "error",
      });
      return;
    }

    UploadSwitchFileToServer();
  };

  const handelNodalDateAndUploadFileToServer = () => {
    if (nodalFile === null) {
      swal({
        title: "Alert!",
        text: "Please Select Nodal File",
        icon: "error",
      }).then(() => {
        setIsNodalFileDateModalOpen(false);
        setNodalFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
      return;
    }

    if (nodalFileUploadDate.startDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Nodal File Start Date",
        icon: "error",
      });
      return;
    }

    if (nodalFileUploadDate.endDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Nodal File End Date",
        icon: "error",
      });
      return;
    }

    UploadNodalFileToServer();
  };

  const sendDatesAndFileType = async (startDate, endDate, fileType) => {
    try {
      const params = {
        startDate: startDate,
        endDate: endDate,
        file: fileType,
      };

      const response = await axios.post(
        ` ${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/save/date`,
        params,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      console.error("Error sending dates and file type:", error);
      return null;
    }
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handleFromToDateInputChange = (from, to) => {
    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (inputToDateTime >= inputFromDateTime) {
      return true;
    } else {
      return false;
    }
  };

  const fetchUploadHistory = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_UPLOAD_HISTORY);

      if (response.statusCode === 200) {
        setLastReconcillationDate(response.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching upload history:", error);
    }
  };

  const HandleMultipleFilter = () => {
    setIsMultiFilterOpen(!isMultiFilterOpen);
  };

  const HandleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  };

  useEffect(() => {
    fetchUploadHistory();
  }, []);

  return (
    <>
   {showLoader && <Loader/> }
  
      <>
        <div className="mt-4 d-flex justify-content-between bg-white">
          <div className=" d-flex justify-content-start align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Reconciliation</div>
          </div>
        </div>{" "}
        <section className="lincpay-dashboard-sec chargeback-reports-sec">
          <Container>
            <div className="cart-view-sec mt-2">
              {/* <Row> */}
              {/* Tab content start here */}

              <Card className="shadow-cst border-0 mb-3 chart-boxes ">
                <Card.Body className=" d-flex justify-content-center px-5   ">
                  <div className=" d-flex border border-2 w-30 p-2 rounded-2 mt-2  ">
                    <b>Last Reconciliation Date : {lastReconcillationDate} </b>
                  </div>
                </Card.Body>
                <Card.Body className="">
                  <div className="w-100 d-flex justify-content-center align-items-between">
                    <div className="w-40 mx-5">
                      <Card.Title className=" d-flex justify-content-start h3 mb-3 card-header-btn">
                        Switch File Upload
                      </Card.Title>
                      <div className="card-chart-box">
                        <div className="excel-uploader">
                          <div className="card-chart-box">
                            <div className="excel-uploader">
                              <div className="form-group upload-field d-flex ">
                                <div className="input-upload-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="FileName"
                                    // accept=".csv"
                                    onChange={handleRoutingFileChange}
                                  />
                                </div>
                                <div className="toolbar-btn mx-2 ">
                                  <Button
                                    variant="primary"
                                    onClick={handleRoutingUpload}
                                  >
                                    Upload
                                  </Button>
                                </div>
                              </div>
                              {/* <Card.Title className="w-100 h3 mb-3 mt-3 card-header-btn  d-flex   gap-5  mt-5"> */}
                              <div className="button-toolbar mt-2 d-flex justify-content-center w">
                                <a
                                  className="btn btn-success"
                                  title="Click here to download Chargeback file template"
                                  href={SwitchTemplate}
                                  download
                                >
                                  Download Template
                                </a>
                              </div>
                              {/* </Card.Title> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-40">
                      <Card.Title className="d-flex justify-content-start h3 mb-3 card-header-btn">
                        Nodal / Bank File Upload
                      </Card.Title>

                      <div className="card-chart-box">
                        <div className="excel-uploader">
                          <div className="card-chart-box">
                            <div className="excel-uploader">
                              <div className="form-group d-flex upload-field  ">
                                <div className="input-upload-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="FileNameNodal"
                                    onChange={handleNodalFileChange}
                                  />
                                </div>
                                <div className="toolbar-btn   mx-2">
                                  <Button
                                    variant="primary"
                                    disabled={isSubmitDisabled}
                                    onClick={handleNodalUpload}
                                  >
                                    Upload
                                  </Button>
                                </div>
                              </div>
                              <div className=" d-flex justify-content-start align-items-center mb-2">
                                <input
                                  type="checkbox"
                                  className="fw-bold"
                                  onChange={handleCheckBox}
                                />
                                <span>
                                  Do You still want to Upload the nodal file
                                </span>
                                <span className="text-danger mt-2 mx-3">
                                  * Note - Kindly upload Switch file first.
                                </span>
                              </div>
                              {/* <Card.Title className="w-100 h3 mb-3 mt-3 card-header-btn  d-flex  justify-content-c gap-5"> */}
                              <div className="button-toolbar">
                                <a
                                  className="btn btn-success"
                                  title="Click here to download Chargeback file template"
                                  href={NodalTemplate}
                                  download
                                >
                                  Download Template
                                </a>
                              </div>
                              {/* </Card.Title> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <canvas id="income" width="auto" height="auto"></canvas> */}
                </Card.Body>
              </Card>
              <div className=" d-flex justify-content-center mt-5 ">
                <Button onClick={ViewPdfs} className="">
                  {filesOpen ? "Close Files" : "View Uploaded Files"}
                </Button>
              </div>

              {filesOpen && (
                <div className="pdf-list-container report-grid-view mt-3 p-5 card ">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Switch File Name</th>
                        <th>consolidated File Name</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {pdfFilenames.map((fileObject, index) => (
                        <tr key={index}>
                          {/* <td>{fileObject["Orignal name"]}</td> */}
                          <td>{fileObject["switchFilePath"]}</td>
                          <td>{fileObject["consolidatedFilePath"]}</td>
                          {/* <td>
                            <button
                              className="btn btn-secondary"
                              onClick={() =>
                                handleViewPDF(fileObject["switchFilePath"])
                              }
                            >
                              View PDF
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* ./col */}
              {/* </Row> */}
            </div>
          </Container>
        </section>
        <section className="lincpay-dashboard-sec chargeback-reports-sec mt-3 ">
          {/* <Container> */}
          <div className="cart-view-sec mt-2">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                {/* <Card className="shadow-cst border-0 mb-3 chart-boxes"> */}
                <Card.Body>
                  <Col className="d-flex justify-content-end gap-3 mb-3">
                    {/* <Button onClick={HandleMultipleFilter}> */}
                    {/* <i className="bi bi-funnel-fill mx-1">
                        </i> */}
                    <IconWrapper title={"More Filter"}>
                      <i
                        class="fa fa-filter"
                        onClick={HandleMultipleFilter}
                      ></i>
                    </IconWrapper>
                    <IconWrapper title={"Advance Filter"}>

                        <i
                          class="fa fa-filter"
                          onClick={HandleAdvanceFilter}
                        ></i>
                      </IconWrapper>
                    {/* <CustomTip size="18" placement="top" title="More Filter">
                      <FilterAltIcon onClick={HandleMultipleFilter} />
                    </CustomTip> */}

                    {/* </Button> */}

                    {/* <CustomTip size="18" placement="top" title="Advance Filter">
                      <FilterAltIcon onClick={HandleAdvanceFilter} />
                    </CustomTip> */}
                    {/* <Button onClick={HandleAdvanceFilter}>
                      Advance Filter
                    </Button> */}
                    {/* <CustomTip size="18" placement="top" title="Reset"> */}
                    <IconWrapper title={"reset"}>
                      <i
                        className="fa fa-refresh"
                        onClick={() => {
                          setGetApi(!getApi);
                        }}
                      ></i>
                    </IconWrapper>
                    {/* </CustomTip> */}
                    {/* <Button
                      onClick={() => {
                        setGetApi(!getApi);
                      }}
                    >
                      {" "}
                      <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                    </Button> */}
                  </Col>
                  <div style={{ height: "500px", width: "100%" }}>

                  <DataGrid
                    rows={reconcialiAtion}
                    columns={columns?.map((column) => ({
                      ...column,
                      renderCell: (params) =>
                        column.field === "action" ? params.value : params.value,
                    }))}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    />
                    </div>
                </Card.Body>

                {isMultiFilterOpen ? (
                  <ReconMultiFilter
                    setReconReportData={setReconcialiAtionData}
                    setIsMultiFilterOpen={setIsMultiFilterOpen}
                    dropDownData=""
                  />
                ) : (
                  ""
                )}
                {isAdvanceFilterOpen ? (
                  <ReconAdvanceSearch
                    setReconReportData={setReconcialiAtionData}
                    setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  />
                ) : (
                  ""
                )}
                <div className="p-2 px-2">
                  <DownloadCsv
                    DownLoadData={reconcialiAtion}
                    Name={Reconciliation}
                  />
                </div>
                {/* </Card> */}
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
          {/* </Container> */}
        </section>
        {isModalOpen ? (     
          <section className="lincpay-dashboard-sec chargeback-reports-sec mt-3">
            <Container>
              <ViewDetailsForReconcialiation
                id={id}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
              />
            </Container>
          </section>
        ) : (
          ""
        )}


     <Modal
          show={isSwitchFileDateModalOpen}
          onHide={handleCloseSwitchFileDateModal}
          centered="true"
        >
          <Modal.Header closeButton>
            <Modal.Title>Switch File:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="switchStartDate">  
              <Form.Label>Switch Start Date</Form.Label>
              <Form.Control
                type="date"
                value={switchFileUploadDate?.startDate}
                onChange={(e) => {
                  if (handleDateInputChange(e)) {
                    setSwitchFileUploadDate({
                      ...switchFileUploadDate,
                      startDate: "",
                    });
                    document
                      .getElementById("startDateErr")
                      .classList.remove("d-none");
                    document.getElementById(
                      "applySwitchDateBtn"
                    ).disabled = true;
                  } else {
                    document
                      .getElementById("startDateErr")
                      .classList.add("d-none");
                    document.getElementById(
                      "applySwitchDateBtn"
                    ).disabled = false;
                    setSwitchFileUploadDate({
                      ...switchFileUploadDate,
                      startDate: e.target.value,
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                // onChange={(e) =>
                //   setSwitchFileUploadDate({
                //     ...switchFileUploadDate,
                //     startDate: e.target.value,
                //   })
                // }
              />
              <span id="startDateErr" className=" text-danger d-none">
                *Please Enter Valid Date!
              </span>
            </Form.Group>
            <Form.Group controlId="switchEndDate" className="mt-3">
              <Form.Label>Switch End Date</Form.Label>
              <Form.Control
                type="date"
                value={switchFileUploadDate.endDate}
                onChange={(e) => {
                  if (handleDateInputChange(e)) {
                    setSwitchFileUploadDate({
                      ...switchFileUploadDate,
                      endDate: "",
                    });
                    document
                      .getElementById("endDateErr")
                      .classList.remove("d-none");
                    document.getElementById(
                      "applySwitchDateBtn"
                    ).disabled = true;
                  } else {
                    if (
                      handleFromToDateInputChange(
                        switchFileUploadDate.startDate,
                        e.target.value
                      )
                    ) {
                      document
                        .getElementById("endDateErr")
                        .classList.add("d-none");
                      document
                        .getElementById("fromToDateErr")
                        .classList.add("d-none");
                      document.getElementById(
                        "applySwitchDateBtn"
                      ).disabled = false;
                      setSwitchFileUploadDate({
                        ...switchFileUploadDate,
                        endDate: e.target.value,
                      });
                    } else {
                      document
                        .getElementById("fromToDateErr")
                        .classList.remove("d-none");
                      document.getElementById(
                        "applySwitchDateBtn"
                      ).disabled = true;
                      setSwitchFileUploadDate({
                        ...switchFileUploadDate,
                        endDate: "",
                      });
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}

                // onChange={(e) =>
                //   setSwitchFileUploadDate({
                //     ...switchFileUploadDate,
                //     endDate: e.target.value,
                //   })
                // }
              />
              <span id="endDateErr" className=" text-danger d-none">
                *Please Enter Valid Date!
              </span>
              <span id="fromToDateErr" className=" text-danger d-none">
                *TO Date can't be less than FROM Date!
              </span>
            </Form.Group>
            <Form.Group
              className="mt-2 d-flex justify-content-center"
              controlId="switchEndDate"
            >
              <Button
                variant="primary"
                id="applySwitchDateBtn"
                onClick={handelSwithDateAndUploadFileToServer}
              >
                Apply Switch Dates
              </Button>
            </Form.Group>
          </Modal.Body>
          <ModalFooter></ModalFooter>
        </Modal>
       
        <Modal
          show={isNodalFileDateModalOpen}
          onHide={handleCloseNodalFileDateModal}
          centered="true"
        >
          <Modal.Header closeButton>
            <Modal.Title>Nodal File:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="switchStartDate">
              <Form.Label>Nodal Start Date</Form.Label>
              <Form.Control
                type="date"
                value={nodalFileUploadDate.startDate}
                onChange={(e) => {
                  if (handleDateInputChange(e)) {
                    setNodalFileUploadDate({
                      ...nodalFileUploadDate,
                      startDate: "",
                    });
                    document
                      .getElementById("startDateErr")
                      .classList.remove("d-none");
                    document.getElementById(
                      "applyNodalDateBtn"
                    ).disabled = true;
                  } else {
                    document
                      .getElementById("startDateErr")
                      .classList.add("d-none");
                    document.getElementById(
                      "applyNodalDateBtn"
                    ).disabled = false;
                    setNodalFileUploadDate({
                      ...nodalFileUploadDate,
                      startDate: e.target.value,
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
              />
              <span id="startDateErr" className=" text-danger d-none">
                *Please Enter Valid Date!
              </span>
            </Form.Group>
            <Form.Group controlId="switchEndDate" className="mt-3">
      <Form.Label>Nodal End Date</Form.Label> {/* Corrected component name */}
      <Form.Control
        type="date"
        value={nodalFileUploadDate?.endDate}
        onChange={(e) => {
          if (handleDateInputChange(e)) {
            setNodalFileUploadDate({
              ...nodalFileUploadDate,
              endDate: "",
            });
            document
              .getElementById("endDateErr")
              .classList.remove("d-none");
            document.getElementById(
              "applyNodalDateBtn"
            ).disabled = true;
          } else {
            if (
              handleFromToDateInputChange(
                nodalFileUploadDate?.startDate,
                e.target.value
              )
            ) {
              document
                .getElementById("endDateErr")
                .classList.add("d-none");
              document
                .getElementById("fromToDateErr")
                .classList.add("d-none");
              document.getElementById(
                "applyNodalDateBtn"
              ).disabled = false;
              setNodalFileUploadDate({
                ...nodalFileUploadDate,
                endDate: e.target.value,
              });
            } else {
              document
                .getElementById("fromToDateErr")
                .classList.remove("d-none");
              document.getElementById(
                "applyNodalDateBtn"
              ).disabled = true;
              setNodalFileUploadDate({
                ...nodalFileUploadDate,
                endDate: "",
              });
            }
          }
        }}
        onKeyDown={(e) => {
          if (e.key === " ") {
            e.preventDefault();
          }
        }}
      />
      <span id="endDateErr" className="text-danger d-none">
        *Please Enter Valid Date!
      </span>
      <span id="fromToDateErr" className="text-danger d-none">
        *TO Date can't be less than FROM Date!
      </span>
    </Form.Group>
            <Form.Group
              className="mt-2 d-flex justify-content-center"
              controlId="switchEndDate"
            >
              <Button
                variant="primary"
                id="applyNodalDateBtn"
                onClick={handelNodalDateAndUploadFileToServer}
              >
                Apply Nodal Dates
              </Button>
            </Form.Group>
          </Modal.Body>
          <ModalFooter></ModalFooter>
        </Modal>

      </>  
      
    </>
  );
};

export default Reconciliation;
