import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Offcanvas } from "react-bootstrap";
import swal from "sweetalert";
import {
  GET_ALL_PENDING_WITHDRAWL,
  UPDATE_WITHDRAWL,
} from "../../../../utils/constant";
import useApiServices from "../../../../utils/useApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DropCredit from "./DropCredit";
import ManualUpload from "./ManualUpload";
import UploadIcon from "@mui/icons-material/Upload";
import CustomTip from "../../../../common/CustomToolTip/CustomTip";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MyModal from "./MyModel";

function RequestedWithdraw({ toggleRightBar }) {
  const [withdrawData, setWithdrawData] = useState([]);
  const [callAgain, setCallagain] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [type, setType] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const[refresh,setRefresh]=useState(false);

  const { apiCallFnforPut, apiCallFnforGet } = useApiServices();

  const handleClick = (id, type) => {
    setShowModal(true);
    setSelectedId(id);
    setType(type);
  };

  const getSelectedWithdrawalData = (selectedId) => {
    return withdrawData.find((item) => item.withdrawalId === selectedId);
  };

  const performApprovalOrRejection = (id, type, data, date, remark) => {
    const selectedWithdrawal = getSelectedWithdrawalData(id);

    const RETURNPAYLOAD = (type) => {
      if (!type) {
        return {
          transactionDate: date,
          utr: data,
          status: "success",
          withdrawalId: id,
          remark: "",
        };
      } else {
        return {
          transactionDate: "",
          utr: "",
          status: "reject",
          withdrawalId: id,
          remark: remark,
        };
      }
    };

    const requestData = RETURNPAYLOAD(type);

    apiCallFnforPut(UPDATE_WITHDRAWL, requestData)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success",
            text: type
              ? "Your application has been rejected."
              : "Your application has been approved.",
            icon: "success",
          });
          setCallagain(!callAgain);
        } else {
          swal({
            title: "Error",
            text: "Something went wrong. Please try again.",
            icon: "error",
          });
        }
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error performing approval/rejection:", error);
        swal({
          title: "Error",
          text: "Something went wrong. Please try again.",
          icon: "error",
        });
        setShowModal(false);
      });
  };

  useEffect(() => {
    apiCallFnforGet(GET_ALL_PENDING_WITHDRAWL)
      .then((response) => {
        if (response.statusCode === 200) {
          const finalRes = response.data.map((item, i) => ({
            id: i,
            mid: item.mid,
            fullName: item.fullName,
            emailId: item.emailId,
            transferType: item.transferType,
            virtualAccountType: item.virtualAccountType,
            virtualAccountNumber: item.virtualAccountNumber,
            ifsc: item.ifsc,
            bankName: item.bankName,
            status: item.status,
            createDate: item.createDate,
            createTime: item.createTime,
            actualAmount: item.actualAmount,
            amount: item.amount,
            serviceCharge:item.serviceCharge,
            gstCharge: item.gstCharge,
            withdrawalId: item.withdrawalId,
            action: (
              <div className="d-flex gap-1 mt-2">
                <button
                  className="badge badge-rounded badge-success"
                  onClick={() => handleClick(item.withdrawalId, false)}
                >
                  Approve
                </button>
                <button
                  className="badge badge-rounded badge-danger"
                  onClick={() => handleClick(item.withdrawalId, true)}
                >
                  Reject
                </button>
              </div>
            ),
          }));
          setWithdrawData(finalRes);
        } else {
          setWithdrawData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching withdrawal data:", error);
      });
  }, [callAgain,refresh, setRefresh]);

  const columns = [
    { headerName: "Merchant Id", field: "mid", minWidth: 150 },
    { headerName: "Merchant Name", field: "fullName", minWidth: 150 },
    { headerName: "Email Id", field: "emailId", minWidth: 150 },
    { headerName: "Transfer Type", field: "transferType", minWidth: 150 },
    { headerName: "Requested Amount", field: "actualAmount", minWidth: 100 },
   { headerName: "Amount", field: "amount", minWidth: 100 },
   { headerName: "Service Charge", field: "serviceCharge", minWidth: 100 },
   { headerName: "GST Charge", field: "gstCharge", minWidth: 100 },
    {
      headerName: "Virtual Account Type",
      field: "virtualAccountType",
      minWidth: 150,
    },
    {
      headerName: "Virtual Account Number",
      field: "virtualAccountNumber",
      minWidth: 150,
    },
    { headerName: "Virtual IFSC Code", field: "ifsc", minWidth: 150 },
    { headerName: "Bank Name", field: "bankName", minWidth: 150 },
    { headerName: "Status", field: "status", minWidth: 100 },
    { headerName: "Created Date", field: "createDate", minWidth: 150 },
    { headerName: "Created Time", field: "createTime", minWidth: 150 }, 
    { headerName: "Action", field: "action", minWidth: 150 },
  ];

  const handleOnClick = () => {
    setShow(true);
  };
  const handleOnClick2 = () => {
    setShow2(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleClose2 = () => {
    setShow2(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className=" d-flex justify-content-between bg-white">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>
            <div className="fs-2 text-dark fw-bold">Requested Withdraw</div>
          </div>

          <div className="d-flex gap-2 justify-content-end me-2 mt-3 mb-2">
            <div className="d-flex justify-content-end">
              <CustomTip size="18" placement="top" title="Drop Credit">
                <div className="" onClick={handleOnClick}>
                  <CreditScoreIcon fontSize="large" />
                </div>
              </CustomTip>
            </div>
            <div className="d-flex justify-content-end">
              <CustomTip size="18" placement="top" title="Manual Upload">
                <div className="" onClick={handleOnClick2}>
                  <UploadIcon fontSize="large" />
                </div>
              </CustomTip>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="col-12">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataGrid
                        rows={withdrawData}
                        columns={columns.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <MyModal
              id={selectedId}
              showModal={showModal}
              setShowModal={setShowModal}
              type={type}
              performApprovalOrRejection={performApprovalOrRejection}
            />
          )}
        </div>
        <DropCredit onHide={handleClose} show={show}  setRefresh={setRefresh} refresh={refresh} />
        <ManualUpload onHide={handleClose2} show={show2} setRefresh={setRefresh} refresh={refresh} />
      </div>
    </>
  );
}

export default RequestedWithdraw;
