import React,{useState, useEffect} from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from '../../../utils/useApiServices';
import {API_FOR_VIEW_LOGS, GET_ALL_DISABLED_SID_FOR_MASTERS, SID_DETAILS} from "../../../utils/constant";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import swal from "sweetalert";
import axios from "axios";
import {
  ENABLE_SIDMASTER,
  UPDATE_MERCHANT,
} from "../../../utils/constant";
import SIDLogs from './SIDLogs';


const SIDDetails = ({type, toggleRightBar}) => {
const {apiCallFnforGet, apiCallFnforPut} = useApiServices();
const [applications, setApplications] = useState([]);
const [selectedAction, setSelectedAction] = useState("");
const token = localStorage.getItem("token");
const [editFormData, setEditFormData] = useState({
  sid: "",
  txnAmt: "",
  mid: "",
  merchantName: "",
  id: "",
  vehicleMasterId: "",
  masterMerchantId: "",
  masterMerchantName: "",
});
const [logsData,setLogsData] = useState([]);
const [logsModal, setLogsModal] = useState(false);

//  FUNCTION TO FETCH DATA FROM API
const FetchData = async()=>{

    try {
        const response = await apiCallFnforGet(SID_DETAILS);
        // const response = await apiCallFnforGet(GET_ALL_DISABLED_SID_FOR_MASTERS);
        
        if (response.statusCode === 200) {
            const finalRes = response.data.map((item, i) => ({
              id: i,
              mid: item.mid,
              company: item.company,
              sid: item.sid,
              merchantVpa:item.merchantVpa,
              vehicleName: item.vehicleName,
              maxTxnAmount: item.maxTxnAmount,
              createDateTime: item.createDateTime,
              updatedDateTime: item.updatedDateTime,
              viewLogs:  ( 
                <button
                    className="badge badge-rounded badge-info"
                     onClick={() => ViewLogs(item.sid)}
                >
                    View
                </button>
            ), 
              action: item.deleted == true ? ( 
                <button
                    className="badge badge-rounded badge-success"
                    // value="true" 
                    onClick={(e) => enableSID(item.id)} // Assuming handleSearchLimit function exists
                >
                    Enable
                </button>
            ) : null, // If item.deleted is false, don't render the button
            }));
    
             setApplications(finalRes);
            
          } else {
            setApplications([]);
          }
    } catch (error) {
        console.log(error)
    }
 
}

const ViewLogs = async(sid)=>{
  console.log(sid)
const response = await apiCallFnforGet(`${API_FOR_VIEW_LOGS}${sid}?sid=${sid}`);
console.log(response)

if(response.statusCode === 200){
  const finalRes = response?.data?.map((item, i)=>({
    id: item?.id,
    mid: item?.mid,
    company: item?.company,
    sid: item?.sid,
    vehicleName: item?.vehicleMaster?.vehicleName,
    maxTxnAmount: item?.maxTxnAmount,
    createDateTime: item?.createDateTime,
    updatedDateTime: item?.updatedDateTime,
    bankName: item?.vehicleMaster?.bank?.name,
    schemeName: item?.vehicleMaster?.scheme?.name,
    switchName: item?.vehicleMaster?.switch1?.name,
    masterMerchantName: item?.masterMerchant?.name,
    remark: item?.remark
  }))
  setLogsData(finalRes);
  setLogsModal(true);
}
else{
  setLogsData([])
}
}
console.log(logsData)
const enableSID = async (id) => {
  // const enable = e.target.value;

  swal({
    title: "Alert",
    text: `Are you sure, you want to Enable`,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDisable) => {
    if (willDisable) {

        apiCallFnforPut(`${ENABLE_SIDMASTER}${id}`)
        .then((response) => {
          if (response.statusCode === 200) {
            swal({
              title: "Success",
              text: `SID Master Enable Successfully.`,
              icon: "success",
            });
            FetchData(token);
          } else {
            swal({
              title: "Error",
              text: `Failed to Enable SID Master.`,
              icon: "error",
            });
          }
        })
        .catch((error) => {
          swal({
            title: "Error",
            text: error,
            icon: "error",
          });
          console.error("Error fetching data:", error);
        });
    } else {
    }
  });
};

// const enableSID = (id) => {
//   // const formData = new FormData();
//   // formData.append("id", id);

//   apiCallFnforPut(`${ENABLE_SIDMASTER}${id}`, null)
//     .then((response) => {
//       if (response.statusCode === 200) {
//         swal({
//           title: "Success!",
//           text: response.message,
//           icon: "success",
//         });
//         FetchData(token);
//       } else {
//         swal({
//           title: "Alert!",
//           text: response.message,
//           icon: "error",
//         });
//       }
//     })
//     .catch((error) => {
//       console.error("Error fetching data:", error);
//     });
// };

// const EnbDsb = async (id, action) => {
//   const EnableOrDisable = action === "enable" ? "enable" : "disable";
//   swal({
//     title: "Alert",
//     text:`Are you sure, you want to ${EnableOrDisable}`,
//     icon: "Warning",
//     buttons: "Okay",
//     dangerMode: true,
//   }).then((willDisable) => {
//     if (willDisable) {
//       const formData = new FormData();
//       formData.append("id", id);
//       apiCallFnforPut(
//         `${ENABLE_SIDMASTER}${id}`
//       ).then((res) => {
//         if (res.statusCode === 200) {
//           swal({
//             title: "Success!",
//             text: `SID Master ${EnableOrDisable} Successfully`,
//             icon: "success",
//           });
//           FetchData();
//         } else {
//           swal({
//             title: "Failed",
//             text: `Failed to ${EnableOrDisable}`,
//             icon: "Error",
//           });
//         }
//       });
//     }
//   });
// };

const handleAction = (item, action) => {
  switch (action) {
    case "enable":
      if (!item.active) {
        enableSID(item.id, "enable");
      }
      break;
    case "disable":
      if (item.active) {
        enableSID(item.id, "disable");
      }
      break;
    default:
      break;
  }
};


useEffect(()=>{
    FetchData();
},[])

//    COLUMN FOR DATAGRID

const columns = [
    {
      headerName: "Merchant ID",
      field: "mid",
      minWidth: 170,
    },
    {
      headerName: "Merchant Name",
      field: "company",
      minWidth: 260,
    },
    {
      headerName: "SID",
      field: "sid",
      minWidth: 170,
    },
    {
      headerName: "Merchant VPA",
      field: "merchantVpa",
      minWidth: 170,
    },
    {
      headerName: "Vehicle Master Name",
      field: "vehicleName",
      minWidth: 170,
    },
    {
      headerName: "Maximum Transaction Amount",
      field: "maxTxnAmount",
      minWidth: 190,
    },
    {
      headerName: "Create Date",
      field: "createDateTime",
      minWidth: 100,
    },
    // {
    //   headerName: "Update Time",
    //   // field: "updatedDateTime",
    //   minWidth: 100,
    // },
    {
      headerName: "View Logs",
      field: "viewLogs",
      minWidth: 100,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 100,
    },
    // {
    //   headerName: "Removed Date",
    //   field: "updateDate",
    //   minWidth: 150,
    // },
];


    
  return (
    // <div className="content-body">
    <div className="container-fluid">
    <div className="d-flex justify-content-start align-items-center">
        <div className='d-flex align-items-center'>
                    <button
                  type="button"
                  class="btn-close m-3"
                  onClick={toggleRightBar}
                ></button>
              
                <div className="fs-2 text-dark fw-bold">SID Details</div>
                    </div>
        </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <div className="table-responsive">
                  <DataGrid
                    rows={applications}
                    columns={columns.map((column) => ({
                      ...column,
                      renderCell: (params) =>
                        column.field === "active" || column.field === "action"
                          ? params.value
                          : params.value,
                    }))}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SIDLogs logsData={logsData} setLogsModal={setLogsModal} logsModal={logsModal} />
    </div>
  // </div>
  )
}

export default SIDDetails