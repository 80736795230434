import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { USE_LIVE_WITHDRAW_ADVANCE_FILTER_API } from "../../utils/ApiServices";
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomTip from "../../common/CustomToolTip/CustomTip";

const LiveWithdrawlAdvanceFilter = ({
  setWithdrawlReportData,
  setIsAdvanceFilterOpen,
  setToggleFilterA,
  toggleFilterA,
  setShowLoader,
  setIsAdvance
}) => {
  const token = localStorage.getItem("token");
  const [searchOption, setSearchOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(true);
  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const handleModalClose = () => {
    setShow(false)
    setIsAdvanceFilterOpen(false);
  };

  const handleClick = (option) => {
    setSearchOption(option);
    setSearchValue("");
  };

  const submitHandler = () => {
    // setSearch(!search)

    USE_LIVE_WITHDRAW_ADVANCE_FILTER_API(
     true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      searchOption === "rrn" ? searchValue : "",
      "",
      "",
      token,
      setWithdrawlReportData,
      setShowLoader,
      setIsAdvance
    );

    handleModalClose();
  };

  const handelSearchData = (e) => {
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
    document.getElementById("searchBtn").disabled = false;
  };



  useEffect(() => {
    handleClick("transactionId");
  }, []);

  return (
    <div className="advanced-search-popup">
    

      <Modal show={show} centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>Withdraw Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setIsSearchValueError(false);
                  setSearchValueError("");
                }}
              >
                <option type="number" value="">
                  Select
                </option>
                <option type="number" value="rrn">
                  UTR Number
                </option>
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                className="advanced-input-box form-control"
                value={searchValue}
                onChange={handelSearchData}
                // onBlur={handelValidations}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder="Enter UTR Number"
              />
              {isSearchValueError ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer  className="d-flex justify-content-between">
          {/* <div className="d-flex justify-content-between"> */}
          <CustomTip size="18" placement="top" title="More Filter"
          >
            <button style={{border:"none", backgroundColor:"white"}} onClick={()=>setToggleFilterA(!toggleFilterA)}>
            <FilterListIcon fontSize="large"/>
            </button>
         
          </CustomTip>
         <div className="d-flex gap-2">
         <Button
            variant="outline-danger"
            size="sm"
            className="text-capitlize"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            id="searchBtn"
            variant="outline-primary"
            size="sm"
            className="text-capitlize"
            onClick={submitHandler}
          >
            <i class="bi bi-search mx-1"></i> Search
          </Button>
         </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LiveWithdrawlAdvanceFilter;
