import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { RELEASE_AMOUNT, SWIPELINC_API } from "../../utils/constant";
import useCheckPrivilages from "../../utils/checkPrivilages";
import useApiServices from "../../utils/useApiServices";
import { Modal } from "react-bootstrap";
import { DateTime } from "luxon";
// import InactiveServices from "../../components/InactiveServices";

function Release({ data }) {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [utrn, setUtrn] = useState("");
  const [txnDate, setTxnDate] = useState("");
  const [date, setDate] = useState("");
  const [formData, setFormData] = useState({
    mid: "",
  });

  const hasPrivilage = useCheckPrivilages(2694);

  const [changedAmount, setChangedAmount] = useState("");

  const [selectedReleaseOption, setSelectedReleaseOption] = useState("");
  const [releaseAmount, setReleaseAmount] = useState("");
  const [userEnteredAmount, setUserEnteredAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();

  useEffect(() => {
    setMerchantData(data);
  }, []);

  const fetchReleaseAmount = async () => {
    try {
      // setShowLoader(true);

      const payload = {
        amountFor: selectedReleaseOption === "hold" ? "hold" : "deposite",
        mid: formData.mid,
      };

      const response = await apiCallFnforPost(RELEASE_AMOUNT, payload);

      if (response.statusCode === 200) {
        const responseData = response.data[0];
        const releaseAmount =
          responseData.totalHold || responseData.allSecurityAmount;
        setReleaseAmount(releaseAmount);
      } else {
        console.error("Error fetching release amount:", response.data.message);
      }
      // setShowLoader(false);
    } catch (error) {
      console.error("Error fetching release amount:", error);
    }
  };

  useEffect(() => {
    if (selectedReleaseOption) {
      fetchReleaseAmount();
    }
  }, [selectedReleaseOption]);

  const handleSaveUserEnteredAmount = async () => {
    if (!selectedReleaseOption) {
      swal({
        title: "Error",
        text: "Please select a release option before saving.",
        icon: "error",
      });
      setReleaseAmount("");
      setUserEnteredAmount("");
      // setShowLoader(false);

      return;
    }

    try {
      const payload = {
        amountFor: selectedReleaseOption === "hold" ? "hold" : "deposite",
        mid: formData.mid,
      };

      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getdepositeAndHoldAmount`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        const responseData = response.data.data[0];
        const availableAmount =
          selectedReleaseOption === "hold"
            ? responseData.totalHold
            : responseData.allSecurityAmount;

        if (parseFloat(userEnteredAmount) > parseFloat(availableAmount)) {
          const alertTitle =
            selectedReleaseOption === "hold" ? "Hold Alert" : "Deposit Alert";
          swal({
            title: alertTitle,
            text: `Release amount must be Lesser than ${
              selectedReleaseOption === "hold" ? "hold" : "deposit"
            } amount.`,
            icon: "error",
          });
          setReleaseAmount("");
          setUserEnteredAmount("");
          return;
        }

        const savePayload = {
          amount: userEnteredAmount,
          mid: formData.mid,
          releaseFrom: selectedReleaseOption === "hold" ? "hold" : "deposite",
          txnDate: txnDate,
          utr: utrn,
        };

        const saveResponse = await axios.post(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/release/save`,
          savePayload,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        );

        if (saveResponse.data.statusCode === 200) {
          swal({
            title: "Success",
            text: "Amount saved successfully.",
            icon: "success",
          });
          setUserEnteredAmount("");
          setReleaseAmount("");
          setSelectedCompany("");
          setSelectedReleaseOption("");
          setFormData({
            email: "",
            mid: "",
            mobile: "",
            companyName: "",
          });
          setUtrn("");
          setTxnDate("");
          setShowModal(false);
        } else {
          console.error("Error saving the amount:", saveResponse);
          swal({
            title: "Failure",
            text: "Error Saving Amount.",
            icon: "error",
          });
        }
        // setShowLoader(false);
      } else {
        console.error("Error fetching release amount:", response);
        swal({
          title: "Failure",
          text: "Error Saving Amount.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error saving the amount:", error);
      setShowModal(false);
    }
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;

    if (selectedCompany === "") {
      setFormData({
        mid: "",
      });

      setSelectedCompany("");
      setSelectedReleaseOption("");
      setReleaseAmount("");
      setUserEnteredAmount("");

      return;
    }
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });

      setChangedAmount("");
      setReleaseAmount("");
      setSelectedReleaseOption("");
      setUserEnteredAmount("");
    }
  };
  const handleReleaseOptionChange = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "") {
      setSelectedReleaseOption("");
      setReleaseAmount("");
      return;
    } else {
      setSelectedReleaseOption(selectedOption);
    }
  };
  const handleUserEnteredAmountChange = (event) => {
    const value = event.target.value;

    if (/^(?!0\d)(\d*(\.\d*)?)$/.test(value)) {
      setUserEnteredAmount(value);
    }
  };
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;

    // Create a DateTime object from the input value
    const inputDateTime = DateTime?.fromISO(newDate, { zone: "UTC" });

    // Check if the input date is valid (i.e., it was successfully parsed)
    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    // Check if the input date is greater than today's date
    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setDate(inputDateTime.toISODate()); // Assuming you want to store the date as a string (ISO format)
      setTxnDate(inputDateTime.toISODate());
    }
  };
  return (
    <>
      {/* {hasPrivilage ? ( */}
      <div className="containerflag">
        {/* <h1 className="mt-3">Select</h1> */}
        <div className="row mt-4">
          <div className="col-sm-12 col-md-6 offset-md-3">
            <label className="d-flex  mb-1 text-dark fs-4 mt-2">
              {" "}
              Select a Merchant
            </label>
            <select
              id="companyDropdown"
              className="form-control "
              onChange={handleCompanyChange}
              value={selectedCompany}
            >
              <option value="">Select a Merchant</option>
              {merchantData?.map((merchant) => (
                <option
                  key={merchant.mid}
                  value={`${merchant.mid} :- ${merchant.merchantName}`}
                >
                  {merchant.mid} :- {merchant.merchantName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 col-md-6 offset-md-3">
            <div className="form-group">
              <label className="mb-1 text-dark fs-4">Merchant ID</label>
              <input
                type="email"
                className="form-control "
                id="email"
                placeholder="Merchant ID"
                value={formData.mid}
                readOnly
              />
            </div>

            <div className="form-group">
              <label className="mb-1 text-dark fs-4">Release From</label>
              <select
                className="form-control "
                id="depositOrHold"
                value={selectedReleaseOption}
                onChange={handleReleaseOptionChange}
              >
                <option value="">Select</option>
                <option value="hold">Hold</option>
                <option value="deposit">Deposit</option>
              </select>
            </div>
            <div className="form-group">
              <label className="mb-1 text-dark fs-4">Amount (in Rupees)</label>
              <div className="d-flex gap-4">
                <input
                  type="text"
                  className="form-control "
                  id="releaseAmount"
                  placeholder="Release Amount"
                  value={releaseAmount}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mb-1 text-dark fs-4">
                Enter Amount (in Rupees)
              </label>
              <div className="d-flex gap-4">
                <input
                  type="text"
                  className="form-control "
                  id="userEnteredAmount"
                  placeholder="Enter Amount"
                  value={userEnteredAmount}
                  onChange={handleUserEnteredAmountChange}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="badge badge-rounded badge-primary mx-auto px-3 py-3 mt-4"
                onClick={() => setShowModal(!showModal)}
              >
                Submit
              </button>

              {/* <button
                  className="badge badge-rounded badge-primary mx-auto px-3 py-3 mt-4"
                  onClick={handleSaveUserEnteredAmount}
                >
                  Submit
                </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <InactiveServices location={"privilages"} />
      )} */}
      {showModal ? (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="">
          <Modal.Header closeButton>
            <Modal.Title className="fs-4 fw-bold">
              Fill these datails
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="d-flex  mb-1 text-dark fs-4 mt-2">
                UTR Number *
              </label>
              <input
                className="form-control "
                type="text"
                placeholder="Enter UTR Number"
                value={utrn}
                onChange={(e) => setUtrn(e.target.value)}
              />
              <label className="d-flex  mb-1 text-dark fs-4 mt-2">
                Transaction Date *
              </label>
              <input
                className="form-control "
                type="date"
                placeholder="Enter Transaction Date"
                value={txnDate}
                onChange={handleDateInputChange}
              />
            </div>
            <button
              className="badge badge-rounded badge-primary mx-auto px-3 py-3 mt-4"
              onClick={handleSaveUserEnteredAmount}
            >
              Submit
            </button>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}

export default Release;
