import React, { useEffect, useState } from "react";
// import ChargeBackFile from "../assets/templateFiles/ChargeBack.csv";
import ChargeBackFile from "../../../Assets/templateFiles/ChargeBack.csv";
import { Container, Card, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
// import useCheckPrivilages from "../utils/checkPrivilages";
import { CHARGEBACK_UPLOAD } from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";

const UploadChargeback = ({ toggleRightBar, type1 }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const { apiCallFnforPost } = useApiServices();

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setFailureMessage("Please select a file to upload.");
      setShowMessage(true);

      return;
    }
    let formdata = new FormData();
    formdata.append("file", selectedFile);
    apiCallFnforPost(CHARGEBACK_UPLOAD, formdata)
      .then((response) => {
        console.log(response);
        if (response.statusCode === 200) {
          swal({
            title: "Success",
            text: `${response.size} File Uploaded Successfully.`,
            icon: "success",
          });
        } else if (response.statusCode === 1001) {
          const errorData = response.data;
          const errorDublicateData = response.duplicateData;

          let errorMessage = " \n  File uploaded partially as:\n";
          let errorDublicateMessage = " \n  Data \n";
          let SuccessMsg = `${response.size} files uploaded Successfully. \n`;

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext =
            SuccessMsg + "\n" + errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.statusCode === 422) {
          const errorData = response.data;
          const errorDublicateData = response.duplicateData;

          let errorMessage = "File not uploaded as:\n";
          let errorDublicateMessage = " \n  Data \n";

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext = errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.statusCode === 600) {
          const errorMessage =
            response.message || "Error occurred while reading the file.";
          setFailureMessage(errorMessage);
          swal({
            title: `${response.status}`,
            text: errorMessage,
            icon: "error",
          });
        } else if (response.statusCode === 500) {
          swal({
            title: "Failed",
            text: "Please Upload CSV File",
            icon: "error",
          });
        } else if (response.statusCode === 101) {
          swal({
            title: "Failed",
            text: "File Not Uploaded Because Data Is Missing or InCorrect",
            icon: "error",
          });
        } else {
          setFailureMessage("File Not Uploaded, Please Upload Valid File.");
        }
        setShowMessage(true);
        // setShowLoader(false);
        document.getElementById("fileUpload").value = "";
        formdata = null;
        setSelectedFile(null);
      })

      .catch((error) => {
        setSuccessMessage("");
        setFailureMessage("File upload failed.");
        setShowMessage(true);
        // setShowLoader(false);
        console.error(error);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between bg-white ">
          <div className="d-flex justify-content-start align-items-center ">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Upload Chargeback</div>
          </div>
        </div>
        <div className="subtab-sec-admin">
          <Container>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="">
                  <Card.Body className=" d-flex flex-column align-items-center">
                    <div>
                      {/* <label className="mb-1 text-dark fs-4">
                        Chargeback Report
                        </label> */}

                      <div className="d-flex mb-3 mt-3">
                        <a
                          className="btn btn-success btn-sm"
                          title="Click here to download Chargeback file template"
                          href={ChargeBackFile}
                          download
                        >
                          <label className="mb-1 text-white fs-5">
                            Download Template
                          </label>
                        </a>
                      </div>
                      <div className="d-flex mb-2 mt-3">
                        <div className="excel-uploader">
                          {showUploadButton && (
                            <div className="form-group upload-field">
                              <div className="input-upload-file">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={handleFileUpload}
                                  id="fileUpload"
                                />
                              </div>
                              <div className="d-flex mt-3">
                                <button
                                  className="badge badge-rounded badge-primary mx-2 px-2 py-2"
                                  onClick={handleUpload}
                                >
                                  <label className="mb-1 text-white fs-5">
                                    Upload
                                  </label>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default UploadChargeback;
