import React, { useState, useEffect, useCallback } from "react";
import { Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { getAllDailyTransactionDataChargeback } from "../../utils/ApiServices";
import { DataGrid, 
  GridToolbar, 
  GridToolbarQuickFilter,
  GridToolbarColumnsButton, 
  GridToolbarContainer, 
  GridToolbarDensitySelector, 
  GridToolbarExport, 
  GridToolbarFilterButton } from "@mui/x-data-grid";
import FloatingReports from "../../common/FlotingReports";
import useApiServices from "../../utils/useApiServices";
import Pagination from "@mui/material/Pagination";
import { GET_REPORT_API } from "../../utils/constant";
import MerchantIcon from "../../common/MerchantIcon";
import IconWrapper from "../../common/IconWrapper/IconWrapper";
import { Box } from "@mui/material";
import swal from "sweetalert";
import Shimmer from "../../common/Shimmer/Shimmer";
import Filters from "./Filters";
import { paginationCountCalc } from "../../utils/helper";
import NewDowloadCSV from "./NewDownloadCSV";
import ViewVendor from "./ViewVendor";
import DataNotFound from "../../common/DataNotFound/DataNotFound";

const offcanvasStyle = {
  width: "90vw", // Set width to 70vw
};

const Release = ({ dropDownData, reportType, type, toggleRightBar }) => {

  const { apiCallFnforPostForQueryParams } = useApiServices();
  const [data, setData] = useState([]);
  const [dataM, setDataM] = useState([]);

  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [show2, setShow2] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [10, 20, 30, 40, 50];
  const [reset, setReset] = useState(false);
  const [condition, setCondition] = useState("1 day");
  const [mid, setMid] = useState("");
  const navigate = useNavigate();
  const [changeCount, setChangeCount] = useState(0);
  const [zoomCount, setZoomCount] = useState(16);
  const [successAmout, setSuccessAmount] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [vendorId, setVenorId] = useState();
  const [size, setSize] = useState(100);
  const [pageM, setPageM] = useState(1);
  const [sizeM, setSizeM] = useState(100);
  const [txnSize,setTxnSize] = useState(0);
  const [txnSizeM,setTxnSizeM] = useState(0);
  const [show, setShow] = useState(false);
  const [sizeOfTransactions, setSizeOfTransactions] = useState(0);
  const [isAdvance, setIsAdvance] = useState(false);
  const [venorData, setVenorData] = useState(null);

  const getDailyTransactionData = async () => {
    // alert("inside")
     setShowLoader(true)
    await apiCallFnforPostForQueryParams(GET_REPORT_API, {
      mid: mid,
      reportType: "release",
      timeFrame: condition,
    },
      {
        page: page-1,
        size,
      }
    )
      .then((response) => {
        setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
          setTxnSize( paginationCountCalc(response?.duplicateData?.txnCount || response?.duplicateData?.totaltxn));
          setSizeOfTransactions(response?.duplicateData?.txnCount);
        if (response.statusCode === 200) {
          setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
          setTxnSize( paginationCountCalc(response?.duplicateData?.txnCount || response?.duplicateData?.totaltxn));
          setSizeOfTransactions(response?.duplicateData?.txnCount);
          const finalRes = response?.data?.map((item, i) => ({
            id: i,
            "Date":item?.txnDate?.split(" ")[0],
            "createDate":item?.createDateTime?.split(" ")[0],
             ...item,
          }));
          setData(finalRes);
          setShowLoader(false)
        } else {
          setData([]);
          setShowLoader(false)
          setSuccessAmount("");
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };

  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData();
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      // Reset to first page when changing entries per page
      setCurrentPage(0);
      getDailyTransactionData();
    }
  };
console.log(txnSizeM);

const handleViewdetails = async (data) => {
  await setVenorData(data)

  setIsModalOpen(true)
};

const columns = [
    { headerName: "Merchant Id", field: "mid", minWidth: "200" },
    { headerName: "Company Name", field: "companyName", minWidth: "200" },
    { headerName: "Date", field: "createDate", minWidth: "200" },
    { headerName: "Amount", field: "amount", minWidth: "200" },
    { headerName: "Transaction Date", field: "Date", minWidth: "200" },
    { headerName: "UTR Number", field: "rrn", minWidth: "120" },
    { headerName: "Release From", field: "releaseFrom", minWidth: "120" },
   
    
  ];

  const handleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    setShow2(!show2);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
  };

  const handleAdvanceFilter = () => {
    setShowAdvanceFilter(!showAdvanceFilter);
  };
  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleReset = () => {
    setReset(!reset);
    setMid("");
    setCondition("1 day");
    setPage(1);
    setPageM(1);
    setDataM([]);
    // getDailyTransactionData();
    setIsAdvance(false);
  };


  const debouncedMiscellenious =useCallback((
    debounce(()=>{
      getDailyTransactionData();
    },0)
  ), [reset, currentPage, entriesPerPage, condition,page, mid]
  )

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);

  useEffect(() => {
    setPage(1);
  }, [condition, mid]);


  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };

  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };

 
  const handleClose = () => setShow(false);

  function CustomToolbar() {
   
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        {sizeOfTransactions > 100 ? (
          <div
            onClick={()=> setShow(true)}
            style={{ color: "#F6FAFD" }}
            className="d-flex align-items-center gap-2"
          >
            {" "}
            <span
              style={{ color: "#3C76D2", fontWeight: "600" }}
              className="mb-1"
            >
              {/* <i class="bi bi-download"></i> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-download fw-bold"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
              </svg>
            </span>
            <span style={{ color: "#3C76D2", fontWeight: "400" }}>EXPORT </span>
          </div>
        ) : (
          <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
        )}
        {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span>
            <div className="d-flex justify-content-start"><GridToolbarQuickFilter /></div>
          </div>
        </div>
      </GridToolbarContainer>
    );

    
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeM = (event, value) => {
    setPageM(value);
  };

  return (
    <div className="container-fluid">
    <ViewVendor isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} vendorId={venorData?.vendorId} mid={venorData?.mid} companyName={venorData?.companyName}/>
      <MerchantIcon setMid={setMid}  reset={reset}/>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">

                <section className="lincpay-dashboard-sec">
                  {/* <div className="d-flex justify-content-end"> */}
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className='d-flex align-items-center'>
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>
                    <div
                      className="col-7 mb-4 mb-sm-0"
                      style={{ textAlign: "right" }}
                    >
                      <IconWrapper title={"Zoomin"}>
                        <span class="" onClick={incrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"Zoomout"}>
                        <span class="" onClick={decrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"reset"}>
                        <i className="fa fa-refresh" onClick={HandleReset}></i>

                      </IconWrapper>
                      <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleAdvanceFilter}></i>
                      </IconWrapper>
                      {/* <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleMultipleFilter}></i>
                      </IconWrapper> */}
                    </div>
                  </div>

                  <div className="dasboard-view-count-boxes">
                    <Card.Body>
                      <div className="report-grid-view">
                        <div className="material-table-records mb-3" style={{height:"500px"}}>
                          {showLoader ? (
                            <Shimmer type="table" />
                          ): (isAdvance && dataM.length === 0) ? (
                            <div className="no-data-found">No data found for the selected filters.</div>
                          )
                          : (!isAdvance && data.length === 0) ? (
                            // <div className="no-data-found">No data found.</div>
                            <DataNotFound />
                          )
                          :  !dataM?.length ?  (
                            <>
                              <DataGrid
                                rows={data}
                                columns={columns}

                                slots={{
                                  toolbar: CustomToolbar
                                }}
                                // className="mb-1 text-dark fw-bold"
                                hideFooterPagination={true}
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  }, 
                                }}
                              />
                              <div style={{ display: "flex", justifyContent: "end", margin: "0.8rem" }}>

                                <Pagination
                                  count={txnSize}
                                  page={page}
                                  onChange={handleChange}
                                />
                              </div>
                            </>

                          ):  (
                            <>
                              <DataGrid
                                rows={dataM}
                                columns={columns}

                                slots={{
                                  toolbar: CustomToolbar
                                }}
                                // className="mb-1 text-dark fw-bold"
                                hideFooterPagination={true}
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  }, 
                                }}
                              />
                              <div style={{ display: "flex", justifyContent: "end", margin: "0.8rem" }}>

                                <Pagination
                                  count={txnSizeM}
                                  page={pageM}
                                  onChange={handleChangeM}
                                />
                              </div>
                            </>

                          ) }
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        
                        </div>
                      </div>
                    </Card.Body>
                   
                      {showAdvanceFilter ? <Filters
                       setReconReportData={setDataM}
                        setIsAdvanceFilterOpen={setShowAdvanceFilter}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        dropDownData={dropDownData}
                        type={type}
                        mid={mid}
                        setSizeM={setSizeM}
                        sizeM={sizeM}
                        pageM={pageM}
                        setShowLoader={setShowLoader}
                        setSuccessAmount={setSuccessAmount}
                        setTxnSizeM={setTxnSizeM}
                        setIsAdvance={setIsAdvance}
                      />
                    :
                     null }
                
                  </div>
                
                </section>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingReports
        type="reports"
        setCondition={setCondition}
        mid={mid}
        // reportType={type}
        setData={setData}
        reset={reset}
      />


      {/* Download CSv */}
      {show === true ? <NewDowloadCSV type="Chargeback" show={show} setShow={setShow} /> : null}

    </div>
  );
};

export default Release;
