import React, { useState, useEffect } from "react";
import { Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import DropDowns from "./DropDowns";
import ShowField from "./ShowField";
import useApiServices from "../../utils/useApiServices";
import MiniLoader from "../../common/MiniLoader/MiniLoader";
import {
  GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER,
  VEHICLE_MASTER_PHASE_2_SAVE,
} from "../../utils/constant";
import swal from "sweetalert";

const Phase2Form = ({ onHide, show, refresh }) => {
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [selectedType, setSelectedType] = useState({
    vehicleId: Number,
  });
  const [showLoader, setShowLoader] = useState(true);
  const [paymentSubType, setPaymentSubType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
  const [MerchantMDRData, setMerchantMDRData] = useState([]);
  const [SchemeMDRData, setMDRDataForScheme] = useState([]);
  const [AggregatorMDRData, setMDRDataForAggreGator] = useState([]);
  const [SwitchMDRData, setMDRDataForSwitch] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    aggregatorName: "",
    bankName: "",
    schemeName: "",
    switchName: "",
    masterMerchantName: "",
    remark: "",
  });
  const [vehicleData, setVehicleData] = useState([]);

  const offcanvasStyle = {
    width: "100vw", // Set width to 70vw
  };

  useEffect(() => {
    GetVehicalMasterData();
  }, [selectedType]);

  const GetVehicalMasterData = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER
      );

      if (response.statusCode === 200) {
        const transformedData = response?.data?.map((item) => {
          return {
            ...item,
            Id: item.id,
            type: item.vehicleName,
          };
        });
        setVehicleData(transformedData);
        setShowLoader(false);
      } else {
        setVehicleData([]);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    GetVehicalMasterData()
  },[refresh])

  const handleTypeChange = (event) => {
    const selectedVehicleValue = event.target.value;

    setSelectedType((prevSelectedType) => ({
      ...prevSelectedType,
      vehicleId: selectedVehicleValue,
    }));

    const selectedVehicle = vehicleData.find(
      (vehicle) => vehicle.Id === parseInt(selectedVehicleValue)
    );

    if (selectedVehicle) {
      setFormData({
        aggregatorName: selectedVehicle.aggregatorName || "NA",
        bankName: selectedVehicle.bankName || "NA",
        schemeName: selectedVehicle.schemeName || "NA",
        switchName: selectedVehicle.switchName || "NA",
        masterMerchantName: selectedVehicle.masterMerchantName || "NA",
        remark: selectedVehicle.remark || "",
      });

      setPaymentSubType(selectedVehicle.paymentSubTypes);

      setMDRData(
        selectedVehicle?.paymentSubTypes?.map((service) => ({
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
        }))
      );
      setMerchantMDRData(
        selectedVehicle?.paymentSubTypes?.map((service) => ({
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
        }))
      );
      setMDRDataForAggreGator(
        selectedVehicle?.paymentSubTypes?.map((service) => ({
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
        }))
      );
      setMDRDataForScheme(
        selectedVehicle?.paymentSubTypes?.map((service) => ({
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
        }))
      );
      setMDRDataForSwitch(
        selectedVehicle?.paymentSubTypes?.map((service) => ({
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
        }))
      );
    }
  };

  const handleMDRDataForBank = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    const foundItem = MDRData?.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    if (foundItem) {
      const updatedMDRData = MDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      setMDRData(updatedMDRData);
    }
  };

  const handleMDRDataForMerchant = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    const foundItem = MerchantMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    if (foundItem) {
      const updatedMDRData = MerchantMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      setMerchantMDRData(updatedMDRData);
    }
  };

  const handleMDRDataSwitch = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    const foundItem = SwitchMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    if (foundItem) {
      const updatedMDRData = SwitchMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      setMDRDataForSwitch(updatedMDRData);
    }
  };

  const handleMDRDataForAggregator = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    const foundItem = AggregatorMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    if (foundItem) {
      const updatedMDRData = AggregatorMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      setMDRDataForAggreGator(updatedMDRData);
    }
  };

  const handleMDRDataForScheme = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    const foundItem = SchemeMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    if (foundItem) {
      const updatedMDRData = SchemeMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      setMDRDataForScheme(updatedMDRData);
    }
  };

  const generatePayload = (Data, type) => {
    const result = Data?.map((item) => ({
      type: type,
      feeFix: item.fixAmount,
      vehicleMasterSubPaymentTypeServiceId:
        item?.vehicleMasterSubPaymentTypeServiceId,
      feePercentage: item.percentage,
    }));
    return result;
  };

  const resultForAggregator = generatePayload(AggregatorMDRData, "AGGREGATOR");
  const resultForScheme = generatePayload(SchemeMDRData, "SCHEME");
  const resultForBank = generatePayload(MDRData, "BANK");
  const resultForSwitch = generatePayload(SwitchMDRData, "SWITCH");
  const resultForMerchant = generatePayload(MerchantMDRData, "MASTER");

  const finalPayload = (formData) => {
    const payload = [];

    if (formData.aggregatorName !== "NA") {
      payload.push(...resultForAggregator);
    }

    if (formData.schemeName !== "NA") {
      payload.push(...resultForScheme);
    }

    if (formData.switchName !== "NA") {
      payload.push(...resultForSwitch);
    }

    if (formData.bankName !== "NA") {
      payload.push(...resultForBank);
    }

    if (formData.masterMerchantName !== "NA") {
      payload.push(...resultForMerchant);
    }

    return payload;
  };

  const handleSubmit = async () => {
    const payloadFinal = finalPayload(formData);

    const hasInvalidFixAmount = payloadFinal.some((item) => {
      const fixAmount = parseFloat(item.feeFix);
      return isNaN(fixAmount) || fixAmount < 0;
    });

    const hasInvalidPercentage = payloadFinal.some((item) => {
      const percentage = parseFloat(item.feePercentage);
      return isNaN(percentage) || percentage < 0 || percentage > 100;
    });

    if (hasInvalidFixAmount || hasInvalidPercentage) {
      swal({
        title: "Alert!",
        text: "Please fill positive values in Fix Fees and ensure Percentage values are in the range of 0 to 100.",
        icon: "error",
      });
      return;
    }

    try {
      const response = await apiCallFnforPost(
        VEHICLE_MASTER_PHASE_2_SAVE,
        payloadFinal
      );

      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
        });

        setFormData({
          aggregatorName: "NA",
          bankName: "NA",
          schemeName: "NA",
          switchName: "NA",
          masterMerchantName: "NA",
          remark: "",
        });

        setSelectedType({
          vehicleId: "",
        });
        onHide();
      } else {
        swal({
          title: "Failed",
          text: "Vehicle Master Form Not Saved",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearFormData = () => {
    setFormData({
      aggregatorName: "NA",
      bankName: "NA",
      schemeName: "NA",
      switchName: "NA",
      masterMerchantName: "NA",
      remark: "",
    });

    setSelectedType({
      vehicleId: "",
    });
  };


  

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => {
          clearFormData(); // Clear form data on hide
          onHide(); // Call onHide prop
        }}
        placement="end"
        style={offcanvasStyle}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            Create Vehicle Phase 2
          </h5>
        </div>
        <Offcanvas.Body>
          <div class="row">
            <div class="col-12">
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <DropDowns
                        fieldName={"Vehicle"}
                        Name={"vehicleId"}
                        HandleChange={handleTypeChange}
                        SelectedType={selectedType.vehicleId}
                        AllTypes={vehicleData}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <ShowField
                        fieldName={"Aggregator"}
                        name="aggregatorId"
                        value={formData.aggregatorName}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <ShowField
                        fieldName={"Scheme"}
                        name="schemeId"
                        value={formData.schemeName}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <ShowField
                        fieldName={"Master Merchant"}
                        name="masterMerchantId"
                        value={formData.masterMerchantName}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <ShowField
                        fieldName={"Bank"}
                        name="bankId"
                        value={formData.bankName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <ShowField
                        fieldName={"Remark"}
                        name="remark"
                        value={formData.remark}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="cmb-3">
                      <ShowField
                        fieldName={"Switch"}
                        name="switchId"
                        value={formData.switchName}
                      />
                    </div>
                  </div>
                </div>

                {formData.schemeName !== "NA" ? (
                  <div className=" merchant-report-view mt-4  px-5">
                    <Row className="d-flex flex-column align-items-center px-3">
                      {paymentSubType?.map((service) => (
                        <Col
                          className="w-100  mt-3"
                          xs={8}
                          lg={4}
                          md={3}
                          key={service.paymentSubTypeId}
                        >
                          <div className="form-group d-flex align-items-center justify-content-evenly  ">
                            <label className="w-25 form-label">
                              {`${formData.schemeName} (${service.type})`}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-25 px-2">
                              <lable>Intercharge Fee Fix</lable>
                              <input
                                type="number"
                                placeholder="Fixed Amount"
                                className="form-control"
                                value={
                                  SchemeMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? SchemeMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).fixAmount
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForScheme(
                                    service.paymentSubservice,
                                    "fixAmount",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                              />
                            </div>
                            <div className="w-25 px-2">
                              <lable>Intercharge Fee Percentage</lable>
                              <input
                                type="number"
                                placeholder="Percentage"
                                className="form-control  "
                                value={
                                  SchemeMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? SchemeMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).percentage
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForScheme(
                                    service.paymentSubservice,
                                    "percentage",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
              
                            <span id="regAddErr" className="text-danger d-none">
                              Please Enter Registered Address*
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}
                {formData.bankName !== "NA" ? (
                  <div className=" merchant-report-view mt-4  px-5">
                    <Row className="d-flex flex-column align-items-center px-3">
                      {paymentSubType?.map((service) => (
                        <Col
                          className="w-100   mt-3"
                          xs={8}
                          lg={4}
                          md={3}
                          key={service.paymentSubTypeId}
                        >
                          <div className="form-group d-flex align-items-center justify-content-evenly  ">
                            <label className="w-25 form-label">
                              {`${formData.bankName} (${service.type})`}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-25 px-2">
                              <lable>Nodal Fee Fix</lable>
                              <input
                                type="number"
                                placeholder="Fixed Amount"
                                className="form-control"
                                value={
                                  MDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? MDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).fixAmount
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForBank(
                                    service.paymentSubservice,
                                    "fixAmount",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="w-25 px-2">
                              <lable>Nodal Fee Percentage</lable>
                              <input
                                type="number"
                                placeholder="Percentage"
                                className="form-control  "
                                value={
                                  MDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? MDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).percentage
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForBank(
                                    service.paymentSubservice,
                                    "percentage",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                       
                            <span id="regAddErr" className="text-danger d-none">
                              Please Enter Registered Address*
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}
                {formData.switchName !== "NA" ? (
                  <div className=" merchant-report-view mt-4  px-5">
                    <Row className="d-flex flex-column align-items-center px-3">
                      {paymentSubType?.map((service) => (
                        <Col
                          className="w-100   mt-3"
                          xs={8}
                          lg={4}
                          md={3}
                          key={service.paymentSubTypeId}
                        >
                          <div className="form-group d-flex align-items-center justify-content-evenly  ">
                            <label className="w-25 form-label">
                              {`${formData.switchName} (${service.type})`}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-25 px-2">
                              <lable>Processing Fee Fix</lable>
                              <input
                                type="number"
                                placeholder="Fixed Amount"
                                className="form-control"
                                value={
                                  SwitchMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? SwitchMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).fixAmount
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataSwitch(
                                    service.paymentSubservice,
                                    "fixAmount",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="w-25 px-2">
                              <lable>Processing Fee Percentage</lable>
                              <input
                                type="number"
                                placeholder="Percentage"
                                className="form-control  "
                                value={
                                  SwitchMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? SwitchMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).percentage
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataSwitch(
                                    service.paymentSubservice,
                                    "percentage",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                            <span id="regAddErr" className="text-danger d-none">
                              Please Enter Registered Address*
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}

                {formData.aggregatorName !== "NA" ? (
                  <div className=" merchant-report-view mt-4  px-5">
                    <Row className="d-flex flex-column align-items-center px-3">
                      {paymentSubType?.map((service) => (
                        <Col
                          className="w-100   mt-3"
                          xs={8}
                          lg={4}
                          md={3}
                          key={service.paymentSubTypeId}
                        >
                          <div className="form-group d-flex align-items-center justify-content-evenly  ">
                            <label className="w-25 form-label">
                              {`${formData.aggregatorName} (${service.type})`}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-25 px-2">
                              <lable>Platform Fee Fix</lable>
                              <input
                                type="number"
                                placeholder="Fixed Amount"
                                className="form-control"
                                value={
                                  AggregatorMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? AggregatorMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).fixAmount
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForAggregator(
                                    service.paymentSubservice,
                                    "fixAmount",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="w-25 px-2">
                              <lable>Platform Fee Percentage</lable>
                              <input
                                type="number"
                                placeholder="Percentage"
                                className="form-control  "
                                value={
                                  AggregatorMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? AggregatorMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).percentage
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForAggregator(
                                    service.paymentSubservice,
                                    "percentage",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                         
                            <span id="regAddErr" className="text-danger d-none">
                              Please Enter Registered Address*
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}
                {formData.masterMerchantName !== "NA" ? (
                  <div className=" merchant-report-view mt-4  px-5">
                    <Row className="d-flex flex-column align-items-center px-3">
                      {paymentSubType?.map((service) => (
                        <Col
                          className="w-100  mt-3"
                          xs={8}
                          lg={4}
                          md={3}
                          key={service.paymentSubTypeId}
                        >
                          <div className="form-group d-flex align-items-center justify-content-evenly  ">
                            <label className="w-25 form-label">
                              {`${formData.masterMerchantName} (${service.type})`}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-25 px-2">
                              <lable>Merchant Fee Fix</lable>
                              <input
                                type="number"
                                placeholder="Fixed Amount"
                                className="form-control"
                                value={
                                  MerchantMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? MerchantMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).fixAmount
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForMerchant(
                                    service.paymentSubservice,
                                    "fixAmount",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="w-25 px-2">
                              <lable>Merchant Fee Percentage</lable>
                              <input
                                type="number"
                                placeholder="Percentage"
                                className="form-control  "
                                value={
                                  MerchantMDRData?.findIndex(
                                    (item) =>
                                      item.serviceId ===
                                      service.paymentSubTypeId
                                  ) !== -1
                                    ? MerchantMDRData?.find(
                                        (item) =>
                                          item.serviceId ===
                                          service.paymentSubTypeId
                                      ).percentage
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMDRDataForMerchant(
                                    service.paymentSubservice,
                                    "percentage",
                                    e.target.value,
                                    service.paymentSubTypeId
                                  )
                                }
                                required
                              />
                            </div>

                            <span id="regAddErr" className="text-danger d-none">
                              Please Enter Registered Address*
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}

                <div className="d-flex justify-content-end">
                  <button className="badge badge-rounded badge-primary mx-2 px-3 py-3" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Phase2Form;
