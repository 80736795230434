import React from "react";

const ShowField = ({ fieldName, name, value }) => {
  console.log(value);
  return (
    <>
      <label className="mb-1 text-dark fs-4">{fieldName}</label>

      <input
        name={name}
        id={name}
        className="form-control"
        readOnly
        value={value}
        // onChange={handleTypeChange}
      />
    </>
  );
};

export default ShowField;
