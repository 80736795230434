import React, { useState } from "react";
import {  Col, Card,  Container } from "react-bootstrap";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from "axios";
import { useEffect } from "react";
import { FIND_BY_ORDER_NO } from "../../utils/constant";
import swal from 'sweetalert';
import useApiServices from "../../utils/useApiServices";

function ViewTxnStatus({mid,orderNo, onCloseModal}) {
 
  const [searchValue, setSearchValue] = useState("");
  const [data1, setData1] = useState([]);
  const [error1, setError1] = useState(null);

  const [orderNumber, setOrderNumber] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const token = localStorage.getItem("token");
  const { apiCallFnforPost } = useApiServices();

  const getData = async (orderNo) => {
    const Payload = {
        mid: mid,
        orderNumber: orderNo,
        rrn: "",
      };
    try {
      // const payload = { mid };
      const response = await apiCallFnforPost( FIND_BY_ORDER_NO, Payload);
        // console.log("hieeeeeeee", response)
      if (response.statusCode === 200) {

        setData1(response?.data);
        console.log(response);
       
      }
        } catch (error) {
        setError1(true);
        console.error(error);
        swal({
          title: "Alert!",
          text: "Something Went Wrong.",
          icon: "error",
        });
        // setShowLoader(false);
        onCloseModal()
        setData1([]); 
      }
  };
 

  const handleSearch = (ordNo) => {
    getData(ordNo);
    // data2 = [data1];
  };

  useEffect(() => {
    setOrderNumber(orderNo);
  }, []);

  useEffect(() => {
    handleSearch(orderNo);
  }, [orderNo]);

  const column = [
    { headerName: "Customer Name", field: "customerName", sort: "asc", minWidth: "150",},
    { headerName: "Transaction ID", field: "extTransactionId", sort: "asc", minWidth: "150",},
    { headerName: "Status", field: "primaryStatus", sort: "asc", minWidth: "150", },
    { headerName: "Actual Status", field: "actualStatus", sort: "asc", minWidth: "150",},
    { headerName: "Transaction Time", field: "txnTime", sort: "asc", minWidth: "150",},
    { headerName: "Amount", field: "amount", sort: "asc", minWidth: "150",},
    { headerName: "UPI ID", field: "upiId", sort: "asc", minWidth: "150",},
    { headerName: " UTR No", field: "custRefNo", sort: "asc", minWidth: "150",},
    { headerName: "Remark", field: "remark", sort: "asc", minWidth: "150",},
  ];

  return (
   
    <>

<div className="">
      
    
        <div className="d-flex align-items-center mb-4">


        <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="mb-1 text-dark fs-4">Enter Order No<sup className="text-danger">*</sup></label>
              <input
                type="text"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                className="form-control"
                />
            </div>
          </div>


        
        </div>

        <Col xs={12} lg={8} md={8}>
            <div className="form-group text-lg-end">
            <button
                id="companyPanVerifyBtn"
                type="button"
                className="btn btn-primary"
                onClick={handleSearch}
            >
                <i className="bi bi-search mx-1"></i>Search
            </button>
            </div>
        </Col>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="col-12">
                  <div className="card-body">
                    <div className="table-responsive">
                    {data1.statusCode === 200 ? (

                        <DataGrid
                            rows={data1}
                            columns={column?.map((column) => ({
                                ...column,
                                renderCell: (params) =>
                                column.field === "active" || column.field === "action"
                                    ? params.value
                                    : params.value,
                            }))}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            slotProps={{
                            toolbar: {
                            showQuickFilter: true,
                            },
                        }}
                        />
                      ) : (
                        <div className="no-data-message fs-5 w-100 bg-primary text-white fw-semibold rounded-3 px-5 pl-5 p-2 d-flex align-items-center justify-content-center">
                          {" "}
                          No data available.{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
       
      </div>


















      
    </>
  );
}
export default ViewTxnStatus;