import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../../utils/useApiServices";
import { SUBMITTED_VENDOR } from "../../../utils/constant";
import ViewVendor from "./ViewVendor";

function PendingVendorRequest({ toggleRightBar, type1 }) {
  const [vendor, setSubmittedVendor] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [id, setId] = useState("");
  const { apiCallFnforGet } = useApiServices();

  useEffect(() => {
    apiCallFnforGet(SUBMITTED_VENDOR, {
      params: {
        vendorStatus: "Submitted",
      },
    })
      .then((response) => {
        if (response?.statusCode === 200) {
          const FinalResp = response.data.map((item, i) => ({
            id: i,
            fullName: item.fullName,
            email: item.email,
            category: item.category,
            mobile: item.mobile,
            companyName: item.companyName,
            parentMid: item.parentMid,

            action: (
              <div>
                <button
                  className="badge badge-rounded badge-warning "
                  onClick={() => View(item.vendorId)}
                >
                  View
                </button>
              </div>
            ),
          }));
          setSubmittedVendor(FinalResp);
        } else {
          setSubmittedVendor([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor:", error.message);
      });
  }, [openView]);

  const View = (vendorId) => {
    setId(vendorId);
    setOpenView(true);
  };

  const columns = [
    
    { headerName: "Vendor Name", field: "fullName", minWidth: 150 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 200 },
    { headerName: "e-Mail", field: "email", minWidth: 200 },
    { headerName: "Category", field: "category", minWidth: 150 },
    { headerName: "Mobile", field: "mobile", minWidth: 200 },
    { headerName: "Merchant ID", field: "parentMid", minWidth: 200 },
    { headerName: "Action", field: "action", minWidth: 200 },
  ];

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-start align-items-center ">
          <button
            type="button"
            class="btn-close m-3"
            onClick={toggleRightBar}
          ></button>

          <div className="fs-2 text-dark fw-bold">Pending Vendor Request</div>
        </div>
        {/* <Container> */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    {!openView ? (
                      <DataGrid
                        rows={vendor}
                        columns={columns?.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    ) : (
                      <ViewVendor id={id} setOpenView={setOpenView} />
                    )}
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Container> */}
      </div>
      {/* </div>  */}
    </>
  );
}

export default PendingVendorRequest;
