import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  APPROVE_REJECT_PAYOUT,
  FETCH_PAYOUT_DETAILS,
  SWIPELINC_API,
} from "../../../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
// import "../../../stylesheet/ViewDetails.css";

import {
  HandleApprovePayout,
  fetchPayoutDetail,
} from "../../../utils/ApiServices";
import useApiServices from "../../../utils/useApiServices";

function ViewDetails({ id, setOpenView, onHide }) {
  const [payoutDetails, setpayoutDetails] = useState([]);
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();
console.log("here is id--->",id)
  useEffect(() => {
    fetchPayoutDetails();
  }, []);

  const fetchPayoutDetails = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_PAYOUT_DETAILS + id);

      if (response?.statusCode === 200) {
        setpayoutDetails(response?.data);
      } else {
        console.error("Api response error:", response.data.data);
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
  };

  // HANDLE APPROVE:

  const handleApproveConfirm = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this application?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          payoutStatus: "Approved",
          id: id,
          remark: "NA",
          requestId: payoutDetails.requestId,
        };

        const response = apiCallFnforPut(APPROVE_REJECT_PAYOUT, payload)
          .then((response) => {
            console.log(response);
            if (response?.statusCode === 200) {
              swal("Success", "Application approved!", "success");
              onHide();
              setOpenView(false);
            } else if (response?.statusCode === 306) {
              swal("Failed", `${response?.data}`, "error");
            } else {
              console.error("Error fetching data:", response.data.message);
              onHide();
            }
          })
          .catch((error) => {
            console.error("Error approving the application:", error.message);
            onHide();
          });
      } else {
      }
    });
  };

  // HANDLE REJECT:

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const payload = {
          payoutStatus: "Rejected",
          id: id,
          remark: remark,
          requestId: payoutDetails.requestId,
        };

        const response = apiCallFnforPut(APPROVE_REJECT_PAYOUT, payload)
          .then((response) => {
            if (response?.statusCode === 200) {
              swal("Success", "Application Rejected!", "success");
      
              onHide();
            } else {
              console.error("Error fetching data:", response.data.message);
              onHide();
            }
          })
          .catch((error) => {
            console.error("Error rejecting the application:", error.message);
            onHide();
          });
      } else {
      }
    });
  };

  const handleBack = () => {
    setOpenView(false);
  };

  return (
    <>
      <div className=" p-4 ">
        <div className="table table-bordered">
          <div className="row">
            {[
              { label: "ID", value: payoutDetails?.id },
              { label: "Vendor Name", value: payoutDetails?.fullName },
              { label: " Merchant Name", value: payoutDetails?.companyName },
              { label: "E-mail", value: payoutDetails?.email },
              { label: "Category", value: payoutDetails?.category },
              { label: "Mobile", value: payoutDetails?.mobile },
              { label: "Bank Name", value: payoutDetails?.bankName },
              { label: "Account Number", value: payoutDetails?.accountNumber },
              { label: "IFSC-Code", value: payoutDetails?.ifscCode },
              { label: "Branch Name", value: payoutDetails?.branchName },
              { label: "UPI", value: payoutDetails?.upi },
              { label: "Amount", value: payoutDetails?.amount },
              { label: "Merchant ID", value: payoutDetails?.mid },
              { label: "Remark", value: payoutDetails?.remark },
              { label: "Payout Status", value: payoutDetails?.payoutStatus },
              { label: "Request Id", value: payoutDetails?.requestId },
            ].map((item, index) => (
              <div
                key={index}
                className="col-6 border border-dark border-bottom"
                style={{
                  borderRight: "0.5px solid black",
                  borderLeft: "0.5px solid black",
                  paddingBottom: "0px",
                }}
              >
                <div className="d-flex justify-content-between align-items-center table table-bordered">
                  <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                    {item.label} <RemoveIcon />
                  </tr>{" "}
                  <tr className="mt-2 w-100 text-end">{item.value}</tr>
                </div>
              </div>
            ))}

<div className="d-flex justify-content-center gap-2 mt-4">
            <div className="d-flex gap-2">
              <button
                class="badge badge-rounded badge-success py-2"
                onClick={() => handleApproveConfirm()}
              >
                Approve
                <span class="btn-icon-end">
                  <i class="fa fa-check"></i>
                </span>
              </button>
              <button
                class="badge badge-rounded badge-danger py-2"
                onClick={() => handleReject()}
              >
                Reject
                <span class="btn-icon-end">
                  <i class="fa fa-close"></i>
                </span>
              </button>
            </div>
          </div>
          </div>
      
        </div>
      </div>
    </>
  );
}
export default ViewDetails;

