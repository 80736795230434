import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomTip from "../../../common/CustomToolTip/CustomTip";
import {
  GET_BBPS_MASTER,
  DISABLE_BBPS_MASTER,
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";
import BBPSMasterForm from "./BBPSMasterForm";


const BBPSMaster = ({ type, toggleRightBar }) => {
 
  const [show, setShow] = useState(false);
  const[refresh,setRefresh]=useState(false)
  const [callAgain,setCallAgain] = useState();

//   useEffect(() => {
//     setActivePhase("Phase1");
//   }, []);

  const { apiCallFnforGet, apiCallFnforDelete } = useApiServices();
  const [applications, setApplications] = useState([]);
  
  const token = localStorage.getItem("token");

  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(GET_BBPS_MASTER);
      if (apiResponse.statusCode === 200) {
        const finalRes = apiResponse.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          tenantId: item.tenantId,
          agentIdMobile: item.agentIdMobile,
          agentIdInternate: item.agentIdInternate,
          xdgiledgeKey: item.xdgiledgeKey,
          action: (
            <div className="d-flex gap-2 align-items-center">
              <button
                className="badge badge-danger mt-2"
                onClick={() => {
                    disablebbps(item.id);
                }}
              >
                Disable
              </button>
            </div>
          ),
          
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disablebbps = (id) => {
    swal({
      title: "Alert",
      text: "Are you sure, you want to disable the BBPS Master",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((remark) => {
        if (remark) {
          const payload = {
            status: "failed",
            remark: remark,
            id: id,
          };

          apiCallFnforDelete(`${DISABLE_BBPS_MASTER}/${id}`, "")
          .then((res) => {
            if (res.statusCode === 200) {

              swal({
                title: "Success",
                text: `BBPS Master disabled Successfully.`,
                icon: "success",
              });
              FetchData();
              // setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Failed",
                text: `Failed to disable BBPS Master.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
      }
    });
  };

  applications.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <sapan>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </sapan>
    );
  });

  useEffect(() => {
    FetchData();
  }, [show,refresh]);


  const columns = [
    {
      headerName: "Merchant ID",
      field: "mid",
      minWidth: 170,
    },
    {
      headerName: "Tenant ID",
      field: "tenantId",
      minWidth: 170,
    },
    {
      headerName: "AgentId Mobile",
      field: "agentIdMobile",
      minWidth: 170,
    },
    {
      headerName: "AgentId Web",
      field: "agentIdInternate",
      minWidth: 170,
    },
    {
      headerName: "X-Digiledge - Key",
      field: "xdgiledgeKey",
      minWidth: 170,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 110,
    },
  ];
  const handleClose = () => setShow(false);

  const handleOnClick = () => {
    setShow(true);
  };

  return (
    <div className="">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">BBPS Master</div>
          </div>

          <CustomTip size="18" placement="top" title="BBPS Master Form">
            <div className=" me-4" onClick={handleOnClick}>
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </CustomTip>

        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={applications}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BBPSMasterForm show={show} onHide={handleClose} setRefresh={setRefresh} refresh={refresh}/>
    </div>
  );
};

export default BBPSMaster;
