import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import styles from "./report.module.css";

import { GET_ALL_CREDIT, GET_ALL_DEBIT } from "../../utils/constant.js";
import Shimmer from "../../common/Shimmer/Shimmer.jsx";
import useApiServices from "../../utils/useApiServices.js";
import IconWrapper from "../../common/IconWrapper/IconWrapper.jsx";
import MerchantIcon from "../../common/MerchantIcon.jsx";

/**
 * Release component renders Report page.
 * @description it takes the type then call api accordingbto the type and render the data into the table.
 * @returns {ReactNode} A React element that renders Report page.
 */
function CreditBalance({ type,toggleRightBar }) {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [mid, setMid] = useState("");
  const [active, setActive] = useState("");
  const [count, setCount] = useState(14);
  const [vendorId, setVendorId] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { apiCallFnforGet, apiCallFnforGetForQueryParams } = useApiServices();

  const columnforCreditBalanceDebit = [
    { headerName: "Merchant Id", field: "mid", minWidth: "200" },
    { headerName: "Company Name", field: "fullName", minWidth: "200" },
    {
      headerName: "Date",
      field: "createDate",
      minWidth: 150,
    },
    {
      headerName: "Time",
      field: "createTime",
      minWidth: 150,
    },
    {
      headerName: "Amount",
      field: "orignalAmount",
      minWidth: 150,
    },
    {
      headerName: "Remark",
      field: "txnType",
      minWidth: 250,
    },
  ];

  const columnforCreditBalance = [
    { headerName: "Merchant Id", field: "mid", minWidth: "200" },
    { headerName: "Company Name", field: "fullName", minWidth: "200" },
    {
      headerName: "Date",
      field: "createDate",
      minWidth: 150,
    },
    {
      headerName: "Time",
      field: "createTime",
      minWidth: 150,
    },
    {
      headerName: "Amount",
      field: "orignalAmount",
      minWidth: 150,
    },
    {
      headerName: "Service Charge",
      field: "deductedAmount",
      minWidth: 150,
    },
    {
      headerName: "Charge In Percentage",
      field: "chargeInPercentage",
      minWidth: 150,
    },
    {
      headerName: "Charges For Service",
      field: "txnType",
      minWidth: 150,
    },
  ];

  const Columns =
    activeButton === 1 ? columnforCreditBalance : columnforCreditBalanceDebit;

  useEffect(() => {
    if (type === "CreditBalance") {
      setActiveButton(1);
      setActive("Customer");
    }
  }, [type]);

  useEffect(() => {
    fetchCreditReport();
  }, [activeButton, type, mid]);

  const fetchCreditReport = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforGetForQueryParams(
        `${activeButton === 1 ? GET_ALL_CREDIT : GET_ALL_DEBIT}`,
        { mid }
      );

      if (response?.statusCode === 200) {
        console.log("data", response.data);
        const finalres = response?.data?.map((item, i) => ({
          id: i,
          ...item,
          chargeInPercentage: item?.chargeInPercentage,
          deductedAmount: item?.deductedAmount,
          orignalAmount: item?.orignalAmount,
          serviceCharge: item?.serviceCharge,
          serviceChargePercentage: item?.serviceChargePercentage,
        }));

        setData(finalres);
        setShowLoader(false);
      } else {
        setData([]);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error?.message);
      setShowLoader(false);
    }
  };

  const incrementZoom = () => {
    {
      count < 28 && setCount(count + 1);
    }
  };

  const decrementZoom = () => {
    {
      count > 14 && setCount(count - 1);
    }
  };

  const handleToolsButtonClick = (type, buttonNumber) => {
    setActive(type);
    setActiveButton(buttonNumber);
  };

  return (
    <>
      <div className={"container-fluid"}>
        <div className="row">
          <div className="col-12">
            <div className="card">
                <MerchantIcon setMid={setMid} />
              {/* <div className="card-header  bg-white d-flex justify-content-between">
                <div className='d-flex align-items-center'>
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>

                <div className="col-7 mb-4 mb-sm-0 text-right">
                  <IconWrapper title={"Zoomin"}>
                    <span className="" onClick={incrementZoom}>
                      <i className="fa-solid fa-magnifying-glass-plus"></i>
                    </span>
                  </IconWrapper>
                  <IconWrapper title={"Zoomout"}>
                    <span className="" onClick={decrementZoom}>
                      <i className="fa-solid fa-magnifying-glass-minus"></i>
                    </span>
                  </IconWrapper>
                </div>
              </div> */}
               <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">Credit Balance</div>
                    </div>
                    <div
                      className="col-7 mb-4 mb-sm-0"
                      style={{ textAlign: "right" }}
                    >
                      <IconWrapper title={"Zoomin"}>
                        <span class="" onClick={incrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"Zoomout"}>
                        <span class="" onClick={decrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                        </span>
                      </IconWrapper>
                    </div>
                  </div>
              {type === "CreditBalance" ? (
                <div className="card-header  bg-white d-flex justify-content-between">
                  <ul className="d-flex gap-3 mb-2">
                    <li
                      className={activeButton === 1 ? "active" : ""}
                      onClick={() => handleToolsButtonClick("Customer", 1)}
                    >
                      <div
                        className={`btn ${
                          activeButton === 1
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        Credit Transactions
                      </div>
                    </li>

                    <li
                      className={activeButton === 2 ? "active" : ""}
                      onClick={() => handleToolsButtonClick("Vendor", 2)}
                    >
                      <div
                        className={`btn ${
                          activeButton === 2
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        Credit Added
                      </div>
                    </li>
                  </ul>
                </div>
              ) : null}

              <div>
                {showLoader ? (
                  <Shimmer type="table" />
                ) : data?.length > 0 ? (
                  <div
                    className={`card-body no-scrollbar ${styles["DataGridHeightClass"]}`}
                  >
                    <DataGrid
                      className="custom-scrollbar"
                      rows={data}
                      columns={Columns}
                      pagination
                      paginationMode="client"
                      page={page - 1}
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 20, 50]}
                      onPageChange={(newPage) => setPage(newPage + 1)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      hideFooterPagination={false}
                      sx={{
                        fontSize: `${count}px`,
                        "& .css-1rtad1": {
                          display: "block",
                        },
                      }}
                    />
                  </div>
                ) : (
                  <h1>NO DATA FOUND</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreditBalance;
