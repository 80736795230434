import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import {
  BBPS_ROUTING_GET,
  BBPS_ROUTING_DISABLE,
  BBPS_ROUTING_ENABLE,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import BBSPRoutingForm from "./BBPSRoutingForm";


const BBSPRouting = ({ type, toggleRightBar }) => {
 
  const [show, setShow] = useState(false);
  const[refresh,setRefresh]=useState(false);

  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();
  const [applications, setApplications] = useState([]);
  
  const token = localStorage.getItem("token");

  const FetchData = async () => {
    try {
      const response = await apiCallFnforGet(BBPS_ROUTING_GET);
      if (response) {
        console.log("this is bbps routing resp",response)
        const finalRes = response.map((item, i) => ({
          id: i,
          agentIdInternate: item.agentIdInternate,
          agentIdMobile: item.agentIdMobile,
          // "apikey": "string",
          createDateTime: item.createDateTime,
          createdBy: item.createdBy,
          // "deleted": true,
          // "encKey": "string",
          // id: item.id,
          isActive: item.isActive,
          mappedMid: item.mappedMid,
          mid: item.mid,
          tenantId: item.tenantId,
          updatedBy: item.updatedBy,
          updatedDateTime: item.updatedDateTime,
          xdgiledgeKey: item.xdgiledgeKey,
          isActive: (
            <div>
            <button
              className={`badge badge-rounded ${
                !item.isActive ? "badge-danger" : "badge-success"
              }`}
              value={item.isActive ? "enablebbps" : "disablebbps"}
              onClick={() => {
                if (item.isActive === true) {
                  disablebbps(item.id);
                } else {
                  enablebbps(item.id);
                }
              }}
            >
              {item.isActive ? "Enable" : "Disable"}
            </button>
          </div>
          ),
          
        }));
        
        setApplications(finalRes);
        console.log("result", finalRes)
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const disablebbps = async (id) => {
    swal({
      title: "Alert",
      text: "Are you sure, you want to Disable BBPS Routing",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
          apiCallFnforPut(`${BBPS_ROUTING_DISABLE}/${id}`, "")
          .then((res) => {
            if (res.statusCode === 200) {
              swal({
                title: "Success",
                text:` BBPS Routing disabled Successfully.`,
                icon: "success",
              });
              FetchData();
              // setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Success",
                text: `Failed to disable BBPS Routing.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
      }
    });
  };

  const enablebbps = async (id) => {
    swal({
      title: "Alert",
      text: "Are you sure, you want to Enable BBPS Routing",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
          apiCallFnforPut(`${BBPS_ROUTING_ENABLE}/${id}`, "")
          .then((res) => {
            if (res.statusCode === 200) {
              swal({
                title: "Success",
                text: `BBPS Routing enabled Successfully.`,
                icon: "success",
              });
              FetchData();
              // setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Success",
                text: `Failed to ebabled BBPS Routing.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
      }
    });
  };

  useEffect(() => {
    FetchData();
  }, [show,refresh]);


  const columns = [
    {
      headerName: "Merchant ID",
      field: "mid",
      minWidth: 170,
    },
    {
      headerName: "Tenant ID",
      field: "tenantId",
      minWidth: 150,
    },
    {
      headerName: "Mapped ID",
      field: "mappedMid",
      minWidth: 150,
    },
    {
      headerName: "AgentId Mobile",
      field: "agentIdMobile",
      minWidth: 170,
    },
    {
      headerName: "AgentId Web",
      field: "agentIdInternate",
      minWidth: 170,
    },
    {
      headerName: "X-Digiledge - Key",
      field: "xdgiledgeKey",
      minWidth: 200,
    },
    {
      headerName: "Action",
      field: "isActive",
      minWidth: 150,
    },
  ];
  const handleClose = () => setShow(false);

  const handleOnClick = () => {
    setShow(true);
  };

  return (
    <div className="">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">BBPS Routing</div>
          </div>

          <CustomTip size="18" placement="top" title="BBPS Routing Form">
            <div className=" me-4" onClick={handleOnClick}>
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </CustomTip>

        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                  <DataGrid
                        rows={applications}
                        columns={columns.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BBSPRoutingForm show={show} onHide={handleClose} setRefresh={setRefresh} refresh={refresh}/>
    </div>
  );
};

export default BBSPRouting;