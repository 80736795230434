import React, { useEffect, useState } from "react";
import { Button, Card, Col, Offcanvas, Row } from "react-bootstrap";
import {
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
  GET_USER_MANAGEMENT_DATA_BY_ID,
} from "../../../utils/constant";
import {
  getAllFunctionForApiCall,
  updateUserData,
} from "../../../utils/ApiServices";
import swal from "sweetalert";
import { getTokenFromLocalStorage } from "../../../utils/tokenService";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { isEmailIdRegexIsValid, getKeyPressed } from "../../../utils/helper";
import useApiServices from "../../../utils/useApiServices";

const EditUser = ({ UserId, show, onHide }) => {
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const token = getTokenFromLocalStorage();
  const { apiCallFnforGet } = useApiServices();

  const decodedToken = jwtDecode(token);
  const { masterId } = decodedToken;
  const offcanvasStyle = {
    width: "90vw", // Set width to 70vw
  };
  const [formDataInputs, setFormDataInputs] = useState({
    userName: "",
    masterMerchantName: "",
    password: "",
    MobileNumber: "",
    emailId: "",
  });
  const privilagesSlice = useSelector((state) => state.privilages);
  useEffect(() => {
    setFormDataInputs({
      userName: UserData?.name,
      password: UserData?.password,
      MobileNumber: UserData?.mobile,
      emailId: UserData?.email,
    });
  }, [UserData]);

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES);

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_USER_MANAGEMENT_DATA_BY_ID + UserId
      );
      if (response.statusCode === 200) {
        setUserData(response?.data);
        const res = response?.data?.privilege?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId;
        });

        setPrivilages(res);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPrivilagesbyID = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_PRIVILAGES_BY_ID + masterId
      );
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId + "";
        });

        setPrivilages(res);
      } else {
        setPrivilages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      value = value.toUpperCase();
      if (name === "MobileNumber") {
        // Remove any non-numeric characters
        value = value.replace(/\D/g, "");
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };

  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;

    console.log("Checkbox checked:", e.target.checked);
    console.log("Privilages state before:", privilages);

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, parseInt(adminPrivilegeSubTypeId)];
        }
      } else {
        if (prevPrivilages.includes(parseInt(adminPrivilegeSubTypeId))) {
          return prevPrivilages.filter(
            (id) => id !== parseInt(adminPrivilegeSubTypeId)
          );
        }
      }

      return prevPrivilages;
    });

    console.log("Privilages state after:", privilages);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserData();
        await getAllPrivilages();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [UserId]);

  const handleSubmit = async (e) => {
    if (formDataInputs.userName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter User Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (!privilages.length) {
      swal({
        title: "Alert!",
        text: "Please select atleast one privilage",
        icon: "error",
      });
      return;
    }
    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });
    const payload = {
      aggrigator_sub_user_Id: UserId,
      email: formDataInputs.emailId?.trim(),
      mobile: formDataInputs.MobileNumber?.trim(),
      name: formDataInputs.userName?.trim(),
      password: formDataInputs.password?.trim(),
      privilegeId: privilegs,
    };

    console.log(payload);

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      const res = await updateUserData(payload);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "User Updated Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          userName: "",
          masterMerchantName: "",
          password: "",
          MobileNumber: "",
          emailId: "",
        });
        setPrivilages([]);
        setPrivilagesData([]);
        getAllPrivilages();
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement="end"
        style={offcanvasStyle}
      >
        <div
          className="d-flex justify-content-start align-items-center mt-3"
          style={{ marginLeft: "28px" }}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5
            class="offcanvas-title fs-2 text-dark fw-bold"
            id="offcanvasLabel"
          >
            Edit User
          </h5>
        </div>
        <Offcanvas.Body>
          <div class="row">
            <div class="col-12">
              {/* <div className="card input-box-shadow"> */}
              <div className="card-body py-4">
                <div id="" className="form-wizard order-create">
                  <div id="" className="tab-pane" role="tabpanel">
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            User Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            className="form-control"
                            name="userName"
                            value={formDataInputs.userName}
                            onChange={handleformDataInputs}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="emailId"
                            value={formDataInputs.emailId}
                            onChange={handleformDataInputs}
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onBlur={handleValidations}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Mobile Number
                            <sup className="text-danger">*</sup>{" "}
                          </label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name="MobileNumber"
                            value={formDataInputs.MobileNumber}
                            onChange={handleformDataInputs}
                            required
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            className="form-control"
                            name="password"
                            value={formDataInputs.password}
                            onChange={handleformDataInputs}
                          />
                        </div>
                      </div>
                      {/* ./form-group */}

                      <div
                        className="pt-4"
                        style={{ borderTop: "0.2px solid gray" }}
                      >
                        <h4 className="fs-2">Privilages</h4>
                      </div>

                      <Row className="mt-3">
                        <div className="d-flex flex-column gap-4">
                          {privilagesData &&
                            privilagesData?.map((item, i) => (
                              <div key={i} className="d-flex flex-column">
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                  <div class="checkbox-wrapper-31">
                                    <input
                                      id="flexSwitchCheckDefault"
                                      type="checkbox"
                                      name={item.adminPrivilegeId}
                                      checked={HeadPrivilages.includes(
                                        item.adminPrivilegeId + ""
                                      )}
                                    />
                                    <svg viewBox="0 0 35.6 35.6">
                                      <circle
                                        class="background"
                                        cx="17.8"
                                        cy="17.8"
                                        r="17.8"
                                      ></circle>
                                      <circle
                                        class="stroke"
                                        cx="17.8"
                                        cy="17.8"
                                        r="14.37"
                                      ></circle>
                                      <polyline
                                        class="check"
                                        points="11.78 18.12 15.55 22.23 25.17 12.87"
                                      ></polyline>
                                    </svg>
                                  </div>
                                  <h1 className="pt-2">{item.privilegeName}</h1>
                                </div>
                                <div className="row fs-4 mt-2 text-dark">
                                  {item.subTypes?.map(
                                    (item, i) =>
                                      privilagesSlice?.privilagesData?.includes(
                                        parseInt(item.adminPrivilegeSubTypeId)
                                      ) && (
                                        <div
                                          key={i}
                                          className="checkbox-wrapper-27 col-lg-4 col-md-6 col-sm-12 d-flex "
                                        >
                                          <label className="checkbox">
                                            <input
                                              id="flexSwitchCheckDefault"
                                              className=""
                                              type="checkbox"
                                              name={
                                                item.adminPrivilegeSubTypeId
                                              }
                                              checked={privilages.includes(
                                                item.adminPrivilegeSubTypeId
                                              )}
                                              onChange={handlePrivilages}
                                            />
                                            <span class="checkbox__icon"></span>
                                            {item.subTypePrivilegeName}
                                          </label>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </Row>
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditUser;
