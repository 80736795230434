import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import ShowField from "./ShowField";
import DropDowns from "./DropDowns";
import swal from "sweetalert";
import { VEHICLE_MASTER_PHASE_2_SUBMITTED, VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID} from "../../utils/constant";
import { Title } from "@mui/icons-material";
import { Offcanvas } from "react-bootstrap";
import useApiServices from "../../utils/useApiServices";
import RemoveIcon from '@mui/icons-material/Remove';
import "./external.css";



const offcanvasStyle = {
  width: '90vw', // Set width to 70vw
};

const VehicleMasterFormDetails = ({id, show, onHide}) => {
    
  const [selectedType, setSelectedType] = useState({
    vehicleId: Number,
  });
 const {apiCallFnforGet} = useApiServices();
  const [paymentSubType, setPaymentSubType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
//   const [Id, setId] = useState("");
  const [MerchantMDRData, setMerchantMDRData] = useState([]);
  const [SchemeMDRData, setMDRDataForScheme] = useState([]);
  const [AggregatorMDRData, setMDRDataForAggreGator] = useState([]);
  const [SwitchMDRData, setMDRDataForSwitch] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({});
  const [vehicleData, setVehicleData] = useState([]);
//   const [paymentSubType, setPaymentSubType] = useState([]);
  // const [vehicleData, setVehicleData] = useState([])

  useEffect(() => {
    GetVehicalMasterData();
  }, [id]);

  const GetVehicalMasterData = async () => {
    // setShowLoader(true);
    try {
      const response = await  apiCallFnforGet(VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID + id);
   console.log(response)
      if (response.statusCode === 200) {
        
        setVehicleData(response.data[0]);
        setPaymentSubType(response.data[0].paymentSubTypes);
        console.log(vehicleData)
      } else {
        setVehicleData([]);
        setPaymentSubType([]);

      }
    } catch (error) {
      console.log(error);
    }
    // setShowLoader(false);
  };

  // const handleClose=()=>{
  //   setShowDetails(false)
  // }

  // const ApiCallTest =async()=>{
  //  const response = await apiCallFnforGet('https://api2.lincpay.in/MerchantAdminPanel/admin/vehicleMaster/getbyId/3097968');
  //     console.log("Response is here", response)
  // }

  // ApiCallTest()

  const handleTypeChange = (event) => {
    const selectedVehicleValue = event.target.value;
    const name = event.target.name;

    // console.log(selectedVehicleValue, name);

    setSelectedType((prevSelectedType) => ({
      ...prevSelectedType,
      vehicleId: selectedVehicleValue,
    }));

    const selectedVehicle = vehicleData.find(
      (vehicle) => vehicle.Id === parseInt(selectedVehicleValue)
    );

    console.log(selectedVehicle);

    if (selectedVehicle) {
      setFormData({
        aggregatorName: selectedVehicle.aggregatorName,
        bankName: selectedVehicle.bankName,
        schemeName: selectedVehicle.schemeName,
        switchName: selectedVehicle.switchName, // Fix: Use 'switchName' instead of 'merchantName'
        masterMerchantName: selectedVehicle.masterMerchantName,
        remark: selectedVehicle.remark, // Assuming 'remark' is a property in the vehicle object
      });

      setPaymentSubType(selectedVehicle.paymentSubTypes);

      console.log(formData);
    } else {
    }

    setMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMerchantMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForAggreGator(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForScheme(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForSwitch(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
  };

  const handleMDRDataForBank = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log(dataType, value);
    const foundItem = MDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = MDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMDRData(updatedMDRData);
    }
  };

  const handleMDRDataForMerchant = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log("Master----------------in", dataType, value);
    const foundItem = MerchantMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = MerchantMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMerchantMDRData(updatedMDRData);
    }
  };

  const handleMDRDataSwitch = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    // console.log(dataType);
    const foundItem = SwitchMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = SwitchMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMDRDataForSwitch(updatedMDRData);
    }
  };

  const handleMDRDataForAggregator = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log(dataType, value);
    const foundItem = AggregatorMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = AggregatorMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      console.log(updatedMDRData);
      setMDRDataForAggreGator(updatedMDRData);
    }
  };

 

  console.log(vehicleData.vehicleName)
 

  return (
    <>


    
    <Offcanvas show={show} onHide={onHide} backdrop="static" keyboard={false} placement="end" style={offcanvasStyle}>
    <div className="d-flex justify-content-start align-items-center mt-3" style={{marginLeft:"28px"}}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
          View External Master
          </h5>
</div>
   
    <Offcanvas.Body >
      <div className="py-4">
      <div id="" className="">
      <div id="" className="tab-pane" role="tabpanel">    
      <div className="table-responsive">
                  <div className="table table-bordered">
                    <div className="row">
                      {[
                        {
                          label: "Vehicle",
                          value: vehicleData?.vehicleName,
                        },
                        {
                          label: "Master Merchant ",
                          value: vehicleData?.masterMerchantName,
                        },
                        {
                          label: "Scheme ",
                          value: vehicleData?.schemeName,
                        },
                        {
                          label: "Aggregator ",
                          value: vehicleData?.aggregatorName,
                        },
                        {
                          label: "Bank",
                          value: vehicleData?.bankName,
                        },
                        {
                          label: "Remark",
                          value: vehicleData?.remark,
                        },
                        {
                          label: "Switch",
                          value: vehicleData?.switchName,
                        },
                      ].map((item, index) => (
                        <div
                          key={index}
                          className="col-6 border-bottom border-dark "
                          // style={{ borderRight: "0.5px solid black", borderLeft: "0.5px solid black", paddingBottom:"0px"}}
                        >
                          <div className="d-flex justify-content-between align-items-center table table-bordered">
                            <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                              {item.label} <RemoveIcon />
                            </tr>{" "}
                            <tr className="mt-2 w-100 text-end">
                              {item.value}
                            </tr>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

<div className="row pt-5">
  <div className="col-lg-10 mx-auto">
    <div className="career-search mb-60">
      {vehicleData.schemeName !== "NA" && (
        <div className="filter-result">
          {paymentSubType?.map((service, i) => (
            <div key={i} className="job-box d-flex align-items-center justify-content-between mb-30 p-4">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="text-center text-md-left fs-3" style={{ fontWeight: "600" }}>
                  {`${vehicleData.schemeName} (${service.type})`}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-4">
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Interchange Fee Fix</div>
                  <div className="fs-5 text-center">{service?.interchangeFeeFix}</div>
                </div>
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Interchange Fee Percentage</div>
                  <div className="fs-5 text-center">{service?.interchangeFeePercentage}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {vehicleData.bankName !== "NA" && (
        <div className="filter-result">
          {paymentSubType?.map((service, i) => (
            <div key={i} className="job-box d-flex align-items-center justify-content-between mb-30 p-4">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="text-center text-md-left fs-3" style={{ fontWeight: "600" }}>
                  {`${vehicleData.bankName} (${service.type})`}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-4">
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Nodal Fee Fix</div>
                  <div className="fs-5 text-center">{service.nodalFeeFix}</div>
                </div>
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Nodal Fee Percentage</div>
                  <div className="fs-5 text-center">{service.nodalFeePercentage}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {vehicleData.switchName !== "NA" && (
        <div className="filter-result">
          {paymentSubType?.map((service, i) => (
            <div key={i} className="job-box d-flex align-items-center justify-content-between mb-30 p-4">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="text-center text-md-left fs-3" style={{ fontWeight: "600" }}>
                  {`${vehicleData.switchName} (${service.type})`}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-4">
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Processing Fee Fix</div>
                  <div className="fs-5 text-center">{service.processingFeeFix}</div>
                </div>
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Processing Fee Percentage</div>
                  <div className="fs-5 text-center">{service.processingFeePercentage}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {vehicleData.aggregatorName !== "NA" && (
        <div className="filter-result">
          {paymentSubType?.map((service, i) => (
            <div key={i} className="job-box d-flex align-items-center justify-content-between mb-30 p-4">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="text-center text-md-left fs-3" style={{ fontWeight: "600" }}>
                  {`${vehicleData.aggregatorName} (${service.type})`}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-4">
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Platform Fee Fix</div>
                  <div className="fs-5 text-center">{service.platformFeeFix}</div>
                </div>
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Platform Fee Percentage</div>
                  <div className="fs-5 text-center">{service.platformFeePercentage}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {vehicleData.masterMerchantName !== "NA" && (
        <div className="filter-result">
          {paymentSubType?.map((service, i) => (
            <div key={i} className="job-box d-flex align-items-center justify-content-between mb-30 p-4">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="text-center text-md-left fs-3" style={{ fontWeight: "600" }}>
                  {`${vehicleData.masterMerchantName} (${service.type})`}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-4">
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Merchant Fee Fix</div>
                  <div className="fs-5 text-center">{service.masterMerchantFess}</div>
                </div>
                <div>
                  <div className="fs-5" style={{ fontWeight: "600" }}>Merchant Fee Percentage</div>
                  <div className="fs-5 text-center">{service.masterMerchantPercentage}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
</div>

     </div>
     </div>

                         </div>

    </Offcanvas.Body>
  </Offcanvas>
  </>
  );
};

export default VehicleMasterFormDetails;
