import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { getAllSubmittedEkycRouting } from "../../utils/ApiServices";
import RouteIcon from "@mui/icons-material/Route";

import { FETCH_ALL_SUBMITTED_EKYC_ROUTING } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import useCheckPrivilages from "../../utils/checkPrivilages";
import EkycRouting from "./EkycRouting";
import EditEkycRouting from "./EditEkycRouting";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const ToolsSubmittedRoutingEkyc = ({ toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [applicationData, setApplicationData] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [currentid, setCurrentId] = useState(null);
  const [show, setShow] = useState(false);
  const [mid, setMid] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [showEditModal, setShowEditModal] = useState(false);
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [refresh, setRefresh] = useState(false);

  const hasPrivilages2638 = useCheckPrivilages(2638);

  useEffect(() => {
    FetchData();
  }, [showEditModal, refresh, setRefresh]);

  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(
        FETCH_ALL_SUBMITTED_EKYC_ROUTING
      );
      if (apiResponse.statusCode === 200) {
        const finalRes = apiResponse?.data?.map((item, i) => ({
          id: i,
          mid: item.mid,
          company: item.company,
          masterMerchantId: item.masterMerchantId,
          merchantMaster: item.merchantMaster,
          vehicleMaster: item.vehicleMaster,
          action: (
            <>
              <button
                className="badge badge-rounded badge-warning "
                onClick={() => EditView(item)}
              >
                Edit
              </button>
            </>
          ),
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const handleShowRouting = () => setIsModalRoutingOpen(true);

  const columns = [
    { headerName: "Merchant Name", field: "company", minWidth: 320 },
    { headerName: "Merchant ID", field: "mid", minWidth: 250 },
    {
      headerName: "Vehicle Master Name",
      field: "vehicleMaster",
      minWidth: 250,
    },
    {
      headerName: "Master Merchant Name",
      field: "merchantMaster",
      minWidth: 250,
    },
    { headerName: "Action", field: "action", minWidth: 250 },
  ];

  const handleClose = () => setShow(false);

  const EditView = (data) => {
    // setShow(true);
    setShowEditModal(true);
    setApplicationData(data);
    // setOpenView(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between bg-white ">
          <div className="d-flex justify-content-start align-items-center ">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">E-Kyc </div>
          </div>
          <div className="d-flex gap-2 justify-content-end mt-3 me-4 mb-2">
            <CustomTip size="18" placement="top" title="  E-KYC Routing">
              <div className="" onClick={handleShowRouting}>
                <RouteIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      getRowId={(row) => row.mid}
                      rows={applications}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "active" || column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditModal && ( // Show EditEkycRouting component conditionall
        <EditEkycRouting
          ApplicationData={applicationData}
          show={showEditModal}
          onHide={() => setShowEditModal(false)} // Hide modal function
        />
      )}

      {isModalRoutingOpen && ( // Show EditEkycRouting component conditionall
        <EkycRouting
          show={isModalRoutingOpen}
          onHide={() => handleCloseModalRouting(false)} // Hide modal function
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default ToolsSubmittedRoutingEkyc;
