import Row from "./Row";
import CustomTip from "../common/CustomTip";
import { formatCompactNumber } from "../utils/helper";

export default function PreviousTxns({ data, payout, dataW }) {
  return (
    <>
      <div className="col-xl-6 col-md-6 col-xxl-6 h-100">
        <div className="card col-md-12">
          <div className="card-header d-block d-sm-flex border-0">
            <div className="me-3">
              <h4 className="card-title mb-2">Other Details</h4>
            </div>
          </div>
          <div className="card-body tab-content p-0 d-flex flex-column">
            <div className="tab-pane active show fade flex-grow-1">
              <div className="table-responsive">
                <table className="table card-table transactions-table">
                  <tbody>
                    <Row Name={"Payin"} Amount={data?.totalAmt} />
                   
                  <Row
                    Name={"Settlement Charges"}
                    Amount={data?.settlementCharge}
                  />
                  <Row Name={"GST On Settlement"} Amount={data?.settlmentGstCharge} />

                  <Row Name={"Hold"} Amount={data?.holdAmount} />
                  <Row Name={"Deposit"} Amount={data?.depositAmount} />
                  <Row Name={"Chargeback"} Amount={data?.chragebackAmount} />
                  <Row
                    Name={"Withdrawn To Account"}
                    Amount={data?.withDrawToAccount}
                  />
                  <Row
                    Name={"Withdrawn To Payout"}
                    Amount={data?.withDrawTopayout}
                  />
                  <Row
                    Name={"Withdrawn To Credit"}
                    Amount={data?.withdrawnTocredit}
                  />
                  <Row Name={"Charges On Payout"} Amount={data?.payoutServiceCharge} />

                  <Row
                    Name={"GST Charges on Payout"}
                    Amount={data?.payoutGstCharge}
                  />
                  <Row
                    Name={"Commission Earned"}
                    Amount={data?.totalCommisionEarnedAmount}
                  />
                  <Row Name={"Direct Deposit To Wallet"} Amount={data?.depositeToWallet} />
                  <Row Name={"Direct Deposit To Payout"} Amount={data?.depositeToPayout} />
                  <Row
                    Name={"Guarantee Amount"}
                    Amount={data?.guranteeAmount}
                  />
                  <Row Name={"Settled To wallet"} Amount={data?.settlmentAmout} />
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="d-flex justify-content-around py-2 border-top border-rounded border-black bg-success mt-auto">
              <div className="fs-16 font-w600 mb-0 text-black fw-bold">Total Wallet Balance</div>
              <CustomTip size={20} title={dataW} placement="top">
                <div className="fs-16 font-w600 mb-0 text-black fw-bold">₹ {formatCompactNumber(dataW) ?? 0}</div>
              </CustomTip>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
