import React, { useState } from "react";
import styles from "./style.module.css"
import { GET_REPORT_API, REPORTS_BY_TIMEFRAME } from "../utils/constant";
import useApiServices from "../utils/useApiServices";
// import Dropdown  from "./Dropdown";
import DropdownReports from "./DropdownReports";
const FloatingReports = ({ mid, reportType, setData, setCondition, reset, type }) => {
  const { apiCallFnforPost } = useApiServices();
  const [isOpen, setIsOpen] = useState(false);
//   const [value, setValue] = useState("");
//   const options = [
//     {
//       label: "select",
//       value: "",
//     },
//     {
//       label: "1 Day",
//       value: "1 day",
//     },
//     {
//       label: "1 Week",
//       value: "1 week",
//     },
//     {
//       label: "1 Month",
//       value: "1 month",
//     },
//     {
//       label: "1 Quarter",
//       value: "1 quarter",
//     },
//   ];

  const handleMouseEnter = () => {
    // document.getElementById("floatingWindow").style.display = "initial";
    // document.getElementById("floatingWindowIcon").style.display = "none";
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    // document.getElementById("floatingWindow").style.display = "none";
    // document.getElementById("floatingWindowIcon").style.display = "initial";
    setIsOpen(false);
  };

//   async function handleSelect(event) {
//     setValue(event.target.value);
//     try {
//       const response = await apiCallFnforPost(GET_REPORT_API, {
//         mid,
//         timeFrame: event.target.value,
//         reportType: "Payin",
//       });

//       console.log("respibnse in floating icons", response);

//       if (response?.statusCode == 200) {
//         const finalres = response?.data?.map((item, i) => ({
//           id: i,
//           ...item,
//         }));
//         setData(finalres);
//       }
//     } catch (error) {
//       console.log("error::", error);
//     }
//   }

  return (
    <div
    className={styles.flot_cont}
    style={{
      position: "fixed",
      bottom: "20px",
      right: "20px",
      zIndex: "9999",
      width: isOpen ? "700px" : "100px",
      transition: "width 0.3s ease",
      overflow: "visible",
    }}
  >
    <div
      className="bg-secondary rounded rounde-5"
      style={{
        height: "50px",
        width: "auto",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // padding: "0px 10px"
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownReports
        className="w-100"
        isOpen={isOpen}
        setCondition={setCondition}
        reset={reset} 
        type={type}
      />
     
    </div>
  </div>
  );
};

export default FloatingReports;