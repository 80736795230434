import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

const CustomTip = ({ size,placement,title,desc,children }) => {

    const fontSize = typeof size === 'number' ? `${size}px` : size;
 
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: fontSize,
      backgroundColor: "#709FBA",
      
      whiteSpace: "pre-line",
       
    },
  }))(Tooltip);
  return (
    // title ? (
      <CustomTooltip title={title} placement={placement} arrow>
        <span>{children}</span>
      </CustomTooltip>
    // ) : (
    //   <span>{children}</span>
    // )
  );
};

export default CustomTip;