import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_ALL_MDR_BY_MID,
  SWIPELINC_API,
  UPDATE_MDR_PERCENTAGE,
} from "../../../utils/constant";
import {
  stopCharacterInput,
  stopSpecialCharacterInput,
} from "../../../utils/helper";
import swal from "sweetalert";
import ServiceCharge from "../Merchant Management/ServiceCharge";
import useCheckPrivilages from "../../../utils/checkPrivilages";
// import InactiveServices from "../../InactiveServices";
import useApiServices from "../../../utils/useApiServices";

const MDRPercentage = ({ toggleRightBar, type1 }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [mid, setMid] = useState("");
  const [fullName, setFullName] = useState("");
  const [MDRTypes, setMDRType] = useState();
  const [MDRData, setMDRData] = useState([]);
  const [activePhase, setActivePhase] = useState("Phase2");
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  const hasPrivilage = useCheckPrivilages(176088);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  const getMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
        console.log(response?.data);
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      setMerchantData([]);
    }
  };

  useEffect(() => {
    getMerchantData();
  }, [mid]);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.prifix} :- ${merchant.mid} ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
    } else {
      setMid("");

      setMDRData([]);
      setMDRType([]);
    }
  };

  const fetchtheTypeOfoption = async () => {
    setMDRData([]);
    setMDRType([]);
    // if (!mid === "") {
    try {
      const MDRType = await apiCallFnforGet(GET_ALL_MDR_BY_MID + mid);
      if (MDRType?.statusCode === 200) {
        setMDRType(MDRType?.data ? MDRType?.data : []);
      } else if (MDRType?.statusCode === 304) {
        swal({
          title: "Failed",
          text: "No details found",
          icon: "error",
        });
        setMDRType([]);
        setMid("");
        setSelectedCompany("");
        setFullName("");
      }
    } catch (error) {
      console.log(error);
    }

    // }
  };

  const setMDrDataInit = (MDRTypes) => {
    MDRTypes.forEach((element) => {
      setMDRData({
        ...MDRData,
        [element.paymentTypeId]: element.mdrPercentage,
      });
    });
    setMDRData();
  };

  useEffect(() => {
    fetchtheTypeOfoption();
  }, [mid]);

  const handleMDRChange = (value, mdrId, name) => {
    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    let data = MDRTypes.filter((elem) => {
      return elem.paymentTypeId === mdrId;
    });

    data[0].mdrPercentage = value;
    setMDRType([...MDRTypes]);
  };

  const handleMDRsubmit = async () => {
    const finalPayload = MDRTypes.map((type) => {
      return { ...type, mid: mid };
    });

    try {
      const response = await apiCallFnforPut(
        UPDATE_MDR_PERCENTAGE,
        finalPayload
      );

      // return response?.data;

      if (response.statusCode === 200) {
        setMDRType([]);
        setMid("");
        setSelectedCompany("");

        swal({
          title: "Success",
          text: "Merchant discount rate updated successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Alert!",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <div>
      <div class="">
        <div className=" d-flex justify-content-between bg-white custom-card-header">
          <div className="d-flex justify-content-start align-items-center mb-4">
            <button class="btn-close m-3" onClick={toggleRightBar}></button>
            <div className="fs-2 text-dark fw-bold">Merchant Discount Rate</div>
          </div>
        </div>
        <div className="subtab-sec-admin">
          {/* <Container> */}
          <Row>
            <Col xs={12} lg={12} md={12}>
              <div>
                <div className="row ">
                  {activePhase === "Phase2" ? (
                    <div className="col-12 offset">
                      <div className="col-sm-12 col-md-6  offset-md-3">
                        <label className="d-flex text-dark fs-4">
                          Select A Merchant
                        </label>

                        <select
                          id="companyDropdown"
                          className="form-control"
                          onChange={handleCompanyChange}
                          value={selectedCompany}
                        >
                          <option value="">Select a Merchant</option>
                          {merchantData?.map((merchant) => (
                            <option
                              key={merchant.mid}
                              value={`${merchant.prifix} :- ${merchant.mid} ${merchant.merchantName}`}
                            >
                              {merchant.prifix} :- {merchant.mid} - {merchant.merchantName}
                            </option>
                          ))}
                        </select>
                        </div>

                      <div className="row ">
                        <div className="col-sm-12 col-md-6 offset-md-3">
                          <div className="form-group mt-2">
                            <label className="d-flex text-dark fs-4 ">
                              Merchant MID
                            </label>
                            <input
                              required
                              type="email"
                              className="form-control "
                              id="email"
                              placeholder="Merchant ID"
                              value={mid}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ServiceCharge data={merchantData} />
                  )}
                </div>
                {activePhase === "Phase2" ? (
                  // <Container>
                  <Row>
                    <Col xs={12}>
                      <Row className="p-3">
                        <Col xs={12} className="mb-3">
                          <label className="mx-4 text-dark fs-4 mt-2">
                            MERCHANT DISCOUNT RATE
                            <sup className="text-danger">*</sup>
                          </label>
                          <label
                            className="fw-semi-bold text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            (Please enter the MDR rate in percentage.)
                          </label>
                        </Col>

                        {MDRTypes?.map((service) => (
                          <Col
                            xs={12}
                            md={4}
                            key={service.paymentTypeId}
                            className="mb-3"
                          >
                            <label className="d-flex text-dark fs-4">
                              {service.paymentService}
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="number"
                              name={service.paymentService}
                              className="form-control"
                              value={service.mdrPercentage}
                              onChange={(e) =>
                                handleMDRChange(
                                  e.target.value,
                                  service.paymentTypeId,
                                  service.paymentService
                                )
                              }
                              required
                            />
                          </Col>
                        ))}

                        {MDRTypes?.length > 0 && (
                          <Col xs={12}>
                            <div className="d-flex justify-content-end mt-3">
                              <button
                                className="badge badge-rounded badge-primary mx-3 px-3 py-2"
                                onClick={handleMDRsubmit}
                                id="mdrSave"
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  // </Container>
                  ""
                )}
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </div>
    </div>
  );
};

export default MDRPercentage;