import React from "react";
import Tooltip from "@mui/material/Tooltip";

const IconWrapper = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      <span className="fs-3 me-3">{children}</span>
    </Tooltip>
  );
};

export default IconWrapper;