import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { GET_ALL_INITIAL_SID, HANDLE_SEARCH_LIMIT } from "../../utils/constant";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../utils/useApiServices";
import NumberInWords from "./NumberInWords";

const ViewMappedSID = () => {
  const [sidData, setSidData] = useState([]);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [name, setName] = useState("");
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();

  const getAllInitialSID = () => {
    apiCallFnforGet(GET_ALL_INITIAL_SID)
      .then((response) => {
        if (response.statusCode === 200) {
          setSidData(response.data);
          let res = response.data.map((elem) => {
            return {
              ...elem,
              maxTxnAmtInputVal: "",

              remainingLimitcolor: (
                <button
                  style={{
                    height: "50px",
                    width: "100px",
                    backgroundColor: "white",
                    color: "red",
                    border: "none",
                    padding: "0",
                    textAlign: "center",
                  }}
                >
                  {elem.remainingLimit}
                </button>
              ),

              action: (
                <button
                  className="badge bg-primary text-white ms-2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handelSearchLimit(e, elem.sid, elem.company)}
                >
                  View Mapped SID's
                </button>
              ),
            };
          });
          setSidData(res);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handelSearchLimit = (e, sid, Name) => {
    setName(Name);
    apiCallFnforGet(HANDLE_SEARCH_LIMIT + sid)
      .then((response) => {
        // console.log(response);
        if (response.statusCode === 200) {
          setSidDetailsData(response.data);
        } else {
          setSidDetailsData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setIsModalOpen(true);
  };

  const sidDetailsColumn = [
    { headerName: "Merchant ID", field: "mid", minWidth: 200 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 200 },
    { headerName: "SID", field: "sid", minWidth: 120 },
    { headerName: "SID Limit", field: "sidLimit", minWidth: 120 },
  ];

  const Column = [
    { headerName: "Company", field: "company", minWidth: 250 },
    { headerName: "MID", field: "mid", minWidth: 150 },
    { headerName: "SID", field: "sid", minWidth: 150 },
    {
      headerName: "Max Txn Amount",
      field: "maxTxnAmount",
      minWidth: 150,
    },
    {
      headerName: "Remaining Limit",
      field: "remainingLimitcolor",
      minWidth: 200,
    },
    { headerName: "Action", field: "action", minWidth: 200 },
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAllInitialSID();
  }, []);

  return (
    <>
      <div style={{ height: "500px", width: "100%" }}>
        <DataGrid
          rows={sidData}
          columns={Column.map((column) => ({
            ...column,
            renderCell: (params) =>
              column.field === "active" ||
              column.field === "action" ||
              column.field === "remainingLimitcolor"
                ? params.value
                : params.value,
          }))}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
      {/* <table className="table table-striped table-bordered shadow">
          <thead className="thead-light">
            <tr>
              <th className="text-dark fw-bold">Company</th>
              <th className="text-dark fw-bold">Maximum Transaction Amount</th>
              <th className="text-dark fw-bold">Remaining Limit</th>
              <th className="text-dark fw-bold">Action</th>
            </tr>
          </thead>
          <tbody>
            {sidData.map((elem) => (
              <tr key={elem.sid}
              className="fs-5 text-dark ">
                <td>{elem.company}</td>
                <td className="fw-bold">{elem.maxTxnAmount}</td>
                <td className="text-danger">{elem.remainingLimit}</td>
                <td>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) =>
                      handelSearchLimit(e, elem.sid, elem.company)
                    }
                  >
                    View Mapped SID's
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}

      <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className=" fs-4 fw-bold mx-2">{name}</Modal.Title>
          
          <Modal.Title className="fs-4 fw-bold">SID Details:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="merchant-report-view">
            <div className="report-table-view">
              <div className="containerflag">
                <div className="row mt-4">
                  <DataGrid
                    rows={sidDetailsData}
                    columns={sidDetailsColumn}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default ViewMappedSID;
