import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { USE_SETTLEMENT_ADVANCE_FILTER as getApiData } from "../../utils/ApiServices";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import FilterListIcon from '@mui/icons-material/FilterList';

const SettlementAdvanceFilter = ({
    setIsAdvanceFilterOpen,
    setReconReportData,
    setToggleFilterA,
    toggleFilterA,
    setShowLoader,
    setIsAdvance
}) => {
    const token = localStorage.getItem("token");

    const [searchOption, setSearchOption] = useState("");
    const [searchValue, setSearchValue] = useState("test");

    const [show2, setShow2] = useState(true);

    const exampleValues = {
        // amount: "Ex- 5500",
        // amountInBetween: "Ex- 100-500",
        // VPA: "Ex- abcde@xyz / 98XXXXXXX1@xyz",
        // paymentType: "Ex-  QR / SQR / DQR / UPI",
        // txnId: "Ex- XXXXXXXXXXXXXXX",
        // rrn: "Ex- XXXXRCYYYYMMDD#####",
        // Add more options and example values as needed
    };

    const [searchValueError, setSearchValueError] = useState("");
    const [isSearchValueError, setIsSearchValueError] = useState(false);

    useEffect(() => {
        setSearchValue("");
    }, [searchOption]);

    const submitHandler = () => {
        getApiData(
            "",
            "",
            "",
            "",
            "",
            searchOption === "rrn" ? searchValue.trim() : "",
            "",
            0,
            token,
            setReconReportData,
            setShowLoader,
            setIsAdvance,
            searchOption === "transactionId" ? searchValue : "",
            searchOption === "payerVPA" ? searchValue : "",

        );
        handleModalClose();
    };

    const handleModalClose = () => {
        setShow2(false);
        setIsAdvanceFilterOpen(false);
    };

    const handelSearchData = (e) => {
        setSearchValue(e.target.value);
        setIsSearchValueError(false);
        setSearchValueError("");
    };

    return (
        <div>
            <div className="d-flex flex-row">
                <Modal show={show2} size="lg" centered>
                    <Modal.Header className="bg-dark ">
                        <Modal.Title className="text-white">
                            Settlement Advance Search
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="filter-field-area">
                            <fieldset className="border-2 rounded-0 mt-3">
                                <legend className="fs-5 fw-bold">Select Parameter</legend>
                                <div className="field-set-form">
                                    <Row className="g-2">
                                        <Col lg={6}>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    value={searchOption}
                                                    onChange={(e) => setSearchOption(e.target.value)}
                                                >
                                                    <option type="number" value="">
                                                        Select
                                                    </option>
                                                    {/* <option type="number" value="txnId">
                            Transaction ID
                          </option> */}
                                                    <option type="number" value="rrn">
                                                        Order Number
                                                    </option>
                                                    <option value="transactionId">Transaction ID</option>
                                                    <option value="payerVPA">VPA</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="form-group">
                                                {/* {searchOption === "txnId" ? (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <span className="ml-2 mt-1">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </div>
                        ) :  */}
                                                {searchOption === "rrn" ? (
                                                    <div>
                                                        <input
                                                            className="advanced-input-box form-control"

                                                            value={searchValue}
                                                            onChange={handelSearchData}
                                                        />

                                                    </div>
                                                ) : 
                                                searchOption === "transactionId" ? (
                                                    <div>
                                                        <input
                                                            className="advanced-input-box form-control"

                                                            value={searchValue}
                                                            onChange={handelSearchData}
                                                        />

                                                    </div>
                                                ) :
                                                searchOption === "payerVPA" ? (
                                                    <div>
                                                        <input
                                                            className="advanced-input-box form-control"

                                                            value={searchValue}
                                                            onChange={handelSearchData}
                                                        />

                                                    </div>
                                                ) : 
                                                (
                                                    ""
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </fieldset>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        {/* <div className="d-flex justify-content-between"> */}
                        <CustomTip size="18" placement="top" title="More Filter"
                        >
                            <button style={{ border: "none", backgroundColor: "white" }} onClick={() => setToggleFilterA(!toggleFilterA)}>
                                <FilterListIcon fontSize="large" />
                            </button>
                        </CustomTip>

                        <div className="d-flex gap-2">
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="text-capitlize"
                                onClick={handleModalClose}
                            >
                                Close
                            </Button>
                            <Button
                                id="searchBtn"
                                variant="outline-primary"
                                size="sm"
                                className="text-capitlize"
                                onClick={submitHandler}
                            >
                                <i class="bi bi-search mx-1"></i> Search
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>

        </div>
    );
};

export default SettlementAdvanceFilter;
