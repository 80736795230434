import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  isEmailIdRegexIsValid,
  isVpaUpiRegexIsValid,
} from "../../../utils/helper";
import {
  
  USE_LIVE_CHARGEBACK_ADVANCE_FILTER_API_2,
  USE_CHARGEBACK_MULTIPLE_FILTER_API as getApiData,
} from "../../../utils/ApiServices";
import CustomTip from "../../../common/CustomToolTip/CustomTip";

const ChargebackAdvanceFilter = ({
  setApplications,
  mid,
  setShowLoader,
  setChargebackReportData,
  setIsAdvanceFilterOpen,
  setToggleFilterA,
  toggleFilterA,
  EditChargeback,
  setRRNNum,
  setChargeBackAdjDate,
  setEditItem,
}) => {
  const token = localStorage.getItem("token");
  const [searchOption, setSearchOption] = useState("email");
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(true);

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const handleModalClose = () => {
    setShow(false);
    setIsAdvanceFilterOpen(false);
    //  setSearch(!search)
  };

  const handleClick = (option) => {
    setSearchOption(option);
    setSearchValue("");
  };

  const submitHandler = () => {
    USE_LIVE_CHARGEBACK_ADVANCE_FILTER_API_2(
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      searchOption === "email" ? searchValue : "",
      searchOption === "mobile" ? searchValue : "",
      searchOption === "payerVPA" ? searchValue : "",
      searchOption === "rrnNo" ? searchValue : "",
      searchOption === "txnUid" ? searchValue : "",
      "",
      token,
      setChargebackReportData,
      setApplications,
      EditChargeback,
      setRRNNum,
      setChargeBackAdjDate,
      setEditItem,
    );

    handleModalClose();
  };

  const handelSearchData = (e) => {
    if (searchOption === "mobile") {
      const numericInput = /^\d*$/.test(e.target.value);
      if (numericInput) {
        setSearchValue(e.target.value.substring(0, 10)); // Only allow up to 10 digits
        setSearchValueError("");
      } else {
        setSearchValueError("Input must be a number.");
      }
      return;
    }

    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
    document.getElementById("searchBtn").disabled = false;
  };

  const handelValidations = (e) => {
    if (searchOption === "payerVPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid VPA.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }
    if (searchOption === "email") {
      if (!isEmailIdRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid Email Id.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }
  };

  useEffect(() => {
    handleClick("email");
  }, []);

  return (
    <div className="advanced-search-popup">
      <Modal show={show} centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title> Chargeback Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setIsSearchValueError(false);
                  setSearchValueError("");
                }}
              >
                <option type="email" value="email">
                  e-Mail
                </option>
                <option value="mobile">Mobile</option>
                <option value="payerVPA">Payer VPA</option>
                <option value="rrnNo">RRN No</option>
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                className="advanced-input-box form-control"
                type={searchOption === "mobile" ? "tel" : "text"}
                value={searchValue}
                onChange={handelSearchData}
                onBlur={handelValidations}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder={
                  searchOption === "email"
                    ? "Enter e-Mail"
                    : searchOption === "payerVPA"
                    ? "Enter Payer VPA"
                    : searchOption === "rrnNo"
                    ? "Enter RRN No"
                    : searchOption === "mobile"
                    ? "Enter Mobile"
                    : ""
                }
              />
              {isSearchValueError ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ">
          {/* <CustomTip size="18" placement="top" title="More Filter">
            <button
              style={{ border: "none", backgroundColor: "white" }}
              onClick={() => setToggleFilterA(!toggleFilterA)}
            >
              <FilterListIcon fontSize="large" />
            </button>
          </CustomTip> */}
          <div className="d-flex gap-2 ">
            <Button
              variant="outline-danger"
              size="sm"
              className="text-capitlize"
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              id="searchBtn"
              variant="outline-primary"
              size="sm"
              className="text-capitlize"
              onClick={submitHandler}
            >
              <i class="bi bi-search mx-1"></i> Search
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChargebackAdvanceFilter;
