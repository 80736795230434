import React,{useState, useRef} from 'react'
import { Modal, Button} from "react-bootstrap"
import CopyAllIcon from '@mui/icons-material/CopyAll';
const ViewResponse = ({setShowResponse, alertData, company, m}) => {
    const [show, setShow] = useState(true);
    const dataRef = useRef(null);

    const copyData = () => {
      // Select the text inside the dataRef
      dataRef.current.select();
      // Execute copy command
      document.execCommand('copy');
      // Deselect the text
      window.getSelection().removeAllRanges();
    };
    const handleModalClose = () => {
        setShow(false);
        setShowResponse(false);
      };
console.log("data-------->",alertData)
  return (
   
    <Modal show={show} centered size='xl'>
    <Modal.Header className="text-white d-flex justify-content-between">
      <Modal.Title className='fw-bold'>{company}:{" "}
      <span className="fw-normal">{m}</span>
      </Modal.Title>
      <button className='bg-white border-0' onClick={copyData}><CopyAllIcon/></button>
    </Modal.Header>
    <Modal.Body>
    <div>
      <div className='fs-6 text-dark' style={{ overflow: "auto", userSelect: "text" }}>
        <textarea
          ref={dataRef}
          style={{ width: '100%', height: '100%', border: 'none', resize: 'none', background: 'transparent' }}
          value={JSON.stringify(alertData, null, 2)}
          readOnly
        />
      </div>
     
    </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="outline-danger"
        size="sm"
        className="text-capitlize"
        onClick={handleModalClose}
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  

  )
}

export default ViewResponse
