import React, { useState, useEffect } from "react";
import { convertDateFormat, convertTime } from "../../../utils/helper";
import ViewDetails from "./ViewDetails";
import { Container, Card, Row, Col } from "react-bootstrap";
import { fetchSubmittedPayout } from "../../../utils/ApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../../utils/useApiServices";
import { SUBMITTED_PAYOUT } from "../../../utils/constant";

function PendingPayoutRequest({ toggleRightBar, type1 }) {
  const [payout, setSubmittedPayout] = useState([]);
  const [id, setId] = useState("");
  const [openView, setOpenView] = useState(false);
  const { apiCallFnforGet } = useApiServices();

  useEffect(() => {
    apiCallFnforGet(SUBMITTED_PAYOUT)
      .then((response) => {
        if (response?.statusCode === 200) {
          const FinalResp = response.data.map((item, i) => ({
            id: i,
            fullName: item.fullName,
            email: item.email,
            category: item.category,
            mobile: item.mobile,
            mid: item.mid,
            companyName: item.companyName,
            createDate: convertDateFormat(item.createDate?.split(" ")[0]) || "",
            createTime:(item.createDate?.split(" ")[1]) || "",
            amount: item.amount,
            action: (
              <div>
                <button
                  className="badge badge-rounded badge-warning"
                  onClick={() => ViewDetail(item.id)}
                >
                  View
                </button>
              </div>
            ),
          }));
          setSubmittedPayout(FinalResp);
        } else {
          setSubmittedPayout([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor:", error.message);
      });
  }, [openView]);

  const ViewDetail = (id) => {
    setOpenView(true);
    setId(id);
  };

  const columns = [
    // { headerName: ' ID', field: 'id', minWidth: 150, },
    { headerName: "Vendor Name", field: "fullName", minWidth: 150 },
    { headerName: "e-Mail", field: "email", minWidth: 150 },
    { headerName: "Category", field: "category", minWidth: 150 },
    { headerName: "Mobile", field: "mobile", minWidth: 150 },
    { headerName: "Merchant ID", field: "mid", minWidth: 150 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 150 },
    { headerName: " Created Date", field: "createDate", minWidth: 150 },
    { headerName: " Created Time", field: "createTime", minWidth: 150 },
    { headerName: " Amount", field: "amount", minWidth: 150 },
    { headerName: "Action", field: "action", minWidth: 150 },
  ];

  // const mappedData = payout? payout?.map((item) => {
  //   const mappedItem = { ...item };
  //   mappedItem.action = (
  //     <div>
  //       <button
  //       className="btn btn-primary"
  //         onClick={() => ViewDetail(item.id )}
  //       >
  //         View Details
  //       </button>
  //       </div>
  //   );
  //   return mappedItem;
  // }):[];

  const onHide =()=>{
     setOpenView(false);
  }

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-start align-items-center   ">
          <button
            type="button"
            class="btn-close m-3"
            onClick={toggleRightBar}
          ></button>

          <div className="fs-2 text-dark fw-bold">Pending Payout Request</div>
        </div>
        {/* <Container> */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    {!openView ? (
                      <DataGrid
                        rows={payout}
                        columns={columns?.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          }}
                        }
                      />
                    ) :
                     openView ? (
                      <ViewDetails id={id}  onHide={onHide}/>
                    ): null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Container> */}
      </div>
      {/* </div>  */}
    </>
  );
}

export default PendingPayoutRequest;
