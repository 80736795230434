import { formatCompactNumber } from "../utils/helper";
import IbtnWrapper from "../common/CustomToolTip/IButtonWrraper";
import CustomTip from "../common/CustomToolTip/CustomTip";
import MiniLoader from "../common/MiniLoader/MiniLoader";

export default function Card({ name, amount, color, icon, imps, upiAmount, showLoader }) {
  return (
    <>
      <div className="col-xl-3 col-xxl-3 col-sm-6">
        <div className={`card ${color} invoice-card relative`}>
          <div className="card-body d-flex">
            <div className="icon me-3">
              <img className="text-white" src={icon} alt="" width={27} height={30} />
            </div>
            <div>
              {
                showLoader || amount == null || amount === undefined ? (
                  <div>
                    <MiniLoader color={"primary"} size={15} />
                  </div>
                ) : (
                  <CustomTip 
                    size={20} 
                    title={
                      upiAmount 
                      ? <span>UPI: {upiAmount}<br />IMPS: {imps}<br />Total: {amount}</span>
                      : amount
                    } 
                    placement="top"
                  >
                    <h2 className="text-white invoice-num">
                      {formatCompactNumber(amount) ?? 0}
                    </h2>
                  </CustomTip>
                )
              }
              <span className="text-white fs-18">{name}</span>
            </div>
          </div>
          <IbtnWrapper color={"text-white"} desc={name} />
        </div>
      </div>
    </>
  );
}
