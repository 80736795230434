import { useState } from "react";
import CustomTip from "../common/CustomToolTip/CustomTip";
import { formatCompactNumber } from "../utils/helper";
import MiniLoader from "../common/MiniLoader/MiniLoader";

export default function MiddleCard({ data, payinServices, availablePayoutBalance }) {
  const [showBalance, setShowBalance] = useState(true);
  const [showMerchants, setShowMerchants] = useState(false);

  const handleToggle = () => {
    setShowMerchants(!showMerchants);
  };

  return (
    <>
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-xl-6">
                <div className="card-bx bg-blue">
                  <img
                    className="pattern-img"
                    src="/images/pattern/pattern6.png"
                    alt=""
                  />
                  <div className="card-info text-white">
                    <img
                      src="/images/pattern/circle.png"
                      className="mb-4"
                      alt=""
                    />
                    {
                      !data ? (
                        <div>
                          <MiniLoader size={18} color={"success"} />
                        </div>
                      ) : (
                        showMerchants ? (
                          <>
                          <div className="text-white fs-2 card-balance" style={{fontWeight:"600"}}>
                            Online Merchants:<span className="fw-normal">{" "}{availablePayoutBalance.onlineMerchantCount}</span>
                          </div>
                          <div className="text-white fs-2 card-balance"  style={{fontWeight:"600"}}>
                            Offline Merchants:<span className="fw-normal">{" "}{availablePayoutBalance.offlineMerchantCount}</span>
                          </div>
                           </>
                        ) : (
                          <CustomTip size={20} title={data} placement="top">
                            <div className="text-white card-balance fs-2">
                              ₹ {formatCompactNumber(data)}
                            </div>
                          </CustomTip>
                        )
                      )
                    }
                    <p className="fs-16">{showMerchants ? "Merchant Info" : "Wallet Balance"}</p>
                    <span> </span>
                  </div>
                  <a className="change-btn btn btn-secondary" onClick={handleToggle}>
                    <i className="fa fa-caret-up up-ico"></i> Change
                    <span className="reload-icon">
                      <i className="fas fa-sync-alt reload active"></i>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row align-items-center mt-xl-0 mt-4 gap-4">
                  <>
                    {
                      payinServices?.length !== 0 ? (
                        <>
                          <h4 className="card-title">Services Overview</h4>
                          <div className="col-md-5">
                            <ul className="card-list">
                              {payinServices?.map((item, i) => {
                                if (i >= 7) {
                                  return null;
                                }
                                return (
                                  <li key={i}>
                                    <span className="bg-blue circle"></span>
                                    {item.paymentService}
                                    <span>{item.percentage} %</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="card-list">
                              {payinServices?.map((item, i) => {
                                if (i < 7) {
                                  return null;
                                }
                                return (
                                  <li key={i}>
                                    <span className="bg-blue circle"></span>
                                    {item.paymentService}
                                    <span>{item.percentage} %</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <MiniLoader size={18} color={"success"} />
                        </div>
                      )
                    }
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
