import React from 'react'
import { Button, Card, Modal } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const SIDLogs = ({logsData,setLogsModal,logsModal}) => {
  
    const columns = [
        {
          headerName: "Merchant ID",
          field: "mid",
          minWidth: 130,
        },
        {
          headerName: "Merchant Name",
          field: "company",
          minWidth: 220,
        },
        {
          headerName: "SID",
          field: "sid",
          minWidth: 130,
        },
        // {
        //   headerName: "Vehicle Master Name",
        //   field: "vehicleName",
        //   minWidth: 170,
        // },
        {
          headerName: "Maximum Transaction Amount",
          field: "maxTxnAmount",
          minWidth: 150,
        },
        {
          headerName: "Create Date",
          field: "createDateTime",
          minWidth: 100,
        },
      
        {
          headerName: "Updated Date",
          field: "updatedDateTime",
          minWidth: 100,
        },
        // {
        //   headerName: "Bank",
        //   field: "bankName",
        //   minWidth: 100,
        // },
        // {
        //   headerName: "Scheme",
        //   field: "schemeName",
        //   minWidth: 100,
        // },
        // {
        //   headerName: "Switch",
        //   field: "switchName",
        //   minWidth: 100,
        // },
        {
          headerName: "Master Merchant",
          field: "masterMerchantName",
          minWidth: 100,
        },
        {
          headerName: "Remark",
          field: "remark",
          minWidth: 200,
        },
      
      ];
      
  return (
    <Modal
    show={logsModal}
    onHide={()=>setLogsModal(false)}
    size="lg"
  >
    <Modal.Header
      
      closeButton
    >
      <Modal.Title className="fw-bold">SID Logs</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/* <Card className="shadow border-0 mb-4"> */}
      <Card.Body>
        <div className="text-success mb-2 fs-4 fw-bold">
          Company :  <span>{logsData[0]?.company}</span>
        </div>
        <div className="text-info mb-2 fs-4 fw-bold">
          SID :  <span>{logsData[0]?.sid}</span>
        </div>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag ">
              <div className="row mt-4 ">
                <DataGrid
                  rows={logsData}
                  columns={columns}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
      {/* </Card> */}
    </Modal.Body>
  </Modal>
  )
}

export default SIDLogs