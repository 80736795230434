import React from 'react'
import {ClockLoader} from "react-spinners"
const style = {
    width:"100vw",
    height: "100%",
    backgroundColor:"#757575",
    // backgroundColor: "red",
    opacity:0.7,
    position:"fixed",
    filter:" blur(1px)",
    // backdropFilter:"blur(20px)",
    zIndex:"1000"
}

const styleInnerDiv = {
    position:"absolute",
    top: "50%",
    left:"50%",
    transform:  "translate(-50%, -50%)"
}

const MyLoader = () => {
  return (
    <>
        <div style={style} >
            <div style={styleInnerDiv} >
                <ClockLoader 
                    color="#ffffff"
                    size={140}
                />
            </div>
        </div>
    </>
  )
}

export default MyLoader