import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { getAllSubmittedEkycRouting } from "../../utils/ApiServices";
import useCheckPrivilages from "../../utils/checkPrivilages";
import EkycRouting from "./EkycRouting";
import { FETCH_ALL_SUBMITTED_EKYC_ROUTING } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import RouteIcon from "@mui/icons-material/Route";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const SubmittedRoutingEkyc = ({ location, type, toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [mid, setMid] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const { apiCallFnforGet } = useApiServices();
  const[refresh,setRefresh]=useState(false)

  const hasPrivilages2638 = useCheckPrivilages(2638);

  useEffect(() => {
    FetchData();
  }, [openView, isModalRoutingOpen,refresh,setRefresh]);

  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(
        FETCH_ALL_SUBMITTED_EKYC_ROUTING
      );
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const handleShowRouting = () => setIsModalRoutingOpen(true);

  const columns = [
    { headerName: "Merchant Name", field: "company", minWidth: 380 },
    { headerName: "Merchant ID", field: "mid", minWidth: 300 },
    {
      headerName: "Vehicle Master Name",
      field: "vehicleMaster",
      minWidth: 300,
    },
    {
      headerName: "Master Merchant Name",
      field: "merchantMaster",
      minWidth: 300,
    },
  ];

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    if (location === "tools") {
      mappedItem.action = (
        <div>
          {hasPrivilages2638 ? (
            <button
              className="btn btn-primary"
              onClick={() => ViewDetails({ ...item })}
            >
              Edit
            </button>
          ) : null}
        </div>
      );
    } else {
    }

    return mappedItem;
  });

  const ViewDetails = (data) => {
    setApplicationData(data);
    setOpenView(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center me-3">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">E-kyc</div>
          </div>

          <div className="">
            <CustomTip size="18" placement="top" title=" Routing">
              <div className="mt-1" onClick={handleShowRouting}>
                <RouteIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>

        <div style={{ height: "500px", width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.mid}
            rows={mappedData}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      </div>

      {/* Modal Routing */}

  
          
            <EkycRouting
             show={isModalRoutingOpen}
             onHide={handleCloseModalRouting}
             setRefresh={setRefresh} 
             refresh={refresh}
            />
    </>
  );
};

export default SubmittedRoutingEkyc;
