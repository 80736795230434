import React, { useState, useEffect, useLayoutEffect } from "react";
// import PopupMessage from "./PopupMessage";
import swal from "sweetalert";
import { Container, Row } from "react-bootstrap";
import { FETCH_WITHDRAW_AMOUNT_API, SAVE_SERVICE_CHARGE } from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";


const ServiceCharge = ({data}) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [ServiceCharge, setServiceCharge] = useState("0");

  const [serviceChargeError, setServiceChargeError] = useState("");
  
  const [open ,setOpen]=useState(false);
  const [message,setMessage]=useState('false')
  
  const [mid, setMid] = useState("");
  const [fullName, setFullName] = useState("");
  const {apiCallFnforGet,apiCallFnforPost} = useApiServices();

  useEffect(() => {
    getMerchantData(token)
  }, [token,ServiceCharge]);


  const getMerchantData = async () => {
    try {
    
      const response = await apiCallFnforGet(FETCH_WITHDRAW_AMOUNT_API);
     
      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
        console.log(response?.data[0].companyName)
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      // setShowLoader(false);

      setMerchantData([]);
    }
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.companyName === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      setFullName(selectedMerchant.fullName);
      setServiceCharge(selectedMerchant.serviceCharge || 0);
    }else{
      setMid( "");
      setFullName( "");
    }
  };

  const handleServiceChargeChange = (event) => {
    const value = event.target.value;
  
    // Remove any '%' signs from the input
    const numericValue = value.replace('%', '');
  
    const numberPattern = /^[0-9]*(\.[0-9]{0,2})?$/; // Allows positive numbers with up to 2 decimal places
    const isPositive = parseFloat(numericValue) >= 0; // Check if the numericValue is non-negative
  
    if (numberPattern.test(numericValue) && isPositive) {
      setServiceCharge(value); // Keep the '%' sign in the input field
      setServiceChargeError("");
    } else {
      setServiceCharge("");
      setServiceChargeError("Service Charge must be a positive number.");
    }
  };
  
 
  
 const handleSubmit = () => {
   
    const apiPayload = {
      mid: mid,
      serviceCharge: ServiceCharge,
    };


    if(!mid.length || !ServiceCharge.length  )
    {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
       
      })
      return;
    };
      apiCallFnforPost(SAVE_SERVICE_CHARGE , apiPayload,)
      .then((response) => {
       if (response.statusCode === 200){
        swal({
          title: "Saved",
          text: "Data Saved Successfully.",
          icon: "success",
         
        })
        setMid('')
        setServiceCharge('')
        setFullName('')
        setSelectedCompany("")
       }else{
        console.error("Error fetching data:", response.data.message);
      }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <Container>
      <Row>
      {/* {showLoader ? <MyLoader/> : ""} */}
         
    <div className="containerflag">
      <div className="card py-3"> 
      {/* <h1 className="mt-3">Select</h1> */}
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <label>Select Merchant *</label>
          <select
            id="companyDropdown"
            className="form-control"
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Select a Company</option>
            {merchantData?.map((merchant) => (
              <option key={merchant.mid} value={merchant.companyName}>
                {merchant.companyName}
              </option>
            ))}
          </select>
         
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <div className="form-group">
            <label htmlFor="email">Merchant ID</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Merchant ID"
              value={mid}
              readOnly
            />
          </div>
         
          <div className="form-group">
            <label htmlFor="holdAmount">Service Charge (In Percentage) *</label>
            <div className="d-flex gap-4">
              <input
                type="text"
                className="form-control"
                id="holdAmount"
                placeholder="Service Charge*"
                value={ServiceCharge}
                onChange={handleServiceChargeChange}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <div className="button-container">
          <button
					className="btn btn-primary" onClick={ handleSubmit}>
            Submit
          </button>
          </div>
        
        </div>
       
      </div>
      {/* {open && <PopupMessage message={message} open={open} close={setOpen} />} */}
      </div>
    </div>
    </Row>
    </Container>
  );
};

export default ServiceCharge;