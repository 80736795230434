import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
    requestSent,
    requestFailed,
    responseReceived,
  } from "../store/slices/utilSlice"

  
  const useApiServices = () => {
    
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userData);
  const [loading, setLoading] = useState(false);
// console.log("tokenis:",token);
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      setLoading(true);
      dispatch(requestSent());
      
      if (token && config.incHead) {
        config.headers.Authentication = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      setLoading(false);
      dispatch(requestFailed());
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      setLoading(false);
      dispatch(responseReceived());
      return response.data;
    },
    (error) => {
      setLoading(false);
      dispatch(requestFailed());
      return Promise.reject(error);
    }
  );

  const apiCallFnforPost = async (url, payload ) => {
    try {
      const response = await instance.post(url, payload,{
        incHead:true
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };
  const apiCallFnforPostForQueryParams = async (url, payload, queryParams) => {
    try {
      const queryString = new URLSearchParams(queryParams).toString();
      const requestUrl = `${url}?${queryString}`;
      const response = await instance.post(requestUrl, payload, {
        incHead: true
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };
  
  


  const apiCallFnforPostForOpenUrl = async (url, payload ) => {
    try {
      const response = await instance.post(url, payload,{
        incHead:false
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforGet = async (url) => {
    // console.log(url);
    try {
      const response = await instance.get(url,{
        incHead:true
      });

      return response;
    } catch (error) {
      console.error("Error receiving:", error.message);
      throw error;
    }
  };

  const apiCallFnforGetForQueryParams = async (url, queryParams) => {
    try {
      const queryString = new URLSearchParams(queryParams).toString();
      const requestUrl =`${url}?${queryString}`;
      const response = await instance.get(requestUrl, {
        incHead: true
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforPut = async (url, payload) => {
    try {
      const response = await instance.put(url,payload, {
        incHead:true
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforPutForOpenUrl = async (url, payload) => {
    try {
      const response = await instance.put(url, payload,{
        incHead:false
      });
      return response?.data;
    } catch (error) {
      console.error("Error sending:", error);
    }
  };

  const apiCallFnforGetOpenUrl = async (url) => {
    try {
      const response = await instance.get(url,{
        incHead:false
      });
      return response?.data;
    } catch (error) {
      console.error("Error sending:", error);
    }
  };

  
  const apiCallFnforDelete = async (url) => {
    try {
      const response = await instance.delete(url, {
        incHead:true
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  // Define other API service functions here...

  return {
    loading,
    apiCallFnforPost,
    apiCallFnforGet,
    apiCallFnforPut,
    apiCallFnforPutForOpenUrl,
    apiCallFnforGetOpenUrl,
    apiCallFnforPostForOpenUrl,
    apiCallFnforPostForQueryParams,
    apiCallFnforGetForQueryParams,
    apiCallFnforDelete
    // Other API service functions
  };
  
  }
  export default useApiServices;