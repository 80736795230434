import React from "react";
import InactiveServices from "../../common/InactiveServices";

const TechnicalSupport = () => {
  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          {/* <div class="row page-titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"> */}
              <InactiveServices location={"fdhjk"}/>
              {/* </li>
            </ol>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TechnicalSupport;
