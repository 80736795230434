import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

const CustomTip = ({ size,placement,title,children }) => {

    const fontSize = typeof size === 'number' ? `${size}px` : size;
 
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: fontSize,
      backgroundColor: "#709FBA",
      // Adjust the font size as needed
    },
  }))(Tooltip);
  return (
    <CustomTooltip title={title} placement={placement} arrow>
      <span>{children}</span>
    </CustomTooltip>
  );
};

export default CustomTip;
