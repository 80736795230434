
let redirectToLogin;

export const setRedirectFunction = (redirectFunction) => {
    redirectToLogin = redirectFunction;
};

export const redirectIfNoToken = () => {
    if (redirectToLogin) {
        redirectToLogin();
    }
};

