import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEffect, useState } from "react";
import useApiServices from "../utils/useApiServices";
import { TREND_CHART_API } from "../utils/constant";
import IbtnWrapper from "../common/CustomToolTip/IButtonWrraper";
import MiniLoader from "../common/MiniLoader/MiniLoader";
import { axisClasses } from "@mui/x-charts";

export default function SimpleBarChart({ condition, mid }) {
  const [successSeries, setSuccessSeries] = useState([]);
  const [failedSeries, setFailedSeries] = useState([]);
  const [pendingSeries, setPendingSeries] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const { apiCallFnforPost } = useApiServices();

  const getDataPayinServices = async () => {
    const payload = {
      mid: mid,
      timeFrame: condition,
    };
    setShowLoader(true); // Start loader when fetching data
    try {
      const response = await apiCallFnforPost(TREND_CHART_API, payload);
      if (response.statusCode === 200) {
        setSuccessSeries(
          response?.responseData?.map((dataPoint) => parseInt(dataPoint.successCount))
        );
        setFailedSeries(
          response?.responseData?.map((dataPoint) => parseInt(dataPoint.failedCount))
        );
        setPendingSeries(
          response?.responseData?.map((dataPoint)=>parseInt(dataPoint.pending))
        )
        setXAxisLabels(
          response?.responseData?.map((dataPoint) => {
            const [year, month, day] = dataPoint.date.split("-");
            return `${month}-${day}`;
          })
        );
      } else {
        setSuccessSeries([]);
        setFailedSeries([]);
        setPendingSeries([])
      }
    } catch (error) {
      setSuccessSeries([]);
      setFailedSeries([]);
      setPendingSeries([])
    } finally {
      setShowLoader(false); // Stop loader after data is fetched
    }
  };

  const chartSetting = {
    yAxis: [
      {
        label: "Transactions Counts",
        fontSize: "10px",
      },
    ],
    xAxis: [
      { data: xAxisLabels, scaleType: "band", label: `Dates of ${condition}` },
    ],
    width: 1000,
    height: 500,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-25px, 0)",
      },
    },
  };

  useEffect(() => {
    getDataPayinServices();
  }, [condition, mid]);

  useEffect(() => {
    console.log(successSeries);
    console.log(failedSeries);
    console.log(xAxisLabels);
  }, [successSeries, failedSeries,pendingSeries, xAxisLabels]);

  return (
    <div className="col-xl-12 col-xxl-12">
      <div className="card">
        <div className="card-header d-block border-0">
          <div className="d-flex align-items-center justify-content-between mb-1">
            <h4 className="card-title">Overview Payment</h4>
            <IbtnWrapper color={"text-black"} desc={"Overview Payment"} />
          </div>
        </div>
        <div className="card-body py-0 px-sm-3 d-flex justify-content-center align-items-center">
          {showLoader ? (
            <div className="d-flex align-items-center justify-content-center mb-4">
              <MiniLoader />
            </div>
          ) : successSeries.length !== 0 && failedSeries.length !== 0  && pendingSeries !== 0 ?  (
            <BarChart
              height={500}
              series={[
                {
                  data: successSeries,
                  label: "Success",
                  id: "pvId",
                  color: "#76FF7A",
                  borderRadius: "5px",
                },
                {
                  data: failedSeries,
                  label: "Failed",
                  id: "uvId",
                  color: "#FF0000",
                  borderRadius: "5px",
                },
                {
                  data: pendingSeries,
                  label: "Pending",
                  id: "vvId",
                  color: "#EE9D2E",
                  borderRadius: "5px",
                },
              ]}
              {...chartSetting}
            />
          ) : (
            <div className="d-flex align-items-center justify-content-center mb-4">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
