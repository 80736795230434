import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Col, Modal, Row, Card } from "react-bootstrap";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import {
  ENABLE_DISABLE_PAYOUT,
  EnableDisableAggragator,
  GET_ALL_AGGREGATOR,
  GET_ALL_MECHANT_PAYOUT_MASTERS,
  GET_ALL_MERCHANT_MASTER,
  GET_ALL_VEHICAL_MASTER_FORMS,
  UPDATE_PAYOUT_SUBMIT,
} from "../../../utils/constant";
import swal from "sweetalert";
import useApiServices from "../../../utils/useApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PayoutMasterForm from "./PayoutMasterForm";
import CustomTip from "../../../common/CustomToolTip/CustomTip";

const SubmittedPayoutMasterForm = ({ type, toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [updateMId, setUpdateMId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isIfscError, setIsIfscError] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [formDataInputs, setFormDataInputs] = useState({
    accountsModuleSecrete: "",
    clientIds: "",
    clientSecrete: "",
    ifsc: "",
    mid: "",
    masterAliusId: "",
    masterVirtualAccount: "",
    transferType: "",
    mvaCustomerId: "",
    mvaMobileNumber: "",
    password: "",
    paymentModuleSecrete: "",
    userName: "",
    providerSecrete: "",
  });
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    vehicle: "",
    merchantMasterName: "",
    merchantMasterId: "",
  });

  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickIcon = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const navigate = useNavigate();
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  const getAllVehicle = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICAL_MASTER_FORMS);

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

  const handleAction = (item, action) => {
    switch (action) {
      case "edit":
        openDetails({ ...item });
        break;
      case "enable":
        if (!item.active) {
          disableAggregator(item.id, "enable");
        }
        break;
      case "disable":
        if (item.active) {
          disableAggregator(item.id, "disable");
        }
        break;
      default:
        break;
    }
  };
  const handleMenuItemClick = (action) => {
    setSelectedAction(action);
    setAnchorEl(null);
    setIsModalOpen(true);

    // Close the menu after selecting an option
  };
  const columns = [
    { headerName: "Merchant ID", field: "mid", width: 150 },
    { headerName: "Merchant Name", field: "companyName", width: 210 },
    { headerName: "User Name", field: "userName", width: 190 },
    {
      headerName: "Vehicle Master Name",
      field: "vehichleMasterName",
      width: 210,
    },
    {
      headerName: "Merchant Master Name",
      field: "masterMerchantName",
      width: 190,
    },
    { headerName: "Client ID", field: "clientIds", width: 150 },
    { headerName: "Client Secret", field: "clientSecrete", width: 230 },
    {
      headerName: "Account Module Secret",
      field: "accountsModuleSecrete",
      width: 210,
    },
    {
      headerName: "Payment Module Secret",
      field: "paymentModuleSecrete",
      width: 210,
    },
    { headerName: "Provider Secret", field: "providerSecrete", width: 220 },
    {
      headerName: "master Virtual Account",
      field: "masterVirtualAccount",
      width: 180,
    },
    { headerName: "Transfer Type", field: "transferType", width: 150 },
    { headerName: "MVA Customer Id", field: "mvaCustomerId", width: 170 },
    { headerName: "MVA Mobile Number", field: "mvaMobileNumber", width: 140 },
    { headerName: "Master Alius Id", field: "masterAliusId", width: 190 },
    { headerName: "IFSC Code", field: "ifsc", width: 150 },
    { headerName: "Password", field: "password", width: 290 },
    { headerName: "Action", field: "action", minWidth: 100 },
    //  {headerName: "Enable / Disable",field: "active",minWidth: 100, },
  ];
  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(GET_ALL_MECHANT_PAYOUT_MASTERS);
      if (apiResponse.statusCode === 200) {
        const finalRes = apiResponse.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          companyName: item.companyName,
          userName: item.userName,
          vehichleMasterName: item.vehichleMasterName,
          masterMerchantName: item.masterMerchantName,
          clientIds: item.clientIds,
          clientSecrete: item.clientSecrete,
          accountsModuleSecrete: item.accountsModuleSecrete,
          paymentModuleSecrete: item.paymentModuleSecrete,
          providerSecrete: item.providerSecrete,
          masterVirtualAccount: item.masterVirtualAccount,
          transferType: item.transferType,
          mvaCustomerId: item.mvaCustomerId,
          mvaMobileNumber: item.mvaMobileNumber,
          masterAliusId: item.masterAliusId,
          ifsc: item.ifsc,
          password: item.password,
          // active: (
          //   <button
          //     className={badge badge-rounded mx-2 px-3 py-2  ${!item.active ? "badge-success" : "badge-danger"}}
          //     value={item.active ? "disable" : "enable"}
          //     onClick={(e) => disableAggregator(e, item.id)}
          //   >
          //     {item.active ? "Disable" : "Enable"}
          //   </button>
          // ),
          action: (
            <Select
              value={selectedAction}
              onChange={(e) => {
                setSelectedAction(e.target.value);
                handleAction(item, e.target.value);
              }}
              displayEmpty
              renderValue={() => null}
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: "8px 2px", // Optional: Adjust padding for better spacing
                height: "25px",
              }}
            >
              <MenuItem
                value="edit"
                onClick={() => {
                  handleMenuItemClick("edit");
                }}
              >
                Edit
              </MenuItem>
              {item.active ? (
                <MenuItem value="disable" className="bg-danger text-white">Disable</MenuItem>
              ) : (
                <MenuItem value="enable" className="bg-success text-white">Enable</MenuItem>
              )}

              {/* onClick={() => openDetails({ ...item })} */}
            </Select>
          ),
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData();
  }, [callAgain, show, setRefresh,refresh]);

  const disableAggregator = async (id, action) => {
    const EnableOrDisable = action === "enable" ? "enable" : "disable";

    swal({
      title: "Alert",
      text: `Are you sure, you want to ${EnableOrDisable}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(`${ENABLE_DISABLE_PAYOUT}${action}/${id}`, "")
          .then((res) => {
            if (res.statusCode === 200) {
              swal({
                title: "Success",
                text: `Aggregator ${EnableOrDisable} Successfully.`,
                icon: "success",
              });
              setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Success",
                text: `Failed to ${EnableOrDisable} Aggregator.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
      }
    });
  };

  const openDetails = ({
    id,
    mid,
    accountsModuleSecrete,
    companyName,
    clientIds,
    clientSecrete,
    ifsc,

    masterAliusId,
    masterVirtualAccount,
    transferType,
    mvaCustomerId,
    mvaMobileNumber,
    password,
    paymentModuleSecrete,

    userName,
    providerSecrete,
    vehichleMasterId,
    masterMerchantId,
    masterMerchantName,
  }) => {
    // console.log(accountsModuleSecrete);
    setFormDataInputs({
      accountsModuleSecrete,
      clientIds,
      clientSecrete,
      ifsc,
      mid,
      masterAliusId,
      masterVirtualAccount,
      transferType,
      mvaCustomerId,
      mvaMobileNumber,
      password,
      paymentModuleSecrete,
      userName,
      providerSecrete,
    });
    setData({
      vehicle: vehichleMasterId,
      merchantMasterName: masterMerchantName,
      merchantMasterId: masterMerchantId,
    });
    setCompanyName(companyName);
    setUpdateId(id);
    setUpdateMId(mid);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function stopSpecialCharacterInput(input) {
    // Define a regular expression for alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    // Test the input against the regular expression
    return alphanumericRegex.test(input);
  }

  function setNumericInput(input) {
    // Define a regular expression for numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test the input against the regular expression
    return numericRegex.test(input) ? input : "";
  }

  const HandleIFSC = (e) => {
    if (e.target.name === "ifsc") {
      const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
      const isValidIfsc = ifscRegex.test(e.target.value);
      if (!isValidIfsc) {
        setIsIfscError(true);
        document.getElementById("ifscErr").classList.remove("d-none");

        return;
      } else {
        setIsIfscError(false);
        document.getElementById("ifscErr").classList.add("d-none");
      }
      setIsIfscError(false);
    }
  };
  const handelDataChange = (e) => {
    console.log(e.target.name, e.target.value);

    if (!e.target.value.length) {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "accountsModuleSecrete" ||
      e.target.name === "paymentModuleSecrete" ||
      e.target.name === "providerSecrete" ||
      e.target.name === "ifsc" ||
      e.target.name === "clientSecrete"
    ) {
      if (stopSpecialCharacterInput(e.target.value)) {
        console.log(stopSpecialCharacterInput(""));
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value,
        });
      } else {
        return;
      }
    } else if (
      e.target.name === "mvaMobileNumber" ||
      e.target.name === "masterVirtualAccount" ||
      e.target.name === "mvaCustomerId"
    ) {
      if (setNumericInput(e.target.value)) {
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value === "" ? "" : e.target.value,
        });
      } else {
        return;
      }
    } else {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value === "" ? "" : e.target.value,
      });
    }
  };

  const handelVehicleDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId, master, e.target.value);
      if (e.target.value === "") {
        setData({
          ...data,
          [e.target.name]: "",
          merchantMasterName: "",
          merchantMasterId: "",
        });
        return;
      }
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    if (formDataInputs.accountsModuleSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Account Module Secret",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.clientIds === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Client ID",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.clientSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Client Secrete",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.ifsc === "") {
      swal({
        title: "Alert!",
        text: "Please Enter IFSC ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.mvaCustomerId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter MVA Customer ID ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.masterAliusId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Alius ID ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.masterVirtualAccount === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Virtual Account ",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.userName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter UserName ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.providerSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Provider Secrete ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.paymentModuleSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Payment Module Secrete ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Password ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.mvaMobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter MVA Mobile Number ",
        icon: "error",
      });
      return;
    }

    const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
    const isValidIfsc = ifscRegex.test(formDataInputs.ifsc);
    if (!isValidIfsc) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid IFSC Code ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.mvaMobileNumber.length !== 10) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid MVA Mobile Number ",
        icon: "error",
      });
      return;
    }

    const payload = {
      ...formDataInputs,
      masterMerchantId: data.merchantMasterId,
      vehichleMasterId: data.vehicle,
    };

    apiCallFnforPut(UPDATE_PAYOUT_SUBMIT + updateId, payload)
      .then((response) => {
        console.log(response);

        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.message,
            icon: "success",
          });

          // setFormDataInputs({
          //   sid: "",
          //   txnAmt: "",
          //   mid: "",
          //   merchantName: "",
          //   id: "",
          // });
          setSelectedAction();
          setFormDataInputs({
            accountsModuleSecrete: "",
            clientIds: "",
            clientSecrete: "",
            ifsc: "",
            mid: "",
            masterAliusId: "",
            masterVirtualAccount: "",
            transferType: "",
            mvaCustomerId: "",
            mvaMobileNumber: "",
            password: "",
            paymentModuleSecrete: "",
            userName: "",
            providerSecrete: "",
          });
          handleCloseModal();
          setCallAgain(!callAgain);
          // openDetails()
        } else {
          swal({
            title: "Alert!",
            text: response.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleClose = () => setShow(false);

  const handleOnClick = () => {
    setShow(true);
  };

  return (
    <div className="">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Payout - Master </div>
          </div>

          <div className="d-flex justify-content-end ">
            <CustomTip size="18" placement="top" title="Payout Master Form">
              <div className="" onClick={handleOnClick}>
                <AddCircleOutlineIcon fontSize="large" />
              </div>
            </CustomTip>
            {/* <div className="d-flex gap-2">
              <button
                className="badge badge-primary px-2 py-2 "
                onClick={handleOnClick}
              >
                Payout Master Form
              </button>
            </div> */}
                      
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={applications}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" keyboard={false} size="xl">
              <Modal.Header closeButton>
                <Modal.Title className="fw-bold">
                  Edit Payout Master
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Card className="shadow border-0 mb-4"> */}
                {/* <Card.Body> */}
                <div className="merchant-report-view">
                  <div className="report-table-view">
                    <div className="containerflag ">
                      <div className="row">
                        <div className="col-sm-12 col-md-8  offset-md-2 ">
                          <label className="mb-1 fs-4 text-dark">
                            Merchant Name
                          </label>
                          <input
                            type="text"
                            className="form-control fs-5"
                            id="email"
                            placeholder="Merchant Name"
                            value={companyName}
                            readOnly
                          />
                        </div>

                        <div className="col-sm-12 col-md-8 offset-md-2">
                          <div className="form-group mt-3">
                            <label className="mb-1 fs-4 text-dark">
                              Merchant MID
                            </label>
                            <input
                              type="text"
                              className="form-control fs-5"
                              id="email"
                              placeholder="Company Name"
                              value={updateMId}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <Row className="mt-2">
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Select A Vehicle
                            </label>
                            <select
                              id="companyDropdown"
                              className="form-control fs-5"
                              onChange={handelVehicleDataChange}
                              name="vehicle"
                              value={data.vehicle}
                            >
                              <option value="">Select a Vehicle</option>
                              {vehicleData?.map((vehicle) => (
                                <option
                                  key={vehicle.id}
                                  data-master={vehicle.masterMerchantName}
                                  data-masterId={vehicle.masterMerchantId}
                                  value={vehicle.id}
                                >
                                  {vehicle.vehicleName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Select Master Merchant
                            </label>
                            <input
                              id="companyDropdown"
                              className="form-control fs-5"
                              // onChange={handelVehicleDataChange}
                              readOnly
                              name="merchantMaster"
                              value={data.merchantMasterName}
                            />
                          </div>
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Account Module Secret
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="accountsModuleSecrete"
                                value={formDataInputs?.accountsModuleSecrete}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Client Id
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="clientIds"
                                value={formDataInputs.clientIds}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Client Secret
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="clientSecrete"
                                value={formDataInputs.clientSecrete}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              IFSC
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="ifsc"
                                value={formDataInputs.ifsc}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>

                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              MVA Customer Id
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="mvaCustomerId"
                                value={formDataInputs.mvaCustomerId}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Master Alius Id
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="masterAliusId"
                                value={formDataInputs.masterAliusId}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Master Virtual Account
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="masterVirtualAccount"
                                value={formDataInputs.masterVirtualAccount}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              User Name
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="userName"
                                value={formDataInputs.userName}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Provider Secret
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="providerSecrete"
                                value={formDataInputs.providerSecrete}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Payment Module Secret
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="paymentModuleSecrete"
                                value={formDataInputs.paymentModuleSecrete}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              Password
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="password"
                                value={formDataInputs.password}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6} className="mt-3">
                          <div className="form-group">
                            <label className="mb-1 fs-4 text-dark">
                              MVA Mobile Number
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="d-flex gap-2">
                              <input
                                className="form-control fs-5"
                                name="mvaMobileNumber"
                                value={formDataInputs.mvaMobileNumber}
                                onChange={handelDataChange}
                              />
                            </div>
                          </div>

                          {/* ./form-group */}
                        </Col>
                      </Row>

                      <div>
                        <div className="d-flex justify-content-end mt-3">
                          <button
                            className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                            id="UpdateFormBtn"
                            onClick={handleUpdateSubmit}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </Card.Body> */}
                {/* </Card> */}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
      <PayoutMasterForm show={show} onHide={handleClose} setRefresh={setRefresh} refresh={refresh} />
    </div>
  );
};

export default SubmittedPayoutMasterForm;