import React, { useEffect, useState } from "react";
// import { FiDownload } from "react-icons/fi";
import Papa from "papaparse";

import { Container, Card, Row, Col, Offcanvas } from "react-bootstrap";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import OpenDetails from "./OpenDetails";
import {
  FETCH_FULL_MERCHANT_DATA,
  GET_ALL_MERCHANT_DATA,
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function Details({ toggleRightBar, type1 }) {
  const [merchantData, setMerchantData] = useState([]);
  const [DownloadData, setDownloadData] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [mid, setMid] = useState("");
  const { apiCallFnforGet } = useApiServices();

  useEffect(() => {
    fetchMerchantData();
    fetchFullMerchantData();
  }, []);

  const fetchMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_DATA);
      if (response.statusCode === 200) {
        const finalResp = response.data.map((item, i) => ({
          id: i,
          companyName: item.companyName,
          fullName: item.fullName,
          mid: item.mid,
          userName: item.userName,
          action: (
            <>
              <button
                className="badge badge-rounded badge-warning "
                onClick={() => openDetails(item.mid)}
              >
                View
              </button>
            </>
          ),
        }));
        setMerchantData(finalResp);
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      console.error("Error fetching merchant data:", error);
    }
  };
  const fetchFullMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_FULL_MERCHANT_DATA);
      if (response.statusCode === 200) {
        setDownloadData(response.data);
      } else {
        setDownloadData([]);
      }
    } catch (error) {
      console.error("Error fetching merchant data:", error);
    }
  };

  const openDetails = (mid) => {
    setMid(mid);
    setOpenView(true);
  };
  const handleClose = () => {
    setMid("");
    setOpenView(false);
  };

  const hasPrivilage = useCheckPrivilages(2681);

  const columns = [
    { headerName: "Company Name", field: "companyName", minWidth: 310 },
    { headerName: "Authorised Person Name", field: "fullName", minWidth: 250 },
    { headerName: "Merchant Id", field: "mid", minWidth: 190 },
    { headerName: "User Name", field: "userName", minWidth: 250 },
    { headerName: "Action", field: "action", minWidth: 150 },
  ];

  const HandleDownloadData = (DownloadData) => {
    const TransformedData = DownloadData.map((item) => {
      return {
        "Company name": item.companyName || "-",
        "API key": item.apiKey || "-",
        "Secret key": item.secretKey || "-",
        "Encription key": item.encKey || "-",
        Username: item.userName || "-",
        Password: item.password || "-",
        "Mobile number": item.mobile || "-",
        "e-Mail ID": item.email || "-",
        "Authorised person first name ": item.authorizedPersonFirstName || "-",
        "Authorised person name ": item.authorizedPersonName || "-",
        "Authorised person last name ": item.authorizedPersonLastName || "-",
        "Bussiness type": item.businessType || "-",
        "Company type": item.companyType || "-",
        "Company PAN number": item.companyPanNumber || "-",
        "GST number": item.gstNumber || "-",
        "Website URL": item.websiteUrl || "-",
        "Business As": item.businessAs || "-",
        "Company Address": item.companyAddress || "-",
        "Company address Pin-Code": item.companyAddressPinCode || "-",
        "Company address city": item.companyAddressCity || "-",
        "Company address state": item.companyAddressState || "-",
        "Aadhar Number": item.aadharNumber || "-",
        "Authorized Person PAN Number": item.authorizedPersonPan || "-",
        "Registered office address": item.registeredOfficeAddress || "-",
        "Registered office address Pin-Code":
          item.registeredOfficeAddressPinCode || "-",
        "Registered office address city":
          item.registeredOfficeAddressCity || "-",
        "Registered office address state":
          item.registeredOfficeAddressState || "-",
        "Communication address": item.communicationAddress || "-",
        "Communication address Pin-Code":
          item.communicationAddressPinCode || "-",
        "Communication address city": item.communicationAddressCity || "-",
        "Communication address state": item.communicationAddressState || "-",
        "Call-Back URL": item.callBackUrl || "-",
        "Merchant bank name": item.merchantBankName || "-",
        "Merchant account number": item.merchantAccountNumber || "-",
        "Merchant IFSC code": item.merchantIfscCode || "-",
        "Bussiness type name": item.businessTypeName || "-",
        "Refferred by": item.referredBy || "-",
        "Bank branch name": item.bankBranchName || "-",
        "Merchant ID": item.mid || "-",
        "Communication address area name":
          item.communicationAddressAreaName || "-",
        "Registered office address area name":
          item.registeredOfficeAddressAreaName || "-",
        "Company address area name": item.companyAddressAreaName || "-",
        "App URL": item.aapUrl || "-",
        "Payout callback URL": item.payoutCallbackUrl || "-",
      };
    });
    return TransformedData;
  };

  const FinalDownloadData = HandleDownloadData(DownloadData);

  const headers = [
    "Company name",
    "API key",
    "Secret key",
    "Encription key",
    "Username",
    "Password",
    "Mobile number",
    "e-Mail ID",
    // "Application Status",
    "Authorised person first name ",
    "Authorised person name ",
    "Authorised person last name ",
    "Bussiness type",
    "Company type",
    "Company PAN number",
    "GST number",
    "Website URL",
    "Business As",
    "Company Address",
    "Company address Pin-Code",
    "Company address city",
    "Company address state",
    "Aadhar Number",
    "Authorized Person PAN Number",
    "Registered office address",
    "Registered office address Pin-Code",
    "Registered office address city",
    "Registered office address state",
    "Communication address",
    "Communication address Pin-Code",
    "Communication address city",
    "Communication address state",
    "Call-Back URL",
    "Merchant bank name",
    "Merchant account number",
    "Merchant IFSC code",
    "Bussiness type name",
    "Referred by",
    "Bank branch name",
    "Merchant ID",
    "Communication address area name",
    "Registered office address area name",
    "Company address area name",
    "App URL",
    "Payout callback URL",
  ];

  function handleDownloadExcel(FinalDownloadData, headers) {
    try {
      if (!FinalDownloadData) {
        console.error("Error: FinalDownloadData is undefined or null");
        return;
      }
      const apiData = FinalDownloadData;
      console.log(FinalDownloadData);
      if (!apiData || apiData.length === 0) {
        console.error("Error: apiData is undefined, null, or empty");
        return;
      }

      if (!Array.isArray(apiData)) {
        console.error("Error: apiData is not an array");
        return;
      }

      if (apiData.length === 0) {
        console.error("Error: apiData is an empty array");
        return;
      }

      // Extract the data from the originalData
      const apiRows = apiData.map((item) => Object?.values(item));

      // Combine header with data
      const dataWithHeader = [headers, ...apiRows];

      // Convert the data with header to CSV format using PapaParse
      const csv = Papa.unparse(dataWithHeader);

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Report.csv"; // Change the file name as needed
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-start align-items-center">
          <button
            type="button"
            class="btn-close m-3"
            onClick={toggleRightBar}
          ></button>

          <div className="fs-2 text-dark fw-bold">Merchant Details</div>
        </div>
        {/* <Offcanvas.Header  style={{background:"#c4c4c4"}}>
    <h5 class="offcanvas-title" id="offcanvasLabel">Create Merchant Phase1</h5>
    </Offcanvas.Header> */}

        {/* <Card className="shadow border-0 mb-4"> */}
        <Card.Body>
          <div style={{ height: "500px", width: "100%" }}>
            {!openView ? (
              <DataGrid
                rows={merchantData}
                columns={columns?.map((column) => ({
                  ...column,
                  renderCell: (params) =>
                    column.field === "action" ? params.value : params.value,
                }))}
                slots={{
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            ) : openView ? (
              <OpenDetails mid={mid} handleClose={handleClose} />
            ) : null}
          </div>

          <div className="report-grid-view">
            <div className="material-table-records mb-3">
              {/* <button
                    className="advanced-btn btn btn-primary text-capitlize"
                    onClick={() =>
                      handleDownloadExcel(FinalDownloadData, headers)
                    }
                  >
                    <FiDownload className="d-none" />
                    <i className="bi bi-arrow-down-circle"></i> Download CSV
                  </button> */}
            </div>
          </div>
        </Card.Body>
        {/* </Card> */}
      </div>
      {/* </div> */}
    </>
  );
}

export default Details;
