import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import RemoveIcon from "@mui/icons-material/Remove";
import { FIND_PAYIN_DETAILS, SWIPELINC_API } from "../../../utils/constant";
// import "../../../assets/css/scrollbar.css"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useApiServices from "../../../utils/useApiServices";

const ViewDetailsForReconcialiation = ({ id, setIsModalOpen, isModalOpen }) => {
  console.log(setIsModalOpen, isModalOpen, id);

  const [payoutDetails, setPayinDetails] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { apiCallFnforGet } = useApiServices();
  useEffect(() => {
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });

    axiosInstance
      .get("/getPayinDetail/id", {
        params: {
          id: id,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setPayinDetails(response.data.data);
        } else if (response.data.responseCode === 304) {
          setPayinDetails([]);
        } else {
          setPayinDetails([]);
        }
        //   setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        // setShowLoader(false)
      });
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">
            Reconcialiation Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4 ">
            <div className="table table-bordered">
              <div className=" ">
                <div className="row">
                  {[
                    { label: " Merchant ID", value: payoutDetails?.mid },
                    { label: "Transaction Id ", value: payoutDetails?.txn_id },
                    {
                      label: "Payment Type ",
                      value: payoutDetails?.paymentType,
                    },
                    { label: "Amount ", value: payoutDetails?.amount },
                    {
                      label: "Actual Amount ",
                      value: payoutDetails?.actualAmount,
                    },
                    {
                      label: "Transaction Date ",
                      value: payoutDetails?.txnDate,
                    },
                    {
                      label: " Response Date",
                      value: payoutDetails?.responseDate,
                    },
                    {
                      label: "Transaction Status ",
                      value: payoutDetails?.txnStatus,
                    },
                    { label: "UTR Number ", value: payoutDetails?.custRefNo },
                    { label: "Order No ", value: payoutDetails?.orderNo },
                    { label: "E-mail ", value: payoutDetails?.email },
                    { label: "UPI ", value: payoutDetails?.userVpa },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="col-6 border border-dark border-bottom"
                      style={{
                        borderRight: "0.5px solid black",
                        borderLeft: "0.5px solid black",
                        paddingBottom: "0px",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center table table-bordered">
                        <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                          {item.label} <RemoveIcon />
                        </tr>{" "}
                        <tr className="mt-2 w-100 text-end">{item.value}</tr>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDetailsForReconcialiation;