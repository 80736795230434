import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import axios from "axios";
import MyModal from "./MyModel";
// import MyModalGuarenteeAmount from "./MyModalGuarenteeAmount";
import { Row, FormLabel, Modal } from "react-bootstrap";

import useCheckPrivilages from "../../utils/checkPrivilages";
import MyModalGuarenteeAmount from "./MyModalGuarenteeAmount";

const GuarenteeAmount = ({ data, show, onHide }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    email: "",
    mid: "",
    mobile: "",
    fullName: "",
  });

  const hasPrivilage = useCheckPrivilages(2693);

  const [option, setOption] = useState("blank");

  const [showModal, setShowModal] = useState(false);
  const resetData = () => {
    setFormData({
      email: "",
      mid: "",
      mobile: "",
      fullName: "",
    });
    setSelectedCompany("");
    // setMerchantData([])
    setOption("blank");
  };

  useEffect(() => {
    setMerchantData(data);
  }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });
    } else {
      // Reset form fields if no company is selected
      setFormData({
        mid: "",
      });
    }
  };

  const handleOptionsChange = (e) => {
    const value = e.target.value;
    setOption(value);
    console.log(option);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    resetData();
  };
  const handleClose = () => {
    onHide();
  };

  return (
    <>
      {!showModal && (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="">
          <Modal.Header closeButton>
            <Modal.Title className="fs-4 fw-bold">Guarantee Amount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row">
              <div class="col-12">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-6 b-2 ">
                    <div className="mb-3">
                      <label className="d-flex  mb-1 text-dark fs-4 mt-2">
                        
                        Select a Merchant
                      </label>
                      <select
                        id="companyDropdown"
                        className="form-control "
                        onChange={handleCompanyChange}
                        value={selectedCompany}
                      >
                        <option value="">Select a Merchant</option>
                        {merchantData?.map((merchant) => (
                          <option
                            key={merchant.mid}
                            value={`${merchant.mid} :- ${merchant.merchantName}`}
                          >
                            {merchant.mid} :- {merchant.merchantName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="d-flex  mb-1 text-dark fs-4 mt-2"
                        >
                          Merchant ID
                        </label>
                        <input
                          type="email"
                          className="form-control "
                          id="email"
                          placeholder="Merchant ID"
                          value={formData.mid}
                          readOnly
                        />
                      </div>

                      <div className="form-group">
                        <label className="d-flex  mb-1 text-dark fs-4 mt-2">
                          Select
                        </label>
                        <select
                          className="form-control "
                          onChange={handleOptionsChange}
                          value={option}
                        >
                          <option key={0} value="blank">
                            Select
                          </option>
                          <option key={1} value="credit">
                            Receive
                          </option>
                          <option key={2} value="debit">
                            Return
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* {formData.mid &&
                  (option === "credit" || option === "debit") ? (
                    <MyModalGuarenteeAmount
                      showModal={showModal}
                      closeModal={closeModal}
                      mid={formData.mid}
                      transactionType={option}
                    />
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showModal &&
      formData.mid &&
      (option === "credit" || option === "debit") ? (
        <MyModalGuarenteeAmount
          showModal={showModal}
          closeModal={closeModal}
          mid={formData.mid}
          transactionType={option}
        />
      ) : (
        ""
      )}

      {/* <div class=""> */}
      {/* <div class=""> */}

      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default GuarenteeAmount;
