import React, { useState, useEffect } from "react";
import { FETCH_ALL_FRM_SUBMITTED_FORM } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import { useNavigate } from "react-router-dom";
import FraudAndRiskManagement from "./FraudAndRiskManagement";
import { Card, Modal } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ViewSingleFrmForm from "./ViewSingleFrmForm";
import FeedIcon from "@mui/icons-material/Feed";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const FRMSubmitted = ({ type, toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [mid, setMid] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const[refresh,setRefresh]=useState(false);
  const navigate = useNavigate();

  const { apiCallFnforGet } = useApiServices();
  useEffect(() => {
    FetchData(token);
  }, [refresh,setRefresh]);

  const handleShowRouting = () => setIsModalRoutingOpen(true);
  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const columns = [
    { headerName: "Merchant Name", field: "companyName", minWidth: 350 },
    { headerName: "e-Mail", field: "email", minWidth: 300 },
    { headerName: "Merchant ID", field: "mid", minWidth: 300 },
    { headerName: "Action", field: "action", minWidth: 200 },
  ];
  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.action = (
      <div>
        <button
          className="badge badge-rounded badge-warning"
          onClick={() => ViewDetails(item.mid)}
        >
          View
        </button>
      </div>
    );
    return mappedItem;
  });
  const ViewDetails = (mid) => {
    setMid(mid);
    setOpenView(true);
  };

  const FetchData = async () => {
    // setShowLoader(true)
    try {
      const apiResponse = await apiCallFnforGet(FETCH_ALL_FRM_SUBMITTED_FORM);

      if (apiResponse.statusCode === 200) {
        console.log(apiResponse);
        const finalRes = apiResponse.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          companyName: item.companyName,
          email: item.email,
          action: (
            <button
              className=" badge badge-rounded badge-warning"
              onClick={() => ViewDetails(item.mid)}
            >
              View
            </button>
          ),
        }));
        finalRes?.sort((a, b) => a?.companyName?.localeCompare(b?.companyName));
        setApplications(finalRes);

        console.log(finalRes);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setMid("");
    setOpenView(false);
  };

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">
              Fraud & Risk Management
            </div>
          </div>

          <div className="me-4">
            <CustomTip
              size="18"
              placement="top"
              title="Froud & Risk Management Form"
            >
              <div className="" onClick={handleShowRouting}>
                <FeedIcon />
              </div>
            </CustomTip>
          </div>
        </div>

        <div style={{ height: "500px", width: "100%" }}>
          {openView ? (
            <ViewSingleFrmForm
              setOpenView={setOpenView}
              mid={mid}
              openView={openView}
              handleClose={handleClose}
            />
          ) : (
            <DataGrid
              rows={applications}
              columns={columns.map((column) => ({
                ...column,
                renderCell: (params) =>
                  column.field === "active" || column.field === "action"
                    ? params.value
                    : params.value,
              }))}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              searching={true}
              entries={50}
              exportToCSV
              theadColor="#333"
              paginationLabel={["Previous", "Next"]}
              sortable={true}
              footer="none"
            />
          )}
        </div>
      </div>
      {/* </div> */}


      {isModalRoutingOpen && ( // Show Routing component conditionall
        <FraudAndRiskManagement
          show={isModalRoutingOpen}
          onHide={() => setIsModalRoutingOpen(false)} // Hide modal function
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}


    </>
  );
};

export default FRMSubmitted;
