import React, { useState } from "react";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  PAYOUTROUTING_FIND_MERCHANT,
  POST_PAYOUT_API_TEST,
  POST_WALLET_BALANCE,
} from "../../utils/constant";
import swal from "sweetalert";
import {
  getKeyPressed,
  isIfscRegexIsValid,
  isVpaUpiRegexIsValid,
  stopAlphabetInput,
  stopSpecialCharacterInput,
} from "../../utils/helper";
import useApiServices from "../../utils/useApiServices";
import { Col } from "react-bootstrap";

const PayoutApiTest = ({ toggleRightBar, type1 }) => {
  const [merchant, setMerchant] = useState([]);
  const [transferType, setTransferType] = useState("upi");
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [merchantData, setMerchantData] = useState({
    referenceId: "",
    mid: "",
    clientId: "",
    providerSecret: "",
    masterVirtualAccount: "",
    AccountModuleSecret: "",
    callbackURL: "",
    apiKey: "",
    secrete: "",
  });

  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPostForOpenUrl } =
    useApiServices();
  const [balance, setBalance] = useState({
    creditBalance: "",
    payoutWalletBalance: "",
  });

  const [response, setResponse] = useState("");

  const [formData, setFormData] = useState({
    merchantMid: "",
    upi: "",
    accNumber: "",
    ifscCode: "",
  });

  const handelInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getMerchantInfo = async (mid, transType) => {
    if (mid === "") {
      swal({
        title: "Alert",
        text: "Please Select A Merchant",
        icon: "error",
      });
      return;
    }

    setMerchantData({
      referenceId: "",
      mid: "",
      clientId: "",
      providerSecret: "",
      masterVirtualAccount: "",
      AccountModuleSecret: "",
      callbackURL: "",
      apiKey: "",
      secrete: "",
    });

    try {
      // setShowLoader(true);
      const res = await apiCallFnforGet(PAYOUTROUTING_FIND_MERCHANT + mid);
      if (res?.statusCode === 306) {
        swal({
          title: "Alert",
          text: res?.message,
          icon: "error",
        });
      }

      if (res?.statusCode === 200) {
        fetchWalletBal(transType, mid);
        if (res?.data?.length === 1) {
          if (
            transType.toLocaleLowerCase() ===
            res.data[0].transferType?.toLocaleLowerCase()
          ) {
            setMerchantData({
              ...merchantData,
              referenceId: "REFID" + Date.now(),
              mid: res.data[0]?.mid,
              callbackURL: res.data[0]?.callbackUrl,
              AccountModuleSecret: res.data[0]?.accountsModuleSecret,
              masterVirtualAccount: res.data[0]?.masterVirtualAccount,
              providerSecret: res.data[0]?.providerSecret,
              clientId: res.data[0]?.clientId,
              apiKey: res.data[0]?.apiKey,
              secrete: res.data[0]?.secrete,
            });
          } else {
            setMerchantData({
              ...merchantData,
              referenceId: "REFID" + Date.now(),
              mid: "",
              callbackURL: "",
              AccountModuleSecret: "",
              masterVirtualAccount: "",
              providerSecret: "",
              clientId: "",
              apiKey: "",
              secrete: "",
            });
          }
        }

        if (res.data.length === 2) {
          if (
            transType.toLocaleLowerCase() ===
            res?.data[0]?.transferType?.toLocaleLowerCase()
          ) {
            setMerchantData({
              ...merchantData,
              referenceId: "REFID" + Date.now(),
              mid: res?.data[0]?.mid,
              callbackURL: res?.data[0]?.callbackUrl,
              AccountModuleSecret: res?.data[0]?.accountsModuleSecret,
              masterVirtualAccount: res?.data[0]?.masterVirtualAccount,
              providerSecret: res?.data[0]?.providerSecret,
              clientId: res?.data[0]?.clientId,
              apiKey: res?.data[0]?.apiKey,
              secrete: res?.data[0]?.secrete,
            });
          } else if (
            transType.toLocaleLowerCase() ===
            res?.data[1].transferType?.toLocaleLowerCase()
          ) {
            setMerchantData({
              ...merchantData,
              referenceId: "REFID" + Date.now(),
              mid: res?.data[1]?.mid,
              callbackURL: res?.data[1]?.callbackUrl,
              AccountModuleSecret: res?.data[1]?.accountsModuleSecret,
              masterVirtualAccount: res?.data[1]?.masterVirtualAccount,
              providerSecret: res?.data[1]?.providerSecret,
              clientId: res?.data[1]?.clientId,
              apiKey: res.data[1]?.apiKey,
              secrete: res.data[1]?.secrete,
            });
          } else {
            setMerchantData({
              ...merchantData,
              referenceId: "REFID" + Date.now(),
              mid: "",
              callbackURL: "",
              AccountModuleSecret: "",
              masterVirtualAccount: "",
              providerSecret: "",
              clientId: "",
              apiKey: "",
              secrete: "",
            });
          }
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchWalletBal = async (transferTypes, mid) => {
    try {
      const res = await apiCallFnforPostForOpenUrl(POST_WALLET_BALANCE, {
        mid: mid,
        payoutFor: transferTypes,
      });

      if (res.statusCode === 200) {
        // console.log("CALLED..............");
        setBalance({
          payoutWalletBalance: res?.data[0]?.amount,
          creditBalance: res?.data[0]?.creditBalance,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

    setMerchant(res?.data);
  };

  const handelSubmit = async () => {
    if (formData.merchantMid === "") {
      swal({
        title: "Alert",
        text: "Please Select A Merchant",
        icon: "error",
      });
      return;
    }

    if (transferType === "upi") {
      if (formData.upi === "") {
        swal({
          title: "Alert",
          text: "Please Enter UPI ID",
          icon: "error",
        });
        return;
      }
    }

    if (transferType === "imps") {
      if (formData.accNumber === "") {
        swal({
          title: "Alert",
          text: "Please Enter Account number",
          icon: "error",
        });
        return;
      }

      if (formData.ifscCode === "") {
        swal({
          title: "Alert",
          text: "Please Enter IFSC Code",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      accountNo: transferType === "upi" ? "" : formData.accNumber,
      apikey: merchantData.apiKey,
      ifscCode: transferType === "upi" ? "" : formData.ifscCode,
      mid: merchantData.mid,
      referenceId: merchantData.referenceId,
      secreteKey: merchantData.secrete,
      transferType: transferType,
      upiId: transferType === "imps" ? "" : formData.upi,
    };

    try {
      const res = await apiCallFnforPost(POST_PAYOUT_API_TEST, payload);

      if (res?.statusCode === 200) {
        swal({
          title: "Success",
          text: res?.data.requestStatusDescription,
          icon: "success",
        });
        setResponse(JSON.stringify(res?.data));
        setFormData({
          merchantMid: "",
          upi: "",
          accNumber: "",
          ifscCode: "",
        });

        setMerchantData({
          referenceId: "",
          mid: "",
          clientId: "",
          providerSecret: "",
          masterVirtualAccount: "",
          AccountModuleSecret: "",
          callbackURL: "",
          apiKey: "",
          secrete: "",
        });
      } else {
        swal({
          title: "Alert",
          text: res?.data?.message,
          icon: "error",
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handelValidations = (e) => {
    if (
      formData.upi !== "" &&
      transferType === "upi" &&
      e.target.name === "upi"
    ) {
      if (!isVpaUpiRegexIsValid(formData.upi)) {
        swal({
          title: "Alert",
          text: "Please Enter Valid UPI Id.",
          icon: "error",
        });
        setFormData({ ...formData, upi: "" });
      }
    }

    console.log(e.target.name);

    if (e.target.name === "ifsc" && formData.ifscCode !== "") {
      if (!isIfscRegexIsValid(formData.ifscCode)) {
        swal({
          title: "Alert",
          text: "Please Enter Valid IFSC Code.",
          icon: "error",
        });
        setFormData({ ...formData, ifscCode: "" });
      }
    }
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  return (
    <>
      {/* <div className="content-body d-flex justify-content-center"> */}
      <div className="container-fluid ">
        <div className="d-flex justify-content-between bg-white ">
          <div className="d-flex justify-content-start align-items-center ">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Payout API Test</div>
          </div>
          <div className="d-flex gap-2 justify-content-end"></div>
        </div>
        <div class="row ">
          <div class="col-12 d-flex justify-content-center">
            <div className="card input-box-shadow ">
              <div className="card-body py-4 ">
                <div id="" className="form-wizard order-create ">
                  <div id="" className="tab-pane " role="tabpanel">
                    <div className="row d-flex ">
                      <div className="col-lg-4 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Select A Merchant
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <select
                            name="merchantMid"
                            value={formData.mid}
                            onChange={(e) => {
                              handelInput(e);
                              setResponse("");
                              getMerchantInfo(e.target.value, "upi");
                            }}
                            className="form-control  "
                          >
                            <option>Select A Merchant</option>
                            {merchant?.map((elem) => (
                              <option key={elem.mid} value={elem.mid}>
                                {elem.merchantName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* </div>

                      <div className="row "> */}
                      <div className="col-lg-4 mb-2">
                        <div className="mb-3">
                          <label className=" d-flex mb-1 text-dark fs-4">
                            {" "}
                            Payout Wallet Balance
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control "
                            name="accountsModuleSecrete"
                            value={balance.payoutWalletBalance}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4  mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Credit Balance
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control "
                            name="accountsModuleSecrete"
                            value={balance.creditBalance}
                          />
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center">
                        <div className="w-100 d-flex  justify-content-center gap-3 mb-4 ">
                          <div
                            className={`${
                              transferType === "upi" ? " " : ""
                            }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                          >
                            <div class="checkbox-wrapper-27">
                              <label class="checkbox">
                                <input
                                  type="checkbox"
                                  style={{
                                    border: "1px solid grey",
                                    scale: "1",
                                  }}
                                  className="form-check-input"
                                  name="bankDetails"
                                  checked={
                                    transferType === "upi" ? true : false
                                  }
                                  onChange={() => {
                                    setTransferType("upi");
                                    getMerchantInfo(
                                      formData.merchantMid,
                                      "upi"
                                    );
                                    fetchWalletBal("upi", formData.merchantMid);
                                  }}
                                />
                                <span class="checkbox__icon"> </span>
                                UPI
                              </label>
                            </div>
                          </div>
                          <div
                            className={`${
                              transferType === "imps" ? " " : ""
                            }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                          >
                            <div class="checkbox-wrapper-27">
                              <label class="checkbox">
                                <input
                                  type="checkbox"
                                  style={{
                                    border: "1px solid grey",
                                    scale: "1",
                                  }}
                                  className="form-check-input"
                                  checked={
                                    transferType === "imps" ? true : false
                                  }
                                  onChange={() => {
                                    setTransferType("imps");
                                    getMerchantInfo(
                                      formData.merchantMid,
                                      "imps"
                                    );
                                    fetchWalletBal(
                                      "imps",
                                      formData.merchantMid
                                    );
                                  }}
                                />
                                <span class="checkbox__icon"> </span>
                                IMPS
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-lg-12 mb-2">
                          <div className="mb-3">
                            {transferType === "upi" ? (
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="d-flex mb-1 text-dark fs-4">
                                      Enter UPI ID
                                      <sup className="text-danger mt-3">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        type="text"
                                        className="form-control "
                                        name="upi"
                                        value={formData.upi}
                                        onBlur={handelValidations}
                                        onChange={(e) =>
                                          setFormData({
                                            ...formData,
                                            upi: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {transferType === "imps" ? (
                              <div className="row">
                                <div className=" col-lg-6 mb-2">
                                  <div className="mb-3">
                                    <div className="form-group">
                                      <label className="d-flex mb-1 text-dark fs-4">
                                        Enter Account Number
                                        <sup className="text-danger mt-3">
                                          *
                                        </sup>
                                      </label>
                                      <div className="d-flex gap-2">
                                        <input
                                          type="text"
                                          className="form-control "
                                          name="accountsModuleSecrete"
                                          value={formData.accNumber}
                                          onKeyDown={(e) =>
                                            setPressedKeyName(getKeyPressed(e))
                                          }
                                          onChange={(e) => {
                                            if (
                                              pressedKeyName === "Backspace"
                                            ) {
                                              setFormData({
                                                ...formData,
                                                accNumber: e.target.value,
                                              });
                                            }

                                            if (
                                              stopAlphabetInput(
                                                pressedKeyName
                                              ) ||
                                              stopSpecialCharacterInput(
                                                pressedKeyName
                                              )
                                            ) {
                                              return;
                                            }
                                            setFormData({
                                              ...formData,
                                              accNumber: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className=" col-lg-6 mb-2">
                                  <div className="mb-3">
                                    <div className="form-group">
                                      <label className="d-flex mb-1 text-dark fs-4">
                                        Enter IFSC Code
                                        <sup className="text-danger mt-3">
                                          *
                                        </sup>
                                      </label>
                                      <div className="d-flex gap-2">
                                        <input
                                          type="text"
                                          className="form-control "
                                          name="ifsc"
                                          value={formData.ifscCode}
                                          onBlur={handelValidations}
                                          onChange={(e) =>
                                            setFormData({
                                              ...formData,
                                              ifscCode: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row ">
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Account Module Secret
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="accountsModuleSecrete"
                            value={merchantData.AccountModuleSecret}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Client Id<sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="accountsModuleSecrete"
                            value={merchantData.clientId}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Master Virtual Account
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="accountsModuleSecrete"
                            value={merchantData.masterVirtualAccount}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Provider Secret
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control  "
                            name="accountsModuleSecrete"
                            value={merchantData.providerSecret}
                          />
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row ">
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Merchant Id<sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control "
                            name="accountsModuleSecrete"
                            value={merchantData.mid}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Reference Id
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="accountsModuleSecrete"
                            value={merchantData.referenceId}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Callback URL
                            <sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="accountsModuleSecrete"
                            value={merchantData.callbackURL}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            API Key<sup className="text-danger mt-3">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="apiKey"
                            value={merchantData.apiKey}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="d-flex mb-1 text-dark fs-4">
                            Secret Key<sup className="text-danger mt-3 ">*</sup>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="secrete"
                            value={merchantData.secrete}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-2">
                      <button
                        className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                        onClick={handelSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {response !== "" ? (
                         <div className="d-flex justify-content-start">
                        <div className="mt-3">
                            <p className="fw-bold">JSON Response</p>
                            {response}
                          </div>
                        </div>
                        ) : (
                          ""
                        )}
                        
                       
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default PayoutApiTest;
