import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Card, Modal } from "react-bootstrap";
import GuarenteeAmount from "./GuarenteeAmount";
import { Offcanvas } from "react-bootstrap";
import DepositAmount from "./DepositAmount";
import PaidIcon from "@mui/icons-material/Paid";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SavingsIcon from "@mui/icons-material/Savings";
import EditIcon from "@mui/icons-material/Edit";
import {
  CHANGE_AMOUNT_HOLD_DEPOSITE,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_ALL_DATA_ADMIN_HOLD,
  GET_HOLD_DATA,
  SWIPELINC_API,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import useCheckPrivilages from "../../utils/checkPrivilages";
import Release from "./Release";
import { getAllMerchantPhase2Approved } from "../../utils/ApiServices";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const HoldAndDeposite = ({ toggleRightBar, type1 }) => {
  const [data, setData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [isModalReleaseOpen, setIsModalReleaseOpen] = useState(false);
  const [isModalDepositeOpen, setIsModalDepositeOpen] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [holdAmount, setHoldAmount] = useState("");
  const [holdAmountpercentage, setHoldAmountpercentage] = useState("");
  const [holdAmountpercentageError, setHoldAmountpercentageError] =
    useState("");
  const [holdAmountError, setHoldAmountError] = useState("");
  const [changedAmount, setChangedAmount] = useState("");
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();
  const [formData, setFormData] = useState({
    mid: "",
  });

  const handleShowRouting = () => setIsModalRoutingOpen(true);
  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };
  const handleShowRelease = () => setIsModalReleaseOpen(true);
  const handleCloseModalRelease = () => {
    setIsModalReleaseOpen(false);
  };
  const handleShowDeposite = () => setIsModalDepositeOpen(true);
  const handleCloseModalDeposite = () => {
    setIsModalDepositeOpen(false);
  };

  useEffect(() => {
    apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS)
      .then((response) => {
        if (response.statusCode === 200) {
          console.log(response.data);
          setMerchantData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const Fetch_Hold_Amount = (mid) => {
  
    apiCallFnforGet(GET_HOLD_DATA + mid)
    
      .then((response) => {
        if (response.statusCode === 200) {
          setHoldAmount(response.data === "null" ? "0" : response.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const Fetch_Hold_Amount_Per = (mid) => {
  
    apiCallFnforGet(GET_ALL_DATA_ADMIN_HOLD + mid)
      .then((response) => {
        console.log(response.data[0].holdPercentage);
        if (response.statusCode === 200) {
          setHoldAmountpercentage(
            response.data[0].holdPercentage === "null"  ? "0": response.data[0].holdPercentage
          );
          console.log(response, holdAmountpercentage);
          //   setShowLoader(false);
        } else if (response.statusCode === 304) {
          setHoldAmountpercentage("0");
          console.log(response, holdAmountpercentage);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    setMerchantData(data);
  }, []);
  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;

    if (selectedCompany === "") {
      setFormData({
        // email: "",
        mid: "",
        // mobile: "",
        // fullName: "",
      });
      setHoldAmount("");
      setHoldAmountpercentage("");
      setSelectedCompany("");

      return;
    }
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        // email: selectedMerchant.emailId || "",
        // mobile: selectedMerchant.mobileNo || "",
        // fullName: selectedMerchant.merchantName || "",
      });
      Fetch_Hold_Amount(selectedMerchant.mid);
      Fetch_Hold_Amount_Per(selectedMerchant.mid);
      setChangedAmount("");
    }
  };

  const handleHoldAmountChange = (event) => {
    const value = event.target.value;

    if (!isNaN(value) && value >= 0) {
      setHoldAmount(value);
      setHoldAmountError("");
    } else {
      setHoldAmount("");
      setHoldAmountError("Hold Amount must be a positive number.");
    }
  };

  const handleHoldAmountperChange = (event) => {
    const value = event.target.value;

    if (!isNaN(value) && value >= 0) {
      setHoldAmountpercentage(value);
      setHoldAmountpercentageError("");
    } else {
      setHoldAmountpercentage("");
      setHoldAmountpercentageError(
        "Hold Amount Percentage must be a positive ."
      );
    }
  };
  useEffect(() => {}, [changedAmount]);

  const handleSubmit = (changedAmount) => {
    if (holdAmountError || holdAmountpercentageError) {
      return;
    }

    const ApiPayload = (changedAmount) => {
      console.log(changedAmount);
      if (changedAmount === "hold") {
        return {
          // email: formData.email,
          mid: formData.mid,
          // mobile: formData.mobile,
          // fullName: formData.fullName,
          amount: holdAmount,
        };
      } else if (changedAmount === "holdAmountpercentage") {
        return {
          // email: formData.email,
          mid: formData.mid,
          // mobile: formData.mobile,
          // fullName: formData.fullName,
          holdPercentage: holdAmountpercentage,
          // isActive : true
        };
      } else {
      }
    };

    const newData = ApiPayload(changedAmount);

    if (!formData.mid.length) {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
      });
      return;
    }

    apiCallFnforPost(
      `${CHANGE_AMOUNT_HOLD_DEPOSITE}/${changedAmount}/save`,
      newData
    )
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success",
            text: "Data Saved Successfully.",
            icon: "success",
          });
          setChangedAmount("");
          setFormData({
            mid: "",
          });
          setHoldAmount("");
          setHoldAmountpercentage("");
          setSelectedCompany("");
        } else if (response.statusCode === 306) {
          swal({
            title: "Failed",
            text: "Amount must be lesser than Wallet Balance.",
            icon: "error",
          });
        } else {
          swal({
            title: "Failed",
            text: response.message,
            icon: "error",
          });
        }
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  // New code

  const getMerchantData = async (token) => {
    try {
      // setShowLoader(true);
      const response = await getAllMerchantPhase2Approved(token);
      // setShowLoader(false);
      if (response?.statusCode === 200) {
        setData(response?.data);
        console.log(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      // setShowLoader(false);

      setData([]);
    }
  };

  useEffect(() => {
    getMerchantData(token);
  }, []);

  return (
    <>
      <div>
      <div class="">
      <div className=" d-flex justify-content-between bg-white mt-2">
          <div className="d-flex justify-content-start align-items-center mb-4">
            <button type="button" class="btn-close m-3" onClick={toggleRightBar} ></button>
            <div className="fs-2 text-dark fw-bold">Hold And Deposit</div>
          </div>
          <div className="d-flex gap-2 justify-content-end mt-3 me-4">
            <CustomTip size="18" placement="top" title=" Guarantee Amount">
              <div className="" onClick={handleShowRouting}>
                <PaidIcon fontSize="large" />
              </div>
            </CustomTip>
            <div className="d-flex justify-content-end gap-2">
              <CustomTip size="18" placement="top" title=" Release Amount">
                <div className="" onClick={handleShowRelease}>
                  <PriceChangeIcon fontSize="large" />
                </div>
              </CustomTip>

              <div className="d-flex justify-content-end">
                <CustomTip size="18" placement="top" title=" Deposit Amount">
                  <div className=" " onClick={handleShowDeposite}>
                    <SavingsIcon  fontSize="large"/>
                  </div>
                </CustomTip>
              </div>
            </div>
          </div>
        </div>
        <div className="subtab-sec-admin">
        <div class="row">
          <div class="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-6 b-2 ">
                      <div className="mb-3">
                        <label className=" d-flex  mb-1 text-dark fs-4 mt-2">
                          {" "}
                          Select a Merchant{" "}
                        </label>
                        <select
                          id="companyDropdown"
                          className="form-control "
                          onChange={handleCompanyChange}
                          value={selectedCompany}
                        >
                          <option value="">Select a Merchant</option>

                          {merchantData?.map((merchant) => (
                            <option
                              key={merchant.mid}
                              value={`${merchant.mid} :- ${merchant.merchantName}`}
                            >
                              {merchant.mid} :- {merchant.merchantName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mt-3">
                        <label className="d-flex  mb-1 text-dark fs-4 mt-2">
                          Merchant ID
                        </label>
                        <input
                          type="email"
                          className="form-control "
                          id="email"
                          placeholder="Merchant ID"
                          value={formData.mid}
                          readOnly
                        />
                      </div>
                      <div className="form-group">
                        <label className=" d-flex mb-1 text-dark fs-4 mt-3">
                          Hold (in Percentage)
                        </label>
                        <div className="d-flex gap-4">
                          <input
                            type="text"
                            className="form-control"
                            id="holdamountpercentage"
                            placeholder="Hold Amount In Percentage"
                            value={holdAmountpercentage}
                            onChange={handleHoldAmountperChange}
                            readOnly={
                              changedAmount === "holdAmountpercentage"
                                ? false
                                : true
                            }
                          />
                          <div className="mt-3">
                            {changedAmount === "holdAmountpercentage" ? (
                              <button
                                className=""
                                class="badge badge-pill badge-primary"
                                onClick={() => handleSubmit(changedAmount)}
                              >
                                Submit
                              </button>
                            ) : (
                              <div
                                className=""
                                onClick={() =>
                                  setChangedAmount("holdAmountpercentage")
                                }
                              >
                                <EditIcon
                                  sx={{ fontSize: 16, color: "currentColor" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className=" d-flex mb-1 text-dark fs-4 mt-3">
                          Hold (in Rupees)
                        </label>
                        <div className="d-flex gap-4">
                          <input
                            type="text"
                            className="form-control "
                            id="holdAmount"
                            placeholder="Hold Amount"
                            value={holdAmount}
                            onChange={handleHoldAmountChange}
                            readOnly={changedAmount === "hold" ? false : true}
                          />
                          <div className="mt-3">
                            {changedAmount === "hold" ? (
                              <button
                                class="badge badge-pill badge-primary"
                                onClick={() => handleSubmit(changedAmount)}
                              >
                                Submit
                              </button>
                            ) : (
                              <div
                                className=""
                                onClick={() => setChangedAmount("hold")}
                              >
                                <EditIcon
                                  sx={{ fontSize: 16, color: "currentColor" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <button
                        className="badge badge-rounded badge-primary mx-auto px-3 py-3 mt-4 "
                        onClick={handleSubmit}
                      >
                        Submit
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>

      {/* <Modal
        show={isModalRoutingOpen}
        onHide={handleCloseModalRouting}
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Guarantee Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
          {/* <Card.Body> */}
          {isModalRoutingOpen && ( // Show Routing component conditionall
        <GuarenteeAmount
          data={data}
          show={isModalRoutingOpen}
          onHide={() => setIsModalRoutingOpen(false)} // Hide modal function
        />
      )}
          {/* </Card.Body> */}
        {/* </Modal.Body>
      </Modal> */}

      <Modal
        show={isModalReleaseOpen}
        onHide={handleCloseModalRelease}
        backdrop="static"
        keyboard={false}
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Release Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <Release data={data} />
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>
      <Modal
        show={isModalDepositeOpen}
        onHide={handleCloseModalDeposite}
        backdrop="static"
        keyboard={false}
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Deposit Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <DepositAmount data={data} />
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HoldAndDeposite;