import React, { useEffect, useState } from "react";

export default function Dropdown({ setCondition, isOpen , reset, type }) {
  const [value, setValue] = useState("this year");

  const options = [
    {
      label: "Yesterday",
      value: "yesterday",
    },
    {
      label: "Today",
      value: "1 day",
    },
    {
      label: "Last Week",
      value: "last week",
    },
    {
      label: "This Week",
      value: "this week",
    },
    {
      label: "Last Month",
      value: "last month",
    },
    {
      label: "This Month",
      value: "this month",
    },
    {
      label: "Last Quarter",
      value: "last quarter",
    },
    {
      label: "This Quarter",
      value: "this quarter",
    },
    {
      label: "This Year",
      value: "this year",
    },
  
  ];

  useEffect(()=>{
    if(reset === true || reset === false){
        setCondition("this year")
      setValue("this year")
    }
      },[reset])

  function handleSelect(event) {
    const selectedValue = event.target.getAttribute("data-value");
    setValue(selectedValue);
    setCondition(selectedValue);
  }


  return (
    <div className="d-flex justify-content-evenly gap-2">
      {!isOpen && (
        <div
          className={`badge fs-10 badge-primary`}
          onClick={handleSelect}
          style={{ cursor: "pointer" }}
        >
          {options?.find((option) => option.value === value)?.label}
        </div>
      )}
      {isOpen && options.map((option, i) => (
        <div
          className={`badge fs-10 ${option.value === value ? "badge-primary" : "badge-secondary"}`}
          key={i}
          data-value={option.value}
          onClick={handleSelect}
          style={{ cursor: "pointer" }}
        >
          {option?.label}
        </div>
      ))}
    </div>
  );
}
