import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";

import useApiServices from "../../utils/useApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import PreviewIcon from "@mui/icons-material/Preview";

import CustomTip from "../../common/CustomToolTip/CustomTip";

import ViewDetailsMobileApp from "./ViewDetailsMobileApp";
import OnbordingApproved from "./OnbordingApproved";
import { GET_ALL_YES_ONBOARD } from "../../utils/constant";

export default function SubmittedMobileApplications({ type, toggleRightBar }) {
  const [applications, setApplications] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataApplication, setDataApplication] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [showMobileApp, setShowMobileApp] = useState(false);
  const [mid, setMid] = useState("");
  const [openView, setOpenView] = useState(false);
  const [showPaymentServices, setShowPaymentServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [id, setId] = useState("");
  const [viewMobileApp, setViewMobileApp] = useState(false);
  const [viewApproved, setviewApproved] = useState(false);
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  const FetchData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_YES_ONBOARD);

      if (response.statusCode === 200) {
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          ...item,
          paymentServices: item.paymentService.map((service) => ({
            serviceName: service.paymentService,
            mdrPercentage: service.mdrPercentage,
          })),
          action: (
            <button
              className="badge badge-rounded badge-warning py-2 me-2"
              onClick={() => handleViewDetails(item)}
            >
              View Details
            </button>
          ),
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewDetails = (data) => {
    const registrationId = data?.registrationId;
    setDataApplication(data);
    setId(registrationId);
    setViewMobileApp(true);
  };

  const columns = [
    { headerName: "Company Name", field: "companyName", minWidth: 200 },
    {
      headerName: "Authorized Person Name",
      field: "authorizedPersonName",
      minWidth: 200,
    },
    { headerName: "e-Mail ID", field: "email", minWidth: 250 },
    { headerName: "Mobile Number", field: "mobile", minWidth: 150 },
    {
      headerName: "Company PAN Number",
      field: "companyPanNumber",
      minWidth: 200,
    },
    { headerName: "GST Number", field: "gstNumber", minWidth: 200 },
    { headerName: "Action", field: "action", minWidth: 150 },
  ];

  const handleApproved = () => setviewApproved(!viewApproved);

  useEffect(() => {
    FetchData();
  }, [refresh]);

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center me-2">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            {!viewApproved ? (
              <div className="fs-2 text-dark fw-bold">
                Mobile App On-Bording Submitted
              </div>
            ) : (
              <div className="fs-2 text-dark fw-bold">
                Mobile App On-Bording Approved
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <CustomTip
              size="18"
              placement="top"
              title={!viewApproved ? "Approved" : "Submitted"}
            >
              <div className="me-4" onClick={handleApproved}>
                <PreviewIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>

        <div className="mb-4">
          <Card.Body>
            {!viewApproved ? (
              <div style={{ height: "500px", width: "100%" }}>
                <DataGrid
                  rows={applications}
                  columns={columns.map((column) => ({
                    ...column,
                    renderCell: (params) =>
                      column.field === "active" || column.field === "action"
                        ? params.value
                        : params.value,
                  }))}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </div>
            ) : (
              <OnbordingApproved />
            )}
            {viewMobileApp && (
              <ViewDetailsMobileApp
                id={id}
                data={dataApplication}
                showMobileApp={viewMobileApp}
                onHide={() => setViewMobileApp(false)} // Handle closing
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
          </Card.Body>
        </div>
      </div>
    </>
  );
}
