import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomTip from '../../common/CustomToolTip/CustomTip';
import { GET_ALL_FREE_SID, SAVE_QR_ROUTING, VIEW_ALL_MERCHANT_FOR_SQR } from '../../utils/constant';
import useApiServices from '../../utils/useApiServices';
import swal from "sweetalert";

const StaticQrRouting = ({setToggleFilterA, toggleFilterA, onHide, show}) => {
    const {apiCallFnforGet, apiCallFnforPost} = useApiServices();
    const [merchantData, setMerchantData] = useState([]);
    const [sidData, setSidData] = useState([]);
    const [selectedCompany,setSelectedCompany] = useState('');
    const [selectedSID, setSelectedSID] = useState("");
    const [ selectedPrifix,setSelectedPrifix] = useState("")
    const [data, setData] = useState({
        sid: "",
        txnAmt: "",
        merchantSelect: "",
      });
      const [formData, setFormData] = useState({
        mid: "",
      });

    const handleClose = ()=>{
        onHide();
        setToggleFilterA(!toggleFilterA)
    }

    const FetchAllQrMerchants = async()=>{
        const response = await apiCallFnforGet(VIEW_ALL_MERCHANT_FOR_SQR)
        console.log(response)
        setMerchantData(response?.data);

    }

    const FetchFreeSIDs = async()=>{
            const response = await apiCallFnforGet(GET_ALL_FREE_SID)
            console.log("New sid",response)
            setSidData(response?.data)
    }

    
    useEffect(()=>{
        FetchAllQrMerchants();
        FetchFreeSIDs();
    },[])

    const handleCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        if (selectedCompany === "") {
          setSelectedCompany("");
          return;
        }
        setSelectedCompany(selectedCompany);
      };

      const handleSIDChange = (event) => {
        const selectedSID = event.target.value;
        if (selectedSID === "") {
          setSelectedCompany("");
          return;
        }

        const selectedSIDData = sidData.find(merchant => merchant.sid === selectedSID);
console.log(selectedSIDData)
        if (selectedSIDData) {
            setSelectedSID(selectedSIDData.sid);
            setSelectedPrifix(selectedSIDData.prifix);
        } else {
            setSelectedSID('');
            setSelectedPrifix('');
        }
        // setSelectedSID(selectedSID);
      };
      const handleSubmit = async()=>{
        
                try {
                    const payload = {
                        domain: selectedPrifix,
                        mid: selectedCompany,
                        prority: 0,
                        routingType: "",
                        sid: selectedSID,
                        sidLimit: 0
                      }
                    const response = await apiCallFnforPost(SAVE_QR_ROUTING,payload)
                    if(response.statusCode === 200){
                      swal({
                          title: "ALERT!",
                          text: response.message,
                          icon: "success",
                        });
                        FetchAllQrMerchants();
                            FetchFreeSIDs();
                            onHide();
                    }
                    else{
                      swal({
                          title: "ALERT!",
                          text: response.message,
                          icon: "error",
                        });
                    }
                } catch (error) {
                        console.log(error)
                    swal({
                        title: "ERROR!",
                        text: error.message,
                        icon: "error",
                      });
                }
       
      }
      
  const handleModal =()=>{
    setToggleFilterA(!toggleFilterA)
  }
      console.log("selected prifix", selectedPrifix)
console.log("selected sid",selectedSID)
console.log("selected comp",selectedCompany)
  return (
    <>
     <Modal
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Static QR Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="container-fluid ">
        <div class="row ">
          <div className="col-lg-4 mb-2">
            <div className="mb-3">
              <label className="mb-1 text-dark fs-4">Select A Merchant</label>

              <select
                          id="companyDropdown"
                          className="form-control"
                          onChange={handleCompanyChange}
                          value={selectedCompany}
                        >
                          <option value="">Select a Company</option>
                          {merchantData?.map((merchant) => (
                  <option key={merchant.mid} value={merchant.mid}>
                    {merchant.companyName} - {merchant.mid}
                  </option>
                ))}
                        </select>
            </div>
          </div>
          <div className="col-lg-4 mb-2">
            <div className="mb-3">
              <label className="mb-1 text-dark fs-4">Select SID's</label>
              <select
                          id="companyDropdown"
                          className="form-control"
                          onChange={handleSIDChange}
                          value={selectedSID}
                        >
                          <option value="">Select SID</option>
                          {sidData?.map((merchant) => (
                  <option key={merchant.sid} value={merchant.sid}>
                    {merchant.prifix} :- {merchant.sid}
                  </option>
                ))}
                        </select>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
          <CustomTip size="18" placement="top" title="Dynamic Routing"
          >
            <button style={{border:"none", backgroundColor:"white"}}
             onClick={handleModal}
             >
            <FilterListIcon fontSize="large"/>
            </button>
         
          </CustomTip>
          <div>
          <button
              className="badge badge-rounded badge-primary mx-2 px-3 py-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
          </div> 
        </div>
      </div>
      </Modal.Body>
      </Modal>
    </>
  )
}

export default StaticQrRouting