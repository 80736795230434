import React, { useState } from "react";
import NumberInWords from "./NumberInWords";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import {
  SWIPELINC_API,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_PREV_MAPPED_DETAILS,
  HANDLE_SEARCH_LIMIT,
  SIDMASTER_GET_ALL_SID,
  CHECK_MAXIMUM_AMOUNT_LIMIT,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  stopCharacterInput,
  stopSpecialCharacterInput,
} from "../../utils/helper";
import { getMechantForPayinOnBoarding } from "../../utils/ApiServices";

const RoutingPayinOnBoarding = ({ show, onHide }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [SidData, setSidData] = useState([]);

  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [mappedMerchant, setMappedMerchant] = useState([]);
  const [prorityNumber, setProrityNumber] = useState([]);
  const [routingPattern, setRoutingPattern] = useState("");
  const [priority, setPriority] = useState([]);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const [data, setData] = useState({
    sid: "",
    txnAmt: "",
    merchantSelect: "",
  });

  // const getAllMerchantForApiTest = async () => {
  //   let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
  //   setMerchantData(res?.data);
  // };

  // useState(() => {
  //   getAllMerchantForApiTest();
  // }, []);

  const getAllMerchantForOnBoardingRoutingPayin = async () => {
    let res = await getMechantForPayinOnBoarding(token);
    setMerchantData(res?.data);
  };

  useState(() => {
    getAllMerchantForOnBoardingRoutingPayin();
  }, []);

  const getPrevMappedDetails = async (mid) => {
    await apiCallFnforGet(GET_PREV_MAPPED_DETAILS + mid)
      .then((response) => {
        if (response.statusCode === 200) {
          setMappedMerchant(response?.data);
          console.log(response.data);
          setPriority(response?.data);

          setRoutingPattern(response?.data[0].routingType);
        } else {
          setRoutingPattern("");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const getAllInitialSID = () => {
    apiCallFnforGet(SIDMASTER_GET_ALL_SID)
      // .get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`, {
      //   headers: {
      //     Authentication: `Bearer ${token}`,
      //   },
      // })
      .then((response) => {
        if (response.statusCode === 200) {
          setSidData(response?.data);
          let res = response?.data?.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "", priority: 0 };
          });
          setSidData(res);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handelDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === "merchantSelect") {
      getAllInitialSID();
      getPrevMappedDetails(e.target.value);
      setMappedMerchant([]);
      setSelectedData([]);
      setProrityNumber([]);
    }

    if (e.target.name === "sid") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const prifix = selectedOption.getAttribute("data-prifix");

      let val = e.target.value;
      let select = SidData?.filter((data) => {
        if (data.sid === val) return { ...data, prifix: prifix };
      });

      const remaining = selectedData?.filter((elem) => {
        return elem.sid !== select[0].sid;
      });

      setSelectedData([...remaining, ...select]);

      console.log(selectedData);

      let allPeriority = [...priority, ...select];
      setPriority(allPeriority);

      setProrityNumber([...prorityNumber, 0]);

      setSidData(
        SidData.filter((elem) => {
          return elem.sid !== val;
        })
      );
    }
  };

  const getKeyName = (e) => {
    setKeyName(e.key);
  };

  const sidDetailsColumn = [
    { headerName: "Merchant ID", field: "mid", minWidth: 250 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 250 },
    { headerName: "SID", field: "sid", minWidth: 250 },
    { headerName: "SID Limit", field: "sidLimit", minWidth: 150 },
  ];

  const handelSearchLimit = (e, sid, token) => {
    apiCallFnforGet(HANDLE_SEARCH_LIMIT + sid)
      .then((response) => {
        // console.log("data =====", response);
        if (response.statusCode === 200) {
          setSidDetailsData(response?.data);
          setIsModalOpen(true);
        } else {
          setSidDetailsData([]);
          console.error("Error fetching data:", response.data.message);
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsModalOpen(true);
      });
  };

  const handelTxnInput = (e, sid) => {
    if (stopSpecialCharacterInput(keyName) || stopCharacterInput(keyName)) {
      return;
    }

    let data = SidData?.map((elem) => {
      if (elem.sid === sid) {
        return { ...elem, maxTxnAmtInputVal: e.target.value };
      }
      return elem;
    });
    setSidData(data);

    let data1 = selectedData?.map((elem) => {
      if (elem.sid === sid) {
        return { ...elem, maxTxnAmtInputVal: e.target.value };
      }
      return elem;
    });
    setSelectedData(data1);
  };

  const handelProrityInput = (e, sid) => {
    let data = SidData?.map((elem) => {
      if (elem.sid === sid) {
        return {
          ...elem,
          priority: e.target.value === "Select" ? 0 : e.target.value,
        };
      }
      return elem;
    });
    setSidData(data);

    let data1 = selectedData?.map((elem) => {
      if (elem.sid === sid) {
        return {
          ...elem,
          priority: e.target.value === "Select" ? 0 : e.target.value,
        };
      }
      return elem;
    });
    setSelectedData(data1);
  };

  const checkMaxTxnAmtLimit = (payload) => {
    let flag = true;

    for (var i = 0; i < payload.length; i++) {
      let sidLimit = parseInt(payload[i].sidLimit);
      let remainingLimit = parseInt(selectedData[i].remainingLimit);
      let company = selectedData[i].company;
      if (sidLimit > remainingLimit) {
        swal({
          title: "Alert!",
          text:
            "Maximum transaction amount can not be greater than remaining limit of " +
            company,
          icon: "error",
        });
        flag = false;
      }
    }

    return flag;
  };

  function areArrayValuesUnique(selectedData) {
    const PriorityArr = selectedData?.map((item) => {
      return item?.priority;
    });
    //  console.log(selectedData,PriorityArr)
    // Use a Set to track unique values
    const uniqueSet = new Set(PriorityArr);

    // If the size of the Set is equal to the length of the array, all values are unique
    return uniqueSet.size === PriorityArr?.length;
  }

  function IsAllPrioritiesSelect(selectedData) {
    const PriorityArr = selectedData?.map((item) => {
      return item?.priority;
    });

    console.log(PriorityArr);
    // If the size of the Set is equal to the length of the array, all values are unique
    return PriorityArr.includes(0);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const IsPrioritiesUnique = areArrayValuesUnique(selectedData);
    const IsPrioritiesSelected = IsAllPrioritiesSelect(selectedData);

    if (routingPattern !== "RR") {
      if (IsPrioritiesSelected) {
        swal({
          title: "ALERT!",
          text: "Please Select Priorities.",
          icon: "error",
        });
        return;
      }

      if (!IsPrioritiesUnique) {
        swal({
          title: "ALERT!",
          text: "Priorities Cannot Be Same.",
          icon: "error",
        });
        return;
      }
    }

    if (data.merchantSelect === "") {
      swal({
        title: "ALERT!",
        text: "Please select a merchant",
        icon: "error",
      });
      return;
    }

    if (routingPattern === "") {
      swal({
        title: "ALERT!",
        text: "Please select a routing pattern",
        icon: "error",
      });
      return;
    }

    if (selectedData.length === 0) {
      swal({
        title: "ALERT!",
        text: "Please select SID",
        icon: "error",
      });
      return;
    }

    const payload = selectedData?.map((elem) => {
      if (elem.maxTxnAmtInputVal === "") {
        swal({
          title: "ALERT!",
          text: "Please enter maximum transaction amount",
          icon: "error",
        });
        throw new Error("Maximum transaction amount not entered");
      }

      console.log(elem.prifix);

      return {
        domain: elem.prifix,
        mid: data.merchantSelect,
        routingType: routingPattern,
        sid: elem.sid,
        sidLimit: elem.maxTxnAmtInputVal,
        prority: parseInt(elem.priority),
      };
    });

    console.log(payload);

    try {
      const save = checkMaxTxnAmtLimit(payload);

      if (save) {
        const response = await apiCallFnforPost(
          CHECK_MAXIMUM_AMOUNT_LIMIT,
          payload
        );
        console.log(response);
        if (response.statusCode === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
          });
          getAllInitialSID();
          setSelectedData([]);
          setMappedMerchant([]);
          setData({
            sid: "",
            txnAmt: "",
            merchantSelect: "",
          });
          getAllMerchantForOnBoardingRoutingPayin();
          onHide();
        } else {
          swal({
            title: "ALERT!",
            text: response.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const removeSelectedSid = (sid, elem) => {
    setSelectedData(
      selectedData?.filter((elem) => {
        return elem.sid !== sid;
      })
    );

    let allPeriority = priority?.filter((elem) => elem.sid !== sid);

    prorityNumber.splice(0, 1);

    setPriority(allPeriority);

    setSidData([...SidData, elem]);
    setData({
      ...data,
      sid: "",
    });
  };

  const handleCancelAction = () => {
    setData({
      sid: "",
      txnAmt: "",
      merchantSelect: "",
    });
    setSelectedData([]);
    setMappedMerchant([]);
    setRoutingPattern("");
  };

  const handleCloseNew = () => {
    onHide();
    setData({
      sid: "",
      txnAmt: "",
      merchantSelect: "",
    });
    setSelectedData([]);
    setMappedMerchant([]);
    setRoutingPattern("");
  };

  return (
    <>
      {/* <div className="content-body d-flex justify-content-center"> */}
      <Modal show={show} onHide={handleCloseNew} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card.Body>
            <div className="container-fluid ">
              <div class="row">
                <div className="col-lg-4 mb-2">
                  <div className="mb-3">
                    <label className="mb-1 text-dark fs-4">
                      Select A Merchant
                    </label>
                    <select
                      id="companyDropdown"
                      className="form-control "
                      onChange={handelDataChange}
                      value={data.merchantSelect}
                      name="merchantSelect"
                    >
                      <option value="">Select a Merchant</option>
                      {merchantData?.map((merchant) => (
                        <option key={merchant.mid} value={merchant.mid}>
                          {merchant.mid} -{merchant.merchantName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 mb-2">
                  <div className="mb-3">
                    <label className="mb-1 text-dark fs-4">Select SID's</label>
                    <select
                      id=""
                      className="form-control"
                      onChange={handelDataChange}
                      value={data.sid}
                      name="sid"
                    >
                      <option value="">Select SID</option>
                      {SidData?.map((elem) => {
                        return (
                          <option
                            key={elem.sid}
                            data-prifix={elem.prifix}
                            value={elem.sid}
                          >
                            {elem.prifix} :- {elem.company} :- {elem.sid} 
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 mb-2">
                  <div className="mb-3">
                    <label className="mb-1 text-dark fs-4">
                      Routing Pattern
                    </label>
                    <select
                      id=""
                      className="form-control"
                      onChange={(e) => setRoutingPattern(e.target.value)}
                      value={routingPattern}
                      name="routingPattern"
                    >
                      <option value="">Select a Pattern</option>
                      <option value="RR">Round Robin</option>
                      <option value="SQ">Sequential</option>
                    </select>
                  </div>
                </div>
                {mappedMerchant && (
                  <div className="d-flex justify-content-start fs-5  ">
                    <div className=" ">
                      {mappedMerchant?.map((elem) => {
                        // console.log("elem---", elem);
                        return (
                          <div
                            key={elem.sid}
                            className="w-100 d-flex mt-3  py-2   "
                          >
                            <div className="form-group d-flex flex-column col-sm-12 col-md-5  mt-2">
                              <div className=" ">
                                {elem.domain} :- {elem.sidCompanyName}
                              </div>

                              <span className=" text-danger">
                                Remaining Limit : {`${elem.sidRemainigLimit}`}
                              </span>
                            </div>
                            <div className="form-group  col-sm-12 col-md-4 mt-2 ">
                              <label htmlFor="email">
                                Enter Maximum Txn Amount
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="txnAmt"
                                name="txnAmt"
                                placeholder="Enter Maximum Txn Amount"
                                value={elem.sidLimit}
                                onKeyDown={getKeyName}
                                onChange={(e) => handelTxnInput(e, elem.sid)}
                              />
                              <span>
                                <NumberInWords number={elem.sidLimit} />
                              </span>
                            </div>
                            <div className="col-sm-12 col-md-2 mx-3 d-flex align-items-center ">
                              <button
                                className="badge bg-primary text-white ms-2"
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  handelSearchLimit(e, elem.sid, elem.company)
                                }
                              >
                                View Mapped SID's
                              </button>
                            </div>

                            {routingPattern === "RR" ? (
                              ""
                            ) : (
                              <div className=" ">
                                <label htmlFor="email">Priority</label>

                                <input
                                  type="text"
                                  value={elem.prority}
                                  readOnly
                                  className="form-control "
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}

                      {selectedData?.map((elem, index) => {
                        console.log("elem---", elem);
                        console.log("index---<<<<<<", index);
                        return (
                          <div
                            key={elem.sid}
                            className="w-100 d-flex mt-3  py-2   "
                          >
                            <div className="form-group d-flex flex-column col-sm-12 col-md-5  mt-2">
                              <div className=" ">
                                {elem.prifix} :- {elem.company}
                              </div>
                              <span className="  text-danger">
                                Maximum Transaction Amount :{" "}
                                {`${elem.maxTxnAmount}`}
                              </span>
                              <span className=" text-danger">
                                Remaining Limit : {`${elem.remainingLimit}`}
                              </span>
                            </div>
                            <div className="form-group  col-sm-12 col-md-4 mt-2 ">
                              <label htmlFor="email">
                                Enter Maximum Txn Amount
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="txnAmt"
                                name="txnAmt"
                                placeholder="Enter Maximum Txn Amount"
                                value={elem.maxTxnAmtInputVal}
                                onKeyDown={getKeyName}
                                onChange={(e) => handelTxnInput(e, elem.sid)}
                              />
                              <span>
                                <NumberInWords
                                  number={elem.maxTxnAmtInputVal}
                                />
                              </span>
                            </div>
                            <div className="col-sm-12 col-md-2 mx-3 d-flex align-items-center ">
                              <button
                                className="badge bg-primary text-white ms-2"
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  handelSearchLimit(e, elem.sid, elem.company)
                                }
                              >
                                View Mapped SID's
                              </button>
                            </div>
                            <div className="col-sm-12 col-md-1 d-flex align-items-center mx-2">
                              <span
                                onClick={() => {
                                  removeSelectedSid(elem.sid, elem);
                                }}
                                style={{ cursor: "pointer" }}
                                className="text-danger fs-4"
                              >
                                {/* <i className="bi bi-trash-fill"></i> */}
                                <DeleteForeverIcon />
                              </span>
                            </div>
                            {routingPattern === "RR" ? (
                              ""
                            ) : (
                              <div className="col-sm-12 col-md-2 my-2 ">
                                <label htmlFor="email">Priority</label>
                                <select
                                  className="form-control "
                                  // id="selectMerchant"
                                  value={elem.priority}
                                  onChange={(e) => {
                                    handelProrityInput(e, elem.sid);
                                  }}
                                >
                                  <option>Select</option>

                                  {prorityNumber?.map((item, index) => {
                                    return (
                                      <option
                                        key={mappedMerchant.length + index + 1}
                                        value={
                                          mappedMerchant.length + index + 1
                                        }
                                      >
                                        {mappedMerchant.length + index + 1}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>

                  <button
                    className="badge badge-rounded badge-info mx-2 px-3 py-3"
                    onClick={handleCancelAction}
                  >
                    Cancel
                  </button>
                </div>

                {/* // EDIT FORM MODEL STARTS */}
                <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title className="fs-4 fw-bold">
                      SID Details:
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* <Card className=" mb-4"> */}
                    {/* <Card.Body> */}

                    <DataGrid
                      rows={sidDetailsData}
                      columns={sidDetailsColumn}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                    />

                    {/* </Card.Body> */}
                    {/* </Card> */}
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
      {/* </div> */}
      {/* </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default RoutingPayinOnBoarding;
