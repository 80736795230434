import { createSlice } from "@reduxjs/toolkit";

const privilagesSlice = createSlice({
  name: "privilagesData",
  initialState: {
    privilagesData:[],
    allPrivilagesData:[],
    prefix: "",
    type: "",
    sectionName: "",
    masterId:""
  },
  reducers: {
    setPrivilagesData: (state, action) => {
      state.privilagesData = action.payload;
    },
    setAllPrivilagesData: (state, action) => {
      state.allPrivilagesData = action.payload;
    },
    setPrifix: (state, action) => {
      state.prefix = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setSectionName: (state, action) => {
      state.sectionName = action.payload;
    },
    removePrifix: (state) => {
      state.prefix ="";
    },
    removePrivilagesData: (state) => {
      state.privilagesData = [];
    },
    setMasterId:(state,action) =>{
      state.masterId = action.payload ;
    }
  },
});

export const { removePrivilagesData,setPrifix,setType,removePrifix,setSectionName,setAllPrivilagesData, setPrivilagesData,setMasterId } =
  privilagesSlice.actions;

export default privilagesSlice.reducer;
 