import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../utils/helper";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { style } from "@mui/system";
import swal from "sweetalert";
import { GET_ALL_PRIVILAGES } from "../utils/constant";
import useApiServices from "../utils/useApiServices";
import { setAllPrivilagesData } from "../store/slices/privilagesSlice";
import { useDispatch, useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";
// import FlagIcon from "@mui/icons-material/Flag";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import HandymanIcon from "@mui/icons-material/Handyman";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import  AssessmentIcon from "@mui/icons-material/Assessment";


export default function SideBar() {
  const [expanded, setExpanded] = useState(false);
  const [expandedmasterMerchant, setExpandedMasterMerchant] = useState(false);
  const [expandedExternal, setExpandedExternal] = useState(false);
  const [expandedmaster, setexpandedmaster] = useState(false);
  const [expandedCustomer, setexpandedCustomer] = useState(false);
  const [expandedonBoarding, setexpandedonBoarding] = useState(false);
  const [dynamicTitleVisible, setDynamicTitleVisible] = useState(true);
  const [expandedTools, setExpandedTools] = useState(false);
  const [expandedSettings, setExpandedSettings] = useState(false);
  const [expandedA, setExpandedA] = useState(false);
  const [expandedB, setExpandedB] = useState(false);
  const [heightVal, setHeightVal] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [toggle, setToggle] = useState(true);
  const { apiCallFnforGet } = useApiServices();
  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPrivilages179774 = useCheckPrivilages(179774);
  const hasPrivilages179773 = useCheckPrivilages(179773);

  const handleClearLocalStorage = (event) => {
    event.preventDefault();

    const token = localStorage.getItem("token");
    localStorage.removeItem("token");

    swal({
      title: "Alert",
      text: "Are You Sure You Want To Logout",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willLogout) => {
      if (willLogout) {
        swal("Logged Out Successfully", {
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      } else {
        localStorage.setItem("token", token);
      }
    });
  };
  const { prefix } = useSelector((state) => state.privilages);

  const handleToggle = (toggleState, setToggleState) => {
    setToggleState(!toggleState);
  };

  const handleHumbergur = () => {
    setToggle(!toggle);
    if (toggle) {
      document.getElementById("main-wrapper").classList.remove("menu-toggle");
      document.getElementById("hamburger").classList.remove("is-active");
    } else {
      document.getElementById("hamburger").classList.add("is-active");
      document.getElementById("main-wrapper").classList.add("menu-toggle");
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      handleHumbergur();
    }
  }, [dynamicTitleVisible]);

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(
        `${GET_ALL_PRIVILAGES}?size=${size}&page=${page}`
      );

      if (response.statusCode === 200) {
        const finalArray = response?.data.map((item) => {
          const final = item.subTypes.map((item2) => {
            return {
              ...item2,
            };
          });

          return final;
        });
        dispatch(setAllPrivilagesData(finalArray.flat()));
      } else {
        dispatch(setAllPrivilagesData(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPrivilages();
  }, []);

  return (
    <>
      {/* <div
       id="DynamicTitleHeader"
       style={{
         display: "flex",
         gap: "0",
         alignItems: "center",
       }}
       className="nav-header"
     >
       <div
         className={`d-flex justify-content-start align-items-center ${
           !dynamicTitleVisible ? "center-logo" : ""
         }`}
       >
         <Link
           href="/DashBoard"
           className="brand-logo d-flex justify-content-center"
         >
           <img src={logoPath} width="53" height="53" />
         </Link>
         <h1 id="DynamicTitle" className="fw-bold mt-2">
           {" "}
           <i>{Name}</i>
         </h1>

         <div className="nav-header">
        <a href="index.html" className="brand-logo">
          <img src={logoPath} width="53" height="53px" />
          <h1 width="124px" height="33px" className="brand-title">
            {Name}
          </h1>
        </a>
        <div className="nav-control" onClick={handleHumbergur}>
          <div id="hamburger" className="hamburger">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
      
      </div>
      </div> */}
      <div class="nav-header">
        <a href="index.html" class="brand-logo">
          <img src={logoPath} width="53" height="53px" />
          <h1 width="124px" height="33px" class="brand-title">
            {Name}
          </h1>
        </a>
        <div class="nav-control" onClick={handleHumbergur}>
          <div id="hamburger" class="hamburger">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </div>
        
      </div>
      <div className="dlabnav " style={{ overflowY: "scroll" }}>
        <div className="dlabnav-scroll">
          <ul className="metismenu" id="menu">
            <li className="dropdown header-profile">
              <a
                className="nav-link"
                href="javascript:void(0);"
                role="button"
                Name
                data-bs-toggle="dropdown"
              >
                <img src="/images/profile/pic1.jpg" width="20" alt="" />
                <div className="header-info ms-3 align-items-center">
                  <span className="font-w600 ">
                    Hi, Admin
                    {/* <b>{merchantData?.companyName?.split(" ")[0]}</b> */}
                  </span>
                  <small className="text-end font-w400">
                    {/* {merchantData?.email} */}
                  </small>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link to="/" className="dropdown-item ai-icon">
                  <svg
                    id="icon-user1"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span className="ms-2">Profile </span>
                </Link> */}
                {/* <a href="/" className="dropdown-item ai-icon">
                  <svg
                    id="icon-inbox"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-success"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  <span className="ms-2">Inbox </span>
                </a> */}
                <a
                  href="/"
                  className="dropdown-item ai-icon"
                  onClick={handleClearLocalStorage}
                >
                  <svg
                    id="icon-logout"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-danger"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span className="ms-2">Logout </span>
                </a>
              </div>
            </li>
            <li>
              <Link to="/Dashboard">
                <Tooltip title="Dashboard">
                  <SpaceDashboardIcon />
                </Tooltip>

                <span class="nav-text">Dashboard</span>
              </Link>
            </li>
            {prefix === "ALL" ? (
              <li>
                <Link
                  className={`${
                    expandedmasterMerchant ? "expanded " : "custom-white-color"
                  }`}
                  to="/MasterMerchant/Submitted"
                  aria-expanded={expandedmasterMerchant}
                  onClick={() =>
                    handleToggle(
                      expandedmasterMerchant,
                      setExpandedMasterMerchant
                    )
                  }
                >
                  <Tooltip title="Master Merchant">
                    <AccountCircleIcon />
                  </Tooltip>

                  <span className="nav-text">Master Merchant</span>
                </Link>
              </li>
            ) : null}
            {prefix === "ALL" ? (
              <li>
                <Link
                  className={`${
                    expandedExternal ? "expanded " : "custom-white-color"
                  }`}
                  to="/ExternalMaster/Submitted"
                  aria-expanded={expandedExternal}
                  onClick={() =>
                    handleToggle(expandedExternal, setExpandedExternal)
                  }
                >
                  {/* <span className="nav-text">Vehicle Management</span> */}
                  {/* <i
                    className="fa-solid fa-file-invoice-dollar"
                    title="Vehicle Management"
                  ></i> */}
                  <Tooltip title="Vehicle Management">
                    <ContactEmergencyIcon />
                  </Tooltip>
                  <span className="nav-text">Vehicle Management</span>
                </Link>
              </li>
            ) : null}

            {hasPrivilages179774 || hasPrivilages179773 ? (
              <li>
                <Link
                  className={`${
                    expandedTools ? "expanded " : "custom-white-color"
                  }`}
                  to="/Masters"
                  aria-expanded={expandedmaster}
                  onClick={() =>
                    handleToggle(expandedmaster, setexpandedmaster)
                  }
                >
                  {/* <i className="fa-solid fa-circle-question" title="Master"></i> */}
                  {/* <i class="fa-solid fa-user-tie" title="Master"></i> */}
                  <Tooltip title="Masters">
                    <PersonIcon />
                  </Tooltip>
                  <span className="nav-text">Masters</span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                className={`${
                  expandedonBoarding ? "expanded " : "custom-white-color"
                }`}
                to="/OnBoarding"
                aria-expanded={expandedonBoarding}
                onClick={() =>
                  handleToggle(expandedonBoarding, setexpandedonBoarding)
                }
              >
                {/* <i
                  className="fa-solid fa-file-invoice-dollar"
                  title="On-Boarding"
                ></i> */}
                <Tooltip title="On-Boarding">
                  <DisplaySettingsIcon />
                </Tooltip>
                <span className="nav-text">On-Boarding</span>
              </Link>
            </li>
            {/* <li>
              <Link
                className={`${
                  expandedonBoarding ? "expanded " : "custom-white-color"
                }`}
                to="/OnBoarding/Phase2Details"
                aria-expanded={expandedonBoarding}
                onClick={() =>
                  handleToggle(expandedonBoarding, setexpandedonBoarding)
                }
              >
                <i className="fa-solid fa-file-invoice-dollar"></i>
                <span className="nav-text">Phase2 details</span>
              </Link>
            </li> */}
            <li>
              <Link
                className={`${
                  expandedTools ? "expanded " : "custom-white-color"
                }`}
                to="/Tools"
                aria-expanded={expandedTools}
                onClick={() => handleToggle(expandedTools, setExpandedTools)}
              >
                <Tooltip title="Tools">
                  <HandymanIcon />
                </Tooltip>
                {/* <i class="fa-solid fa-screwdriver-wrench"></i> */}
                {/* <i class="" title="Tools"></i> */}
                <span className="nav-text">Tools</span>
              </Link>
            </li>
            <li>
              <Link
                className={`${expanded ? "expanded " : "custom-white-color"}`}
                to="/Reports/LiveCummulativeReport"
                aria-expanded={expanded}
                onClick={() => handleToggle(expanded, setExpanded)}
              >
                <Tooltip title="Reports">
                  <AssessmentIcon/>
                </Tooltip>
                {/* <i class="fa-solid fa-flag"></i> */}
                {/* <i title="Reports"></i> */}
                <span className="nav-text">Reports</span>
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  expandedCustomer ? "expanded " : "custom-white-color"
                }`}
                to="#"
                aria-expanded={expandedCustomer}
                onClick={() =>
                  handleToggle(expandedCustomer, setexpandedCustomer)
                }
              >
                <Tooltip title="Customer-Support">
                  <SupportAgentIcon />
                </Tooltip>
                {/* <i class="fa-solid fa-headset"></i> */}
                <span className="nav-text">Customer Support</span>
              </Link>

              <ul
                className={`dropdown-menu shadow-none ${
                  expandedCustomer ? "show" : ""
                }`}
              >
                <li>
                  <Link to="/CustomerSupport/OnBoarding">On-Boarding</Link>
                </li>
                <li>
                  <Link to="/CustomerSupport/TechnicalSupport">
                    Technical Support
                  </Link>
                </li>
                <li>
                  <Link to="/CustomerSupport/RiskManagementSupport">
                    Risk Manag. Supp.
                  </Link>
                </li>
                <li>
                  <Link to="/CustomerSupport/Accounting">Accounting</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                className={`${
                  expandedSettings ? "expanded " : "custom-white-color"
                }`}
                to="/Settings"
                aria-expanded={expandedSettings}
                onClick={() =>
                  handleToggle(expandedSettings, setExpandedSettings)
                }
              >
                <Tooltip title="Settings">
                  <SettingsIcon />
                </Tooltip>
                {/* <i className="fa-solid fa-gear fw-bold" title="Settings"></i> */}
                <span className="nav-text">Settings</span>
              </Link>
            </li>
          </ul>
          <div class="copyright">
            <p>
              <strong>Icepe</strong> © 2024 All Rights Reserved
            </p>
            <p class="fs-12">
              Made with <span class="heart"></span> by Icepe
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
