import React, { useState, useEffect } from "react";
import { Card, Col, Offcanvas, Row } from "react-bootstrap";
import DropDowns from "./DropDowns";
import swal from "sweetalert";
import { getKeyPressed } from "../../utils/helper";
import useApiServices from "../../utils/useApiServices";
import {
  GET_ALL_SCHEME,
  GET_ALL_MERCHANT_MASTER,
  GET_ALL_BANK,
  GET_ALL_SWITCH_DATA,
  GET_ALL_AGGREGATOR_DATA,
  GET_ALL_VEHICLE_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE,
  SAVE_VEHICLE_MASTER_FORM,
} from "../../utils/constant";

const Phase1Form = ({ onHide, show, setRefresh, refresh }) => {
  const [schemeData, setSchemeData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [aggeregatorData, setAggeregatorData] = useState([]);
  const [switchData, setSwitchData] = useState([]);
  const [masterMerchantData, setMasterMerchantData] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentSubTypes, setPaymentSubTypes] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [selectedType, setSelectedType] = useState({
    schemeId: "",
    aggeregatorId: "",
    masterMerchantId: "",
    switchId: "",
    vehicleName: "",
    bankId: "",
    schemeName: "",
    aggeregatorName: "",
    // masterMerchantId: "",
    switchName: "",

    bankName: "",
    remark: "",
  });
  const offcanvasStyle = {
    width: "100vw", // Set width to 70vw
  };
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });

  useEffect(() => {
    GetScheme();
    GetAllBankData();
    GetAllAggregatorData();
    GetAllSwitchData();
    GetVehicleType();
    GetVehicleSubType();
    getAllMerchantMaster();
  }, []);

  const GetScheme = async () => {
    try {
      // setShowLoader(true);
      const response = await apiCallFnforGet(GET_ALL_SCHEME);

      if (response.statusCode === 200) {
        setSchemeData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.schemeId,
            };
          })
        );
      } else {
        setSchemeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER);
      if (response.statusCode === 200) {
        setMasterMerchantData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.masterMerchantId,
            };
          })
        );
      } else {
        setMasterMerchantData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBankData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_BANK); // Add the await keyword here

      if (response.statusCode === 200) {
        setBankData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.bankId,
            };
          })
        );
      } else {
        setBankData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllSwitchData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_SWITCH_DATA); // Add the await keyword here

      if (response.statusCode === 200) {
        setSwitchData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.switchId,
            };
          })
        );
      } else {
        setSwitchData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllAggregatorData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_AGGREGATOR_DATA);

      if (response.statusCode === 200) {
        setAggeregatorData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.aggregatorId,
            };
          })
        );
      } else {
        setAggeregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleType = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICLE_TYPE_WITH_ACTIVE);

      if (response.statusCode === 200) {
        setPaymentTypes(response?.data);
      } else {
        setPaymentTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleSubType = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE
      ); // Add the await keyword here

      if (response.statusCode === 200) {
        setPaymentSubTypes(response?.data);
      } else {
        setPaymentSubTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    // console.log(type);

    if (pressedKeyName === "Backspace" && name === "remark") {
      setSelectedType({ ...selectedType, [name]: value });
    }

    if (name === "remark" && selectedType.remark.length >= 200) {
      return;
    }

    if (
      name === "platformFeePercentage" ||
      name === "processingFeePercentage" ||
      name === "nodelFeePercentage" ||
      name === "interchargePercentage"
    ) {
      if (value >= 0 && value <= 100) {
        setSelectedType({ ...selectedType, [name]: value });
      }
      return;
    }
    setSelectedType({ ...selectedType, [name]: value?.toUpperCase() });
  };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const remarkLength = 200;

    if (
      name === "remark" &&
      selectedType.remark?.length + pastedText?.length > remarkLength
    ) {
      swal({
        title: "Alert!",
        text: `Remark must be lesser than ${remarkLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  const handleTypeCheckBox = (e) => {
    const found = selectedPaymentTypes?.find(
      (item) => item === parseInt(e.target.value)
    );
    if (found) {
      setSelectedPaymentTypes(
        selectedPaymentTypes?.filter((item) => item !== found)
      );
    } else {
      setSelectedPaymentTypes([
        ...selectedPaymentTypes,
        parseInt(e.target.value),
      ]);
    }
  };

  const handleSubTypeCheckBox = (e) => {
    const paymentSubTypeId = parseInt(e.target.value);
    const correspondingPaymentType = paymentTypes?.find((type) =>
      type.paymentSubTypedtos.some(
        (subType) => subType.paymentSubTypeId === paymentSubTypeId
      )
    );

    if (!correspondingPaymentType) {
      swal({
        title: "Alert!",
        text: "Invalid payment subtype selected.",
        icon: "error",
      });
      return;
    }

    const foundPaymentType = selectedPaymentTypes.includes(
      correspondingPaymentType.paymentTypeId
    );

    if (!foundPaymentType) {
      setSelectedPaymentSubTypes([]);
      swal({
        title: "Alert!",
        text: "Please select the corresponding Vehicle Type first.",
        icon: "error",
      });
      return;
    }

    const found = selectedPaymentSubTypes.includes(paymentSubTypeId);

    if (found) {
      // Deselecting payment subtype
      setSelectedPaymentSubTypes(
        selectedPaymentSubTypes?.filter((item) => item !== paymentSubTypeId)
      );
    } else {
      // Selecting payment subtype
      setSelectedPaymentSubTypes([
        ...selectedPaymentSubTypes,
        paymentSubTypeId,
      ]);
    }
  };

  const generatePayload = () => {
    return {
      aggregatorId: selectedType.aggeregatorId,
      bankId: selectedType.bankId,
      paymentSubTypes: selectedPaymentSubTypes,
      paymentTypes: selectedPaymentTypes,
      schemeId: selectedType.schemeId,
      switchId: selectedType.switchId,
      masterMerchantId: selectedType.masterMerchantId,
      vehicleName: selectedType.vehicleName,
      remark: selectedType.remark,
    };
  };

  const handleSubmit = async () => {
    const payload = generatePayload();

    if (selectedType.vehicleName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Vehicle Name.",
        icon: "error",
      });
      return;
    }

    if (selectedType.schemeId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Scheme.",
        icon: "error",
      });
      return;
    }

    if (selectedType.bankId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Bank.",
        icon: "error",
      });
      return;
    }

    if (selectedType.switchId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch.",
        icon: "error",
      });
      return;
    }

    if (selectedType.aggeregatorId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Aggeregator.",
        icon: "error",
      });
      return;
    }

    // if (
    //   selectedType.aggeregatorId == "3" &&
    //   selectedType.bankId == "3" &&
    //   selectedType.switchId == "3" &&
    //   selectedType.schemeId == "3"
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please select a section that does not contain N/A.",
    //     icon: "error",
    //   });
    //   return;
    // }

    if (!selectedPaymentTypes.length) {
      swal({
        title: "Alert!",
        text: "Please Select Vehicle Types.",
        icon: "error",
      });
      return;
    }
    const paymentTypesWithSubtypes = paymentTypes?.filter(
      (type) => type.paymentSubTypedtos.length > 0
    );

    for (const paymentType of paymentTypesWithSubtypes) {
      if (
        selectedPaymentTypes?.includes(paymentType.paymentTypeId) &&
        !selectedPaymentSubTypes.some((subType) =>
          paymentType.paymentSubTypedtos.some(
            (subtype) => subtype.paymentSubTypeId === subType
          )
        )
      ) {
        swal({
          title: "Alert!",
          text: `Please select all subtypes for '${paymentType.paymentService}`,
          icon: "error",
        });
        return;
      }
    }
    try {
      const response = await apiCallFnforPost(
        SAVE_VEHICLE_MASTER_FORM,
        payload
      );

      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
        });

        setSelectedType({
          vehicleName: "",
          schemeId: "",
          bankId: "",
          switchId: "",
          masterMerchantId: "",
          aggeregatorId: "",
          remark: "",
        });
        setSelectedPaymentTypes([]);
        setSelectedPaymentSubTypes([]);
        setRefresh(!refresh)
        onHide();
      } else if (response.statusCode === 309) {
        swal({
          title: "Alert",
          text: "Service already exists",
          icon: "alert",
        });
      
      } else {
        swal({
          title: "Failed",
          text: "Vehicle Master Form Not Saved",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setSelectedType({
      vehicleName: "",
      schemeId: "",
      bankId: "",
      switchId: "",
      masterMerchantId: "",
      aggeregatorId: "",
      remark: "",
    });
    setSelectedPaymentTypes([]);
    setSelectedPaymentSubTypes([]);
    setRefresh(!refresh);
    onHide();
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            Create Vehicle Phase 1
          </h5>
        </div>

        <Offcanvas.Body>
          <div class="row ">
            <div class="col-12 d-flex justify-content-center">
              <div className="">
                <div className="card-body py-4 ">
                  <div id="" className="form-wizard order-create ">
                    <div id="" className="tab-pane " role="tabpanel">
                      <div className="row ">
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label
                              htmlFor="vehicleName"
                              className="form-label text-dark fs-4"
                            >
                              Vehicle Name
                            </label>
                            <input
                              type="text"
                              name="vehicleName"
                              id="vehicleName"
                              className="form-control"
                              value={selectedType.vehicleName}
                              onChange={handleTypeChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <DropDowns
                              fieldName={"Aggregator"}
                              Name={"aggeregatorId"}
                              HandleChange={handleTypeChange}
                              SelectedType={selectedType.aggeregatorId}
                              AllTypes={aggeregatorData}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <DropDowns
                              fieldName={"Scheme"}
                              Name={"schemeId"}
                              HandleChange={handleTypeChange}
                              SelectedType={selectedType.schemeId}
                              AllTypes={schemeData}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <DropDowns
                              fieldName={"Master Merchant"}
                              Name={"masterMerchantId"}
                              HandleChange={handleTypeChange}
                              SelectedType={selectedType.masterMerchantId}
                              AllTypes={masterMerchantData}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <DropDowns
                              fieldName={"Bank"}
                              Name={"bankId"}
                              HandleChange={handleTypeChange}
                              SelectedType={selectedType.bankId}
                              AllTypes={bankData}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <DropDowns
                              fieldName={"Switch"}
                              Name={"switchId"}
                              HandleChange={handleTypeChange}
                              SelectedType={selectedType.switchId}
                              AllTypes={switchData}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark fs-4 ">
                              Remark
                            </label>

                            <input
                              name="remark"
                              id="remark"
                              className="form-control"
                              value={selectedType.remark}
                              onPaste={(e) => handelPasteEvent(e, "remark")}
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={handleTypeChange}
                            />
                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 - selectedType.remark?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                    

                        {/* Statr */}
                        {/* <div class="row">
  
 
</div> */}
                        {/* ending */}

                        {/* Vehicle Types & Sub-type */}
                        <div class="container ">
                          <div className="container-pinterest">
                            {paymentTypes?.map((paymentType, i) => (
                              <div class="box card input-box-shadow">
                                <div class="card-body">
                                  <div className="pb-2 d-flex justify-content-start align-items-center gap-2">
                                    <div class="checkbox-wrapper-31">
                                      <input
                                        type="checkbox"
                                        style={{
                                          border: "1px solid grey",
                                          scale: "1",
                                        }}
                                        name="typeOfServices"
                                        className="form-check-input"
                                        value={paymentType.paymentTypeId}
                                        checked={
                                          selectedPaymentTypes.includes(
                                            paymentType.paymentTypeId
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={handleTypeCheckBox}
                                      />
                                      <svg viewBox="0 0 35.6 35.6">
                                        <circle
                                          class="background"
                                          cx="17.8"
                                          cy="17.8"
                                          r="17.8"
                                        ></circle>
                                        <circle
                                          class="stroke"
                                          cx="17.8"
                                          cy="17.8"
                                          r="14.37"
                                        ></circle>
                                        <polyline
                                          class="check"
                                          points="11.78 18.12 15.55 22.23 25.17 12.87"
                                        ></polyline>
                                      </svg>
                                    </div>
                                    <h5 class="card-title">
                                      {paymentType?.paymentService?.toUpperCase()}
                                    </h5>
                                  </div>

                                  {paymentType?.paymentSubTypedtos?.map(
                                    (paymentSubType, i) => (
                                      <div
                                        key={i}
                                        class="checkbox-wrapper-27 pt-1"
                                      >
                                        <label class="checkbox w-100">
                                          <input
                                            type="checkbox"
                                            name="typeOfServices"
                                            value={
                                              paymentSubType.paymentSubTypeId
                                            }
                                            checked={
                                              selectedPaymentSubTypes.includes(
                                                paymentSubType.paymentSubTypeId
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={handleSubTypeCheckBox}
                                          />
                                          <span class="checkbox__icon"></span>
                                          {paymentSubType.paymentSubService}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* Vehicle Types & Sub-type Ends here */}
                        <div className="d-flex justify-content-end">
                          <button
                            className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Phase1Form;
