import React,{useState, useEffect} from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Ledger = ({type, toggleRightBar}) => {
  const [applications, setApplications] = useState([])
  const columns = [
    { headerName: 'Date', field: 'Date', minWidth: 'auto' },
    { headerName: 'Time', field: 'Time', minWidth: 'auto' },
    { headerName: 'Transaction Id', field: 'To Customer/Vendor Name', minWidth: 'auto' },
    { headerName: 'Credit', field: 'Particular(A/C or VPA)', minWidth: 'auto' },
    { headerName: 'Debit', field: 'Amount', minWidth: 'auto' },
    { headerName: 'RRN', field: 'RRN', minWidth: 'auto' },
 
  
  ];
  return (
    // <div className="content-body">
    <div className="container-fluid">
  <div className="d-flex justify-content-start align-items-center mb-4">
                    <div className='d-flex align-items-center'>
                    <button
                  type="button"
                  class="btn-close m-3"
                  onClick={toggleRightBar}
                ></button>
              
                <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>
                    </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <div className="table-responsive">
                  <DataGrid
                    rows={applications}
                    columns={columns.map((column) => ({
                      ...column,
                      renderCell: (params) =>
                        column.field === "active" || column.field === "action"
                          ? params.value
                          : params.value,
                    }))}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                  />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  // </div>
  );
};

export default Ledger;
