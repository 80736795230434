import React, { useState, useEffect } from "react";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useApiServices from "../../utils/useApiServices";
import { GET_PAYOUT_RULES_API, GET_RULES_API } from "../../utils/constant";
import { DataGrid } from "@mui/x-data-grid";
import SlideBox from "../../common/SlideBox/SlideBox";
import PayoutFrmAndRoutingRule from "./PayoutFrmAndRoutingRule";
import { Offcanvas } from "react-bootstrap";
import ShuffleRules from "./ShuffleRules";
import CheckExportConst from "../../utils/CheckRxportConst"




const FrmRoutingTable = ({ toggleRightBar }) => {
  const { apiCallFnforGet } = useApiServices();
  const [merchants, setMerchants] = useState([]);
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [showRule, setShowRule] = useState(false);
  const [mid, setMid] = useState("");
  const [viewOnly, setViewOnly] = useState(false);

  
  const {
    hasPrilage2146,
    hasPrilage2145
  } = CheckExportConst();

  console.log("Frm-Rule_View hasPrilage2146-->",hasPrilage2146)
  console.log(" Frm-Rule-Edit hasPrilage2145-->",hasPrilage2145)
  



  const getAllMerchantsWithTheirRules = async () => {
    try {
      const response = await apiCallFnforGet(GET_PAYOUT_RULES_API);
      if (response.statusCode == 200) {
        const finalRes = response.data.map((item) => ({
          id: item.mid,
          companyName: item.companyName,
          action: (
            <button className="badge bg-primary text-white ms-2">View</button>
          ),
        }));
        setMerchants(finalRes);
      } else if (response?.statusCode === 304) {
        setMerchants([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggle = () => {
    console.log('toggleong');
  }

  //   const toggleRightBar = (type) => {
  //     if (type) {
  //       setReport("type");
  //     }

  //     setFlag(!flag);
  //   };

  const addRule = () => {
    // setFlag(true);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false)
  }

  useEffect(() => {
    getAllMerchantsWithTheirRules();
  }, [show]);

  const columns = [
    {
      headerName: "Merchant Id",
      field: "id",
      minWidth: 300,
    },
    {
      headerName: "Company Name",
      field: "companyName",
      minWidth: 300,
    },
    {
      headerName: "Action",
      filed: "action",
      minWidth: 300,
      renderCell: (params) => {

        const onClick = () => {
          console.log(params?.row?.id)
          setMid(params?.row?.id);
          setShowRule(true);
          setViewOnly(false);
        }
        const onClickView = () => {
          console.log(params?.row?.id)
          setMid(params?.row?.id);
          setShowRule(true);
          setViewOnly(true);
        }
        return <div className="d-flex justify-content-start align-items-center mt-3 gap-3"><button className="badge badge-info" onClick={onClickView} >View</button>
       {hasPrilage2145 && <button className="badge badge-primary" onClick={onClick} >Edit</button>} 
        </div>;
      },
    },
  ];

  const offcanvasStyle = {
    width: "90vw", // Set width to 70vw
  };


  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between bg-white">
        <div className="d-flex justify-content-start align-items-center">
          <button
            type="button"
            className="btn-close m-3"
            onClick={toggleRightBar}
          ></button>
          <div className="fs-2 text-dark fw-bold">FRM And Routing Rule</div>
        </div>
        {hasPrilage2145 &&
        <div className="d-flex gap-2 justify-content-end mt-3 me-4 mb-2">
          <CustomTip size="18" placement="top" title="Add Rules">
            <div
              className=""
              onClick={addRule}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </CustomTip>
        </div>}
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
        keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton onClick={() => setShow(false)}></Offcanvas.Header>
          <h5 class="offcanvas-title  fs-2 text-dark fw-bold" id="offcanvasLabel">
            Add Rule
          </h5>
        </div>
        <Offcanvas.Body>
          <PayoutFrmAndRoutingRule handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>


      {/* <div className={chatbox chat1 ${flag && "active"}}>
      <div 
      onClick={toggleRightBar}></div>
      <div class="d-flex flex-column">
      

        <div
          class="card-body contacts_body p-0 dlab-scroll  "
          id="dlab_W_Contacts_Body"
        >
          <div><FrmAndRoutingRule toggleRightBar={toggleRightBar}/></div>
        </div>

       
      </div>
    </div> */}



      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <div>
                  <DataGrid columns={columns} rows={merchants} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShuffleRules mid={mid} showRule={showRule} setShowRule={setShowRule}  viewOnly={viewOnly} isPayout={true}/>
    </div>
  );
};

export default FrmRoutingTable;