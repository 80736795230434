import React, { useState, useEffect } from "react";
import useApiServices from "../../utils/useApiServices";
import { GET_ALL_FRM, SAVE_FRM_DATA } from "../../utils/constant";
import { styled } from "@mui/material/styles";
import { Card, Col, Container, Row, Modal } from "react-bootstrap";
import Slider from "@mui/material/Slider";

import swal from "sweetalert";

const CustomSlider = styled(Slider)({
  color: "#3e85f7",
  height: 8,
  " .MuiSlider-track": {
    border: "none",
  },
  " .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    ":focus, :hover, .Mui-active, .Mui-focusVisible": {
      boxShadow: "inherit",
    },
    ":before": {
      display: "none",
    },
  },
  ".MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#3e85f7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const FraudAndRiskManagement = ({show, onHide, setRefresh, refresh}) => {
  const [merchantData, setMerchantData] = useState([]);
  const [callAgain, setCallAgain] = useState(!false);

  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const [FRMData, setFRMData] = useState({
    location: 0,
    typeOfBusiness: 0,
    websiteAnalysis: 0,
    appAnalysis: 0,
    directorProfile: 0,
    companyProfile: 0,
    businessProfile: 0,
    chargebackProbability: 0,
    bankApprovalProbability: 0,
    ticketSize: 0,
    volume: 0,
    numberOfTickets: 0,
    mid: "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFRMData({ ...FRMData, [name]: value });
  };

  const handelFRMSubmit = async (e) => {
    e.preventDefault();
    if (FRMData.mid === "") {
      swal({
        title: "Alert!",
        text: "Please select company !",
        icon: "error",
      });
      return;
    }
  
    await apiCallFnforPost(SAVE_FRM_DATA, FRMData)
     
      .then((response) => {
        if (response.statusCode === 200) {
          handleClose();
          swal({
            title: "Success!",
            text: response.message,
            icon: "success",
          });
          
          setCallAgain(!callAgain);
          setFRMData({
            location: 0,
            typeOfBusiness: 0,
            websiteAnalysis: 0,
            appAnalysis: 0,
            directorProfile: 0,
            companyProfile: 0,
            businessProfile: 0,
            chargebackProbability: 0,
            bankApprovalProbability: 0,
            ticketSize: 0,
            volume: 0,
            numberOfTickets: 0,
            mid: "",
          });
          setRefresh(!refresh);
          return;
        } else {
          swal({
            title : "failed!",
            text: response.message,
            icon : "error"
          })
        }
        
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        
      });

  
  };
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  useEffect(() => {
    apiCallFnforGet(GET_ALL_FRM)
      // .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforFRM`, {
      //   headers: {
      //     Authentication: `Bearer ${token}`,
      //   },
      // })
      .then((response) => {
        if (response.statusCode === 200) {
          
          setMerchantData(response?.data);
        } else if (response.statusCode === 304) {
          setMerchantData(response?.data);
        } else {
          console.error("NO APPLICATIONS FOUND:", response.data);
        }
        
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
      
      });
  }, [callAgain]);

  const handleClose = () => {
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">
            {" "}
            Fraud & Risk Management Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {/* <div class="content-body"> */}
      <div class="container-fluid">
        <div className="">
          <div className="merchant-form-info">
            <form
              className="form-section-form-group d-flex flex-column align-items-center"
              // onSubmit={handleSubmit}
            >
              <div className="col-lg-4 mb-4">
                <label className="mx-4 text-dark fs-4">
                  Please Select Company
                  <sup className="text-danger">*</sup>
                </label>
                <select
                              className="form-control"
                              name="mid"
                              value={FRMData.mid}
                              onChange={handelChange}
                            >
                              <option value="">
                                Select
                                <span className="star">*</span>
                              </option>
                              {merchantData?.map((merchant) => (
                                <option key={merchant.mid} value={merchant.mid}>
                                 {merchant.companyName}
                                </option>
                              ))}
                            </select>
              </div>

              <Row className="mt-3">
                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Location
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="location"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.location}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Types Of Business
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="typeOfBusiness"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.typeOfBusiness}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Website Analysis
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="websiteAnalysis"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.websiteAnalysis}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Application Analysis
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="appAnalysis"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.appAnalysis}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Director's Profile
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="directorProfile"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.directorProfile}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Company's Profile
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="companyProfile"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.companyProfile}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Business Profile
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="businessProfile"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.businessProfile}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Chargeback Probability
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="chargebackProbability"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.chargebackProbability}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Bank Approval Probability
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="bankApprovalProbability"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.bankApprovalProbability}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}
              </Row>

              <Card.Title className="h3 mt-5 d-flex flex-start text-primary">
                <h4>Product Based</h4>
              </Card.Title>
              <p className="bg-primary w-100" style={{ height: "1px" }}></p>
              <Row className="mt-3 w-100">
                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4 ">
                    Ticket Size
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="ticketSize"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.ticketSize}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Volume
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="volume"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.volume}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}

                <div className="col-lg-4 mb-2">
                  <label className="mx-4 text-dark fs-4">
                    Number Of Tickets
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="px-4">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      onChange={handelChange}
                      name="numberOfTickets"
                      marks={marks}
                      defaultValue={0}
                      value={FRMData.numberOfTickets}
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                </div>
                {/* ./form-group */}
              </Row>
            </form>

            <div className="d-flex justify-content-end">
              <div className="form-group  tool-bar-btn mb-0 mt-3">
                <button
                  className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                  onClick={handelFRMSubmit}
                  id="submitDetailsBtn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          {/* Tab content end here */}

          {/* ./col */}
        </div>
      </div>
      </Modal.Body>
      </Modal>

      {/* </div> */}
    </>
  );
};

export default FraudAndRiskManagement;
