import SwipelincLogo from "../Assets/images/SwipelincLogo.png";
import LincpayLogo from "../Assets/images/LincpayLogo.png";
import SilverPeLogo from "../Assets/images/vector.svg";
import icepeLogo from "../Assets/images/icepeLogo.png";
import LotusLogo from "../Assets/images/LotusPayments.png";
import KwicPayLogo from "../Assets/images/KwicPayLogo.jpg";
import swal from "sweetalert";

// REGEX CONSTANTS
export const VPA_UPI_REGEX = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+$/
);

export const PAN_CARD_REGEX = new RegExp(
  /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
);
export const EMAIL_ID_REGEX = new RegExp(
  /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
);

export const WEBSITE_URL_REGEX = new RegExp(
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/
);
export const UTR_NUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/);
export const TRANSACTION_ID_REGEX = new RegExp(/^[0-9]*$/);
export const ORDER_NUMBER_REGEX = new RegExp(/^[0-9]*$/);
export const GST_NUMBER_REGEX = new RegExp(
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
);
const MOBILE_NUMBER_REGEX = /^[789]\d{9}$/;
const AADHAR_NUMBER_REGEX = /^\d{12}$/;
export const getServerEnvironment = (hostName) => {
  if (hostName === "admin.lincpay.in") return "productionLincpay";
  else if (hostName === "admin.swipelinc.com") return "productionSwipelinc";
  else if (hostName === "admin.icepe.in") return "productionIcepe";
  else if (hostName === "godemo.in/admin") return "GoDemodevelopment";
  else if (hostName === "newadmin.lincpay.in") return "productionNewAdmin";
  else if (hostName === "kwicpayadmin.finglo.in") return "productionKwicPay";
  else if (hostName === "admin.silverpe.in") return "productionSilverPe";
  else if (hostName === "admin.lotuspayments.online") return "productionLotus";
  else if (hostName === "localhost" || hostName === "newadmin.lincpay.in")
    return "development";
  else return "development";
  // else {
  //   swal({
  //     title: "ALERT!",
  //     text: `Invalid Host "${hostName}" !! Please Contact to Admin`,
  //     icon: "error",
  //   }).then(() => {
  //     return;
  //   })
  //   return
  // }
};

export function getCompleteDomain() {
  const { hostname } = window.location;

  return hostname;
}

export const config = (serverEnvironment) => {
  const logos = {
    productionLincpay: LincpayLogo,
    productionSilverPe: SilverPeLogo,
    productionSwipelinc: SwipelincLogo,
    productionLotus: LotusLogo,
    development: LincpayLogo,
    GoDemodevelopment: LincpayLogo,
    productionIcepe: icepeLogo,
    productionNewAdmin: icepeLogo,
    productionKwicPay: KwicPayLogo,
  };

  const Names = {
    productionLincpay: "Lincpay",
    productionSilverPe: "SilverPe",
    productionSwipelinc: "Swipelinc",
    productionLotus: "Lotus Payments",
    development: "Lincpay",
    GoDemodevelopment: "Lincpay",
    productionIcepe: "Icepe",
    productionNewAdmin: "Icepe",
    productionKwicPay: "KwicPay",
  };
  const Urls = {
    productionLincpay: "https://api.lincpay.in/",
    productionSwipelinc: "https://api.icepe.in/",
    productionSilverPe: "https://api.icepe.in/",
    // development: 'https://uatmerchant.lincpay.in/',
    productionLotus: "https://uatmerchant.lincpay.in/",
    development: "https://newadmin.lincpay.in/api/",
    GoDemodevelopment: "https://godemo.in/api/",
    // productionLotus: 'https://uatmerchant.lincpay.in/',
    productionIcepe: "https://admin.icepe.in/api/",
    productionNewAdmin: "https://newadmin.lincpay.in/api/",
    productionKwicPay: "https://kwicpayadmin.finglo.in/api/",
  };
  const logoPath = logos[serverEnvironment];
  const Name = Names[serverEnvironment];
  const Url = Urls[serverEnvironment];
  // const  bankDeatails = bankDetails[serverEnvironment] ;

  return {
    logoPath,
    Name,
    Url,
  };
};

export const dateConvert = (date) => {
  const date1 = new Date(date);
  const formattedDate = date1.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

const IFSC_REGEX = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);

export const isIfscRegexIsValid = (inputVpaValue) => {
  const isValidIfsc = IFSC_REGEX.test(inputVpaValue);
  if (isValidIfsc) {
    return true;
  }
  return false;
};

export const isGSTRegexIsValid = (str) => {
  // const isValidGST = GST_NUMBER_REGEX.test(inputValue);
  // if (isValidGST) return true;
  // return false;
  if (str == null) {
    return "false";
  }

  // Return true if the GST_CODE
  // matched the ReGex
  if (GST_NUMBER_REGEX.test(str) === true) {
    return true;
  } else {
    return false;
  }
};

export function convertDateFormat(inputDate) {
  if (!inputDate) {
    return null;
  }

  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export const isPanNumRegexIsValid = (inputValue) => {
  const isValidPan = PAN_CARD_REGEX.test(inputValue);
  if (isValidPan) return true;
  return false;
};
export const getKeyPressed = (e) => {
  return e.key;
};

export const stopSpecialCharacterInput = (inputValue) => {
  if (
    inputValue === "!" ||
    inputValue === "@" ||
    inputValue === "#" ||
    inputValue === "$" ||
    inputValue === "%" ||
    inputValue === "^" ||
    inputValue === "&" ||
    inputValue === "*" ||
    inputValue === "(" ||
    inputValue === ")" ||
    inputValue === "+" ||
    inputValue === "-" ||
    inputValue === "*" ||
    inputValue === "/" ||
    inputValue === ";" ||
    inputValue === ":" ||
    inputValue === "'" ||
    inputValue === '"' ||
    inputValue === "," ||
    inputValue === "<" ||
    // inputValue === "." ||
    inputValue === ">" ||
    inputValue === "?" ||
    inputValue === "{" ||
    inputValue === "}" ||
    inputValue === "[" ||
    inputValue === "]" ||
    inputValue === "|" ||
    inputValue === "=" ||
    inputValue === "_" ||
    inputValue === "-"
  )
    return true;
  return false;
};
export const stopCharacterInput = (inputValue, float) => {
  if (
    inputValue === "a" ||
    inputValue === "b" ||
    inputValue === "c" ||
    inputValue === "d" ||
    inputValue === "e" ||
    inputValue === "f" ||
    inputValue === "g" ||
    inputValue === "h" ||
    inputValue === "i" ||
    inputValue === "j" ||
    inputValue === "k" ||
    inputValue === "l" ||
    inputValue === "m" ||
    inputValue === "n" ||
    inputValue === "o" ||
    inputValue === "p" ||
    inputValue === "q" ||
    inputValue === "r" ||
    inputValue === "s" ||
    inputValue === "t" ||
    (float ? inputValue === "u" : inputValue === ".") ||
    inputValue === "u" ||
    inputValue === "v" ||
    inputValue === "w" ||
    inputValue === "x" ||
    inputValue === "y" ||
    inputValue === "z" ||
    inputValue === "`" ||
    inputValue === "~" ||
    inputValue === "A" ||
    inputValue === "B" ||
    inputValue === "C" ||
    inputValue === "D" ||
    inputValue === "E" ||
    inputValue === "F" ||
    inputValue === "G" ||
    inputValue === "H" ||
    inputValue === "I" ||
    inputValue === "J" ||
    inputValue === "K" ||
    inputValue === "L" ||
    inputValue === "M" ||
    inputValue === "N" ||
    inputValue === "O" ||
    inputValue === "P" ||
    inputValue === "Q" ||
    inputValue === "R" ||
    inputValue === "S" ||
    inputValue === "T" ||
    inputValue === "U" ||
    inputValue === "V" ||
    inputValue === "W" ||
    inputValue === "X" ||
    inputValue === "Y" ||
    inputValue === "Z"
  )
    return true;
  return false;
};

export const isVpaUpiRegexIsValid = (inputVpaValue) => {
  const isValidVpa = VPA_UPI_REGEX.test(inputVpaValue);
  if (isValidVpa) {
    return true;
  }
  return false;
};

export const isTransactionIdRegexIsValid = (inputValue) => {
  const isValidTransaction = TRANSACTION_ID_REGEX.test(inputValue);
  if (isValidTransaction) return true;
  return false;
};

export const isEmailIdRegexIsValid = (inputValue) => {
  const isValidEmail = EMAIL_ID_REGEX.test(inputValue);
  if (isValidEmail) return true;
  return false;
};

export const isUtrNumRegexIsValid = (inputValue) => {
  const isValidUTR = UTR_NUM_REGEX.test(inputValue);
  if (isValidUTR) return true;
  return false;
};

export const isOrderNumberRegexIsValid = (inputValue) => {
  const isValidOrderNumber = ORDER_NUMBER_REGEX.test(inputValue);
  if (isValidOrderNumber) return true;
  return false;
};

export const isMobileNumberValid = (inputValue) => {
  return MOBILE_NUMBER_REGEX.test(inputValue);
};

export const isAadharNumberValid = (inputValue) => {
  return AADHAR_NUMBER_REGEX.test(inputValue);
};

export const convertStringDateToDateType = (inputDateString) => {
  const dateParts = inputDateString.split(" ");

  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const day = parseInt(dateParts[2], 10);
  const month = months[dateParts[1]];
  const year = parseInt(dateParts[5], 10);
  const timeParts = dateParts[3].split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  const seconds = parseInt(timeParts[2], 10);

  // Replace time zone abbreviation with standard offset (e.g., 'IST' with '+0530')
  const offsetString = dateParts[4];
  const offsetHours = parseInt(offsetString.slice(0, 3), 10);
  const offsetMinutes = parseInt(offsetString.slice(3), 10);
  const timeZoneOffset = (offsetHours * 60 + offsetMinutes) * 60000;

  const date = new Date(year, month, day, hours, minutes, seconds);
  // Adjust the date with the time zone offset
  date.setTime(date.getTime() + timeZoneOffset);

  return date;
};

export const getMonthName = (monthNumber) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Check if the monthNumber is valid (between 1 and 12)
  if (monthNumber >= 1 && monthNumber <= 12) {
    // Array indices are 0-based, so subtract 1 from the monthNumber
    return months[monthNumber];
  } else {
    return "Invalid month number";
  }
};

export const isExpired = (toDate, expiryDate) => {
  const expirationDate = new Date(expiryDate);

  // Compare the two dates
  if (expirationDate < toDate) {
    return true; // The expiry date has passed
  } else {
    return false; // The expiry date is in the future
  }
};

// export const CheckPrivilages = (privilageId) => {
//   const { privilagesData, allPrivilagesData } = useSelector((state) => state.privilages);
//   const dispatch = useDispatch();

//   const getAdminPrivilegeBySubTypeId = (privilageId) => {
//     const matchingPrivilege = allPrivilagesData.find(
//       (privilege) => privilege.adminPrivilegeSubTypeId === privilageId
//     );

//     return matchingPrivilege || null;
//   };

//   console.log(privilagesData);

//   if (privilagesData.includes(privilageId)) {
//     return true;
//   } else {
//     dispatch(setSectionName(getAdminPrivilegeBySubTypeId(privilageId)));
//   }

//   return false; // Add a default return value
// };

export const stopNumberCharInput = (inputValue) => {
  // alert("called")
  if (
    inputValue === "1" ||
    inputValue === "2" ||
    inputValue === "3" ||
    inputValue === "4" ||
    inputValue === "5" ||
    inputValue === "6" ||
    inputValue === "7" ||
    inputValue === "8" ||
    inputValue === "9" ||
    inputValue === "0"
  )
    return true;
  return false;
};

export const stopAlphabetInput = (inputValue) => {
  // alert("called")
  if (
    inputValue !== "1" &&
    inputValue !== "2" &&
    inputValue !== "3" &&
    inputValue !== "4" &&
    inputValue !== "5" &&
    inputValue !== "6" &&
    inputValue !== "7" &&
    inputValue !== "8" &&
    inputValue !== "9" &&
    inputValue !== "0" &&
    inputValue !== "!" &&
    inputValue !== "@" &&
    inputValue !== "#" &&
    inputValue !== "$" &&
    inputValue !== "%" &&
    inputValue !== "^" &&
    inputValue !== "&" &&
    inputValue !== "*" &&
    inputValue !== "(" &&
    inputValue !== ")" &&
    inputValue !== "+" &&
    inputValue !== "-" &&
    inputValue !== "*" &&
    inputValue !== "/" &&
    inputValue !== ";" &&
    inputValue !== ":" &&
    inputValue !== "'" &&
    inputValue !== '"' &&
    inputValue !== "," &&
    inputValue !== "<" &&
    inputValue !== "." &&
    inputValue !== ">" &&
    inputValue !== "?" &&
    inputValue !== "{" &&
    inputValue !== "}" &&
    inputValue !== "[" &&
    inputValue !== "]" &&
    inputValue !== "|" &&
    inputValue !== "=" &&
    inputValue !== "_" &&
    inputValue !== "-"
  )
    return true;
  return false;
};

export const isWebsiteURLRegexIsValid = (inputValue) => {
  const isValidWebURL = WEBSITE_URL_REGEX.test(inputValue);
  if (isValidWebURL) return true;
  return false;
};

export function formatCompactNumber(number) {
  // console.log(number)
  if (number < 0) {
    //
    return "-" + formatCompactNumber(-number);
  }
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_00) {
    return (number / 1000).toFixed(2) + "K";
  } else if (number >= 1_000_00 && number < 1_000_000_0) {
    return (number / 1_000_00).toFixed(2) + "L";
  } else if (number >= 1_000_000_0 && number < 1_000_000_000_0) {
    return (number / 1_000_000_0).toFixed(2) + "Cr";
  } else if (number >= 1_000_000_000_00 && number < 1_000_000_000_000_0) {
    return (number / 1_000_000_000).toFixed(2) + "Ar";
  }
}

export const convertTime = (timestamp) => {
  if (!timestamp) {
    return;
  }
  // Split the timestamp string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timestamp?.split(":");

  // Convert hours to 12-hour format
  const formattedHours = (parseInt(hours, 10) % 12 || 12)
    ?.toString()
    ?.padStart(2, "0");

  // Determine AM or PM
  const period = parseInt(hours, 10) < 12 ? "AM" : "PM";

  // Construct the formatted time string without milliseconds
  const formattedTime = `${formattedHours}:${minutes}:${
    seconds?.split(".")[0]
  } ${period}`;

  console.log(formattedTime);

  return formattedTime;
};

export const getTimeWithoutMillisecondsAndDate = (dateTimeString) => {
  // Split the datetime string at the space to separate date and time
  if (dateTimeString !== null) {
    let dateTimeParts = dateTimeString?.split(" ");

    // The second part contains the time with milliseconds
    let timeWithMilliseconds = dateTimeParts[1];

    // Split the time part at the dot (.) to remove milliseconds
    let timeParts = timeWithMilliseconds?.split(".");

    // The first part contains the time without milliseconds
    let timeWithoutMilliseconds = timeParts[0];

    return timeWithoutMilliseconds;
  } else {
    return "";
  }
};

export const paginationCountCalc = (count) => {
  let totalPages = 0;
  if (count < 100) {
    return 1;
  } else {
    totalPages = Math.floor(count / 100); // Calculate full pages
    const remainder = count % 100; // Calculate remainder

    if (remainder !== 0) {
      totalPages++; // Increment totalPages if there's any remainder
    }

    return totalPages;
  }
};
