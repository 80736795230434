import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { DateTime } from "luxon";
import { isVpaUpiRegexIsValid } from "../../../utils/helper";
import { USE_SETTLEMENT_MULTIPLE_FILTER_API as getApiData } from "../../../utils/ApiServices";

const SettlementMultipleFilter = ({
  setApplications,
  mid,
  setSettlementReportData,
  setIsMultipleFilterOpen,
  setShowLoader,
}) => {
  const token = localStorage.getItem("token");
  const [endDate, setEndDate] = useState(Date);
  const [startDate, setStartDate] = useState(Date);
  const [startAmount, setStartAmount] = useState(Number);
  const [payinAmount, setPayinAmount] = useState(Number);
  const [startPayinAmount, setStartPayinAmount] = useState(Number);
  const [range, setRange] = useState("");
  const [endAmount, setEndAmount] = useState(Number);
  const [endPayinAmount, setEndPayinAmount] = useState(Number);
  const [searchOption, setSearchOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchOption2, setSearchOption2] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [show2, setShow2] = useState(true);

  const exampleValues = {
    amount: "Ex- 5500",
    amountInBetween: "Ex- 100-500",
    VPA: "Ex- abcde@xyz / 98XXXXXXX1@xyz",
    // Add more options and example values as needed
  };

  // const startDate = useSelector((store) => store.report.startDate);
  // const endDate = useSelector((store) => store.report.endDate);
  // const storeSearchValue = useSelector((state) => state.report.searchValue);
  // const dispatch = useDispatch();
  // console.log(amount)
  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);
  const [isAlphabet, setIsAlphabet] = useState(false);

  const handleAmountRange = (e) => {
    setRange(e.target.value);
    function splitString(inputString) {
      const parts = inputString.split("-").map((part) => part.trim());
      return parts;
    }

    const [startAmount, endAmount] = splitString(e.target.value);

    if (e.target.name === "amountInBetween") {
      setStartAmount(startAmount);
      setEndAmount(endAmount);
    } else if (e.target.name === "payinAmountInBetween") {
      setStartPayinAmount(startAmount);
      setEndPayinAmount(endAmount);
    }

    const amountRangeErr = document.getElementById("amountRangeErr");
    const searchBtn = document.getElementById("searchBtn");
  
    if (Number(startAmount) > Number(endAmount)) {
      amountRangeErr.classList.remove("d-none");
      searchBtn.setAttribute("disabled", "true");
    } else {
      amountRangeErr.classList.add("d-none");
      searchBtn.removeAttribute("disabled");
    }

    console.log(startAmount, endAmount);
  };

  const HandleSingleAmount = (e) => {
    console.log(e.target.name, e.target.value);
    if (e.target.name === "payinAmount") {
      setSearchValue("");
      setPayinAmount(e.target.value);
    } else if (e.target.name === "amount") {
      setPayinAmount("");
      setSearchValue(e.target.value);
    }
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handleFromToDateInputChange = (from, to) => {
    console.log(from, ":", to);

    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (inputToDateTime >= inputFromDateTime) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setSearchValue("");
  }, [searchOption]);
  useEffect(() => {
    setSearchValue2("");
  }, [searchOption2]);
  // endAmount,
  // startAmount,
  // vpa,
  // orderNo,
  // txnId,
  // endPayinAmount,
  // startPayinAmount,
  // payinAmount,
  // startDate,
  // endDate,
  // mid,
  // token,
  // setApplications

  const submitHandler = () => {
    //  alert("bgghfj")
    getApiData(
      0,
      0,
      // searchOption === "amountInBetween" ? endAmount : 0,
      // searchOption === "amount" ? searchOption === "amount" ? searchValue : 0 : searchOption === "amountInBetween" ? startAmount: 0,
      searchOption === "VPA" ? "" : "",
      "",
      "",

      searchOption === "payinAmountInBetween" ? endPayinAmount : 0,
      searchOption === "payinAmountInBetween" ? startPayinAmount : 0,
      searchOption === "payinAmount" ? payinAmount : 0,

      searchOption2 === "date"
        ? searchOption2 === "date"
          ? searchValue2
          : ""
        : searchOption2 === "dateInBetween"
        ? startDate
        : "",
      searchOption2 === "dateInBetween" ? endDate : "",

      mid,
      token,
      setApplications
    );
    handleModalClose();
  };

  const handleModalClose = () => {
    setShow2(false);
    setIsMultipleFilterOpen(false);
  };

  const handelSearchData = (e) => {
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
  };

  const getKeyCode = (e) => {
    // get alphabet
    if (
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.key === "Backspace" ||
      e.keyCode === 32
    ) {
      setSearchValue(e.target.value);
      setIsAlphabet(true);
      return;
    }
    setIsAlphabet(false);
    return;
  };

  const handelValidations = (e) => {
    if (searchOption === "VPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Invalid Enter Valid VPA");
      }
      return;
    }
  };

  // Modal Popup filter conts
  // const [show, setShow] = useState(false);

  // const handleModalClose = () => setShow(false);
  // const handleModalShow = () => setShow(true);
  return (
    <div>
      <div className="d-flex flex-row">
        <Modal show={show2} size="lg" centered>
          <Modal.Header className="bg-dark ">
            <Modal.Title className="text-white">Multiple Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-field-area">
              <fieldset className="rounded-0 mb-3">
                <legend className="fs-5 fw-bold">Select Date</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6} sm={12}>
                      <div>
                        <select
                          className="form-control"
                          value={searchOption2}
                          onChange={(e) => setSearchOption2(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="date">Date</option>
                          <option value="dateInBetween">Date In Between</option>
                          <i class="bi bi-caret-down-fill"></i>
                        </select>
                      </div>
                    </Col>

                    {searchOption2 !== "dateInBetween" ? (
                      <Col lg={6} sm={12}>
                        <div className="form-group">
                          <input
                            className="advanced-input-box form-control"
                            type="date"
                            value={searchValue2}
                            onChange={(e) => {
                              if (handleDateInputChange(e)) {
                                setSearchValue2("");
                                console.log("true..........");
                                document
                                  .getElementById("dateErr")
                                  .classList.remove("d-none");
                              } else {
                                document
                                  .getElementById("dateErr")
                                  .classList.add("d-none");
                                setSearchValue2(e.target.value);
                                console.log("false..........");
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            placeholder={
                              searchOption === "Date" ? "Enter Date" : ""
                            }
                          />
                          <span id="dateErr" className=" text-danger d-none">
                            *Date must be lesser than current date.
                          </span>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={startDate}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setStartDate("");
                                  document
                                    .getElementById("startDateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("startDateErr")
                                    .classList.add("d-none");
                                  setStartDate(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="Enter Start Date"
                            />
                            <span
                              id="startDateErr"
                              className=" text-danger d-none"
                            >
                              *Please Enter Valid Date!
                            </span>
                          </div>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={endDate}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setEndDate("");
                                  document
                                    .getElementById("endDateErr")
                                    .classList.remove("d-none");
                                } else {
                                  if (
                                    handleFromToDateInputChange(
                                      startDate,
                                      e.target.value
                                    )
                                  ) {
                                    document
                                      .getElementById("endDateErr")
                                      .classList.add("d-none");
                                    document
                                      .getElementById("fromToDateErr")
                                      .classList.add("d-none");
                                    setEndDate(e.target.value);
                                  } else {
                                    document
                                      .getElementById("fromToDateErr")
                                      .classList.remove("d-none");
                                  }
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="End Date"
                            />
                            <span
                              id="endDateErr"
                              className=" text-danger d-none"
                            >
                              *Please Enter Valid Date!
                            </span>
                            <span
                              id="fromToDateErr"
                              className=" text-danger d-none"
                            >
                              *TO Date can't be less than FROM Date!
                            </span>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </fieldset>

              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Select Parameter</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={searchOption}
                          onChange={(e) => setSearchOption(e.target.value)}
                        >
                          <option type="number" value="">
                            Select
                          </option>
                          {/* <option type="number" value="amount">
                            Amount
                          </option>
                          <option type="number" value="amountInBetween">
                            Amount Range
                          </option> */}
                          <option type="number" value="payinAmount">
                            Payin Amount
                          </option>
                          <option type="number" value="payinAmountInBetween">
                            Payin Amount Range
                          </option>
                          {/* <option type="text" value="VPA">
                            UPI ID / VPA
                          </option> */}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        {searchOption === "amountInBetween" ||
                        searchOption === "payinAmountInBetween" ? (
                          <>
                            <input
                              className="advanced-input-box form-control"
                              name={searchOption}
                              type="text"
                              min={0}
                              value={range}
                              onChange={(e) => handleAmountRange(e)}
                            />
                            <span id="amountRangeErr" className=" d-none">
                              * Start amount should not be greater than end
                              amount.
                            </span>
                            <span className="ml-2">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </>
                        ) : searchOption === "custLocation" ||
                          searchOption === "bankLocation" ? (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              value={searchValue}
                              onKeyDown={getKeyCode}
                              onChange={
                                isAlphabet
                                  ? (e) => setSearchValue(e.target.value)
                                  : () => {}
                              }
                            />
                            <span className="ml-2 mt-1">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </div>
                        ) : searchOption === "amount" ||
                          searchOption === "payinAmount" ? (
                          <div>
                            <input
                              name={searchOption}
                              className="advanced-input-box form-control"
                              type="number"
                              value={searchValue || payinAmount}
                              onChange={HandleSingleAmount}
                            />
                            <span className="ml-2 mt-1">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              value={searchValue}
                              onChange={handelSearchData}
                              onBlur={handelValidations}
                            />
                            {isSearchValueError ? (
                              <span id="searchValErr" className=" text-danger">
                                {`${searchValueError}`}
                              </span>
                            ) : (
                              ""
                            )}
                            <div>
                              <span className="ml-2 mt-1">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>

              {/* <fieldset  className='border-2 rounded-0 mt-3'>
                                <legend className='fs-5 fw-bold'>Enter VPA / UPI ID</legend>
                                <div className="field-set-form">
                                    <Row className='g-2'>
                                        <Col lg={12} sm={12}>
                                            <div className='form-group'>
                                                <input type="text" className='advanced-input-box form-control' value={payerVPA}
                                                    onChange={(e) => setPayerVPA(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </fieldset> */}

              {/* <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Transaction Status</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={12} sm={12}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={transectionStatus}
                          onChange={(e) => setTransectionStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="success">Success</option>
                          <option value="failed">Failure</option>
                          <option value="fraud">Fraud</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              size="sm"
              className="text-capitlize"
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
            id="searchBtn"
              className="text-capitlize"
              size="sm"
              variant="outline-primary"
              onClick={submitHandler}
            >
              <i class="bi bi-search mx-1"></i> Search
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* {multiApiResponse && multiApiResponse.length === 0 && (
    <div className="no-data-message">No data available.</div>
  )} */}
    </div>
  );
};

export default SettlementMultipleFilter;
