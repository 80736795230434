import React, { useEffect, useState } from "react";
import {
  FETCH_PAYIN_FOR_ACKNOWLEDGMENT,
  GET_ALL_AGGREGATOR,
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
  GET_USER_MANAGEMENT_DATA_BY_ID,
} from "../../../utils/constant";
import { getAllFunctionForApiCall } from "../../../utils/ApiServices";
import { Card, Col, Offcanvas, Row } from "react-bootstrap";
import { getTokenFromLocalStorage } from "../../../utils/tokenService";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import useApiServices from "../../../utils/useApiServices";

const ViewUser = ({ UserId, show, onHide }) => {
  const [aggregatorData, setAggregatorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [privilagesData, setPrivilagesData] = useState([]);
  const [privilages, setPrivilages] = useState([]);
  const [privilagesWithSubTypeData, setPrivilagesWithSubTypeData] = useState(
    []
  );
  const token = getTokenFromLocalStorage();
  const { apiCallFnforGet } = useApiServices();
  const offcanvasStyle = {
    width: "90vw", // Set width to 70vw
  };
  const decodedToken = jwtDecode(token);
  const { masterId } = decodedToken;
  console.log("userID IS", UserId);

  const getAggregatorData = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_USER_MANAGEMENT_DATA_BY_ID + UserId
      );
      if (response.statusCode === 200) {
        setAggregatorData(response?.data);

        const res = response?.data?.privilege?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId;
        });

        setPrivilagesData(res);
      } else {
        setAggregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const privilagesSlice = useSelector((state) => state.privilages);

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_PRIVILAGES_BY_ID + masterId
      );
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId;
        });

        await setPrivilagesData(res);
        console.log("fhgf--------", privilagesData);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    privilagesData?.map((item, i) => {
      console.log(item.adminPrivilegeSubTypeId);
      setPrivilages((prevPrivilages) => {
        return [...prevPrivilages, item.adminPrivilegeSubTypeId];
      });
    });
    console.log(privilages);
  }, [privilagesWithSubTypeData, privilagesData]);

  const getAllPrivilagesWithSubType = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES);

      if (response.statusCode === 200) {
        setPrivilagesWithSubTypeData(response?.data);
        console.log(privilagesData);
      } else {
        setPrivilagesWithSubTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // API calls
        await getAllPrivilagesWithSubType();
        // await getAllPrivilages();
        await getAggregatorData();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [UserId]);

  useEffect(() => {
    privilagesWithSubTypeData.map((item) => {
      privilagesData.includes(item.adminPrivilegeSubTypeId)
        ? console.log("true")
        : console.log("true");
    });
  }, [privilagesData]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement="end"
        style={offcanvasStyle}
      >
        <div
          className="d-flex justify-content-start align-items-center mt-3"
          style={{ marginLeft: "28px" }}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5
            class="offcanvas-title fs-2 text-dark fw-bold"
            id="offcanvasLabel"
          >
            View User
          </h5>
        </div>

        <Offcanvas.Body>
          <div className="card-body py-4">
            <div id="" className="form-wizard order-create">
              <div id="" className="tab-pane" role="tabpanel">
                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <div className="mb-3">
                      <label className="mb-1 text-dark fs-3">
                        User Name
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="mb-1 text-dark fs-5">
                        {aggregatorData?.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="mb-3">
                      <label className="mb-1 text-dark fs-3">
                        e-Mail Id
                        <sup className="text-danger">*</sup>
                      </label>

                      <div className="mb-1 text-dark fs-5">
                        {aggregatorData?.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="mb-3">
                      <label className="mb-1 text-dark fs-3">
                        Mobile Number
                        <sup className="text-danger">*</sup>{" "}
                      </label>

                      <div className="mb-1 text-dark fs-5">
                        {aggregatorData?.mobile}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="mb-3">
                      <label className="mb-1 text-dark fs-3">Password</label>

                      <div className="mb-1 text-dark fs-5">
                        {aggregatorData?.password}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4" style={{ borderTop: "0.2px solid gray" }}>
            <h4 className="fs-2">Privilages</h4>
          </div>
          <Row className="mt-3">
            <div className="d-flex flex-column gap-4">
              {privilagesData
                ? privilagesWithSubTypeData.map((item, i) => (
                    <div key={i} className="d-flex flex-column">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        {/* <div class="checkbox-wrapper-31">
                          {item.privilegeName}
                        </div> */}
                        <h1 className="pt-2">{item.privilegeName}</h1>
                      </div>
                      <div className="row fs-4 mt-2 text-dark">
                        {item.subTypes.map(
                          (item, i) =>
                            privilagesSlice?.privilagesData?.includes(
                              parseInt(item.adminPrivilegeSubTypeId)
                            ) && (
                              <>
                                <div
                                  key={i}
                                  class="checkbox-wrapper-27 col-lg-4 col-md-6 col-sm-12 d-flex "
                                >
                                  <label className="checkbox">
                                    <input
                                      id="flexSwitchCheckDefault"
                                      className=""
                                      type="checkbox"
                                      readOnly
                                      name={item.adminPrivilegeSubTypeId}
                                      checked={privilagesData.includes(
                                        item.adminPrivilegeSubTypeId
                                      )}
                                    />
                                    <span class="checkbox__icon"></span>
                                    {item.subTypePrivilegeName}
                                  </label>
                                </div>
                              </>
                            )
                        )}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewUser;
