import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, Modal } from "react-bootstrap";
import PayoutRouting from "./PayoutRouting";
import swal from "sweetalert";
import RouteIcon from "@mui/icons-material/Route";
import {
  ENABLE_DISABLE_PAYOUT_ROUTING,
  UPDATE_MERCHANT,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import {
  GET_ALL_SUBMITTED_PAYOUT_ROUTING,
  GET_VIRTUAL_ACCOUNT_DATA,
} from "../../utils/constant";

import useCheckPrivilages from "../../utils/checkPrivilages";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const ToolsPayoutSubmitted = ({ toggleRightBar, type1 }) => {
  const [applications, setApplications] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [accessType, setAccessType] = useState("");
  const [routing, setRoutingWith] = useState("");
  const [mid, setmid] = useState("");
  const [companyMid, setCompanyMid] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [transType, setTransType] = useState("");

  const handleClickIcon = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [editCallback, setEditCallback] = useState({
    id: "",
    callbackUrl: "",
    transferType: "",
    mappedMid: "",
    mappedWithCompanyName: "",
    usedFor: "",
    mid: "",
    requestRoute: "",
  });

  const [selectedAction, setSelectedAction] = useState("");
  const handleShowRouting = () => setIsModalRoutingOpen(true);

  const columns = [
    { headerName: "Merchant ID", field: "mid", width: 200 },
    { headerName: "Merchant Name", field: "companyName", width: 200 },
    { headerName: "Mapped With", field: "mappedWithCompanyName", width: 200 },
    { headerName: "Callback URL", field: "callbackUrl", width: 200 },
    { headerName: "Transfer Type", field: "transferType", width: 200 },
    { headerName: "Access Type", field: "usedFor", width: 200 },
    { headerName: "Route With", field: "requestRoute", width: 200 },
    { headerName: "Action", field: "action", width: 200 },
    // { headerName: "Active", field: "active", minwidth: 200 },
  ];
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();
  const hasPrivilages2636 = useCheckPrivilages(2636);

  const getVirtualAccountData = async (type) => {
    await apiCallFnforGet(GET_VIRTUAL_ACCOUNT_DATA + type)
      .then((response) => {
        if (response.statusCode === 200) {
          setCompanyData(response.data);
        } else setCompanyData();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  console.log("---->",companyData)
  const openDetails = (
    id,
    callbackUrl,
    transferType,
    mappedMid,
    mappedWithCompanyName,
    usedFor,
    mid,
    requestRoute
  ) => {
    setEditCallback({
      id,
      callbackUrl,
      transferType,
      mappedMid,
      mappedWithCompanyName,
      usedFor,
      mid,
      requestRoute,
    });

    setIsModalOpen(true);
    getVirtualAccountData(transferType);
    // setSelectedCompany(mappedWithCompanyName);
    setCompanyMid(mappedMid);
    setmid(mid);
    setAccessType(usedFor);
    setCallbackUrl("");
    setRoutingWith(requestRoute);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditCallback({
      id: "",
      callbackUrl: "",
      transferType: "",
      mappedMid: "",
      mid: "",
    });
    setAccessType("");
    setRoutingWith("");
    setRefresh(!refresh);
  };

  const EnbDsb = async (id, action) => {
    const EnableOrDisable = action === "enable" ? "enable" : "disable";
    swal({
      title: "Alert",
      text:`Are you sure, you want to ${EnableOrDisable}`,
      icon: "Warning",
      buttons: "Okay",
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(
          `${ENABLE_DISABLE_PAYOUT_ROUTING}${EnableOrDisable}/${id}`
        ).then((res) => {
          if (res.statusCode === 200) {
            swal({
              title: "Success!",
              text: `Payout Routing ${EnableOrDisable} Successfully`,
              icon: "success",
            });
            FetchData();
          } else {
            swal({
              title: "Failed",
              text: `Failed to ${EnableOrDisable}`,
              icon: "Error",
            });
          }
        });
      }
    });
  };
  const handelDataChange = (e) => {
    const { name, value } = e.target;
    setEditCallback({ ...editCallback, [name]: value });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    if (editCallback.usedFor === "Api") {
      if (editCallback.callbackUrl === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Callback URL",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      callbackUrl: editCallback.callbackUrl,
      id: editCallback.id,
      mappedMid: companyMid,
      mid: mid,
      transferType: transType,
      usedFor: accessType,
      requestRoute: routing,
    };
    handleCloseModal();

    await apiCallFnforPut(UPDATE_MERCHANT, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: "Details Updated Successfully.",
            icon: "success",
          });

          handleCloseModal();
          FetchData();
          setRefresh(!refresh);
        } else {
          swal({
            title: "Alert!",
            text: response.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // setShowLoader(false);
      });
  };

  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const handleAction = (item, action) => {
    switch (action) {
      case "edit":
        openDetails(
          item.id,
          item.callbackUrl,
          item.transferType,
          item.mappedMid,
          item.mappedWithCompanyName,
          item.usedFor,
          item.mid,
          item.requestRoute
        );
    //     const Company =  companyData?.filter((company) =>{ 
    //  if(company.mid === item.mappedMid){
    //   console.log("see here--->",company.companyName)
    //   return company.companyName
    //  }
    // });
    // setSelectedCompany(Company);
        break;
      case "enable":
        if (!item.active) {
          EnbDsb(item.id, "enable");
        }
        break;
      case "disable":
        if (item.active) {
          EnbDsb(item.id, "disable");
        }
        break;
      default:
        break;
    }
  };
  const handleMenuItemClick = (action, type, item) => {
    setEditCallback({
      id:item.id,   
      callbackUrl:item.callbackUrl,
      transferType:item.transferType,
      mappedMid:item.mappedMid,
      mappedWithCompanyName:item.mappedWithCompanyName,
      usedFor:item.usedFor,   
      mid:item.mid,
      requestRoute:item.requestRoute,
    });
    setAccessType(item.usedFor)
    // console.log(item.mappedMid)
    // console.log(companyData)
    // const Company =  companyData?.filter((company) =>{ 
    //  if(company.mid === item.mappedMid){
    //   console.log("see here--->",company.companyName)
    //   return company.companyName
    //  }
    // });
    // setSelectedCompany(Company);
    setSelectedAction(action);
    setAnchorEl(null);
    setIsModalOpen(true);
    setTransType(type);

    // Close the menu after selecting an option
  };

  
  const FetchData = async () => {
    // setShowLoader(true);
    try {
      const apiResponse = await apiCallFnforGet(
        GET_ALL_SUBMITTED_PAYOUT_ROUTING
      );
      //   setShowLoader(false);
      if (apiResponse.statusCode === 200) {
        const finalRes = apiResponse.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          companyName: item.companyName,
          mappedWithCompanyName: item.mappedWithCompanyName,
          callbackUrl: item.callbackUrl,
          transferType: item.transferType,
          usedFor: item.usedFor ? item.usedFor.toUpperCase() : "",
          requestRoute: item.requestRoute,
          action: (
            <Select
              value={selectedAction}
              onChange={(e) => {
                setSelectedAction(e.target.value);
                handleAction(item, e.target.value);
              }}
              displayEmpty
              renderValue={() => null}
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: "8px 2px", // Optional: Adjust padding for better spacing
                height: "25px",
              }}
            >
              <MenuItem
                value="edit"
                onClick={() => {
                  handleMenuItemClick("edit", item.transferType, item);
                  setSelectedCompany(item.mappedWithCompanyName);
                }}
              >
                Edit
              </MenuItem>
              {item.active ? (
                <MenuItem value="disable" className="bg-danger text-white">Disable</MenuItem>
              ) : (
                <MenuItem value="enable" className="bg-success text-white">Enable</MenuItem>
              )}
            </Select>
          ),
        }));

        //   action: (
        //     <>
        //       <button
        //         className="badge badge-rounded badge-warning"
        //         onClick={() =>
        //           openDetails(
        //             item.id,
        //             item.callbackUrl,
        //             item.transferType,
        //             item.mappedMid,
        //             item.mappedWithCompanyName,
        //             item.usedFor,
        //             item.mid,
        //             item.requestRoute
        //           )
        //         }
        //       >
        //         Edit
        //       </button>
        //     </>
        //   ),
        //   active: (
        //     <button
        //       className={`badge badge-rounded mx-2 px-3 py-2  ${
        //         !item.active ? "badge-success" : "badge-danger"
        //       }`}
        //       value={item.active ? "disable" : "enable"}
        //       onClick={(e) => EnbDsb(e, item.id)}
        //     >
        //       {item.active ? "Disable" : "Enable"}
        //     </button>
        //   ),
        // }));
        setApplications(finalRes);
      } else {
      }
    } catch (error) {
      //   setShowLoader(false);
    }
  };
  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    const companyMid =
      companyData?.find((company) => company.companyName === selectedCompany)
        ?.mid || "";
    setSelectedCompany(selectedCompany);
    setCompanyMid(companyMid);
  };

  useEffect(() => {
    FetchData();
  }, [refresh]);

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-between bg-white  ">
          <div className="d-flex justify-content-start align-items-center ">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Payout-Routing</div>
          </div>
          <div className="d-flex gap-2 justify-content-end mt-3 me-4">
            <CustomTip size="18" placement="top" title=" Payout Routing">
              <div className="" onClick={handleShowRouting}>
                <RouteIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={applications}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                     }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PayoutRouting
        show={isModalRoutingOpen}
        onHide={handleCloseModalRouting}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      {/* </div> */}

      <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Edit Callback URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card className="shadow border-0 mb-4"> */}
          {/* <Card.Body> */}
          <div className="merchant-report-view">
            <div className="report-table-view">
              <div className="containerflag ">
                <div className="row mt-4 ">
                  <div className="col-sm-12 col-md-8 offset-md-2">
                    {accessType?.toUpperCase() === "API" && (
                      <div className="form-group mt-3">
                        <label
                          htmlFor="callbackUrl"
                          className="mb-1 text-dark fs-4"
                        >
                          Enter Callback URL
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          id="callbackUrl"
                          name="callbackUrl"
                          placeholder="Enter Callback URL"
                          value={editCallback.callbackUrl}
                          onChange={handelDataChange}
                        />
                      </div>
                    )}

                    <div className="row mt-2">
                      <div className=" my-2">
                        <label
                          htmlFor="holdAmount"
                          className="mb-1 text-dark fs-4"
                        >
                          Select Virtual Account
                        </label>

                        <select
                          className="form-control "
                          id="holdAmount"
                          value={selectedCompany}
                          onChange={handleCompanyChange}
                        >
                        {console.log(selectedCompany)}
                          <option value="">Select a Company</option>
                          {companyData?.map((company) => (
                            <option
                              key={company.mid}
                              value={company.companyName}
                            >
                              {company.companyName + "hello"}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label
                        htmlFor="accessType"
                        className="mb-1 text-dark fs-4"
                      >
                        Access Type
                      </label>
                      <select
                        className="form-control "
                        id="accessType"
                        name="accessType"
                        value={accessType}
                        onChange={(e) => setAccessType(e.target.value)}
                      >
                        <option value="API">API</option>
                        <option value="Panel">Panel</option>
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <label
                        htmlFor="RouteWith"
                        className="mb-1 text-dark fs-4"
                      >
                        Route With
                      </label>
                      <select
                        className="form-control "
                        id="routing"
                        name="routing"
                        value={routing}
                        onChange={(e) => setRoutingWith(e.target.value)}
                      >
                        {/* <option value="">select</option> */}
                        <option value="DECENTRO">DECENTRO</option>
                        <option value="FINZEN">FINZEN</option>
                        <option value="BRANCHX">BRANCHX</option>
                        <option value="PAYWINGS">PAYWINGS</option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-4">
                    <button
                      className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                      onClick={handleUpdateSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Card.Body> */}
          {/* </Card> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ToolsPayoutSubmitted;