import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { isVpaUpiRegexIsValid } from "../../utils/helper";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER_API,
  USE_LIVE_FOR_CSV_DOWNLOAD,
  USE_LIVE_PAYIN_MORE_FILTER_API,
} from "../../utils/ApiServices";
import { useDispatch } from "react-redux";
import {
  setamount,
  setstartAmount,
  setendAmount,
  setdate,
  setstartDate,
  setendDate,
  setbankLocation,
  setcustomerLocation,
  setipAddress,
  setmid,
  setpayerVPA,
  setpaymentType,
  setsid,
  settransactionStatus,
  setisFilterUsed,
  
} from "../../store/slices/FilterSlice";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const LivePayinMutipleFilter = ({
  setTransactionReportData,
  setLiveBoxData,
  setShowMultipleFilter,
  dropDownData,
  setDownloadCSVData,
  SIDData,
  setToggleFilterA,
  toggleFilterA,
  mid,
  sizeM,
  pageM,
  ViewDetailsCallbackResponse,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setSuccessData,
  setTotalTransactionCount,
  setIsAdvance,
  HandleShowResponseCode
}) => {
  
  
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const [startAmount, setStartAmount] = useState(Number);
  const [range, setRange] = useState("");
  // const [SIDData, setSIDData] = useState([]);
  const [endAmount, setEndAmount] = useState(Number);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [searchValue, setSearchValue] = useState("test");
  const [transectionStatus, setTransectionStatus] = useState("");
  const [searchOption2, setSearchOption2] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [show2, setShow2] = useState(true);
  const [flag, setFlag] = useState(false);

  const date = new Date().toJSON().slice(0, 10);

  const exampleValues = {
    amount: "Ex- 5500",
    amountInBetween: "Ex- 100-500",
    VPA: "Ex- abcde@xyz / 98XXXXXXX1@xyz",
    paymentType: "Ex-  QR / SQR / DQR / UPI",
    custLocation: "Ex- Madhya Pradesh",
    bankLocation: "Ex- Bhopal",
    // Add more options and example values as needed
  };

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);
  const [isAlphabet, setIsAlphabet] = useState(false);
  const [formData, setFormData] = useState({
    mid: "",
    sid: "",
  });
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedSID, setSelectedSID] = useState("");

//   const handleAmountRange = (e) => {
//     const input = e.target.value;
//     setRange(input);

//     function splitString(inputString) {
//         const parts = inputString.split("-").map((part) => part.trim());
//         return parts;
//     }

//     const [startAmount, endAmount] = splitString(input);

//     setStartAmount(startAmount);
//     setEndAmount(endAmount);

//     if (Number(startAmount) > Number(endAmount)) {
//         document.getElementById("amountRangeErr").classList.remove("d-none");
//     } else {
//         document.getElementById("amountRangeErr").classList.add("d-none");
//     }

//     console.log(startAmount, endAmount);
// };
const handleAmountRange = (e) => {
  setRange(e.target.value);

  function splitString(inputString) {
    const parts = inputString.split("-").map((part) => part.trim());
    return parts;
  }

  const [startAmount, endAmount] = splitString(e.target.value);
  setStartAmount(startAmount);
  setEndAmount(endAmount);

  const amountRangeErr = document.getElementById("amountRangeErr");
  const searchBtn = document.getElementById("searchBtn");

  if (Number(startAmount) > Number(endAmount)) {
    amountRangeErr.classList.remove("d-none");
    searchBtn.setAttribute("disabled", "true");
  } else {
    amountRangeErr.classList.add("d-none");
    searchBtn.removeAttribute("disabled");
  }

  console.log(startAmount, endAmount);
};
  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        mid: mid,
      };
    });
  }, [mid]);

  const handleSIDChange = (event) => {
    const selectedSid = event.target.value;
    console.log(selectedSid);
    if (selectedSid === "") {
      setFormData((prev) => {
        return {
          ...prev,
          mid: "",
        };
      });

      setSelectedSID("");
      return;
    }

    setSelectedSID(selectedSid);

    const selected = SIDData.find(
      (merchant) => `${merchant.prifix} :- ${merchant.company}` === selectedSid
    );
    console.log(selected);

    if (selected) {
      setFormData((prev) => {
        return {
          ...prev,
          sid: selected.sid,
        };
      });
    }
  };

  useEffect(() => {
    setSearchValue("");
  }, [searchOption]);
  useEffect(() => {
    setSearchValue2("");
  }, [searchOption2]);

  const withOrWithoutMid = () => {
    if (formData.mid === "") {
      return true;
    } else {
      return false;
    }
  };

  const DispatcherFunction = () => {
    dispatch(setamount(searchOption === "amount" ? searchValue : 0));
    dispatch(
      setbankLocation(searchOption === "bankLocation" ? searchValue : "")
    );
    dispatch(
      setcustomerLocation(searchOption === "custLocation" ? searchValue : "")
    );
    dispatch(setdate(searchOption2 === "date" ? searchValue2 : ""));
    dispatch(setendAmount(searchOption === "amountInBetween" ? endAmount : 0));
    dispatch(setendDate(searchOption2 === "dateInBetween" ? endDate : ""));
    dispatch(setipAddress(ipAddress));
    dispatch(setmid(mid));
    dispatch(setpayerVPA(searchOption === "VPA" ? searchValue : ""));
    dispatch(
      setpaymentType(
        searchOption === "paymentType" ? searchValue.toUpperCase() : ""
      )
    );
    dispatch(
      setstartAmount(searchOption === "amountInBetween" ? startAmount : 0)
    );
    dispatch(setstartDate(searchOption2 === "dateInBetween" ? startDate : ""));
    dispatch(setsid(formData.sid));
    dispatch(settransactionStatus(transectionStatus));
    dispatch(setisFilterUsed(true));
  };

  useEffect(()=>{
    if (flag === true) {
      USE_LIVE_PAYIN_MORE_FILTER_API(
        withOrWithoutMid(mid),
        searchOption === "amount" ? searchValue : 0,
        searchOption === "bankLocation" ? searchValue : "",
        searchOption === "custLocation" ? searchValue : "",
        searchOption2 === "date" ? searchValue2 : "",
        searchOption === "amountInBetween" ? endAmount : 0,
        searchOption2 === "dateInBetween" ? endDate : "",
        "",
        "",
        "",
        mid,
        searchOption === "VPA" ? searchValue : "",
        searchOption === "paymentType" ? searchValue.toUpperCase() : "",
        formData.sid,
        searchOption === "amountInBetween" ? startAmount : 0,
        searchOption2 === "dateInBetween" ? startDate : "",
        token,
        transectionStatus,
        setTransactionReportData,
        pageM,
        sizeM,
        ViewDetailsCallbackResponse,
        setShowLoader,
        setSuccessAmount,
        setTxnSizeM,
        setSuccessData,
        setTotalTransactionCount,
        setIsAdvance,
        HandleShowResponseCode
      );
    }
  },[pageM])

  const submitHandler = () => {
    USE_LIVE_PAYIN_MORE_FILTER_API(
      withOrWithoutMid(formData.mid),
      searchOption === "amount" ? searchValue : 0,
      searchOption === "bankLocation" ? searchValue : "",
      searchOption === "custLocation" ? searchValue : "",
      searchOption2 === "date" ? searchValue2 : "",
      searchOption === "amountInBetween" ? endAmount : 0,
      searchOption2 === "dateInBetween" ? endDate : "",
      "",
      "",
      "",
      mid,
      searchOption === "VPA" ? searchValue : "",
      searchOption === "paymentType" ? searchValue.toUpperCase() : "",
      formData.sid,
      searchOption === "amountInBetween" ? startAmount : 0,
      searchOption2 === "dateInBetween" ? startDate : "",
      token,
      transectionStatus,
      setTransactionReportData,
      pageM,
      sizeM,
      ViewDetailsCallbackResponse,
      setShowLoader,
      setSuccessAmount,
      setTxnSizeM,
      setSuccessData,
      setTotalTransactionCount,
      setIsAdvance,
      HandleShowResponseCode
    );

  
    GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER_API(
      searchOption2 === "date" ? searchValue2 : "",
      searchOption2 === "dateInBetween" ? endDate : "",
      mid,
      formData.sid,
      searchOption2 === "dateInBetween" ? startDate : "",
      token,
      setLiveBoxData
    );

    DispatcherFunction();

    handleModalClose();
    setFormData({
      mid: "",
      sid: "",
    });
    setFlag(true);
  };
 

  const handleModalClose = () => {
    setShow2(false);
    setShowMultipleFilter(false);
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;

    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handelSearchData = (e) => {
    console.log(e.target.value);
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
  };

  const getKeyCode = (e) => {
    // get alphabet
    if (
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.key === "Backspace" ||
      e.keyCode === 32
    ) {
      setSearchValue(e.target.value);
      setIsAlphabet(true);
      return;
    }
    setIsAlphabet(false);
    return;
  };

  const handelValidations = (e) => {
    if (searchOption === "VPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Invalid Enter Valid VPA");
      }
      return;
    }

    if (searchOption === "custLocation" || searchOption === "bankLocation") {
      const regex = new RegExp(/^[a-z]*$/);
      const isValidData = regex.test(searchValue);

      if (!isValidData) {
        setIsSearchValueError(true);
        setSearchValueError(
          `Invalid Enter Valid ${
            searchOption === "bankLocation"
              ? "Bank Location"
              : "Customer Location"
          } `
        );
      }
      return;
    }
  };

  // const handleFromToDateInputChange = (from, to) => {
  //   const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
  //   const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });

  //   if (inputToDateTime > inputFromDateTime) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const handleFromToDateInputChange = (from, to) => {
    console.log(from, ":", to);

    const searchBtn = document.getElementById("searchBtn");

    // Parse the input dates
    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });

    // Check if dates are valid
    const isValidFrom = inputFromDateTime.isValid;
    const isValidTo = inputToDateTime.isValid;

    if (
      !isValidFrom ||
      !isValidTo ||
      from === to ||
      inputToDateTime < inputFromDateTime
    ) {
      // Disable the button if dates are invalid, the same, or the 'to' date is before the 'from' date
      searchBtn.setAttribute("disabled", "true");
    } else {
      // Enable the button if dates are valid and 'to' date is after or equal to 'from' date
      searchBtn.removeAttribute("disabled");
    }

    // Return whether the dates are valid and the 'to' date is after or equal to the 'from' date
    return isValidFrom && isValidTo && inputToDateTime >= inputFromDateTime;
  };
  return (
    <div>
      <div className="d-flex flex-row">
        <Modal show={show2} size="lg" centered>
          <Modal.Header className="bg-dark ">
            <Modal.Title className="text-white">Multiple Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-field-area">
              <fieldset className="rounded-0 mb-3">
                <legend className="fs-5 fw-bold">Select Date</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6} sm={12}>
                      <div>
                        <select
                          className="form-control"
                          value={searchOption2}
                          onChange={(e) => setSearchOption2(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="date">Date</option>
                          <option value="dateInBetween">Date In Between</option>
                        </select>
                      </div>
                    </Col>

                    {searchOption2 !== "dateInBetween" ? (
                      <Col lg={6} sm={12}>
                        <div className="form-group">
                          <input
                            className="advanced-input-box form-control"
                            type="date"
                            value={searchValue2}
                            onChange={(e) => {
                              if (handleDateInputChange(e)) {
                                setSearchValue2("");
                                console.log("true..........");
                                document
                                  .getElementById("dateErr")
                                  .classList.remove("d-none");
                              } else {
                                document
                                  .getElementById("dateErr")
                                  .classList.add("d-none");
                                setSearchValue2(e.target.value);
                                console.log("false..........");
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            placeholder={
                              searchOption2 === "date" ? "Enter Date" : ""
                            }
                          />
                          <span id="dateErr" className="text-danger d-none">
                            *Date must be lesser than the current date.
                          </span>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={startDate}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setStartDate("");
                                  document
                                    .getElementById("startDateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("startDateErr")
                                    .classList.add("d-none");
                                  setStartDate(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="Enter Start Date"
                            />
                            <span
                              id="startDateErr"
                              className="text-danger d-none"
                            >
                              *Please Enter Valid Date!
                            </span>
                          </div>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={endDate}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setEndDate("");
                                  document
                                    .getElementById("endDateErr")
                                    .classList.remove("d-none");
                                } else {
                                  if (
                                    handleFromToDateInputChange(
                                      startDate,
                                      e.target.value
                                    ) &&
                                    startDate !== e.target.value
                                  ) {
                                    document
                                      .getElementById("endDateErr")
                                      .classList.add("d-none");
                                    document
                                      .getElementById("fromToDateErr")
                                      .classList.add("d-none");
                                    setEndDate(e.target.value);
                                  } else {
                                    document
                                      .getElementById("fromToDateErr")
                                      .classList.remove("d-none");
                                  }
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="End Date"
                            />
                            <span
                              id="endDateErr"
                              className="text-danger d-none"
                            >
                              *Please Enter Valid Date!
                            </span>
                            <span
                              id="fromToDateErr"
                              className="text-danger d-none"
                            >
                              *TO Date can't be less than or equal to FROM Date!
                            </span>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </fieldset>

              <fieldset className="border-2 rounded-0 mt-3">
    <legend className="fs-5 fw-bold">Select Parameter</legend>
    <div className="field-set-form">
        <Row className="g-2">
            <Col lg={6}>
                <div className="form-group">
                    <select
                        className="form-control"
                        value={searchOption}
                        onChange={(e) => setSearchOption(e.target.value)}
                    >
                        <option type="number" value="">Select</option>
                        <option type="number" value="amount">Amount</option>
                        <option type="number" value="amountInBetween">Amount Range</option>
                        <option type="text" value="VPA">UPI ID / VPA</option>
                        <option type="text" value="paymentType">Type Of Payment</option>
                        <option type="text" value="custLocation">Customer Location</option>
                        <option type="text" value="bankLocation">Bank Location</option>
                    </select>
                </div>
            </Col>
            <Col lg={6}>
                <div className="form-group">
                    {searchOption === "amountInBetween" ? (
                        <>
                            <input
                                className="advanced-input-box form-control"
                                type="text"
                                value={range}
                                onChange={(e) => handleAmountRange(e)}
                            />
                            <span id="amountRangeErr" className=" d-none">
                                * Start amount should not be greater than end amount.
                            </span>
                            <span className="ml-2">{searchOption && exampleValues[searchOption]}</span>
                        </>
                    ) : searchOption === "custLocation" || searchOption === "bankLocation" ? (
                        <div>
                            <input
                                className="advanced-input-box form-control"
                                type="text"
                                value={searchValue}
                                onKeyDown={getKeyCode}
                                onChange={isAlphabet ? (e) => setSearchValue(e.target.value) : () => {}}
                            />
                            <span className="ml-2 mt-1">{searchOption && exampleValues[searchOption]}</span>
                        </div>
                    ) : searchOption === "amount" ? (
                        <div>
                            <input
                                className="advanced-input-box form-control"
                                type="number"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <span className="ml-2 mt-1">{searchOption && exampleValues[searchOption]}</span>
                        </div>
                    ) : (
                        <div>
                            <input
                                className="advanced-input-box form-control"
                                type="text"
                                value={searchValue}
                                onChange={handelSearchData}
                                onBlur={handelValidations}
                            />
                            {isSearchValueError ? (
                                <span id="searchValErr" className="text-danger">{`${searchValueError}`}</span>
                            ) : (
                                ""
                            )}
                            <div>
                                <span className="ml-2 mt-1">{searchOption && exampleValues[searchOption]}</span>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    </div>
</fieldset>


              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Select SID</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6}>
                      <div className="form-group">
                        <div className=" ">
                          <select
                            id="SIDDropdown"
                            className="form-control"
                            onChange={handleSIDChange}
                            value={selectedSID}
                          >
                            <option value="">Select a Company</option>
                            {SIDData.map((item) => (
                              <option
                                key={item.sid}
                                value={`${item.prifix} :- ${item.company}`}
                              >
                                {item.prifix} :- {item.company}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="">
                        <input
                          id="SIDDropdown"
                          className="form-control"
                          value={formData.sid}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>


              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Transaction Status</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={12} sm={12}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={transectionStatus}
                          onChange={(e) => setTransectionStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="success">Success</option>
                          <option value="failed">Failure</option>
                          <option value="fraud">Fraud</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <CustomTip size="18" placement="top" title="Advance Filter">
              <button
                style={{ border: "none", backgroundColor: "white" }}
                onClick={() => setToggleFilterA(!toggleFilterA)}
              >
                <FilterListIcon fontSize="large" />
              </button>
            </CustomTip>
            <div className="d-flex gap-2">
              <Button
                variant="danger"
                size="sm"
                className="text-capitlize"
                onClick={handleModalClose}
              >
                Close
              </Button>
              <Button
                id="searchBtn"
                variant="outline-primary"
                size="sm"
                className="text-capitlize"
                onClick={submitHandler}
              >
                <i class="bi bi-search mx-1"></i> Search
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>

    </div>
  );
};

export default LivePayinMutipleFilter;