import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import LogIn from "./Login/LogIn";
import Test from "./Components/MasterMerchant/CreateMasterMerchant";
import ProtectedRoute from "./ProtectedRoute";
import CreateMasterMerchant from "./Components/MasterMerchant/CreateMasterMerchant";
import ForgotPassword from "./Login/ForgotPassword";
import MMSubmitted from "./Components/MasterMerchant/MMSubmitted";
import Phase1Form from "./Components/ExternalMaster/Phase1Form"
import Phase2Form from "./Components/ExternalMaster/Phase2Form"
import XMSubmitted from "./Components/ExternalMaster/XMSubmitted"
import SidMasterForm from "./Components/Masters/SidMaster/SidMasterForm"
// import PayoutMaster from "./Components/Masters/PayoutMaster"
// import Applications from "./Components/OnBoarding/Applications";
import Routing from "./Components/OnBoarding/Routing";
// import MDR from "./Components/OnBoarding/MDR";
import FraudAndRiskManagement from "./Components/OnBoarding/FraudAndRiskManagement";
import MerchantManagement from "./Components/Tools/MerchantManagement";
// import PayoutApiTest from "./Components/Tools/PayoutApiTest";
// import CallBackTest from "./Components/Tools/CallBackTest";
import FraudManagement from "./Components/Tools/FraudManagement"
import OnBoarding from "./Components/CustomerSupport/OnBoarding"
import TechnicalSupport from "./Components/CustomerSupport/TechnicalSupport"
import RiskManagementSupport from "./Components/CustomerSupport/RiskManagementSupport"
import Accounting from "./Components/CustomerSupport/Accounting"
import Settings from "./Components/Settings/Settings"
import MerchantTransaction from "./Components/Reports/MerchantTransaction"
import LiveCummulativeReport from "./Components/Reports/LivePayinReport"
import EKyc from "./Components/Reports/EKyc"
import Ledger from "./Components/Reports/Ledger"
import DownloadCSV from "./Components/Reports/DownloadCSV"
import ViewSingleSubmittedAggregator from "./Components/MasterMerchant/ViewSingleSubmittedAggregator";
import MasterMerchantEdit from "./Components/MasterMerchant/MasterMerchantEdit"
import SIDSubmitted from "./Components/Masters/SidMaster/SIDSubmitted";
import ViewExternalMaster from "./Components/ExternalMaster/ViewExternalMaster"
// import EditPhase2Form from "./Components/ExternalMaster/EditPhase2Form"
import SIDDetails from "./Components/Masters/SidMaster/SIDDetails";
import SIDMerchantOnboarding from "./Components/Masters/SidMaster/SIDMerchantOnboarding";
import PayoutMasterForm from "./Components/Masters/PayoutMaster/PayoutMasterForm";
import SubmittedPayoutMasterForm from "./Components/Masters/PayoutMaster/SubmittedPayoutMasterForm";
// import EditPhase1Form from "./Components/ExternalMaster/EditPhase1Form";
import Phase1Applications from "./Components/OnBoarding/Phase1Applications";
import DashBoard from "./DashBoard/DashBoard";
import IdleTimerContainer from "./common/IdleTimerContainer";
import Details from "./Components/Tools/Merchant Management/Details";
import MDRPercentage from "./Components/Tools/Merchant Management/MDRPercentage";
import PendingPayoutRequest from "./Components/Tools/Merchant Management/PendingPayoutRequest";
import PendingVendorRequest from "./Components/Tools/Merchant Management/PendingVendorRequest";
import WalletBalance from "./Components/Tools/WalletBalance";
import UploadChargeback from "./Components/Tools/Merchant Management/UploadChargeback";
import ManualUpload from "./Components/Tools/Merchant Management/Withdraw/ManualUpload";
import RequestedWithdraw from "./Components/Tools/Merchant Management/Withdraw/RequestedWithdraw";
import DropCredit from "./Components/Tools/Merchant Management/Withdraw/DropCredit";
import Reconciliation from "./Components/Tools/Merchant Management/Reconciliation";
import SubmittedFraudManagement from "./Components/Tools/Merchant Management/SubmittedFraudManagement";
import RoutingPayinOnBoarding from "./Components/OnBoarding/PayinRouting";
import SubmittedRoutingPayin from "./Components/OnBoarding/PayinRoutingSubmitted"
import ViewMappedSID from "./Components/OnBoarding/ViewMappedSid";
// import PayoutSubmitted from "./Components/OnBoarding/PayoutSubmitted";
import PayoutSubmitted from "./Components/OnBoarding/PayoutSubmitted";
import FRMSubmitted from "./Components/OnBoarding/FRMSubmitted";
import SubmittedRoutingEkyc from "./Components/OnBoarding/EkycRoutingSubmitted";
import MDRSubmittedAccountApplications from "./Components/OnBoarding/MDRSubmitted";
import HoldAndDeposite from "./Components/Tools/HoldAndDeposite"
// import FlagManagement from "./Components/Tools/FlagManagement";
import ToolsSubmittedPayinRouting from "./Components/Tools/SubmittedPayinRouting";
import ToolsPayoutSubmitted from "./Components/Tools/PayoutSubmitted";
import ToolsSubmittedRoutingEkyc from "./Components/Tools/EkycRoutingSubmitted";
import FlagSubmitted from "./Components/Tools/FlagSubmitted";
import CallBackPayout from "./Components/Tools/CallBackPayout";
import Report from "./Components/Reports/Report";
import LivePayinReport from "./Components/Reports/LivePayinReport";
// import Tools from "./Components/Tools/Tools";

import ToolsSection from "./Components/Tools/Tools";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "./utils/helper";
import Loader from "./Components/Loader/Loader";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import MastersView from "./Components/Masters/MastersView";
import OnBoardingView from "./Components/OnBoarding/OnBoardingView";
import SubmittedUser from "./Components/Tools/UserManagement/SubmittedUser";


function App() {
  const { Name, logoPath } = config(getServerEnvironment(getCompleteDomain()));
  const AppNameCapitalized = Name;
  const AppName = AppNameCapitalized;
  const { loading } = useSelector((state) => state.utils);

  useEffect(() => {
    document.title = `${AppNameCapitalized}`;
    const favicon = document.getElementById("dynamicLogo");
    if (favicon) {
      favicon.href = logoPath;
    }
  }, [Name]);
  // console.log(loading)
  return (
    <>
       {loading ? <Loader /> : null}
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/ForgotPassword" element={<ForgotPassword/>} />
        

        {/* Dashboard */}
         <Route path="/Dashboard" element = {<ProtectedRoute/>} >
         <Route index element = {<DashBoard/>}/>
         </Route>

       
      
          {/* MasterMerchant */}
        <Route path="/MasterMerchant" element={<ProtectedRoute isHeaderVisible={false}/>} >
         <Route path="Submitted" element={<MMSubmitted />} />
         
        </Route>

        {/* ExternalMaster */}
        <Route path="/ExternalMaster" element={<ProtectedRoute isHeaderVisible={false}/>}>
        <Route path="Submitted" element={<XMSubmitted />} />
         
          <Route />
        </Route>

        {/* Masters */}
        <Route path="/Masters" element={<ProtectedRoute />}>
          <Route index element={<MastersView/>} />
          {/* <Route path="SIDMaster/Form" element={<SidMasterForm />} /> */}
          <Route path="SIDMaster/Submitted" element={<SIDSubmitted/>}/>
          <Route path="SIDMaster/Details" element={<SIDDetails/>}/>
          <Route path="SIDMaster/OnBoarding" element={<SIDMerchantOnboarding/>}/>
          <Route path ="PayoutMaster/SubmittedPayoutMasterForm" element = {<SubmittedPayoutMasterForm/>}/>
         

          <Route />
        </Route>
        {/* On-Boarding */}
        <Route path="/OnBoarding" element={<ProtectedRoute />}>
          <Route index element={<OnBoardingView/>}/>
          <Route path="Phase1Applications" element={<Phase1Applications/>} />
          <Route path="PayinRouting" element={<SubmittedRoutingPayin />} />
          <Route path="Routing" element={<RoutingPayinOnBoarding />} />
          <Route path="PayoutRouting" element={<PayoutSubmitted />} />
          <Route path="EkycRouting" element={<SubmittedRoutingEkyc />} />
          <Route path="ViewMappedSid" element={<ViewMappedSID />} />
          <Route path="MDR" element={<MDRSubmittedAccountApplications />} />
          {/* <Route path="MDR" element={<MDR />} /> */}
          <Route path="FraudAndRiskManag" element={<FRMSubmitted />} />
          <Route />
          <Route />
        </Route>
        {/* Tools */}
        
        <Route path="/Tools" element={<ProtectedRoute />}>
          <Route index element = {<ToolsSection/>}/>
          <Route path="MerchantManagement" element={<Details/>} />
          <Route path="Withdraw" element = {<DropCredit/>}/>
          {/* <Route path="Hold&Deposite" element={<HoldAndDeposite />} /> */}
          <Route path="SubmittedPayinRouting" element={<ToolsSubmittedPayinRouting />} />
          <Route path="PayoutSubmitted" element={<ToolsPayoutSubmitted />} />
          <Route path="EkycRoutingSubmitted" element={<ToolsSubmittedRoutingEkyc />} />
          <Route path="Hold&Deposite" element={<HoldAndDeposite />} />
          <Route path="FlagSubmitted" element={<FlagSubmitted />} />
          <Route path="SubmittedUser" element={<SubmittedUser />} />


          {/* <Route path="Flag" element={<FlagManagement />} /> */}
          {/* <Route path="PayoutApiTest" element={<PayoutApiTest />} /> */}
          <Route path="CallBackPayout" element={<CallBackPayout/>} />
          <Route path="SubmittedFraudManagement" element={<SubmittedFraudManagement />} />
          <Route />
          
        </Route>
        {/* Reports */}
        <Route path="/Reports" element={<ProtectedRoute />}>
          <Route path="MerchantTransaction" element={<MerchantTransaction />} />
          {/* <Route path="LiveCummulativeReport" element={<LiveCummulativeReport />} /> */}
           <Route path="LiveCummulativeReport" element={<Report/>} />
          {/* <Route path="EKyc" element={<EKyc />} /> */}
          <Route path = "LivePayinReport" element = {<LivePayinReport/>}/>
          <Route path="Ledger" element={<Ledger />} />
         
          <Route path="ArchivedReports" element={<DownloadCSV />} />
          <Route />
        </Route>
        {/* CustomerSupport */}
        <Route path="/CustomerSupport" element={<ProtectedRoute />}>
          <Route path="OnBoarding" element={<OnBoarding />} />
          <Route path="TechnicalSupport" element={<TechnicalSupport />} />
          <Route path="RiskManagementSupport" element={<RiskManagementSupport />} />
          <Route path="Accounting" element={<Accounting />} />
          <Route />
        </Route>
        {/* Settings */}
        <Route path="/Settings" element={<ProtectedRoute />}>
          <Route index element={<Settings />} />
        </Route>
       
      </Routes>
    </>
  );
}

export default App;