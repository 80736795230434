import React, { useEffect, useState } from "react";
import {
  SaveVehicleMasterPhase2Form,
  UpdateVehicleMasterPhase2Form,
  fetchAllActiveAndDisableVehicleMaster,
  getAllByIdUsingGet,
  getAllPhase2Submitter,
  getPhase2VehicleMasterSubmittedByID,
} from "../../utils/ApiServices";
import swal from "sweetalert";
import { Card, Col, Modal, Row } from "react-bootstrap";
import ShowField from "./ShowField";


const EditVicleMasterPhase2 = ({
  id,
  data,
  isModalOpen2,
  handleCloseModal,
  // setShowLoader,
  
}) => {
  const [selectedType, setSelectedType] = useState({
    vehicleId: Number,
    vehicleName: "",
    aggeregatorName: "",
    schemeName: "",
    masterMerchantName: "",
    bankName: "",
    remark: "",
    switchName: "",
  });

  

  const [dataForSave, setDataForSave] = useState([
    {
      subTypeId: "",
      feeFix: "",
      feePercentage: "",
    },
  ]);

  const [paymentSubType, setPaymentSubType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
  const [MerchantMDRData, setMerchantMDRData] = useState([]);
  const [MDRBankData, setMDRBankData] = useState([]);
  const [SchemeMDRData, setMDRDataForScheme] = useState([]);
  const [AggregatorMDRData, setMDRDataForAggreGator] = useState([]);
  const [SwitchMDRData, setMDRDataForSwitch] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({});
  const [vehicleData, setVehicleData] = useState([]);

  const [dataFromApi, setDataFromApi] = useState();

  // ---------------------------------------------------

  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);

  // const [vehicleData, setVehicleData] = useState([])

  useEffect(() => {
    GetVehicalMasterData(token);
  }, [selectedType]);

  useEffect(() => {
    setDataToFeilds(data);
    // getAllForDropdown(token);
  }, []);

  const setDataToFeilds = (data) => {
    // console.log("DATA>>>>>", data);

    setSelectedType({
      schemeName: data.schemeName,
      aggeregatorName: data.aggregatorName,
      switchName: data.switchName + "",
      vehicleName: data.vehicleName,
      masterMerchantName: data.masterMerchantName,
      bankName: data.bankName + "",
      remark: data.remark,
    });

    const PaymentTypes = data?.paymentTypes?.map((item) => {
      return item.paymentTypeId;
    });
    setSelectedPaymentTypes(PaymentTypes);
    console.log(selectedPaymentTypes);
    // console.log(PaymentTypes);
    const PaymentSubTypes = data?.paymentSubTypes?.map((item) => {
      return item.paymentSubTypeId;
    });
    setSelectedPaymentSubTypes(PaymentSubTypes);
    // setPaymentSubType(data.)
    console.log(selectedPaymentSubTypes);
    // console.log(PaymentSubTypes);
  };

  const getAllForDropdown = async (token) => {
    // setShowLoader(true);

    try {
      const response = await getAllPhase2Submitter(token);
      console.log("RES >>>", response.data);
      if (response.statusCode === 200) {
        setVehicleData(response.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
    // setShowLoader(false);
  };

  const GetVehicalMasterData = async (token) => {
    // setShowLoader(true);
    try {
      const response = await getAllByIdUsingGet(token, id);
      console.log(
        "RES >>>>>>>>>>>>> ////***********//////",
        response.data[0]?.paymentSubTypes
      );
      if (response.statusCode === 200) {
        setDataFromApi(response.data[0]);
        setPaymentSubType(response.data[0]?.paymentSubTypes);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
    // setShowLoader(false);
  };

  useEffect(() => {
    if (selectedType.schemeName != "NA") {
      let res = paymentSubType.map((elem) => {
        return {
          subTypeId: elem.paymentSubTypeId,
          interchargeFeeFix: elem.interchangeFeeFix === null ? "" : elem.interchangeFeeFix,
          interchargePercentage: elem.interchangeFeePercentage === null ? "" : elem.interchangeFeePercentage,
          name: selectedType.schemeName + "(" + elem.type + ")",
          subTypeName: elem.type,
          type: "SCHEME",
          vehicleMasterSubPaymentTypeServiceId:
            elem.vehicleMasterSubPaymentTypeServiceId,
        };
      });
      setMDRDataForScheme(res);
    }

    if (selectedType.aggeregatorName != "NA") {
      let res = paymentSubType.map((elem) => {
        console.log("ELEM >>>>>> ????? >>>>>", elem);
        return {
          subTypeId: elem.paymentSubTypeId,
          platformFeeFix: elem.platformFeeFix === null ? "" : elem.platformFeeFix,
          platformPercentage: elem.platformFeePercentage === null ? "" : elem.platformFeePercentage,
          name: selectedType.aggeregatorName + "(" + elem.type + ")",
          subTypeName: elem.type,
          type: "AGGREGATOR",
          vehicleMasterSubPaymentTypeServiceId:
            elem.vehicleMasterSubPaymentTypeServiceId,
        };
      });
      setMDRDataForAggreGator(res);
    }

    if (selectedType.masterMerchantName != "NA") {
      let res = paymentSubType.map((elem) => {
        return {
          subTypeId: elem.paymentSubTypeId,
          masterMerchantFessFix: elem.masterMerchantFess === null ? "" : elem.masterMerchantFess,
          masterMerchantPercentage: elem.masterMerchantPercentage === null ? "" : elem.masterMerchantPercentage,
          name: selectedType.masterMerchantName + "(" + elem.type + ")",
          subTypeName: elem.type,
          type: "MASTER",
          vehicleMasterSubPaymentTypeServiceId:
            elem.vehicleMasterSubPaymentTypeServiceId,
        };
      });
      setMerchantMDRData(res);
    }

    if (selectedType.bankName != "NA") {
      let res = paymentSubType.map((elem) => {
        return {
          subTypeId: elem.paymentSubTypeId,
          nodelFeeFix: elem.nodelFeeFix === null ? "" : elem.nodelFeeFix,
          nodelFeePercentage: elem.nodelFeePercentage === null ? "" : elem.nodelFeePercentage,
          name: selectedType.bankName + "(" + elem.type + ")",
          subTypeName: elem.type,
          type: "BANK",
          vehicleMasterSubPaymentTypeServiceId:
            elem.vehicleMasterSubPaymentTypeServiceId,
        };
      });
      setMDRBankData(res);
    }

    if (selectedType.switchName != "NA") {
      let res = paymentSubType.map((elem) => {
        return {
          subTypeId: elem.paymentSubTypeId,
          processingFeeFix: elem.processingFeeFix === null ? "" : elem.processingFeeFix,
          processingFeePercentage: elem.processingFeePercentage === null ? "" : elem.processingFeePercentage,
          name: selectedType.switchName + "(" + elem.type + ")",
          subTypeName: elem.type,
          type: "SWITCH",
          vehicleMasterSubPaymentTypeServiceId:
            elem.vehicleMasterSubPaymentTypeServiceId,
        };
      });
      setMDRDataForSwitch(res);
    }
  }, [dataFromApi]);

  const handleTypeChange = (event) => {
    const selectedVehicleValue = event.target.value;
    const name = event.target.name;

    // console.log(selectedVehicleValue, name);

    setSelectedType((prevSelectedType) => ({
      ...prevSelectedType,
      vehicleId: selectedVehicleValue,
    }));

    const selectedVehicle = vehicleData.find(
      (vehicle) => vehicle.Id === parseInt(selectedVehicleValue)
    );

    console.log(selectedVehicle);

    if (selectedVehicle) {
      setFormData({
        aggregatorName: selectedVehicle.aggregatorName,
        bankName: selectedVehicle.bankName,
        schemeName: selectedVehicle.schemeName,
        switchName: selectedVehicle.switchName, // Fix: Use 'switchName' instead of 'merchantName'
        masterMerchantName: selectedVehicle.masterMerchantName,
        remark: selectedVehicle.remark, // Assuming 'remark' is a property in the vehicle object
      });

      // setPaymentSubType(selectedVehicle.paymentSubTypes);

      console.log(formData);
    } else {
    }

    setMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMerchantMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForAggreGator(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForScheme(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForSwitch(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
  };

  const handleMDRDataForBank = (name, value, paymentSubTypeId) => {
    const foundItem = MDRBankData.find(
      (item) => item.subTypeId === paymentSubTypeId
    );

    if((name === "nodelFeePercentage" && (value > 100 || value < 0)) || (name ==="nodelFeeFix" && value < 0)  ) return
    
    foundItem[name] = value;
    setMDRBankData([...MDRBankData]);
  };

  const handleMDRDataForMerchant = (name, value, paymentSubTypeId) => {
    const foundItem = MerchantMDRData.find(
      (item) => item.subTypeId === paymentSubTypeId
    );

    if((name === "masterMerchantPercentage" && (value > 100 || value < 0)) || (name ==="masterMerchantFessFix" && value < 0)  ) return

    foundItem[name] = value;
    setMerchantMDRData([...MerchantMDRData]);
  };

  const handleMDRDataSwitch = (name, value, paymentSubTypeId) => {
    const foundItem = SwitchMDRData.find(
      (item) => item.subTypeId === paymentSubTypeId
    );

    if((name === "processingFeePercentage" && (value > 100 || value < 0)) || (name ==="processingFeeFix" && value < 0)  ) return
    
    foundItem[name] = value;
    setMDRDataForSwitch([...SwitchMDRData]);
  };

  const handleMDRDataForAggregator = (name, value, paymentSubTypeId) => {
    const foundItem = AggregatorMDRData.find(
      (item) => item.subTypeId === paymentSubTypeId
    );

    if((name === "platformPercentage" && (value > 100 || value < 0)) || (name ==="platformFeeFix" && value < 0)  ) return

    foundItem[name] = value;
    setMDRDataForAggreGator([...AggregatorMDRData]);
  };

  const handleMDRDataForScheme = (name, value, paymentSubTypeId) => {
    const foundItem = SchemeMDRData.find(
      (item) => item.subTypeId === paymentSubTypeId
    );

    console.log("name >>>>", name);
    console.log("value >>>>", value);
    console.log("type >>>>", typeof value);

    if((name === "interchargePercentage" && (value > 100 || value < 0)) || (name ==="interchargeFeeFix" && value < 0)  ) return

    foundItem[name] = value;
    setMDRDataForScheme([...SchemeMDRData]);
  };

  const generatePayload = (Data, type) => {
    const result = Data?.map((item) => {
      // console.log(item)
      return {
        type: type,
        feeFix: item.fixAmount,

        vehicleMasterSubPaymentTypeServiceId:
          item?.vehicleMasterSubPaymentTypeServiceId,

        feePercentage: item.percentage,
        // MerchantPercentage: item.MerchantPercentage,
        // subpaymentTypeId: item.serviceId,
        // vehicleMasterId: selectedType.vehicleId,
      };
    });

    return result;
  };

  const resultForAggregator = generatePayload(AggregatorMDRData, "AGGREGATOR");
  const resultForScheme = generatePayload(SchemeMDRData, "SCHEME");
  const resultForBank = generatePayload(MDRData, "BANK");
  const resultForSwitch = generatePayload(SwitchMDRData, "SWITCH");
  const resultForMerchant = generatePayload(MerchantMDRData, "MASTER");

  const finalPayload = () => {

    for(let elem of MDRBankData) {
      if (elem.nodelFeeFix === "") {
        swal({
          title: "Alert",
          text: `Please enter Nodal Fee Fix of ${elem.name}`,
          icon: "error",
        })
        return
      }

      if (elem.nodelFeePercentage === "") {
        swal({
          title: "Alert",
          text: `Please enter Nodal Fee Percentage of ${elem.name}`,
          icon: "error",
        })
        return
      }
    }

    for(let elem of MerchantMDRData) {
      if (elem.masterMerchantFessFix === "") {
        swal({
          title: "Alert",
          text: `Please enter Merchant Fee Fix of ${elem.name}`,
          icon: "error",
        })
        return
      }

      if (elem.masterMerchantPercentage === "") {
        swal({
          title: "Alert",
          text: `Please enter Merchant Fee Percentage of ${elem.name}`,
          icon: "error",
        })
        return
      }
    }

    for(let elem of SwitchMDRData) {
      if (elem.processingFeeFix === "") {
        swal({
          title: "Alert",
          text: `Please enter Processing Fee Fix of ${elem.name}`,
          icon: "error",
        })
        return
      }

      if (elem.processingFeePercentage === "") {
        swal({
          title: "Alert",
          text: `Please enter Processing Fee Percentage of ${elem.name}`,
          icon: "error",
        })
        return
      }
    }

    for(let elem of AggregatorMDRData ) {
      if (elem.platformFeeFix === "") {
        swal({
          title: "Alert",
          text: `Please enter Platform Fee Fix of ${elem.name}`,
          icon: "error",
        })
        return
      }

      if (elem.platformPercentage === "") {
        swal({
          title: "Alert",
          text: `Please enter Platform Fee Percentage of ${elem.name}`,
          icon: "error",
        })
        return
      }
    }

    for(let elem of SchemeMDRData ) {
      if (elem.interchargeFeeFix === "") {
        swal({
          title: "Alert",
          text: `Please enter Intercharge Fee Fix of ${elem.name}`,
          icon: "error",
        })
        return
      }

      if (elem.interchargePercentage === "") {
        swal({
          title: "Alert",
          text: `Please enter Intercharge Fee Percentage of ${elem.name}`,
          icon: "error",
        })
        return
      }
    }

    let bankData = MDRBankData?.map(elem => {
      return {
        feeFix: parseInt(elem.nodelFeeFix),
        feePercentage: parseInt(elem.nodelFeePercentage),
        type: elem.type,
        vehicleMasterSubPaymentTypeServiceId: elem.vehicleMasterSubPaymentTypeServiceId
      }
    })

    let masterMerchantData = MerchantMDRData?.map( elem => {
      return {
        feeFix: parseInt(elem.masterMerchantFessFix),
        feePercentage: parseInt(elem.masterMerchantPercentage),
        type: elem.type,
        vehicleMasterSubPaymentTypeServiceId: elem.vehicleMasterSubPaymentTypeServiceId
      }
    })

    let SwitchData = SwitchMDRData?.map( elem => {
      return {
        feeFix: parseInt(elem.processingFeeFix),
        feePercentage: parseInt(elem.processingFeePercentage),
        type: elem.type,
        vehicleMasterSubPaymentTypeServiceId: elem.vehicleMasterSubPaymentTypeServiceId
      }
    })

    let AggeregatorData = AggregatorMDRData?.map( elem => {
      return {
        feeFix: parseInt(elem.platformFeeFix),
        feePercentage: parseInt(elem.platformPercentage),
        type: elem.type,
        vehicleMasterSubPaymentTypeServiceId: elem.vehicleMasterSubPaymentTypeServiceId
      }
    })

    let schemeData = SchemeMDRData?.map( elem => {
      return {
        feeFix: parseInt(elem.interchargeFeeFix),
        feePercentage: parseInt(elem.interchargePercentage),
        type: elem.type,
        vehicleMasterSubPaymentTypeServiceId: elem.vehicleMasterSubPaymentTypeServiceId
      }
    })

    console.log("Bank Data >>>>", bankData);
    console.log("masterMerchantData >>>>", masterMerchantData);
    console.log("SwitchData >>>>", SwitchData);
    console.log("AggeregatorData >>>>", AggeregatorData);
    console.log("schemeData >>>>", schemeData);

    return [...bankData, ...masterMerchantData, ...SwitchData, ...AggeregatorData, ...schemeData]

  };

  const handleSubmit = async () => {
    const payloadFinal = finalPayload();
    console.log("payloadFinal", payloadFinal);

    // return

    try {
      // setShowLoader(true);
      const response = await UpdateVehicleMasterPhase2Form(payloadFinal, token);
      // console.log('payloadfinal',payloadFinal)

      console.log("API Response:", response);
      if (response.statusCode === 200) {
        // setShowLoader(false);
        swal({
          title: "Success",
          text: "Updated Successfully",
          icon: "success",
        }).then(() => {

          handleCloseModal()
        })
        // setFormData({
        //   aggregatorName: "NA",
        //   bankName: "NA",
        //   schemeName: "NA",
        //   switchName: "NA",
        //   masterMerchantName: "NA",
        //   remark: "",
        // });
        // setSelectedType({
        //   vehicleId: "",
        // });
      } else {
        // setShowLoader(false);
        swal({
          title: "Failed",
          text: "Vehicle Master Form Not Saved",
          icon: "error",
        });
      }
    } catch (error) {
      // setShowLoader(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
    <Modal show={isModalOpen2} onHide={handleCloseModal} backdrop="static" keyboard={false} size="xl">
  <Modal.Header closeButton>
    <Modal.Title className=" fw-bold">Edit Vehicle Master Phase 2</Modal.Title>
  </Modal.Header>
  <Modal.Body className="w-100">
    <Card.Body>
      <div className="merchant-report-view">
        <div className="report-table-view">
          <div className="row mt-4">
            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <label htmlFor="email" className="mb-1 text-dark fs-4">
                  Vehicle Name<sup className="text-danger">*</sup>
                </label>
                <input
                  name="vehicleName"
                  id="vehicleName"
                  className="form-control"
                  value={selectedType?.vehicleName}
                  // onChange={handleTypeNameAndDropdownChange}
                />
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <ShowField
                  fieldName={"Aggregator"}
                  name="aggregatorId"
                  value={selectedType?.aggeregatorName}
                />
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <ShowField
                  fieldName={"Scheme"}
                  name="schemeId"
                  value={selectedType.schemeName}
                />
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <ShowField
                  fieldName={"Master Merchant"}
                  name="masterMerchantId"
                  value={selectedType.masterMerchantName}
                />
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <ShowField
                  fieldName={"Bank"}
                  name="bankId"
                  value={selectedType.bankName}
                />
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <ShowField
                  fieldName={"Remark"}
                  name="remark"
                  value={selectedType.remark}
                />
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="col-sm-12">
                <ShowField
                  fieldName={"Switch"}
                  name="switchId"
                  value={selectedType.switchName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedType.schemeName !== "NA" && (
        <div className="merchant-report-view mt-4 px-5">
          <Row className="d-flex flex-column align-items-center px-3">
            {SchemeMDRData?.map((service) => (
              <Col
                className="w-100 mt-3"
                xs={8}
                lg={4}
                md={3}
                key={service.subTypeId}
              >
                <div className="form-group d-flex align-items-center justify-content-evenly">
                  <label className="w-25 form-label">
                    {`${selectedType.schemeName} (${service.subTypeName})`}
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="w-25 px-2">
                    <label>Intercharge Fee Fix</label>
                    <input
                      type="number"
                      placeholder="Fixed Amount"
                      className="form-control"
                      value={service.interchargeFeeFix}
                      onChange={(e) =>
                        handleMDRDataForScheme(
                          "interchargeFeeFix",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                    />
                  </div>
                  <div className="w-25 px-2">
                    <label>Intercharge Fee Percentage</label>
                    <input
                      type="number"
                      placeholder="Percentage"
                      className="form-control"
                      value={service.interchargePercentage}
                      onChange={(e) =>
                        handleMDRDataForScheme(
                          "interchargePercentage",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <span id="regAddErr" className="text-danger d-none">
                    Please Enter Registered Address*
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {selectedType.bankName !== "NA" && (
        <div className="merchant-report-view mt-4 px-5">
          <Row className="d-flex flex-column align-items-center px-3">
            {MDRBankData?.map((service) => (
              <Col
                className="w-100 mt-3"
                xs={8}
                lg={4}
                md={3}
                key={service.subTypeId}
              >
                <div className="form-group d-flex align-items-center justify-content-evenly">
                  <label className="w-25 form-label">
                    {`${selectedType.bankName} (${service.subTypeName})`}
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="w-25 px-2">
                    <label>Nodal Fee Fix</label>
                    <input
                      type="number"
                      placeholder="Fixed Amount"
                      className="form-control"
                      value={service.nodelFeeFix}
                      onChange={(e) =>
                        handleMDRDataForBank(
                          "nodelFeeFix",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <div className="w-25 px-2">
                    <label>Nodal Fee Percentage</label>
                    <input
                      type="number"
                      placeholder="Percentage"
                      className="form-control"
                      value={service.nodelFeePercentage}
                      onChange={(e) =>
                        handleMDRDataForBank(
                          "nodelFeePercentage",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <span id="regAddErr" className="text-danger d-none">
                    Please Enter Registered Address*
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {selectedType.switchName !== "NA" && (
        <div className="merchant-report-view mt-4 px-5">
          <Row className="d-flex flex-column align-items-center px-3">
            {SwitchMDRData?.map((service) => (
              <Col
                className="w-100 mt-3"
                xs={8}
                lg={4}
                md={3}
                key={service.subTypeId}
              >
                <div className="form-group d-flex align-items-center justify-content-evenly">
                  <label className="w-25 form-label">
                    {`${selectedType.switchName} (${service.subTypeName})`}
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="w-25 px-2">
                    <label>Processing Fee Fix</label>
                    <input
                      type="number"
                      placeholder="Fixed Amount"
                      className="form-control"
                      value={service.processingFeeFix}
                      onChange={(e) =>
                        handleMDRDataSwitch(
                          "processingFeeFix",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <div className="w-25 px-2">
                    <label>Processing Fee Percentage</label>
                    <input
                      type="number"
                      placeholder="Percentage"
                      className="form-control"
                      value={service.processingFeePercentage}
                      onChange={(e) =>
                        handleMDRDataSwitch(
                          "processingFeePercentage",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <span id="regAddErr" className="text-danger d-none">
                    Please Enter Registered Address*
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {selectedType.aggeregatorName !== "NA" && (
        <div className="merchant-report-view mt-4 px-5">
          <Row className="d-flex flex-column align-items-center px-3">
            {AggregatorMDRData?.map((service) => (
              <Col
                className="w-100 mt-3"
                xs={8}
                lg={4}
                md={3}
                key={service.subTypeId}
              >
                <div className="form-group d-flex align-items-center justify-content-evenly">
                  <label className="w-25 form-label">
                    {`${selectedType.aggeregatorName} (${service.subTypeName})`}
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="w-25 px-2">
                    <label>Platform Fee Fix</label>
                    <input
                      type="number"
                      placeholder="Fixed Amount"
                      className="form-control"
                      value={service.platformFeeFix}
                      onChange={(e) =>
                        handleMDRDataForAggregator(
                          "platformFeeFix",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <div className="w-25 px-2">
                    <label>Platform Fee Percentage</label>
                    <input
                      type="number"
                      placeholder="Percentage"
                      className="form-control"
                      value={service.platformPercentage}
                              onChange={(e) =>
                                handleMDRDataForAggregator(
                                  "platformPercentage",
                                  e.target.value,
                                  service.subTypeId
                                )
                              }
                      required
                    />
                  </div>
                  <span id="regAddErr" className="text-danger d-none">
                    Please Enter Registered Address*
                  </span>
                </div>
              </Col>
            ))}
             {selectedType.masterMerchantName !== "NA" && (
        // <div className="merchant-report-view mt-4 px-5">
          <Row className="d-flex flex-column align-items-center">
            {MerchantMDRData?.map((service) => (
              <Col
                className="w-100 mt-3"
                xs={8}
                lg={4}
                md={3}
                key={service.subTypeId}
              >
                <div className="form-group d-flex align-items-center justify-content-evenly">
                  <label className="w-25 form-label">
                    {`${selectedType.masterMerchantName} (${service.subTypeName})`}
                    <sup className="text-danger">*</sup>
                  </label>
                  <div className="w-25 px-2">
                    <label>Merchant Fee Fix</label>
                    <input
                      type="number"
                      placeholder="Fixed Amount"
                      className="form-control"
                      value={service.masterMerchantFessFix}
                      onChange={(e) =>
                        handleMDRDataForMerchant(
                          "masterMerchantFessFix",
                          e.target.value,
                          service.subTypeId
                        )
                      }
                      required
                    />
                  </div>
                  <div className="w-25 px-2">
                    <label>Merchant Fee Percentage</label>
                    <input
                      type="number"
                      placeholder="Percentage"
                      className="form-control"
                      value={service.masterMerchantPercentage}
                              onChange={(e) =>
                                handleMDRDataForMerchant(
                                  "masterMerchantPercentage",
                                  e.target.value,
                                  service.subTypeId
                                )
                              }
                      required
                    />
                  </div>
                  <span id="regAddErr" className="text-danger d-none">
                    Please Enter Registered Address*
                  </span>
                </div>
              </Col>
            ))}

            <div className="d-flex justify-content-center mt-4">
                <button className="badge badge-rounded badge-primary  mx-2 px-3 py-3" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
          </Row>
        // </div>
      )}
          </Row>
        </div>
      )}

    </Card.Body>
  </Modal.Body>
</Modal>

    </>
  );
};

export default EditVicleMasterPhase2;
