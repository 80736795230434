import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import NumberInWords from "./NumberInWords";
import { Button, Card, Modal } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomTip from "../../../common/CustomToolTip/CustomTip";
import {
  FETCH_ALL_SID_MASTER_DETAILS,
  SID_DISABLE_API,
  GET_All_MAPPED_SID,
  UPDATE_SID_MASTER,
  GET_ALL_VEHICAL_MASTER_FORMS,
  GET_ALL_MERCHANT_MASTER,
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";
import SidMasterForm from "./SidMasterForm";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";

const SIDSubmitted = ({ type, toggleRightBar }) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [show, setShow] = useState(false);
  const { prefix } = useSelector((state) => state.privilages);
  const[refresh,setRefresh]=useState(false)

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();
  const [applications, setApplications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [disableID, setDisableID] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  const [editFormData, setEditFormData] = useState({
    sid: "",
    txnAmt: "",
    mid: "",
    merchantName: "",
    id: "",
    vehicleMasterId: "",
    masterMerchantId: "",
    masterMerchantName: "",
  });
  const token = localStorage.getItem("token");

  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(FETCH_ALL_SID_MASTER_DETAILS);
      if (apiResponse.statusCode === 200) {
        const finalRes = apiResponse.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          company: item.company,
          sid: item.sid,
          merchantVpa: item.merchantVpa,
          vehicleName: item.vehicleName,
          masterMarchantName: item.masterMarchantName,
          maxTxnAmount: item.maxTxnAmount,
          action: (
            <Select
            value={selectedAction}
            onChange={(e) => setSelectedAction(e.target.value)}
            displayEmpty
            renderValue={() => null}
            sx={{
              width: 40,
              backgroundColor: "#f72b50", // Adjust the color as per your preference
              borderRadius: 8, // Optional: Adds rounded corners for a nicer look
              padding: "8px 2px", // Optional: Adjust padding for better spacing
              height: "25px",
            }}
          >
            <MenuItem
              value="edit"
              onClick={() =>
                openDetails(
                  item.mid,
                  item.company,
                  item.sid,
                  item.maxTxnAmount,
                  item.id,
                  item.vehicleMasterId,
                  item.masterMarchantid,
                  item.masterMarchantName,
                  item.merchantVpa
                )
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              value="remove" className="bg-danger text-white"
              onClick={(e) => handelSearchLimit(e, item.sid, item.id)}
            >
              Disable
            </MenuItem>
          </Select>
          ),
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  applications.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <sapan>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </sapan>
    );
  });

  useEffect(() => {
    FetchData();
  }, [show,refresh]);

  const openDetails = (
    mid,
    company,
    sid,
    maxTxnAmount,
    id,
    vehicleMasterId,
    masterMerchantId,
    masterMerchantName,
    MerchantVPA
  ) => {
    setEditFormData({
      txnAmt: maxTxnAmount,
      mid,
      merchantName: company,
      sid,
      id,
      vehicleMasterId: vehicleMasterId,
      masterMerchantId: masterMerchantId,
      masterMerchantName: masterMerchantName,
      MerchantVPA: MerchantVPA,
    });

    setIsModalOpen(true);
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here

      // console.log(">>>>>", response.data);

      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllVehicle = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICAL_MASTER_FORMS);

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, [refresh,setRefresh]);
  const sidDetailsColumn = [
    { headerName: "Merchant ID", field: "mid", minWidth: 170 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 150 },
    { headerName: "SID", field: "sid", minWidth: 150 },
    { headerName: "Merchant VPA", field: "merchantVpa", minWidth: 150 },
    {
      headerName: "Vehicle Master ID",
      field: "vehicleMasterId",
      minWidth: 150,
    },
    { headerName: "Transaction Limit", field: "sidLimit", minWidth: 150 },
  ];
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRefresh(!refresh)
  };

  const handleCloseModal2 = () => {
    setIsModal2Open(false);
  };

  const handelSearchLimit = (e, sid, id) => {
    setDisableID(id);

    apiCallFnforGet(GET_All_MAPPED_SID + sid)
      .then((response) => {
        if (response.statusCode === 200) {
          console.log(response.data);
          const finalRes = response?.data?.map((item, i) => ({
            id: i,
            mid: item.mid,
            companyName: item.companyName,
            sid: item.sid,
            merchantVpa: item.merchantVPA,
            vehicleMasterId: item.vehicleMasterId,
            sidLimit: item.sidLimit,
          }));

          setSidDetailsData(finalRes);
          isModal2Open(false);
        } else if (response.statusCode === 306) {
          setSidDetailsData([]);
        } else {
          setSidDetailsData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setIsModal2Open(true);
  };

  const disableSID = () => {
    const formData = new FormData();
    formData.append("id", disableID);

    apiCallFnforPut(`${SID_DISABLE_API}${disableID}`, null)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.message,
            icon: "success",
          });
          setEditFormData({
            sid: "",
            txnAmt: "",
            mid: "",
            merchantName: "",
            id: "",
            merchantVpa: "",
            vehicleMasterId: "",
            masterMerchantId: "",
          });
          handleCloseModal2();
          FetchData(token);
          setDisableID("");
          isModal2Open(false);
        } else {
          swal({
            title: "Alert!",
            text: response.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handelDataChange = (e) => {
    if (e.target.name === "vehicleMasterId") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-masterName");
      const masterId = selectedOption.getAttribute("data-masterId");

      setEditFormData({
        ...editFormData,
        [e.target.name]: e.target.value,
        masterMerchantName: master ? master : "",
        masterMerchantId: masterId,
      });
    } else {
      setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
    }
  };

  const handleUpdateSubmit = (e) => {
    if (editFormData.merchantName === "") {
      swal({
        title: "Alert!",
        text: "Please Select Merchant",
        icon: "error",
      });
      return;
    }

    if (editFormData.sid === "") {
      swal({
        title: "Alert!",
        text: "Please Enter SID",
        icon: "error",
      });
      return;
    }

    if (editFormData.txnAmt === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Maximum Transaction Amount",
        icon: "error",
      });
      return;
    }
    if (editFormData.vehicleMasterId === "") {
      swal({
        title: "Alert!",
        text: "Please select vehicle",
        icon: "error",
      });
      return;
    }
    if (editFormData.masterMerchantId === "") {
      swal({
        title: "Alert!",
        text: "Please select merchant master",
        icon: "error",
      });
      return;
    }

    e.preventDefault();

    const payload = {
      company: editFormData.merchantName,
      maxTxnAmount: parseInt(editFormData.txnAmt),
      mid: editFormData.mid,
      sid: editFormData.sid,
      id: editFormData.id,
      vehicleMasterId: parseInt(editFormData.vehicleMasterId),
      masterMerchantId: parseInt(editFormData.masterMerchantId),
      merchantVpa: editFormData.MerchantVPA,
    };

    apiCallFnforPut(UPDATE_SID_MASTER, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.message,
            icon: "success",
          });
          setEditFormData({
            sid: "",
            txnAmt: "",
            mid: "",
            merchantName: "",
            id: "",
            vehicleMasterId: "",
          });
          handleCloseModal();
          FetchData(token);
          setRefresh(!refresh)
        } else {
          swal({
            title: "Alert!",
            text: response.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const columns = [
    {
      headerName: "Merchant ID",
      field: "mid",
      minWidth: 170,
    },
    {
      headerName: "Merchant Name",
      field: "company",
      minWidth: 360,
    },
    {
      headerName: "SID",
      field: "sid",
      minWidth: 170,
    },
    {
      headerName: "Merchant VPA",
      field: "merchantVpa",
      minWidth: 170,
    },
    {
      headerName: "Vehicle Name",
      field: "vehicleName",
      minWidth: 170,
    },

    {
      headerName: "Merchant Master",
      field: "masterMarchantName",
      minWidth: 150,
    },
    {
      headerName: "Maximum Transaction Amount",
      field: "maxTxnAmount",
      minWidth: 220,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 110,
    },
  ];
  const handleClose = () => setShow(false);

  const handleOnClick = () => {
    setShow(true);
  };

  return (
    <div className="">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Enabled SID's</div>
          </div>

          <CustomTip size="18" placement="top" title="SID Master Form">
            <div className=" me-4" onClick={handleOnClick}>
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </CustomTip>

          {/* <div className="d-flex gap-2">
                    <button className="badge badge-primary px-2 py-2 " onClick={handleOnClick}>
                    SID Master Form
          </button>
                    </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={applications}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                    {/* EDIT  */}
                    <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Edit SID Master</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <>
                          <div className="merchant-report-view">
                            <div className="report-table-view">
                              <div className="containerflag">
                                <div className="row ">
                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <label htmlFor="merchantName" className="fs-4 text-dark">Merchant Name</label>
                                    <div id="merchantName" className="form-control d-flex align-items-center fs-5">
                                      {editFormData.merchantName}
                                    </div>
                                  </div>

                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <div className="form-group mt-3">
                                      <label htmlFor="merchantMID" className="fs-4 text-dark">Merchant MID</label>
                                      <div id="merchantMID" className="form-control d-flex align-items-center fs-5">
                                        {editFormData.mid}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <div className="form-group mt-3">
                                      <label htmlFor="sid" className="fs-4 text-dark">Enter SID</label>
                                      <input
                                        type="text"
                                        className="form-control d-flex align-items-center fs-5"
                                        id="sid"
                                        name="sid"
                                        placeholder="Enter SID"
                                        value={editFormData.sid}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <div className="form-group mt-3">
                                      <label htmlFor="MerchantVPA" className="fs-4 text-dark">Enter Merchant VPA</label>
                                      <input
                                        type="text"
                                        className="form-control d-flex align-items-center fs-5"
                                        id="MerchantVPA"
                                        name="MerchantVPA"
                                        placeholder="Enter Merchant VPA"
                                        value={editFormData.MerchantVPA}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <div className="form-group mt-3">
                                      <label htmlFor="txnAmt" className="fs-4 text-dark">Enter Maximum Transaction Amount</label>
                                      <input
                                        type="number"
                                        className="form-control d-flex align-items-center fs-5"
                                        id="txnAmt"
                                        name="txnAmt"
                                        placeholder="Enter Maximum Txn Amount"
                                        value={editFormData.txnAmt}
                                        onChange={handelDataChange}
                                      />
                                      <span>
                                        <NumberInWords number={editFormData.txnAmt} />
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <div className="form-group ">
                                      <label htmlFor="companyDropdown" className="fs-4 text-dark">Select A Vehicle</label>
                                      <select
                                        id="companyDropdown"
                                        className="form-control d-flex align-items-center fs-5"
                                        onChange={handelDataChange}
                                        name="vehicleMasterId"
                                        value={editFormData.vehicleMasterId}
                                      >
                                        {/* <option value="">Select a Vehicle</option> */}
                                        {vehicleData?.map((vehicle) => (
                                          <option
                                            data-MasterName={vehicle.masterMerchantName}
                                            data-MasterId={vehicle.masterMerchantId}
                                            key={vehicle.id}
                                            value={vehicle.id}
                                          >
                                            {vehicle.vehicleName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-sm-12 col-md-8 offset-md-2">
                                    <div className="form-group mt-3">
                                      <label htmlFor="masterMerchantId" className="fs-4 text-dark">Select Merchant Master</label>
                                      <input
                                        id="masterMerchantId"
                                        className="form-control d-flex align-items-center fs-5"
                                        name="masterMerchantId"
                                        value={editFormData.masterMerchantName}
                                      />
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center mt-4">
                                    <button
                                      className="badge badge-rounded badge-primary px-3 py-3 mx-2"
                                      onClick={handleUpdateSubmit}
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </Modal.Body>
                    </Modal>

                    {/* Remove */}
                    <Modal
                      show={isModal2Open}
                      onHide={handleCloseModal2}
                      size="lg"
                    >
                      <Modal.Header
                        
                        closeButton
                      >
                        <Modal.Title className="fw-bold">SID Details:</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* <Card className="shadow border-0 mb-4"> */}
                        <Card.Body>
                          <div className="text-danger mb-2   fw-bold">
                            Following merchant is mapped with this SID
                          </div>
                          <div className="merchant-report-view">
                            <div className="report-table-view">
                              <div className="containerflag ">
                                <div className="row mt-4 ">
                                  <DataGrid
                                    rows={sidDetailsData}
                                    columns={sidDetailsColumn}
                                    slots={{
                                      toolbar: GridToolbar,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-danger mb-2   fw-bold">
                            Do you want to disable this SID ?
                          </div>
                          <div className="d-flex gap-2">
                            <button
                              className="badge badge-rounded badge-danger mx-2 py-2"
                              onClick={disableSID}
                            >
                              Disable
                            </button>
                            <button
                              className="badge badge-rounded badge-info mx-2  py-2"
                              onClick={handleCloseModal2}
                            >
                              Cancel
                            </button>
                          </div>
                        </Card.Body>
                        {/* </Card> */}
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SidMasterForm show={show} onHide={handleClose} setRefresh={setRefresh} refresh={refresh}/>
    </div>
  );
};

export default SIDSubmitted;
