import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { GET_ALL_MERCHANT_TRANSACTION } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import Pagination from "@mui/material/Pagination";
import TransactionReport from "./TransactionReport";
import { Box } from "@mui/material";
import Shimmer from "../../common/Shimmer/Shimmer";
import { Link } from "react-router-dom";

const MerchantTransaction = ({ type, toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState(0);
  const [totalrow, setTotalrow] = useState([]);
  const [zoomCount, setZoomCount] = useState(16);
  const [showReport, setShowReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedMids, setSelectedMids] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const { apiCallFnforGet } = useApiServices();
  const [showLoader, setShowLoader] = useState(false);
  const [totals, setTotals] = useState({
    TotalAmounts: "Total Amounts",
  });

  const fetchMerchantTransaction = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforGet(
        `${GET_ALL_MERCHANT_TRANSACTION}?page=${page-1}&size=${size}`
      );
      if (response.statusCode === 200) {
        setTotalData(response?.data)
        const finalRes = await response?.data?.map((item, i) => ({
          id: i,
          name: item.name,
          SelectMerchant: (
            <input
              type="checkbox"
              name={item.mid}
              value={selectedMids.includes(item.mid)}
              onChange={handleCheckbox}
            />
          ),
          mid: item.mid,
          totalTransactionAmount: item.totalTransactionAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() =>
                handleReportClick(item.mid, "totalTransactionAmount")
              }
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.totalTransactionAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          settlmentAmount: item.settlmentAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "settlementAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.settlmentAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          chargeBackAmount: item.chargeBackAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "chargeBackAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.chargeBackAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          holdAmount: item.holdAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "holdAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.holdAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          securityDeposit: item.securityDeposit ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "securityDeposit")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.securityDeposit}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          payoutAmount: item.payoutAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "payoutAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.payoutAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          withdrawalAmount: item.withdrawalAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "withdrawalAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.withdrawalAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          guaranteeAmount: item.guaranteeAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "guaranteeAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.guaranteeAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),

          remaningAmount: item.remaningAmount,
          ballancePayoutUpi: item.ballancePayoutUpi,
          ballancePayoutImps: item.ballancePayoutImps,
          creditBalance: item.creditBalance ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "creditBalance")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.creditBalance}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          sumOfSettlemenetAdjustAmount: item.sumOfSettlemenetAdjustAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() =>
                handleReportClick(item.mid, "sumOfSettlemenetAdjustAmount")
              }
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.sumOfSettlemenetAdjustAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          serviceCharge: item.serviceCharge,
          totalVendors: item.totalVendors ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "totalVendors")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.totalVendors}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          releaseAmount: item.releaseAmount ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "releaseAmount")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.releaseAmount}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
          walletBalance: item.walletBalance ? (
            <span
              className="badge badge-primary badge-hover"
              onClick={() => handleReportClick(item.mid, "walletBalance")}
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              {item.walletBalance}
            </span>
          ) : (
            <span
              className="badge badge-primary badge-hover"
              style={{
                cursor: "pointer",
                minWidth: "70px",
                textAlign: "center",
              }}
            >
              0
            </span>
          ),
        }));
        setApplications(finalRes);
        setCount(response?.duplicateData);
        setShowLoader(false);
      } else {
        console.error("Api response error:", response.data.data);
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
      setShowLoader(false);
    }
  };
  useEffect(() => {
    fetchMerchantTransaction();
  }, [page]);

  const handleCloseReport = () => setShowReport(false);

  const handleReportClick = (mid, type) => {
    setSelectedReport(mid);
    setSelectedType(type);
    setShowReport(true);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            {/* <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span> */}
                     <div className="d-flex justify-content-start"><GridToolbarQuickFilter /></div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  } 
  const handleCheckbox = (e) => {
    const mid = e.target.name;
    const checked = e.target.checked;

    console.log(mid, checked);
    if (checked) {
      setSelectedMids((prev) => [...prev, mid]);
    } else {
      setSelectedMids((prev) => prev.filter((item) => item != mid));
    }
  };

  useEffect(() => {
    console.log("--------", selectedMids);
  }, [selectedMids]);

  const columns = [
    { headerName: "Select Merchant", field: "SelectMerchant", minWidth: "150" },
    { headerName: "Company Name", field: "name", minWidth: "270" },
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "PayIn Amount", field: "totalTransactionAmount", minWidth: "150"},
    { headerName: "Settlement Amount", field: "settlmentAmount", minWidth: "150"},
    { headerName: "ChargeBack Amount", field: "chargeBackAmount", minWidth: "150" },
    { headerName: "Hold Amount", field: "holdAmount", minWidth: "100" },
    { headerName: " Deposit", field: "securityDeposit", minWidth: "100" },
    { headerName: "Payout Amount", field: "payoutAmount", minWidth: "150" },
    { headerName: "Withdrawl Amount", field: "withdrawalAmount", minWidth: "150"},
    { headerName: "Guarantee Amount", field: "guaranteeAmount", minWidth: "150"},
    { headerName: "Available Credit Amount", field: "creditBalance", minWidth: "170"},
    { headerName: "Settlement Adjustment amount", field: "sumOfSettlemenetAdjustAmount", minWidth: "210"},
    { headerName: "Service Charge", field: "serviceCharge", minWidth: "150" },
    { headerName: "Balance (Pay In)", field: "remaningAmount", minWidth: "150"},
    { headerName: "Balance (Payout UPI)", field: "ballancePayoutUpi", minWidth: "170"},
    { headerName: "Balance (Payout IMPS)", field: "ballancePayoutImps", minWidth: "170"},
    { headerName: "Vendors", field: "totalVendors", minWidth: "100" },
    { headerName: "Release Amount", field: "releaseAmount", minWidth: "150" },
    { headerName: "Wallet Balance", field: "walletBalance", minWidth: "150" },
  ];
  const columnsTotal = [
    // { headerName: "Total Amounts", field: "TotalAmounts" },
    // { headerName: "Merchant ID", field: "mid" },
    { headerName: "PayIn Amount", field: "totalTransactionAmount" },
    { headerName: "Settlement Amount", field: "settlmentAmount" },
    { headerName: "ChargeBack Amount", field: "chargeBackAmount" },
    { headerName: "Hold Amount", field: "holdAmount" },
    { headerName: "Deposit", field: "securityDeposit" },
    { headerName: "Payout Amount", field: "payoutAmount" },
    { headerName: "Withdrawal Amount", field: "withdrawalAmount" },
    { headerName: "Balance (Pay In)", field: "remaningAmount" },
    { headerName: "Balance (Payout UPI)", field: "ballancePayoutUpi" },
    { headerName: "Balance (Payout IMPS)", field: "ballancePayoutImps" },
    { headerName: "Guarantee Amount", field: "guaranteeAmount" },
    { headerName: "Available Credit Amount", field: "creditBalance" },
    {
      headerName: "Settlement Adjustment amount",
      field: "sumOfSettlemenetAdjustAmount",
    },
    { headerName: "Service Charge", field: "serviceCharge" },
    { headerName: "Vendors", field: "totalVendors" },
    { headerName: "Release Amount", field: "releaseAmount" },
    { headerName: "Wallet Balance", field: "walletBalance" },
  ];
  

  useEffect(() => {
    console.log(selectedMids);
  }, [selectedMids]);
  let rows;
  useEffect(() => {
    if (selectedMids?.length > 0 && totalData?.length > 0) {
      const totals = {};
      selectedMids.forEach((mid) => {
        const merchantData = totalData.find((item) => item.mid === mid);
        if (merchantData) {
          Object.keys(merchantData).forEach((key) => {
            // Exclude fields "email", "mobile", and "name"
            if (["email", "mobile", "name", "mid","id","SelectMerchant",].includes(key)) return;
            
             
            const value = parseFloat(merchantData[key]);
            console.log(key,value)
            if (!isNaN(value) ) {
              totals[key] = (totals[key] || 0) + value;
            }
          });
        }
      });
      setTotals(totals);
    } else {
      setTotals({});
    }
    console.log(totals);
  }, [selectedMids, totalData]);

  const handleChange = (event, value) => {
    setPage(value);
    // setShowLoader(true);
  };

  return (
    // <div className="content-body">
    <div className="container-fluid">
      <div className="d-flex justify-content-start align-items-center mb-4">
        <div className="d-flex align-items-center">
          <button
            type="button"
            class="btn-close m-3"
            onClick={toggleRightBar}
          ></button>

          <div className="fs-2 text-dark fw-bold">Merchant Transaction</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <div className="table-responsive">
                  {showLoader ? (
                    <Shimmer type="table" />
                  ) : applications?.length > 0 ? (
                    <>
                      <DataGrid
                        rows={applications}
                        columns={columns?.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "active" ||
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: CustomToolbar,
                        }}
                        hideFooterPagination={true}
                        // className="mb-1 text-dark fw-bold"
                        sx={{
                          fontSize: `${zoomCount}px`,
                          "& .css-1rtad1": {
                            display: "block",
                          }, // Adjust the font size as needed
                        }}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          margin: "0.8rem",
                        }}
                      >
                        <Pagination
                          count={count}
                          page={page}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <div className=" mt-4">
                        <DataGrid
                        rows={[{...totals,id:1}]}
                       columns={columnsTotal}
                        
                      />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>No data available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TransactionReport
         show={showReport}
         onHide={handleCloseReport}
         reportType={selectedType}
         mid={selectedReport}
         pageChange={page}
      />
    </div>
    // </div>
  );
};

export default MerchantTransaction;
