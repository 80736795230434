import React from "react";
import "./slidebox.css";

const SlideBox = ({ flag, toggleRightBar, children , type1}) => {
  console.log("Type--->", type1)
  return (
    <div className={`chatbox ${type1 !== "MDR" ? "chat1" : "chat11"} ${flag == true && "active"}`} >
      <div className={`${type1 !== "MDR" ? "chatbox-close chat1close" : "chatbox chat11close"}`} 
      // onClick={toggleRightBar}
      >

      </div>
      <div class="d-flex flex-column">
        {/* <button
          type="button"
          class="btn-close m-3"
          onClick={toggleRightBar}
        ></button> */}

        <div
          class="card-body contacts_body p-0 dlab-scroll  "
          id="dlab_W_Contacts_Body"
        >
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SlideBox;