import React from "react";
import nodatafound from "../../Assets/images/nodatafound.png";

const DataNotFound = () => {
  return (
    <div>
        <img className="w-50 h-50" src={nodatafound} alt="nodatafound" />
    </div>
  );
};

export default DataNotFound;