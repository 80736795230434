import { DataGrid,GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import useApiServices from '../../utils/useApiServices'
import { GET_ALL_CUB_ONBOARDING, GET_UCB_BANK_STATUS } from '../../utils/constant'
import swal from 'sweetalert'

const CUBTable = ({setIsModalCUB,isModalCUB}) => {

  const [cubData, setCubData] = useState([]);
  const {apiCallFnforGet} = useApiServices();

  const FetchCubOnboardingData= async()=>{
    try {
      const response = await apiCallFnforGet(GET_ALL_CUB_ONBOARDING)
      console.log(response)
        if(response.statusCode === 200){
        const finalRes = response.data.map((item)=>({
          id: item.registrationId,
          createDateTime: item.createDateTime,
          status: item.status,
          cpId: item.cpId,
          mid: item.mid,
          ref_Id: item.ref_Id,
          agg_Id: item.agg_Id,
          callbackStatus: item.callbackStatus,
          callBackRemark: item.callBackRemark,
          onBoardType: item.onBoardType,
          bussinesType: item.bussinesType,
          mccCode: item.mccCode,
          callbackJson: (item.callbackJson ? "Received": "Not Received"),
          action: (
            <button
              className="badge bg-primary text-white ms-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => handelCheckStatus(item.ref_Id)}
            >
              Check
            </button>
          )
        }))
        console.log(finalRes)
        setCubData(finalRes);
      }
      else{
        setCubData([]);
      }
    } catch (error) {
      console.log(error);
    }
     

  }

  useEffect(()=>{
    FetchCubOnboardingData()
  },[isModalCUB])


  //function to check status
  const handelCheckStatus = async(id)=>{
      try {
        const response = await apiCallFnforGet(`${GET_UCB_BANK_STATUS}${id}?id=${id}`)
        if(response.statusCode === 200){
          swal({
            title: response.message,
            text: response.data,
            icon: "success",
          });
        }
        else{
          swal({
            title: response.message,
            text: response.data,
            icon: "warning",
          });
        }
      } catch (error) {
        swal({
          title: "Alert",
          text: error,
          icon: "error",
        });
      }
     
  }

  const Column = [
    { headerName: "MID", field: "mid", minWidth: 150 },
    { headerName: "Registration ID", field: "id", minWidth: 150 },
    {
      headerName: "CP Id",
      field: "cpId",
      minWidth: 150,
    },
    {
      headerName: "Reference Id",
      field: "ref_Id",
      minWidth: 150,
    },
    {
      headerName: "Created Date",
      field: "createDateTime",
      minWidth: 150,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
    },
    {
      headerName: "OnBoard-Type",
      field: "onBoardType",
      minWidth: 150,
    },
    {
      headerName: "MCC Code",
      field: "mccCode",
      minWidth: 150,
    },
    {
      headerName: "Callback Status",
      field: "callbackStatus",
      minWidth: 150,
    },
    {
      headerName: "Callback Remark",
      field: "callBackRemark",
      minWidth: 150,
    },
    {
      headerName: "Callback Received",
      field: "callbackJson",
      minWidth: 150,
    },
    { headerName: "Check Status", field: "action", minWidth: 200 },
  ];
  return (
    <>
       <Modal show={isModalCUB} onHide={()=>setIsModalCUB(false)} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">CUB Onboarding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <div style={{ height: "500px", width: "100%" }}>
        <DataGrid
          rows={cubData}
          columns={Column.map((column) => ({
            ...column,
            renderCell: (params) =>
              column.field === "active" ||
              column.field === "action" ||
              column.field === "remainingLimitcolor"
                ? params.value
                : params.value,
          }))}
          // disableColumnFilter
          // disableColumnSelector
          // disableDensitySelector
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CUBTable