import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
// import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";
// import {} from "../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
// import "../stylesheet/ViewDetails.css";
import {
  GET_MERCHANT_BY_MID,
  HANDLE_VIEW_DATA,
  SWIPELINC_API,
  GET_MERCHANT_BY_ID,
  UPDATE_STAGE2,
  CHECK_CALLBACK_STATUS,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import PaymentsIcon from "@mui/icons-material/Payments";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CustomTip from "../../common/CustomToolTip/CustomTip";

const Phase2Approved = ({ id, handleClose, status }) => {
  console.log("ID----->", id);
  console.log("MID in approved", id);
  console.log("status kya h", status);
  const { apiCallFnforGet, apiCallFnforPut, apiCallFnforPost } = useApiServices();
  const [merchantData, setMerchantData] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewDataClicked, setViewDataClicked] = useState(false);
  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [merchantId, setmerchantId] = useState("");
  const [stage1, setStage1] = useState(null);
  const [stage2, setStage2] = useState(null);
  const [payinCallbackStatus, setPayinCallbackStatus] = useState(false)
  const [chargebackCallbackStatus, setChargebackCallbackStatus] = useState(false)
  const [payoutCallbackStatus, setPayoutCallbackStatus] = useState(false)
  const [reset,setReset] = useState(false);
  const token = localStorage.getItem("token");
  const handleClosePDF = () => {
    window.close();
  };

  const GetMerchantByMid = () => {
    apiCallFnforGet(GET_MERCHANT_BY_ID + id)
      .then((response) => {
        setMerchantData(response?.data);
        setmerchantId(response?.data?.mid);
        setStage1(response?.data?.stage1);
        setStage2(response?.data?.stage2);
        handleViewData(merchantId);
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
        // setShowLoader(false);
      });
  };

  useEffect(() => {
    // setShowLoader(true);
    GetMerchantByMid();
  }, [id,reset]);
  console.log("stag1-->", stage1)
  console.log("stag2-->", stage2)
  console.log("Details------->", merchantData);
  const handleViewData = (merchantId) => {
    apiCallFnforGet(HANDLE_VIEW_DATA + merchantId)
      .then((response) => {
        if (response.statusCode === 200) {
          setPdfFilenames(response?.data);
          setViewDataClicked((prev) => !prev);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  // const handleViewPDF = (filename) => {
  //   const encodedFilename = encodeURIComponent(filename);
  //   const headers = {
  //     Authentication: `Bearer ${token}`,
  //   };
  //   console.log("Here is the phase 2")
  //   axios
  //     .get(
  //       `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename}`,
  //       {
  //         responseType: "arraybuffer",
  //         headers,
  //       }
  //     )
  //     .then((response) => {
  //       const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  //       window.open(pdfUrl, "_blank");
  //       // setSelectedFile(pdfUrl);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching PDF:", error.message);
  //     });
  // };

  const handleViewPDF = async (name) => {
    // console.log(name)
    const headers = {
      Authentication: `Bearer ${token}`,
    };
    // setShowLoader(true);
    
    await axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${name}`, {
        responseType: "arraybuffer",
        headers,
      })
      .then((response) => {
        // setShowLoader(false);
        
        // Determine the file extension and MIME type
        const fileExtension = name.split('.').pop().toLowerCase();
        let mimeType;
        
        switch (fileExtension) {
          case 'pdf':
            mimeType = 'application/pdf';
            break;
          case 'jpg':
          case 'jpeg':
            mimeType = 'image/jpeg';
            break;
          case 'png':
            mimeType = 'image/png';
            break;
          case 'gif':
            mimeType = 'image/gif';
            break;
          // Add more cases as needed for different file types
          default:
            console.error('Unsupported file type:', fileExtension);
            return;
        }
        
        // Create a blob with the correct MIME type
        const blob = new Blob([response.data], { type: mimeType });
        const fileUrl = URL.createObjectURL(blob);
        
        // Open the file in a new tab
        window.open(fileUrl, "_blank");
      })
      .catch((error) => {
        // setShowLoader(false);
        console.error("Error fetching file:", error.message);
      });
  };

  const handleApprove = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          applicationStatus: "Approved",
          payoutAutoApprovel: true,
          registrationId: id,
          rejectionRemark: "NA",
          // masterMerchantId: prefix === "ALL" ? merchantMaster : masterId,
          showReferralTransaction: true,
          // mccCode: mccCode,
          vendorAutoApproved: true,
        };

        apiCallFnforPut(UPDATE_STAGE2, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              swal("Success", "Application approved!", "success").then(() => {
                // setOpen(false);
                handleClose();
              });
            } else {
              swal("Error", response.message, "error");
            }
          })
          .catch((error) => { });
      } else {
        setOpen(false);
        handleClose();
      }
    });
  };
  // handle rejection:

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const payload = {
          applicationStatus: "rejected",
          payoutAutoApprovel: false,
          registrationId: id,
          rejectionRemark: remark,
          showReferralTransaction: false,
          vendorAutoApproved: false,
        };

        apiCallFnforPut(UPDATE_STAGE2, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              swal("Success", "Application Rejected!", "success").then(
                () => { }
              );
              handleClose();
            } else {
              swal("Error", response.message, "error");
            }
          })
          .catch((error) => { });
      } else {
      }
    });
  };

  const handleVerifyUrl = async (url) => {
    try {
      const response = await apiCallFnforPost(CHECK_CALLBACK_STATUS, { url: url });
      console.log(response);
     if(response === true){
      setReset(!reset)
     }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <>
        <div classNam e="container my-4">
          <div className="card p-4 shadow-sm  ">

            <div className="row">
              {[
                { label: "Company Name", value: merchantData?.companyName },
                { label: "Authorized Person Name", value: merchantData?.authorizedPersonName, },
                {
                  label: "Payment Services",
                  value: Array.from(
                    new Set(
                      merchantData?.paymentService?.map(
                        (service) => service.paymentService
                      )
                    )
                  )
                    .map((service) => `• ${service}`)
                    .join(" "),
                },
                { label: "Merchant Bank Name", value: merchantData?.merchantBankName, },
                { label: "Merchant Account Number", value: merchantData?.merchantAccountNumber, },
                { label: " Merchant IFSC Code", value: merchantData?.merchantIfscCode, },
                { label: "Website", value: merchantData?.websiteUrl },
                // { label: "Payin Callback URL", value: merchantData?.callBackUrl, },
                { label: "Payin Callback URL",
                  value: merchantData?.callBackUrlStatus
                  ? <>
                    <span>{merchantData?.callBackUrl}</span>
                    <VerifiedIcon className="fs-4 text-success" /><span style={{fontSize:"12px"}} className="text-success">Verified</span>
                  </>
                  : <>
                    <div>{merchantData?.callBackUrl}</div>
                    <NewReleasesIcon style={{ cursor: 'pointer'}}   onClick={() => handleVerifyUrl(merchantData?.callBackUrl)} className="fs-5 text-danger"/><span style={{fontSize:"12px"}} className="text-danger"> Not Verified</span>
                  </>
                 },
                // { label: " Payout Callback URL", value: merchantData?.payoutCallbackUrl },payoutCallbackUrlStatus
                {
                  label: " Payout Callback URL",
                  value: merchantData?.payoutCallbackUrlStatus
                  ? <>
                    <span>{merchantData?.payoutCallbackUrl}</span>
                    <VerifiedIcon className="fs-4 text-success" /><span style={{fontSize:"12px"}} className="text-success">Verified</span>
                  </>
                  : <>
                    <div>{merchantData?.payoutCallbackUrl}</div>
                    
                       <NewReleasesIcon style={{ cursor: 'pointer' }}   onClick={() => handleVerifyUrl(merchantData?.payoutCallbackUrl)} className="fs-5 text-danger"/><span style={{fontSize:"12px"}} className="text-danger"> Not Verified</span>
                  </>
                },
                {
                  label: "Chargeback Callback URL",
                  value: merchantData?.chargebackCallbackUrlStatus
                  ? <>
                    <span>{merchantData?.chargebackCallbackUrl}</span>
                    <VerifiedIcon className="fs-4 text-success" /><span style={{fontSize:"12px"}} className="text-success">Verified</span>
                  </>
                  : <>
                    <div>{merchantData?.chargebackCallbackUrl}</div>
                    
                       <NewReleasesIcon style={{ cursor: 'pointer' }}   onClick={() => handleVerifyUrl(merchantData?.chargebackCallbackUrl)}  className="fs-5 text-danger"/><span style={{fontSize:"12px"}} className="text-danger"> Not Verified</span>
                  </>
                },
                { label: "Referred By", value: merchantData?.referredBy },

              ].map((item, index) => (
                <div
                  key={index}
                  className="col-6 border border-dark "
                  style={{
                    borderRight: "0.5px solid black",
                    borderLeft: "0.5px solid black",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center table table-bordered">
                    <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                      {item.label} <RemoveIcon />
                    </tr>{" "}
                    <tr className="mt-2 w-100 text-end">{item.value}</tr>
                  </div>
                </div>
              ))}
            </div>


            {viewDataClicked && (
              <div className="mt-4">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pdfFilenames?.map((fileObject, index) => (
                      <tr key={index}>
                        <td className="fs-5 font-weight-bold">
                          {fileObject["File name"]}
                        </td>
                        <td>
                          <button
                            className=""
                            onClick={() =>
                              handleViewPDF(fileObject["Orignal name"])
                            }
                          >
                            <PictureAsPdfIcon
                              sx={{ width: 35, height: 35, color: "#AA4A44" }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="d-flex justify-content-end mt-4">
              <button
                className="badge badge-rounded badge-warning py-2 me-2"
                onClick={() => handleViewData(merchantId)}
              >
                View Data
              </button>
              <div className="d-flex justify-content-end ">
                <button
                  type="button"
                  class="badge badge-rounded badge-info mx-4 px-4 py-2 mb-25"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>

              {stage2 === "Submitted" && (
                <>
                  <button
                    className="badge badge-rounded badge-success py-2 me-2"
                    onClick={handleApprove}
                  >
                    Approve <i className="fa fa-check ms-2"></i>
                  </button>
                  <button
                    className="badge badge-rounded badge-danger py-2"
                    onClick={handleReject}
                  >
                    Reject <i className="fa fa-close ms-2"></i>
                  </button>
                </>
              )}
            </div>

            {selectedFile && (
              <div className="mt-4">
                <iframe
                  src={selectedFile}
                  title="PDF Viewer"
                  className="w-100"
                  style={{ height: "500px", border: "none" }}
                ></iframe>
                <div className="text-center mt-3">
                  <button className="btn btn-danger" onClick={handleClosePDF}>
                    Close <i className="fa fa-times ms-2"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default Phase2Approved;