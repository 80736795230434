// get Token
export const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token")
}

// set new Token
export const setTokenToLocalStorage = (token) => {
    localStorage.setItem("token", token)
}

// remove token

export const removeTokenFromLocalStorage = () => {
    localStorage.removeItem("token")
}