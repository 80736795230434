import React from "react";
 import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "./DashBoard/SideBar";
import Header from "./DashBoard/Header";
import IdleTimerContainer from "./common/IdleTimerContainer";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children, isHeaderVisible=true }) => {
  const redirect = "/";
  const { isAuthenticated } = useSelector((state) => state.userData);
  const access_token = Cookies.get("access_token");

  if (!access_token) {
    return <Navigate to={redirect} />;
  }

  return children ? (
    <>
     
      <SideBar />
      {isHeaderVisible === true &&    <Header />}
   
      {children}
    </>
  ) : (
    <>
       <IdleTimerContainer />
     <SideBar />
     {isHeaderVisible === true && <Header />}
         
      <Outlet />;
    </>
  );
};

export default ProtectedRoute;
