import React from "react";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_ALL_MERCHANT_MASTER,
  POST_BBPS_MASTER_FORM,
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";
import { Offcanvas } from "react-bootstrap";

const BBPSMasterForm = ({show,onHide,refresh,setRefresh}) => {

  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [merchantMasterData, setMerchantMasterData] = useState([]);

  const { apiCallFnforPost, apiCallFnforGet } = useApiServices();

  const [formData, setFormData] = useState({
    agentIdInternate: "",
    agentIdMobile: "",
    mid: "",
    tenantId: "",
    xdgiledgeKey: ""
  });
  const offcanvasStyle = {
    width: '60vw', // Set width to 70vw
  };

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update the formData state based on the input field name and value
    setFormData({
      ...formData, // Spread the previous state to preserve other values
      [name]: value, // Update the specific field (mid or tenantId)
    });
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);
    console.log("selectedCompany", selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const showConfirmationAlert = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application ?",
        icon: "warning",
        buttons: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          if (formData.merchantName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant",
              icon: "error",
            });
            return;
          }

          const payload = {
            active: true,
            agentIdInternate: formData.agentIdInternate,
            agentIdMobile: formData.agentIdMobile,
            mid: formData.mid,
            tenantId: formData.tenantId,
            xdgiledgeKey: formData.xdgiledgeKey,
          }

          apiCallFnforPost(POST_BBPS_MASTER_FORM, payload)
            .then((response) => {
              if (response.statusCode === 200) {
                swal({
                  title: "Success!",
                  text:  response.message,
                  icon: "success",
                });
                setFormData({
                    mid: "",
                    agentIdInternate: "",
                    agentIdMobile: "",
                    tenantId: "",
                    xdgiledgeKey: "",
                });
                setSelectedCompany("");
                onHide();
                setRefresh(!refresh)
              } else {
                swal({
                  title: "Alert!",
                  text: response.message,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          swal("Submission Cancelled!", {
            icon: "info",
          });
        }
      });
    };

    showConfirmationAlert();
  };
  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

    setMerchantData(res?.data);
  };
  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllMerchantMaster();
  }, []);
  const handleClose = () => {
    setFormData({
        mid: "",
        agentIdInternate: "",
        agentIdMobile: "",
        tenantId: "",
        xdgiledgeKey: "",
    });
    setSelectedCompany("");
    setRefresh(!refresh);
    onHide();
  };

  console.log("heloo",formData)
  return (
    <>
    
     <Offcanvas show={show} onHide={handleClose} backdrop="static" keyboard={false} placement="end" style={offcanvasStyle}>
     <div className="d-flex justify-content-start align-items-center mt-3">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            BBPS Master Form
          </h5>
        </div>
    
    <Offcanvas.Body >
        <div class="container-fluid">

          <div class="row">
            <div class="col-12">
                <div className="card-body py-4">
                  <div id="" className="form-wizard order-create">
                    <div id="" className="tab-pane" role="tabpanel">
                      {/* <form className="form-section-form-group"> */}
                      <div className="containerflag">
                        <div className="row mt-">
                          <div className="col-md-6 offset">
                            <div className="">
                              <label className="mx-4 text-dark fs-4">Select A Merchant</label>
                              <select
                                id="companyDropdown"
                                className="form-control"
                                onChange={handleCompanyChange}
                                value={selectedCompany}
                              >
                                <option value="">Select a Merchant</option>
                                {merchantData?.map((merchant) => (
                                  <option
                                    key={merchant.mid}
                                    value={merchant.merchantName}
                                  >
                                    {merchant.mid} - 
                                    {merchant.merchantName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* <div className="row mt-2"> */}
                              <div className="">
                                <div className="form-group mt-2">
                                <label className="mx-4 text-dark fs-4 ">Tenant ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tenantId"
                                  name="tenantId"
                                  placeholder="Tenant Id"
                                  value={formData.tenantId}
                                  onChange={handleChange}
                                />
                                </div>
                              </div>
                            {/* </div> */}

                            <div className="">
                                  <div className="form-group">
                                  <label className="mx-4 text-dark fs-4">
                                      X-Digiledge - Key</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="xdgiledgeKey"
                                        name="xdgiledgeKey"
                                        placeholder="x-degiledge key"
                                        value={formData.xdgiledgeKey}
                                        onChange={handleChange}
                                        />
                                  </div>
                                </div>
                          </div>

                          <div className="col-md-6 offset">
                            <div className="">
                            <label className="mx-4 text-dark fs-4">
                                AgentId Mobile</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="agentIdMobile"
                                  name="agentIdMobile"
                                  placeholder="AgentId Mobile"
                                  value={formData.agentIdMobile}
                                  onChange={handleChange}
                                //   onChange={handelDataChange}
                                />
                            </div>

                            <div className="">
                            <label className="mx-4 text-dark fs-4 mt-2">
                                AgentId Web
                              </label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="agentIdInternate"
                                  name="agentIdInternate"
                                  placeholder="AgentId Web"
                                  value={formData.agentIdInternate}
                                  onChange={handleChange}
                                />
                            </div>

                            </div>
                           
                          <div className="d-flex justify-content-end mt-4">
                            <button
                             className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      {/* </div> */}
      </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BBPSMasterForm;
