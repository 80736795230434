import React, { useState } from "react";
import { Card, Col, Row, Offcanvas, Button } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FloatingReports from "../../common/FlotingReports";
import MerchantIcon from "../../common/MerchantIcon";



const EKyc = ({ show, onHide, type, toggleRightBar }) => {
  const [condtion, setCondition] = useState("this month");
  const [mid, setMid] = useState("");
  const data = [];

  const columns = [
    { headerName: "ID", field: "id", minWidth: "100" },
    { headerName: "Request For", field: "requestFor", minWidth: "100" },
    {
      headerName: "Request Date and Time",
      field: "requestDateAndTime",
      minWidth: "100",
    },
    {
      headerName: "Request Parameter",
      field: "requestParameter",
      minWidth: "100",
    },
    {
      headerName: "Response Parameter",
      field: "responseParameter",
      minWidth: "100",
    },
    { headerName: "Merchant ID", field: "merchantId", minWidth: "100" },
    { headerName: "Amount", field: "amount", minWidth: "100" },
  ];

  return (
    <>
      <div className="">
        <MerchantIcon setMid={setMid}/>
        <div className="container-fluid">
          {/* <div className="row page-titles">
      <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">SID Details</a>
              </li>
            </ol>
      </div> */}
        <div className="d-flex justify-content-start align-items-center mb-4">
                    <div className='d-flex align-items-center'>
                    <button
                  type="button"
                  class="btn-close m-3"
                  onClick={toggleRightBar}
                ></button>
              
                <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>
                    </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="col-12">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataGrid
                        rows={data}
                        columns={columns.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action" ? params.value : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FloatingReports
          setCondition={setCondition}
        // mid={mid}
        // reportType={type}
        // setData={setData}
        />
      </div>
    </>

  );
};

export default EKyc;
