import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { VEHICLE_MASTER_PHASE_2_SUBMITTED } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import { Card } from "react-bootstrap";
import VehicleMasterFormDetails from "./VehicleMasterFormDetails";
import VehicleMasterEditForm from "./VehicleMasterEditForm";
import EditVicleMasterPhase2 from "./EditVicleMasterPhase2";
import Phase1Form from "./Phase1Form";
import Phase2Form from "./Phase2Form";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MenuList, Paper, Popper } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const XMSubmitted = () => {
  const [show, setShow] = useState(false);
  const [vehicalMasterData, setVehicalMasterData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [registrationId, setRegistrationId] = useState("");
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [schemeName2, setSchemeName2] = useState("");
  const [bankName2, setBankName2] = useState("");
  const [switchName2, setSwitchName2] = useState("");
  const [aggeregatorName2, setAggeregatorName2] = useState("");
  const [openEdit2, setOpenEdit2] = useState(false);
  const [schemeName, setSchemeName] = useState("");
  const [bankName, setBankName] = useState("");
  const [switchName, setSwitchName] = useState("");
  const [aggeregatorName, setAggeregatorName] = useState("");
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const { apiCallFnforGet } = useApiServices();
  const [refresh, setRefresh] = useState(false);
  
  useEffect(() => {
    setData({ v1: 3 });
    GetVehicalMasterData();
  }, [show2, show3, openEdit, openEdit2]);
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickIcon = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuItemClick = (action) => {
    setSelectedAction(action);
    setAnchorEl(null); // Close the menu after selecting an option
  };

  const GetVehicalMasterData = async () => {
    try {
      const response = await apiCallFnforGet(VEHICLE_MASTER_PHASE_2_SUBMITTED);
      if (response.statusCode === 200) {
        const finalResp = response?.data?.map((item, i) => ({
          id: i,
          vehicleName: item.vehicleName,
          aggregatorName: item.aggregatorName,
          masterMerchantName:item.masterMerchantName,
          schemeName: item.schemeName,
          switchName: item.switchName,
          bankName: item.bankName,
          remark: item.remark,
          PaymentServices: (
            <div className="d-flex justify-content-start align-items-center gap-2 pt-3">
              {item.paymentTypes?.map((item) => (
                <div className="badge badge-rounded badge-warning">
                  {item.paymentservice}
                </div>
              ))}
            </div>
          ),
          PaymentSubServices: (
            <div className="d-flex justify-content-start align-items-center gap-2 pt-3">
              {item.paymentSubTypes?.map((item) => (
                <div className="badge badge-rounded badge-success">
                  {item.type}
                </div>
              ))}
            </div>
          ),
          action: (
            <Select
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value)}
              displayEmpty
              renderValue={() => null}
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED",
                borderRadius: 8,
                padding: '8px 2px',
                height: '25px',
              }}
            >
              <MenuItem
                value="view"
                onClick={() => {
                  handleMenuItemClick("view");
                  ViewVehicleDetails(item.id);
                }}
              >
                View
              </MenuItem>
              <MenuItem
                value="editPhase1"
                onClick={() => {
                  handleMenuItemClick("editPhase1");
                  OpenEditPhase1(item.id, { ...item });
                }}
              >
                Edit Phase 1
              </MenuItem>
              <MenuItem
                value="editPhase2"
                onClick={() => {
                  handleMenuItemClick("editPhase2");
                  OpenEditPhase2(item.id, { ...item });
                }}
              >
                Edit Phase 2
              </MenuItem>
            </Select>
          ),
        }));
        setVehicalMasterData(finalResp);
      } else {
        setVehicalMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OpenEditPhase1 = (id, data) => {
    setRegistrationId(id);
    setData(data);
    setBankName(data.bankName);
    setAggeregatorName(data.aggregatorName);
    setSwitchName(data.switchName);
    setSchemeName(data.schemeName);
    setOpenEdit(true);
  };

  const OpenEditPhase2 = (id, data) => {
    setRegistrationId(id);
    setData2(data);
    setBankName2(data.bankName);
    setAggeregatorName2(data.aggregatorName);
    setSwitchName2(data.switchName);
    setSchemeName2(data.schemeName);
    setOpenEdit2(true);
  };

  const ViewVehicleDetails = (id) => {
    setShow(true);
    setRegistrationId(id);
    setShowDetails(true);
  };

  const handleCloseModal = () => {
    setRegistrationId("");
    setData({});
    setOpenEdit(false);
  };

  const handleCloseModal2 = () => {
    setRegistrationId("");
    setData({});
    setOpenEdit2(false);
    GetVehicalMasterData();
  };

  const columns = [
    {
      headerName: "Vehicle Name",
      field: "vehicleName",
      minWidth: 220,
    },
    {
      headerName: "Master Merchant Name",
      field: "masterMerchantName",
      minWidth: 190,
    },
    {
      headerName: "Scheme",
      field: "schemeName",
      minWidth: 170,
    },

    {
      headerName: "Aggregator",
      field: "aggregatorName",
      minWidth: 150,
    },
    {
      headerName: "Switch",
      field: "switchName",
      minWidth: 150,
    },
    {
      headerName: "Bank",
      field: "bankName",
      minWidth: 150,
    },
    {
      headerName: "Remark",
      field: "remark",
      minWidth: 350,
    },
    {
      headerName: "Payment Services",
      field: "PaymentServices",
      minWidth: 500,
    },
    {
      headerName: "Payment Sub Services",
      field: "PaymentSubServices",
      minWidth: 650,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 150,
    }
  ];

  const handleClose = () => setShow(false);

  const handleClick = () => {
    setShow2(true);
    setAnchorEl(null); // Close the menu when clicking on Phase 1
  }

  const handleClick2 = () => {
    setShow3(true);
    setAnchorEl(null); // Close the menu when clicking on Phase 2
  }

  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);

  return (
    <div className="d-flex justify-content-end">
      <div className="w-75 m-4">
        <div className="container-fluid">
          <div className="my-4 mx-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fs-2 text-black" style={{fontWeight:"600"}}>
                Vehicle Management
              </div>
              <div>
                <AddCircleOutlineIcon fontSize="large" onClick={handleClickIcon} />
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
                  <Paper>
                    <MenuList>
                      <MenuItem onClick={handleClick}>Phase 1</MenuItem>
                      <MenuItem onClick={handleClick2}>Phase 2</MenuItem>
                    </MenuList>
                  </Paper>
                </Popper>
              </div>
            </div>
          </div>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              {openEdit ? (
                <VehicleMasterEditForm
                  id={registrationId}
                  data={data}
                  isModalOpen={openEdit}
                  handleCloseModal={handleCloseModal}
                  bankNameData={{ bankName, setBankName }}
                  schemeNameData={{ schemeName, setSchemeName }}
                  switchNameData={{ switchName, setSwitchName }}
                  aggregatorNameData={{ aggeregatorName, setAggeregatorName }}
                />
              ) : openEdit2 ? (
                <EditVicleMasterPhase2
                  id={registrationId}
                  data={data2}
                  isModalOpen2={openEdit2}
                  handleCloseModal={handleCloseModal2}
                  bankNameData={{ bankName2, setBankName2 }}
                  schemeNameData={{ schemeName2, setSchemeName2 }}
                  switchNameData={{ switchName2, setSwitchName2 }}
                  aggregatorNameData={{ aggeregatorName2, setAggeregatorName2 }}
                />
              ) : (
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <DataGrid
                      rows={vehicalMasterData}
                      columns={columns?.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
        <VehicleMasterFormDetails
          show={show}
          onHide={handleClose}
          id={registrationId}
        />
        <Phase1Form
          show={show2}
          onHide={handleClose2}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <Phase2Form
          show={show3}
          onHide={handleClose3}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
};

export default XMSubmitted;
