import React, { useState } from "react";
import {MastersIcons } from "../../Assets/images/image";
import styles from "../Reports/report.module.css";
import SlideBox from "../../common/SlideBox/SlideBox";
import SIDSubmitted from "./SidMaster/SIDSubmitted";
import SIDDetails from "./SidMaster/SIDDetails";
import BBPSMaster from "./SidMaster/BBPSMaster";
import SIDMerchantOnboarding from "./SidMaster/SIDMerchantOnboarding";
import SubmittedPayoutMasterForm from "./PayoutMaster/SubmittedPayoutMasterForm";
import useCheckPrivilages from "../../utils/checkPrivilages";
import { useSelector } from "react-redux";


const MastersView = () => {
  const [flag, setFlag] = useState(false);
  const [report, setReport] = useState("");
  const { prefix, type } = useSelector((state) => state.privilages);

  const hasPrivilages179774 = useCheckPrivilages(179774);
  const hasPrivilages179773 = useCheckPrivilages(179773);

  const reportType = {
    SIDSubmitted: "SIDSubmitted",
    SIDDetails:"SIDDetails",
    SIDMerchantOnboarding:"SIDMerchantOnboarding",
    SubmittedPayoutMasterForm:"SubmittedPayoutMasterForm",
    BBPSMaster:"BBPSMaster",
  };

  const toggleRightBar = (type) => {
    if (type) {
      setReport(type);
    }

    setFlag(!flag);
  };
 
 
  return (
    <div className="content-body">
      <div className="container-fluid ">
        <div class="card card-inverse card-primary text-center">
          <div class="card-body pb-1">
          <SlideBox flag={flag} toggleRightBar={toggleRightBar}>
              {reportType[report] === "SIDSubmitted" && <SIDSubmitted type={report} toggleRightBar={toggleRightBar}/>}
              {reportType[report] === "SIDDetails" && <SIDDetails type={report} toggleRightBar={toggleRightBar}/>}
              {reportType[report] === "SIDMerchantOnboarding" && <SIDMerchantOnboarding type={report} toggleRightBar={toggleRightBar}/>}
              {reportType[report] === "SubmittedPayoutMasterForm" && <SubmittedPayoutMasterForm type={report} toggleRightBar={toggleRightBar}/>}
              {reportType[report] === "BBPSMaster" && <BBPSMaster type={report} toggleRightBar={toggleRightBar}/>}
            </SlideBox>

          

            <div class="row text-center text-lg-start">
            {hasPrivilages179773 ? (
            <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => toggleRightBar("SIDSubmitted")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={MastersIcons.SIDSubmitted}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">Enabled SID's</h5>
                </div>
              </div>
            ):null}

         {hasPrivilages179773 ? (
            <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("SIDDetails")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={MastersIcons.SIDDetails}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">SID Details</h5>
                </div>
              </div>
         ):null}

       {
        type !== "user" && (
          <>
             {hasPrivilages179773 ? (
              <div
                className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("SIDMerchantOnboarding")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={MastersIcons.Onboarding}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                  SID Merchant-Onboarding
                  </h5>
                </div>
              </div>
):null}
          </>
        )
       }

{hasPrivilages179774 ? (  
              <div
                className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("SubmittedPayoutMasterForm")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={MastersIcons.MastersForm}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                  PayoutMaster Form
                  </h5>
                </div>
              </div>
):null}

{/* {hasPrivilages179773 ? ( */}
            <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => toggleRightBar("BBPSMaster")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={MastersIcons.BBPSMaster}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">BBPS Master</h5>
                </div>
              </div>
            {/* ):null} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MastersView;