import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
  name: "report search",
  initialState: {
    startDate: "",
    endDate: "",
    email: "",
    mobile: "",
    orderNumber: "",
    transactionId: "",
    searchValue: "",
  },
  reducers: {
    setStartDateValue: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDateValue: (state, action) => {
      state.endDate = action.payload;
    },
    setEmailValue: (state, action) => {
      state.email = action.payload;
    },
    setMobileValue: (state, action) => {
      state.mobile = action.payload;
    },
    setOrderNumberValue: (state, action) => {
      state.orderNumber = action.payload;
    },
    setTransactionIdValue: (state, action) => {
      state.transactionId = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

export const {
  setStartDateValue,
  setEndDateValue,
  setEmailValue,
  setMobileValue,
  setOrderNumberValue,
  setTransactionIdValue,
  setSearchValue,
} = reportSlice.actions;

export default reportSlice.reducer;
