import React from "react";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FETCH_MERCHANT_FOR_MASTERS_FORMS,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_ALL_MERCHANT_MASTER,
  POST_SID_MASTER_V,
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";
import { Offcanvas } from "react-bootstrap";
import NumberInWords from "./NumberInWords";

const SidMasterForm = ({show,onHide,refresh,setRefresh}) => {
  const navigate = useNavigate();
  // const [activePhase, setActivePhase] = useState("Phase1");

  const { prefix } = useSelector((state) => state.privilages);

  // useEffect(() => {
  //   setActivePhase("Phase1");
  // }, []);

  // const handlePhaseClick = (phase) => {
  //   setActivePhase(phase);
  // };
  
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  // const [masterName, setMasterName] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [data, setData] = useState({
    sid: "",
    txnAmt: "",
    vehicle: "",
    merchantMasterId: "",
    merchantMasterName: "",
    MerchantVPA: "",
  });
  const { apiCallFnforPost, apiCallFnforGet } = useApiServices();
  // const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });
  const offcanvasStyle = {
    width: '60vw', // Set width to 70vw
  };

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId);
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);
    console.log("selectedCompany", selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const showConfirmationAlert = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application ?",
        icon: "warning",
        buttons: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          if (formData.merchantName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant",
              icon: "error",
            });
            return;
          }

          if (data.vehicle === "") {
            swal({
              title: "Alert!",
              text: "Please Select Vehicle",
              icon: "error",
            });
            return;
          }

          if (data.sid === "") {
            swal({
              title: "Alert!",
              text: "Please Enter SID",
              icon: "error",
            });
            return;
          }

          if (data.txnAmt === "") {
            swal({
              title: "Alert!",
              text: "Please Enter Maximum Transaction Amount",
              icon: "error",
            });
            return;
          }
          if (data.merchantMasterName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant Master",
              icon: "error",
            });
            return;
          }
          if (data.MerchantVPA === "") {
            swal({
              title: "Alert!",
              text: "Please Enter Merchant VPA",
              icon: "error",
            });
            return;
          }
          const payload = {
            company: formData.merchantName.trim(),
            maxTxnAmount: data.txnAmt.trim(),
            mid: formData.mid.trim(),
            sid: data.sid.trim(),
            vehicleMasterId: data?.vehicle.trim(),
            masterMerchantId: data?.merchantMasterId.trim(),
            merchantVpa: data?.MerchantVPA.trim(),
          };

          apiCallFnforPost(POST_SID_MASTER_V, payload)
            .then((response) => {
              if (response.statusCode === 200) {
                swal({
                  title: "Success!",
                  text:  response.message,
                  icon: "success",
                });
                setFormData({
                  mid: "",
                  merchantName: "",
                });
                setData({
                  sid: "",
                  txnAmt: "",
                  vehicle: "",
                  merchantMasterId: "",
                  merchantMasterName: "",
                  merchantVpa:""
                });
                setSelectedCompany("");
                onHide();
                setRefresh(!refresh)
              } else {
                swal({
                  title: "Alert!",
                  text: response.message,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          swal("Submission Cancelled!", {
            icon: "info",
          });
        }
      });
    };

    showConfirmationAlert();
  };
  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

    setMerchantData(res?.data);
  };
  useState(() => {
    getAllMerchantForApiTest();
  }, []);
  const getAllVehicle = async () => {
    try {
      const payload = {
        serviceType: "UPI",
      };
      const response = await apiCallFnforPost(
        FETCH_MERCHANT_FOR_MASTERS_FORMS,
        payload
      ); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);
  const handleClose = () => {
    setFormData({
      mid: "",
      merchantName: "",
    });
    setData({
      sid: "",
      txnAmt: "",
      vehicle: "",
      merchantMasterId: "",
      merchantMasterName: "",
      merchantVpa: "",
    });
    setSelectedCompany("");
    setRefresh(!refresh);
    onHide();
  };

  return (
    <>
    
     <Offcanvas show={show} onHide={handleClose} backdrop="static" keyboard={false} placement="end" style={offcanvasStyle}>
     <div className="d-flex justify-content-start align-items-center mt-3">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            SID Master Form
          </h5>
        </div>
    
    
    <Offcanvas.Body >
      {/* <div class="content-body"> */}
        <div class="container-fluid">
          {/* <div class="row page-titles">
          
          </div> */}

          <div class="row">
            <div class="col-12">
              {/* <div className="card"> */}
                {/* <div className="card-header">
                  <h4 className="card-title">SID Master Form</h4>
                </div> */}
                <div className="card-body py-4">
                  <div id="" className="form-wizard order-create">
                    <div id="" className="tab-pane" role="tabpanel">
                      {/* <form className="form-section-form-group"> */}
                      <div className="containerflag">
                        <div className="row mt-">
                          <div className="col-md-6 offset">
                            <div className="">
                              <label className="mx-4 text-dark fs-4">Select A Merchant</label>
                              <select
                                id="companyDropdown"
                                className="form-control"
                                onChange={handleCompanyChange}
                                value={selectedCompany}
                              >
                                <option value="">Select a Merchant</option>
                                {merchantData?.map((merchant) => (
                                  <option
                                    key={merchant.mid}
                                    value={merchant.merchantName}
                                  >
                                    {merchant.mid} - 
                                    {merchant.merchantName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* <div className="row mt-2"> */}
                              <div className="">
                                <div className="form-group mt-2">
                                <label className="mx-4 text-dark fs-4 ">Merchant MID</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Company Name"
                                    value={formData.mid}
                                    readOnly
                                  />
                                </div>
                              </div>
                            {/* </div> */}
                            <div className="">
                              <div className="form-group mt-2">
                              <label className="mx-4 text-dark fs-4">
                                  Enter Maximum Transaction Amount
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="txnAmt"
                                  name="txnAmt"
                                  placeholder="Enter Maximum Txn Amount "
                                  value={data.txnAmt}
                                  onChange={handelDataChange}
                                />
                                <span>
                                  <NumberInWords number={data.txnAmt} />
                                </span>
                              </div>
                            </div>

                            <div className="">
                                  <div className="form-group">
                                  <label className="mx-4 text-dark fs-4">
                                      Enter Merchant VPA</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="MerchantVPA"
                                      name="MerchantVPA"
                                      placeholder="Enter Merchant VPA"
                                      value={data.MerchantVPA}
                                      onChange={handelDataChange}
                                    />
                                  </div>
                                </div>
                          </div>

                          <div className="col-md-6 offset">
                            <div className="">
                            <label className="mx-4 text-dark fs-4">
                                Select A Vehicle</label>
                              <select
                                id="companyDropdown"
                                className="form-control"
                                onChange={handelDataChange}
                                name="vehicle"
                                value={data.vehicle}
                              >
                                <option value="">Select a Vehicle</option>
                                {vehicleData?.map((vehicle) => (
                                  <option
                                    key={vehicle.id}
                                    data-master={vehicle.masterMerchantName}
                                    data-masterId={vehicle.masterMerchantId}
                                    value={vehicle.id}
                                  >
                                    {vehicle.vehicleName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="">
                            <label className="mx-4 text-dark fs-4 mt-2">
                                Select Master Merchant
                              </label>
                              <input
                                id="companyDropdown"
                                className="form-control"
                                onChange={handelDataChange}
                                readOnly
                                name="merchantMaster"
                                value={data.merchantMasterName}
                              />
                            </div>

                            <div className="">
                              <div className="form-group mt-2">
                              <label className="mx-4 text-dark fs-4">
                                  Enter SID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="sid"
                                  name="sid"
                                  placeholder="Enter SID"
                                  value={data.sid}
                                  onChange={handelDataChange}
                                />
                              </div>
                            </div>
                            </div>
                           
                          <div className="d-flex justify-content-end mt-4">
                            <button
                             className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      {/* </div> */}
      </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SidMasterForm;
