import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import IbtnWrapper from "../common/CustomToolTip/IButtonWrraper";
import CustomTip from "../common/CustomTip";
import { errorCodeDescrition } from "../utils/ConstantKeys";
import Shimmer from "../common/Shimmer/Shimmer";

const PieCharts = ({ data, type ,showLoader}) => {
  return (
    <div className={"col-xl-10 col-xxl-12"}>
      <div className="card">
        <div className="card-header border-0">
          <h4 className="card-title">
            {type === "services"
              ? "Services Percentage Chart"
              : "Error Code & Counts Chart"}
          </h4>
          <IbtnWrapper
            color={"text-black"}
            desc={
              type === "services"
                ? "Services Percentage Chart"
                : "Error Code & Counts Chart"
            }
          />
        </div>
        <div className="card-body pt-0 text-center ">
          {showLoader ? (
            <div>
              <Shimmer type={"circle"} size={300} />
            </div>
          ) : data && data.length > 0 ? (
            <div style={{ width: "80%", height: "28rem", display: "flex" }}>
              {data && (
                <PieChart
                  series={[
                    {
                      data: data,
                      innerRadius: 30,
                      paddingAngle: 1,
                      cornerRadius: 5,
                    },
                  ]}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
              )}
              <div style={{ width: "80%", height: "80%" }} className="row mt-5">
                {data &&
                  data.map((item, i) => (
                    <div
                      key={item.id}
                      className="col-4 col-md-4 col-lg-3 col-xl-3 d-flex justify-content-start align-item-center"
                    >
                      <CustomTip
                        size={20}
                        title={
                          type === "services"
                            ? `${item.label} : ${item.value}`
                            : `${item.label} : ${item.value} \n ${
                                errorCodeDescrition[item.label] ?? ""
                              }`
                        }
                        placement="top"
                      >
                        <div
                          key={item.id}
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: `${item.color}`,
                          }}
                          className="mx-2 cursor-pointer"
                        ></div>
                      </CustomTip>
                      <p className="text-dark">
                        {item.label === "VIRTUAL TERMINAL" ? "VT" : item.label}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center mb-4">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PieCharts;
