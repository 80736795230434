import React, { useState, useEffect } from "react";

import swal from "sweetalert";

import { DateTime } from "luxon";

import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  SAVE_WITHDRAW,
} from "../../../../utils/constant";
import useApiServices from "../../../../utils/useApiServices";
import { Offcanvas } from "react-bootstrap";

const ManualUpload = ({ onHide, show, refresh, setRefresh}) => {
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [utrNumber, setUtrNumber] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [securityAmountError, setSecurityAmountError] = useState("");
  const [mid, setMid] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("false");
  const [transferType, setTransferType] = useState("");
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const offcanvasStyle = {
    width: "50vw", // Set width to 70vw
  };
  const getMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

      if (response?.statusCode === 200) {
        setData(response?.data);
        console.log(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };
  useState(() => {
    getMerchantData();
  }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = data?.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
    }
  };

  const handleWithdrawAmountChange = (e) => {
    // Check if the input is a leading zero (e.g., "01" or "001")
    const sanitizedValue = e.target.value.replace(/^-|[^0-9]/g, "");
    if (/^0[0-9]*$/.test(sanitizedValue)) {
      // Remove the leading zero and update the state
      setWithdrawAmount(sanitizedValue.slice(1));
    } else {
      setWithdrawAmount(sanitizedValue);
    }
  };

  const handleUTRChange = (event) => {
    const value = event.target.value;
    //   console.log()
    // Define a regular expression pattern that allows only numbers
    const numberPattern = /^[A-Za-z0-9_-]*$/;

    if (numberPattern.test(value)) {
      setUtrNumber(value);
      setSecurityAmountError("");
    } else {
      setSecurityAmountError("UTR should only contain numbers.");
    }
  };
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;

    // Create a DateTime object from the input value
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    // Check if the input date is valid (i.e., it was successfully parsed)
    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setTransactionDate(inputDateTime.toISODate());
    }
  };
  const handleSubmit = () => {
    if (
      !utrNumber.length ||
      !mid.length ||
      !withdrawAmount.length ||
      !transactionDate.length ||
      !transferType.length
    ) {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
      });
      return;
    }

    let apiPayload = {
      utr: utrNumber,
      mid: mid,
      amount: withdrawAmount,
      transactionDate: transactionDate,
      transferType: transferType,
      virtualAccountType: "",
    };
    if (transferType === "Payout") {
      const selectedPayoutType = document.getElementById("payoutType").value;
      if (selectedPayoutType === "") {
        swal({
          title: "Warning",
          text: "Please select a Virtual Account Type for Payout.",
          icon: "warning",
        });
        return;
      }
      apiPayload.virtualAccountType = selectedPayoutType;
    }

    apiCallFnforPost(SAVE_WITHDRAW, apiPayload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Saved",
            text: "Data Saved Successfully.",
            icon: "success",
          });
          setMid("");
          setUtrNumber("");
          setWithdrawAmount("");
          setTransactionDate("");

          setSelectedCompany("");
          setTransferType("");
          onHide();
          setRefresh(!refresh);
        } else if (response.statusCode === 309) {
          swal({
            title: "Failed",
            text: "Insuffcient Wallet Balance",
            icon: "warning",
          });
        }
        console.log("Data saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleClose =()=>{
    onHide();
    setMid("");
    setUtrNumber("");
    setWithdrawAmount("");
    setTransactionDate("");

    setSelectedCompany("");
    setTransferType("");
    setRefresh(!refresh);
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
          keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton> </Offcanvas.Header>
          <h5 class="offcanvas-title fw-bold fs-2 text-dark" id="offcanvasLabel">
            Manual Upload
          </h5>
        </div>

        <Offcanvas.Body>
          {/* <div className="content-body"> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <div className="col-12">
                    {/* <div className="card-body"> */}
                      {/* <div className="containerflag"> */}
                      <div className="row mt-4">
                        <div className="col-sm-12 col-md-6 offset-md-3">
                          <label className="mb-1 text-dark fs-4">
                            Select Merchant *
                          </label>
                          <select
                            id="companyDropdown"
                            className="form-control "
                            onChange={handleCompanyChange}
                            value={selectedCompany}
                          >
                            <option value="">Select a Merchant</option>
                            {data?.map((merchant) => (
                              <option
                                key={merchant.mid}
                                value={`${merchant.mid} :- ${merchant.merchantName}`}
                              >
                                {merchant.mid} :- {merchant.merchantName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-6 offset-md-3">
                          <div className="form-group">
                            <label className="mb-1 text-dark fs-4">
                              Merchant ID
                            </label>
                            <input
                              required
                              type="email"
                              className="form-control "
                              id="email"
                              placeholder="Merchant ID"
                              value={mid}
                              readOnly
                            />
                          </div>

                          <div className="form-group">
                            <label className="mb-1 text-dark fs-4">
                              Transfer Type *
                            </label>
                            <select
                              required
                              className="form-control "
                              id="transferType"
                              value={transferType}
                              onChange={(e) => setTransferType(e.target.value)}
                            >
                              <option value="">Select Transfer Type</option>
                              <option value="Account">Account</option>
                              <option value="Payout">Payout</option>
                            </select>
                          </div>

                          {transferType === "Payout" && (
                            <div className="form-group">
                              <label className="mb-1 text-dark fs-4">
                                Virtual Account Type *
                              </label>
                              <select
                                required
                                className="form-control "
                                id="payoutType"
                              >
                                <option value="">Account Type</option>
                                <option value="UPI">UPI</option>
                                <option value="IMPS">IMPS</option>
                              </select>
                            </div>
                          )}
                          <div className="form-group">
                            <label className="mb-1 text-dark fs-4">
                              Withdraw Amount (in Rupees) *
                            </label>
                            <div className="d-flex gap-4">
                              <input
                                required
                                type="text"
                                className="form-control "
                                id="holdAmount"
                                placeholder="Withdraw Amount"
                                value={withdrawAmount}
                                onChange={handleWithdrawAmountChange}
                                inputMode="numeric"
                                pattern="[0-9]*"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="mb-1 text-dark fs-4">
                              UTR Number *
                            </label>
                            <div className="d-flex gap-4">
                              <input
                                required
                                type="text"
                                className="form-control "
                                id="securityAmount"
                                placeholder="UTR Number*"
                                value={utrNumber}
                                onChange={handleUTRChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="mb-1 text-dark fs-4">
                              Transaction Date*
                            </label>
                            <div className="d-flex gap-4">
                              <input
                                required
                                type="date"
                                className="form-control "
                                id="transactionDate"
                                placeholder="Transaction Date*"
                                value={transactionDate}
                                onChange={handleDateInputChange}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-center mt-3">
                            <button
                              className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManualUpload;
