import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useApiServices from "../../utils/useApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SUBMITTED_PHASE1_APPLICATIONS } from "../../utils/constant";
import ViewFile from "./ViewFile";
import CreateMerchantPhase1 from "./Create Merchant/CreateMerchantPhase1";
import CreateMerchantPhase2 from "./Create Merchant/CreateMerchantPhase2";
import Phase2Applications from "./Phase2Applications";
import Phase2Approved from "./Phase2Approved";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useCheckPrivilages from "../../utils/checkPrivilages";
import Phase1Approved from "./Phase1Approved";
import BankOnboarding from "./BankOnboarding";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import CUBTable from "./CUBTable";
import ViewDetailsMobileApp from "./ViewDetailsMobileApp";

export default function Phase1Applications({ type, toggleRightBar }) {
  const [viewApproved, setViewApproved] = useState(false);
  const [viewApprovedPhase2, setViewApprovedPhase2] = useState(false);
  const [applications, setApplications] = useState([]);
  const [showPaymentServices, setShowPaymentServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [openView, setOpenView] = useState(false);
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [mid, setMid] = useState("");
  const [status, setStatus] = useState("");
  const { apiCallFnforGet } = useApiServices();
  const [selectedForm, setSelectedForm] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onboardView, setOnboardView] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isModalCUB, setIsModalCUB] = useState(false);

  const handleCloseModal3 = () => {
    setIsModalCUB(false);
  };
  const handleShow = () => setIsModalCUB(true);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const hasPrilage2595 = useCheckPrivilages(2595);
  const hasPrivilage2630 = useCheckPrivilages(2630);

  const handleSelect = (registrationId) => {
    setShowPaymentServices((prevShowPaymentServices) => ({
      ...prevShowPaymentServices,
      [registrationId]: !prevShowPaymentServices[registrationId],
    }));
  };

  function getColorClass(stage) {
    switch (stage) {
      case "Approved":
        return "badge-success"; // Bootstrap primary color
      case "rejected":
        return "badge-danger"; // Bootstrap danger color
      case "Pending":
        return "badge-warning"; // Bootstrap warning color
      case "Submitted":
        return "badge-primary"; // Bootstrap info color
      default:
        return "badge-secondary"; // Default Bootstrap secondary color
    }
  }

  function capitalizeStatus(status) {
    if (!status) return "";
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  const fetchSubmittedApplications = async () => {
    try {
      const response = await apiCallFnforGet(SUBMITTED_PHASE1_APPLICATIONS);

      if (response.statusCode === 200) {
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          companyName: item.companyName,
          mid: item.mid,
          applicationStatus: (
            <button
              className={`badge badge-rounded mx-2 px-3 py-2 w-50 ${getColorClass(
                item.applicationStatus
              )}`}
              onClick={() =>
                item.applicationStatus &&
                handleViewDetailsPhase1(
                  item.registrationId,
                  item.applicationStatus
                )
              }
            >
              {capitalizeStatus(item.applicationStatus)}
            </button>
          ),
          stage2: (
            <button
              className={`badge badge-rounded mx-2 px-3 py-2 w-50 ${getColorClass(
                item.stage2
              )}`}
              onClick={() =>
                handleViewDetails(item.registrationId, item.stage2)
              }
            >
              {capitalizeStatus(item.stage2)}
            </button>
          ),
          bankonboarding:
            item.stage2 === "Approved" ? (
              <button
                style={{ backgroundColor: "MidnightBlue" }}
                className={`badge badge-rounded mx-2 px-3 py-2`}
                onClick={() => handleBankOnboard(item.registrationId, item.mid)}
              >
                Bank On-Board
              </button>
            ) : null,
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSubmittedApplications();
  }, [openView, viewApproved, viewApprovedPhase2, show, show2]);

  const handleViewDetails = (id, status) => {
    setId(id);
    setStatus(status);
    setViewApprovedPhase2(true);
  };

  const handleBankOnboard = (id, mid) => {
    setId(id);
    setMid(mid);
    setOnboardView(true);
  };

  const handleViewDetailsPhase1 = (id, status) => {
    setId(id);
    setStatus(status);
    setViewApproved(true);
  };

  const handleClose = () => {
    setId("");
    setSelectedServices({});
    setViewApproved(false);
  };

  const handleClosePhase1 = () => {
    setId("");
    setSelectedServices({});
    setViewApprovedPhase2(false);
  };

  const columns = [
    { headerName: "Company Name", field: "companyName", minWidth: 360 },
    { headerName: "Merchant ID", field: "mid", minWidth: 200 },
    {
      headerName: "Stage-1",
      field: "applicationStatus",
      minWidth: 180,
    },
    { headerName: "Stage-2", field: "stage2", minWidth: 180 },
    { headerName: "Bank Onboarding", field: "bankonboarding", minWidth: 180 },
  ];

  const handleClick = () => {
    setShow(true);
    handleClose1();
  };
  const handleClick2 = () => {
    setShow2(true);
    handleClose1();
  };
  const handleClose2 = () => setShow(false);
  const handleClose3 = () => setShow2(false);

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center me-2">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn-close m-3"
              onClick={toggleRightBar}
            ></button>
            <div className="fs-2 text-dark fw-bold">Applications</div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <CustomTip size="18" placement="top" title="CUB Onboarding">
              <div className="me-4" onClick={handleShow}>
                <PreviewIcon fontSize="large" />
              </div>
            </CustomTip>
            {hasPrivilage2630 && (
              <>
                <IconButton onClick={handleClick1}>
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose1}
                >
                  <MenuItem onClick={handleClick}> Phase 1</MenuItem>
                  <MenuItem onClick={handleClick2}> Phase 2</MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>

        <div className="mb-4">
          <Card.Body>
            {viewApproved ? (
              <Phase1Approved
                id={id}
                handleClose={handleClose}
                status={status}
              />
            ) : viewApprovedPhase2 ? (
              <Phase2Approved
                id={id}
                handleClose={handleClosePhase1}
                status={status}
              />
            ) : (
              <div style={{ height: "500px", width: "100%" }}>
                <DataGrid
                  rows={applications}
                  columns={columns?.map((column) => ({
                    ...column,
                    renderCell: (params) =>
                      column.field === "stage2" ||
                      column.field === "stage3" ||
                      column.field === "applicationStatus"
                        ? params.value
                        : params.value,
                  }))}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </div>
            )}
            {onboardView ? (
              <BankOnboarding
                id={id}
                mid={mid}
                onboardView={onboardView}
                setOnboardView={setOnboardView}
                // onboardView={onboardView}
              />
            ) : null}
          </Card.Body>
        </div>
      </div>

      <CreateMerchantPhase1
        show={show}
        onHide={handleClose2}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <CreateMerchantPhase2
        show={show2}
        onHide={handleClose3}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <CUBTable setIsModalCUB={setIsModalCUB} isModalCUB={isModalCUB} />
    </>
  );
}
