import axios from "axios";
import React, { useEffect, useState } from "react";
import { SAVE_DEPOSIT, SWIPELINC_API } from "../../utils/constant";
import swal from "sweetalert";
import useApiServices from "../../utils/useApiServices";
import EditIcon from "@mui/icons-material/Edit";

const DepositAmount = ({ data }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [securityAmountPer, setSecurityAmountPer] = useState("");
  const [holdAmountError, setHoldAmountError] = useState("");
  const [securityAmountError, setSecurityAmountError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    mid: "",
    mobile: "",
    fullName: "",
  });

  const [securityAmount, setSecurityAmount] = useState("");
  //  const [securityAmountPer , setSecurityAmountPer] = useState ('');
  const [changedAmount, setChangedAmount] = useState(false);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const Fetch_Hold_Amount = (mid) => {
    // setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}/MerchantAdminPanel/merchant/depositpercentage/getDepositPercentageByMid/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response.data);
          setSecurityAmountPer(
            response.data.data[0].depositPercentage === "null"
              ? "0"
              : response.data.data[0].depositPercentage
          );
        } else {
          console.error("Error fetching data:", response.data.message);
        }
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const Fetch_Deposit_Amount = (mid) => {
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/securitydeposit/getsecuirtydata/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setSecurityAmount(
            response.data.data === "null" ? "0" : response.data.data
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    setMerchantData(data);
  }, [data]);

  // useEffect(() => {
  //   axios
  //     .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`, {
  //       headers: {
  //         Authentication: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setMerchantData(response.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    if (selectedCompany === "") {
      setFormData({
        mid: "",
      });
      setSecurityAmountPer("");
      setSecurityAmount("");
      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );
    console.log(selectedMerchant);

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });
      Fetch_Hold_Amount(selectedMerchant.mid);
      Fetch_Deposit_Amount(selectedMerchant.mid);
      setChangedAmount("");
    }
  };

  const handleHoldAmountChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, "");
    setSecurityAmountPer(sanitizedValue);
  };

  const handleSecurityAmountChange = (e) => {
    // Check if the input is a leading zero (e.g., "01" or "001")
    const sanitizedValue = e.target.value.replace(/^-|[^0-9]/g, "");
    if (/^0[0-9]*$/.test(sanitizedValue)) {
      setSecurityAmount(sanitizedValue.slice(1));
    } else {
      setSecurityAmount(sanitizedValue);
    }
  };
  useEffect(() => {
    console.log(changedAmount);
  }, [changedAmount]);

  const handleSubmit = (changedAmount) => {
    if (holdAmountError || securityAmountError) {
      return;
    }

    const ApiPayload = (changedAmount) => {
      console.log(changedAmount);
      if (changedAmount === "depositpercentage") {
        return {
          mid: formData.mid,
          depositPercentage: securityAmountPer,
        };
      } else if (changedAmount === "securitydeposit") {
        return {
          mid: formData.mid,
          amount: securityAmount,
        };
      } else {
        console.log("not chnage");
      }
    };

    const newData = ApiPayload(changedAmount);
    console.log(newData);

    if (!formData.mid.length) {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
      });
      return;
    }
    // setShowLoader(true);
    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/${changedAmount}/save`,
        newData,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal({
            title: "Success",
            text: "Data Saved Successfully.",
            icon: "success",
          });
          setChangedAmount("");
          setFormData({
            mid: "",
          });
          setSecurityAmountPer("");
          setSecurityAmount("");
          setSelectedCompany("");
        } else if (response.data.statusCode === 202) {
          swal({
            title: "Failed",
            text: "Amount must be lesser than Wallet Balance.",
            icon: "warning",
          });
        } else {
          console.log(response.data.message);
          swal({
            title: "Failed",
            text: "Something went wrong.",
            icon: "error",
          });
        }
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <div className="containerflag">
      {/* <h1 className="mt-3">Select</h1> */}
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <label className="mb-1 text-dark fs-4">Select Merchant *</label>
          <select
            id="companyDropdown"
            className="form-control "
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Select a Merchant</option>
            {merchantData.map((merchant) => (
              <option
                key={merchant.mid}
                value={`${merchant.mid} :- ${merchant.merchantName}`}
              >
                {merchant.mid} :- {merchant.merchantName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <div className="form-group">
            <label className="mb-1 text-dark fs-4">Merchant ID</label>
            <input
              type="email"
              className="form-control "
              id="email"
              placeholder="Merchant ID"
              value={formData.mid}
              readOnly
            />
          </div>

          {/* <div className="form-group">
          <label className="mb-1 text-dark fs-4">
              Full Name</label>
            <input
              type="text"
              className="form-control "
              id="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              readOnly
            />
          </div> */}

          <div className="form-group">
            <label className="mb-1 text-dark fs-4">
              {" "}
              Deposit (in Percentage)
            </label>
            <div className="d-flex gap-4">
              <input
                type="text"
                className="form-control "
                id="depositpercentage"
                placeholder="Amount Percentage"
                value={securityAmountPer}
                onChange={handleHoldAmountChange}
                readOnly={changedAmount === "depositpercentage" ? false : true}
              />
              <div className="mt-3">
                {changedAmount === "depositpercentage" ? (
                  <button
                    class="badge badge-pill badge-primary"
                    onClick={() => handleSubmit(changedAmount)}
                  >
                    Submit
                  </button>
                ) : (
                  <div onClick={() => setChangedAmount("depositpercentage")}>
                    <EditIcon sx={{ fontSize: 16, color: "currentColor" }} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="mb-1 text-dark fs-4"> Deposit (in Rupees)</label>
            <div className="d-flex gap-4">
              <input
                type="text"
                className="form-control "
                id="securityAmount"
                placeholder="Amount"
                value={securityAmount}
                onChange={handleSecurityAmountChange}
                readOnly={changedAmount === "securitydeposit" ? false : true}
              />
              <div className="mt-3">
                {changedAmount === "securitydeposit" ? (
                  <button
                    class="badge badge-pill badge-primary"
                    onClick={() => handleSubmit(changedAmount)}
                  >
                    Submit
                  </button>
                ) : (
                  <div onClick={() => setChangedAmount("securitydeposit")}>
                    <EditIcon sx={{ fontSize: 16, color: "currentColor" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositAmount;
