import React, { useState } from "react";
import { toolIcons } from "../../Assets/images/image";
import styles from "../Reports/report.module.css";
import DropCredit from "./Merchant Management/Withdraw/DropCredit";
import SlideBox from "../../common/SlideBox/SlideBox";
import Details from "./Merchant Management/Details";
import PayoutSubmitted from "../OnBoarding/PayoutSubmitted";
import MDRPercentage from "./Merchant Management/MDRPercentage";
import HoldAndDeposite from "./HoldAndDeposite";
import FlagSubmitted from "./FlagSubmitted";
import PendingPayoutRequest from "./Merchant Management/PendingPayoutRequest";
import PendingVendorRequest from "./Merchant Management/PendingVendorRequest";
import UploadChargeback from "./Merchant Management/UploadChargeback";
import Reconciliation from "./Merchant Management/Reconciliation";
import WalletBalance from "./WalletBalance";
import SubmittedFraudManagement from "./Merchant Management/SubmittedFraudManagement";
import ToolsSubmittedPayinRouting from "./SubmittedPayinRouting";
import ToolsPayoutSubmitted from "./PayoutSubmitted";
import ToolsSubmittedRoutingEkyc from "./EkycRoutingSubmitted";
import CallBackPayout from "./CallBackPayout";
import PayoutApiTest from "./PayoutApiTest";
import RequestedWithdraw from "./Merchant Management/Withdraw/RequestedWithdraw";
import { useSelector } from "react-redux";
import CheckExportConst from "../../utils/CheckRxportConst";
import SubmittedUser from "./UserManagement/SubmittedUser";
import ShuffleRules from "./ShuffleRules";
import FrmRoutingTable from "./FrmRoutingTable";
import PayoutFrmRoutingTable from "./PayoutFrmRoutingTable";
import BBPSRouting from "./BBPSRouting";

const ToolsSection = () => {
  const [flag, setFlag] = useState(false);
  const { prefix, type } = useSelector((state) => state.privilages);
  console.log(type);
  const [report, setReport] = useState("");

  const {
    hasPrivilages2681,
    hasPrivilages2682,
    hasPrivilages2683,
    hasPrivilages2684,
    hasPrivilages2685,
    hasPrivilages2686,
    hasPrivilages2688,
    hasPrivilages2689,
    hasPrivilages2691,
    hasPrivilages2692,
    hasPrivilages2693,
    hasPrivilages2694,
    hasPrivilages2696,
    hasPrivilages2697,
    hasPrivilages2698,
    hasPrivilages2699,
    hasPrivilages2700,
    hasPrivilages2701,
    hasPrivilages2702,
    hasPrivilages2703,
    hasPrivilages2706,
    hasPrivilages2708,
    hasPrivilages2709,
    HasPrivilagesMerchantManagement,
  } = CheckExportConst();

  const reportType = {
    RequestedWithdraw: "RequestedWithdraw",
    MerchantDetails: "MerchantDetails",
    PayoutSubmitted: "PayoutSubmitted",
    MDR: "MDR",
    HoldAndDeposite: "HoldAndDeposite",
    FlagSubmitted: "FlagSubmitted",
    pendingpayout: "pendingpayout",
    pendingvendor: "pendingvendor",
    UploadChargeback: "UploadChargeback",
    Reconciliation: "Reconciliation",
    WalletBalance: "WalletBalance",
    FraudManagement: "FraudManagement",
    PayinRouting: "PayinRouting",
    PayoutSubmitted: "PayoutSubmitted",
    Ekyc: "Ekyc",
    callbackapitest: "callbackapitest",
    payoutApiTest: "payoutApiTest",
    SubmittedUser: "SubmittedUser",
    frmRoutingTable: "frmRoutingTable",
    payoutFrmRoutingTable: "payoutFrmRoutingTable",
    BBPSRouting: "BBPSRouting",
  };

  const toggleRightBar = (type) => {
    if (type) {
      setReport(type);
    }

    setFlag(!flag);
  };

  return (
    <div className="content-body">
      <div className="container-fluid ">
        <div class="card card-inverse card-primary text-center">
          <div class="card-body pb-1">
            <SlideBox
              flag={flag}
              toggleRightBar={toggleRightBar}
              type1={report}
            >
              {reportType[report] === "RequestedWithdraw" && (
                <RequestedWithdraw
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "MerchantDetails" && (
                <Details type1={report} toggleRightBar={toggleRightBar} />
              )}

              {reportType[report] === "SubmittedUser" && (
                <SubmittedUser type1={report} toggleRightBar={toggleRightBar} />
              )}

              {/* {reportType[report] === "PayoutSubmitted" && <ToolsPayoutSubmitted type1={report} toggleRightBar={toggleRightBar} />} */}

              {reportType[report] === "MDR" && (
                <MDRPercentage type1={report} toggleRightBar={toggleRightBar} />
              )}

              {reportType[report] === "HoldAndDeposite" && (
                <HoldAndDeposite
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "FlagSubmitted" && (
                <FlagSubmitted type1={report} toggleRightBar={toggleRightBar} />
              )}

              {reportType[report] === "pendingpayout" && (
                <PendingPayoutRequest
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "pendingvendor" && (
                <PendingVendorRequest
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "UploadChargeback" && (
                <UploadChargeback
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "Reconciliation" && (
                <Reconciliation
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "WalletBalance" && (
                <WalletBalance type1={report} toggleRightBar={toggleRightBar} />
              )}

              {reportType[report] === "FraudManagement" && (
                <SubmittedFraudManagement
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "PayinRouting" && (
                <ToolsSubmittedPayinRouting
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "PayoutSubmitted" && (
                <ToolsPayoutSubmitted
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "Ekyc" && (
                <ToolsSubmittedRoutingEkyc
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "callbackapitest" && (
                <CallBackPayout
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}

              {reportType[report] === "payoutApiTest" && (
                <PayoutApiTest type1={report} toggleRightBar={toggleRightBar} />
              )}
              {reportType[report] === "frmRoutingTable" && (
                <FrmRoutingTable
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "payoutFrmRoutingTable" && (
                <PayoutFrmRoutingTable
                  type1={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "BBPSRouting" && (
                <BBPSRouting type={report} toggleRightBar={toggleRightBar} />
              )}
            </SlideBox>

            <div class="row text-center text-lg-start">
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("MerchantDetails")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.MerchantDetails}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Merchant Details{" "}
                    </h5>
                  </div>
                </div>
              ) : null}

              {type === "user" ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("SubmittedUser")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.userManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      User Management
                    </h5>
                  </div>
                </div>
              ) : null}

              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("RequestedWithdraw")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.toolWithdraw}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Withdraw
                    </h5>
                  </div>
                </div>
              ) : null}

              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("MDR")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.MDR}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">MDR</h5>
                  </div>
                </div>
              ) : null}

              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("HoldAndDeposite")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.HoldDepo}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Hold and Deposit{" "}
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("FlagSubmitted")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.FlagManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Flag Management{" "}
                    </h5>
                  </div>
                </div>
              ) : null}

              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5  ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("pendingpayout")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.pendingpayout}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Pending Payout Request
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("pendingvendor")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.pendingvendor}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Pending Vendor Request
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("UploadChargeback")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.uploadChargeback}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Upload Chargeback
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Reconciliation")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.reconciliationTools}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Reconciliation{" "}
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("WalletBalance")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.Wallet}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Wallet Balance
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("PayinRouting")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.PayinRouting}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Pay-in Routing{" "}
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("PayoutSubmitted")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.toolPayout}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payout-Routing{" "}
                    </h5>
                  </div>
                </div>
              ) : null}
              {HasPrivilagesMerchantManagement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Ekyc")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.eKYC}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      e-KYC Routing{" "}
                    </h5>
                  </div>
                </div>
              ) : null}
              {hasPrivilages2703 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("FraudManagement")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.FraudManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Fraud Management{" "}
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2703 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("callbackapitest")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.callbackapitest}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      CallBack API Test{" "}
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2702 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("payoutApiTest")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.payoutApiTest}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payout API Test{" "}
                    </h5>
                  </div>
                </div>
              ) : null}

              <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("frmRoutingTable")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={toolIcons.routingRules}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    FRM Routing Rules{" "}
                  </h5>
                </div>
              </div>
              {/* <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("payoutFrmRoutingTable")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={toolIcons.routingRules}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Payout FRM Routing Rules{" "}
                  </h5>
                </div>
              </div> */}

              {/* {hasPrivilages2702 ? ( */}
              <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("BBPSRouting")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={toolIcons.BBPSMaster}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    BBPS Routing{" "}
                  </h5>
                </div>
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsSection;
