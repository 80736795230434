import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import PreviewIcon from "@mui/icons-material/Preview";
import RouteIcon from "@mui/icons-material/Route";
import swal from "sweetalert";
import {
  GET_ALL_ROUTING_VIEW,
  GET_ALL_INITIAL_SID,
  GET_DETAILS,
  ENABLE_DISABLE_PAYOUT_ROUTING,
  UPDATE_ALL_DATA_ADMIN_,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import useCheckPrivilages from "../../utils/checkPrivilages";
import ViewMappedSID from "./ViewMappedSid";
import RoutingPayinOnBoarding from "./PayinRouting";

const SubmittedRoutingPayin = ({ showEditButton, type, toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [mappedMerchant, setMappedMerchant] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isModalViewMappedOpen, setIsModalViewMappedOpen] = useState(false);
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [SidData, setSidData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [checkTxnLimit, setCheckTxnLimit] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState([]);
  const [midToSearch, setMidToSearch] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [priority, setPriority] = useState([]);
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  const hasPrivilages2634 = useCheckPrivilages(2634);

  const handleCloseModal2 = () => {
    setIsModal2Open(false);
  };

  const handleCloseModal3 = () => {
    setIsModalViewMappedOpen(false);
  };

  const handleShow = () => setIsModalViewMappedOpen(true);

  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const handleShowRouting = () => setIsModalRoutingOpen(true);

  const showRoutingType = () => {
    if (selectedEditData?.routingType === "RR") return false;
    else return true;
  };

  const columns = [
    { headerName: "Merchant ID", field: "mid", minWidth: 400 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 400 },
    { headerName: "Action", field: "action", minWidth: 100 },
  ];

  const columnsForEditDetails = showRoutingType()
    ? [
        { headerName: "Merchant ID", field: "mid", minWidth: 200 },
        { headerName: "Merchant Name", field: "companyName", minWidth: 200 },
        { headerName: "SID", field: "sid", minWidth: 200 },
        { headerName: "Domain", field: "domain", minWidth: 200 },
        {
          headerName: "SID Company Name",
          field: "sidCompanyName",
          minWidth: 200,
        },
        {
          headerName: "Remaining SID Limit",
          field: "sidRemainigLimit",
          minWidth: 200,
        },
        { headerName: "Priority", field: "prority", minWidth: 200 },
        {
          headerName: "Maximum Transaction Amount",
          field: "sidLimit",
          minWidth: 200,
        },
        { headerName: "Action", field: "action", minWidth: 200 },
      ]
    : [
        { headerName: "Merchant ID", field: "mid", minWidth: 200 },
        { headerName: "Merchant Name", field: "companyName", minWidth: 200 },
        { headerName: "SID", field: "sid", minWidth: 200 },
        { headerName: "Domain", field: "domain", minWidth: 200 },
        {
          headerName: "SID Company Name",
          field: "sidCompanyName",
          minWidth: 200,
        },
        {
          headerName: "Remaining SID Limit",
          field: "sidRemainigLimit",
          minWidth: 200,
        },
        {
          headerName: "Maximum Transaction Amount",
          field: "sidLimit",
          minWidth: 200,
        },
        { headerName: "Action", field: "action", minWidth: 200 },
      ];

  const columnsForDetails = [
    { headerName: "Merchant ID", field: "mid", minWidth: 200 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 200 },
    { headerName: "SID", field: "sid", minWidth: 200 },
    { headerName: "Domain", field: "domain", minWidth: 200 },
    { headerName: "SID Company Name", field: "sidCompanyName", minWidth: 200 },
    {
      headerName: "Maximum Transaction Amount",
      field: "sidLimit",
      minWidth: 200,
    },
  ];

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <span>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </span>
    );

    if (mappedItem.status === true) {
      mappedItem.action = hasPrivilages2634 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          {showEditButton && (
            <button
              className="badge badge-rounded badge-warning"
              onClick={() => {
                openDetails(
                  mappedItem.mid,
                  mappedItem.companyName,
                  mappedItem.sid,
                  item.sidLimit,
                  item.id,
                  true,
                  item.routingType,
                  item.prority,
                  item.domain
                );
                setSelectedEditData(mappedItem);
                Details(item.mid, "edit");
              }}
            >
              Edit
            </button>
          )}

          <button
            className="badge badge-rounded badge-warning mt-2"
            onClick={() => Details(item.mid, "details")}
          >
            View
          </button>
        </div>
      ) : null;
    }

    if (mappedItem.status === false) {
      mappedItem.action = hasPrivilages2634 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          {showEditButton && (
            <button
              className="badge-rounded badge-warning"
              onClick={() => {
                openDetails(
                  mappedItem.mid,
                  mappedItem.companyName,
                  mappedItem.sid,
                  item.sidLimit,
                  item.id,
                  false,
                  item.routingType,
                  item.prority
                );
                Details(item.mid, "edit");
              }}
            >
              Edit
            </button>
          )}
          <button
            className="badge badge-rounded badge-warning"
            onClick={() => Details(item.mid, "details")}
          >
            View
          </button>
        </div>
      ) : null;
    }

    return mappedItem;
  });

  const handelEditMaxTxnAmtInput = (id, e) => {
    let typedData = editFormData?.filter((elem) => {
      return elem.id === id;
    });
    typedData[0].sidLimit = e.target.value;

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const handelEditPrority = (id, e) => {
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });

    typedData[0].prority = parseInt(e.target.value);

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const handelEditRoutingType = (id, e) => {
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });

    typedData[0].routingType = e.target.value;

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const editMappedData = mappedMerchant?.map((item, index) => {
    const mappedItem = { ...item };
    mappedItem.sidLimit = (
      <input
        // readOnly={true}
        className="form-control"
        name={item.id}
        type="number"
        onChange={(e) => handelEditMaxTxnAmtInput(item.id, e)}
        value={mappedItem.sidLimit}
      />
    );

    mappedItem.prority = (
      <div>
        <select
          className="form-control"
          value={mappedItem.prority}
          onChange={(e) => handelEditPrority(item.id, e)}
        >
          <option value="0">Select</option>

          {priority?.map((item, index) => {
            return <option>{index + 1}</option>;
          })}
        </select>
      </div>
    );

    if (mappedItem.status === true) {
      mappedItem.action = hasPrivilages2634 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          <button
            className="btn btn-danger btn-sm"
            onClick={() => EnbDsb(item.id)}
          >
            Disable
          </button>
        </div>
      ) : null;
    }

    if (mappedItem.status === false) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          <button
            className="btn btn-success btn-sm"
            onClick={() => EnbDsb(item.id)}
          >
            Enable
          </button>
        </div>
      );
    }

    return mappedItem;
  });

  const getAllInitialSID = async () => {
    await apiCallFnforGet(GET_ALL_INITIAL_SID)
      .then((response) => {
        if (response.statusCode === 200) {
          setSidData(response?.data);
          let res = response?.data?.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "" };
          });

          let selectedData = response.data.filter(
            (elem) => editFormData.sid === elem.sid
          );
          setCheckTxnLimit(selectedData[0]);
          setSidData(res);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const Details = async (mid, openModel) => {
    setMidToSearch(mid);

    try {
      const response = await apiCallFnforGet(GET_DETAILS + mid);

      if (response.statusCode === 200) {
        setMappedMerchant(response?.data);
        setEditFormData(response?.data);
        setPriority(response?.data);
      } else {
        swal({
          title: "Alert",
          text: "No data found",
          icon: "warning",
        });
        setIsModal2Open(false);
      }

      if (openModel === "edit") setIsModalOpen(true);
      else setIsModal2Open(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openDetails = (
    mid,
    companyName,
    sid,
    sidLimit,
    id,
    status,
    routingType,
    prority,
    domain
  ) => {
    setEditFormData({
      sidLimit: sidLimit,
      mid,
      merchantName: companyName,
      sid,
      routingType: routingType,
      id,
      status,
      prority: parseInt(prority),
      prority: parseInt(prority),
      domain: domain,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const EnbDsb = async (e, id) => {
    const EnableOrDisable = e.target.value;
    swal({
      title: "Alert",
      text: `Are you sure, you want to ${EnableOrDisable}?`,
      icon: "Warning",
      buttons: "true",
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(
          `${ENABLE_DISABLE_PAYOUT_ROUTING}${EnableOrDisable}/${id}`
        ).then((res) => {
          if (res.statusCode === 200) {
            swal({
              title: "Success!",
              text: `Payout Routing ${EnableOrDisable}successfully`,
              icon: "success",
            });
          } else {
            swal({
              title: "Failed",
              text: `Failed to  ${EnableOrDisable}`,
              icon: "Error",
            });
          }
        });
      }
    });
  };

  function areArrayValuesUnique(editFormData) {
    if (selectedEditData?.routingType === "SQ") {
      // Check if routing pattern is "Sequential"
      const PriorityArr = editFormData?.map((item) => {
        return item?.prority;
      });

      // Use a Set to track unique values
      const uniqueSet = new Set(PriorityArr);

      // If the size of the Set is equal to the length of the array, all values are unique
      return uniqueSet.size === PriorityArr?.length;
    }
    // If routing pattern is not "Sequential," return true to bypass this check
    return true;
  }

  function IsAllPrioritiesSelect(editFormData) {
    if (selectedEditData?.routingType === "SQ") {
      const PriorityArr = editFormData?.map((item) => {
        return item?.prority;
      });

      return PriorityArr.includes(0);
    }
    // If routing pattern is not "Sequential," return false to bypass this check
    return false;
  }

  function IsRoutingPatternSelect(editFormData) {
    const RoutingArr = editFormData?.map((item) => {
      return item?.routingType;
    });
    return RoutingArr.includes("");
  }

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    const IsPrioritiesUnique = areArrayValuesUnique(editFormData);
    const IsPrioritiesSelected = IsAllPrioritiesSelect(editFormData);
    const IsRoutingPatternSelected = IsRoutingPatternSelect(editFormData);

    if (!IsPrioritiesUnique) {
      swal({
        title: "ALERT!",
        text: "Priorities Cannot Be Same.",
        icon: "error",
      });
      return;
    }
    if (IsPrioritiesSelected) {
      swal({
        title: "ALERT!",
        text: "Please Select Priorities.",
        icon: "error",
      });
      return;
    }
    if (IsRoutingPatternSelected) {
      swal({
        title: "ALERT!",
        text: "Please Select Routing Pattern.",
        icon: "error",
      });
      return;
    }
    const payload = editFormData?.map((elem) => {
      return {
        sidLimit: elem.sidLimit,
        mid: elem.mid,
        sid: elem.sid,
        routingType: selectedEditData.routingType,
        processor: "",
        prority: elem.prority,
        id: elem.id,
        status: elem.status,
        flag: elem.flag,
        domain: elem.domain,
      };
    });

    handleCloseModal();
    apiCallFnforPut(UPDATE_ALL_DATA_ADMIN_)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.message,
            icon: "success",
          });

          handleCloseModal();
          FetchData(token);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {});
  };

  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(GET_ALL_ROUTING_VIEW);

      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
      }
    } catch (error) {}
  };

  const handelRoutingChange = (e) => {
    setSelectedEditData({ ...selectedEditData, routingType: e.target.value });
  };

  useEffect(() => {
    FetchData();
  }, [isModalRoutingOpen]);

  useEffect(() => {
    getAllInitialSID();
  }, [editFormData.id]);

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center me-3">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Payin</div>
          </div>

          <div className="d-flex align-items-center mt-3">
            <CustomTip size="18" placement="top" title=" Routing">
              <div className="me-4 " onClick={handleShowRouting}>
                <RouteIcon fontSize="large" />
              </div>
            </CustomTip>
            <CustomTip size="18" placement="top" title="  View Mapped SID">
              <div className="me-4" onClick={handleShow}>
                <PreviewIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>
        <div>
          <DataGrid
            rows={mappedData}
            columns={columns.map((column) => ({
              ...column,
              renderCell: (params) =>
                column.field === "active" || column.field === "action"
                  ? params.value
                  : params.value,
            }))}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      </div>
      {/* </div> */}

      {/* EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold"> Edit :</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <div className="col-sm-12 col-md-6 mt-2">
              <label htmlFor="email">Routing Pattern</label>
              <select
                id=""
                className="form-control"
                onChange={handelRoutingChange}
                value={selectedEditData?.routingType}
                name="routingPattern"
              >
                <option value="">Select a Pattern</option>
                <option value="RR">Round Robin</option>
                <option value="SQ">Sequential</option>
              </select>
            </div>

            <div className="merchant-report-view">
              <div className="report-table-view">
                <div className="containerflag ">
                  <div className="row mt-4 ">
                    <DataGrid
                      rows={editMappedData}
                      columns={columnsForEditDetails}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-success"
                      onClick={handleUpdateSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          
        </Modal.Body>
      </Modal>
      <Modal show={isModal2Open} onHide={handleCloseModal2} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold"> Details:-</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <div className="">
            <div className="">
              <div className=" ">
                <div className="">
                  <DataGrid
                    rows={mappedMerchant}
                    columns={columnsForDetails}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-3"></div>
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>

      {/* Modal for view mapped SID */}

      <Modal show={isModalViewMappedOpen} onHide={handleCloseModal3} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">View Mapped SID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <ViewMappedSID />
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>

      {/* Modal Routing */}

     
            <RoutingPayinOnBoarding 
            show={isModalRoutingOpen}
        onHide={handleCloseModalRouting} />
          
    </>
  );
};

export default SubmittedRoutingPayin;
