import React, { useState, useEffect } from "react";
import { Card, Col, Row, Offcanvas } from "react-bootstrap";
import useApiServices from "../../utils/useApiServices";
import { GET_ALL_PRIVILAGES, AGGREGATOR_SAVE } from "../../utils/constant";
import swal from "sweetalert";
// import { useDispatch } from "react-redux";

import { setAllPrivilagesData } from "../../store/slices/privilagesSlice";
import { isEmailIdRegexIsValid, getKeyPressed, isMobileNumberValid } from "../../utils/helper";
import MyLoader from "../../common/MyLoader";
import { useDispatch } from "react-redux";
import { Refresh } from "@mui/icons-material";


const offcanvasStyle = {
  width: '90vw', // Set width to 70vw
};

const CreateMasterMerchant = ({show, onHide,setRefresh,refresh}) => {
  const [showLoader, setShowLoader] = useState(false);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES);

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        dispatch(setAllPrivilagesData(response?.data));
        console.log(response.data);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  function setNumericInput(input) {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(input) ? input : "";
  }
  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      if (name === "Prifix") {
        value = value
          .slice(0, 2)
          .toUpperCase()
          .replace(/[^A-Z]/g, "");
      } else if (name === "MobileNumber") {
        value = value.replace(/\D/g, "");
      } else {
        value = value.toUpperCase();
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    const head = e.target.dataset.head + "";
    console.log(head);

    // setHeadPrivilages((prevPrivilages) => {
    //   if (!e.target.checked) {
    //     // If any sub-privilege is unchecked, remove the head privilege
    //     return prevPrivilages.filter((id) => id !== head);
    //   }
    //   return prevPrivilages;
    // });

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = e.target.name;
    console.log(e.target.checked);

    const subtypeArray = privilagesData?.find((item) => {
      return String(item.adminPrivilegeId) === adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.adminPrivilegeSubTypeId + "";
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev?.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };
  useEffect(() => {
    console.log(privilages);
  }, [privilages]);

  function convertCamelCaseToSentence(inputString) {
    const spacedString = inputString
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
      .replace(/([a-zA-Z])([0-9])/g, "$1 $2")
      .replace(/([A-Z])(?=[A-Z])/g, "$1 ");

    // Capitalize the first letter of the result
    const capitalizedString =
      spacedString.charAt(0).toUpperCase() + spacedString.slice(1);

    return capitalizedString;
  }

  const handleSubmit = async (e) => {
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });

    const payload = {
      name: formDataInputs.AggregatorName.trim(),
      password: formDataInputs.password.trim(),
      mobile: formDataInputs.MobileNumber.trim(),
      midprifix: formDataInputs.Prifix.trim(),
      email: formDataInputs.emailId.trim(),
      adminPrivilege: privilegs,
    };

    if (formDataInputs.AggregatorName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Merchant Name",
        icon: "error",
      });
      return;
    }
    // if (formDataInputs.MobileNumber === "") {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Mobile Number",
    //     icon: "error",
    //   });
    //   return;
    // }

    if (!isMobileNumberValid(formDataInputs.MobileNumber)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Mobile Number",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs, MobileNumber: "" });
      });
      return;
    }
    if (formDataInputs.Prifix === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Prifix",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter password",
        icon: "error",
      });
      return;
    }

    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }

    if (privilages.length === 0) {
      swal({
        title: "Alert!",
        text: "Please select at least one privilege",
        icon: "error",
      });
      return;
    }
    console.log(payload);

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      setShowLoader(true);
      const res = await apiCallFnforPost(AGGREGATOR_SAVE, payload);
      setShowLoader(false);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "Aggregator Saved Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          AggregatorName: "",
          password: "",
          MobileNumber: "",
          Prifix: "",
          emailId: "",
        });
        setPrivilages([]);
        setPrivilagesData([]);
        getAllPrivilages();
        onHide();
        setRefresh(!refresh);

      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };
  const handleClose = () => {
    setFormDataInputs({
      AggregatorName: "",
      password: "",
      MobileNumber: "",
      Prifix: "",
      emailId: "",
    });
    setPrivilages([]);
    setPrivilagesData([]);
    getAllPrivilages();
    onHide();
    setRefresh(!refresh);
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}

      {/* new */}

      <Offcanvas show={show} onHide={handleClose} placement="end" style={offcanvasStyle}>

   <div className="d-flex justify-content-start align-items-center mt-3" style={{marginLeft:"28px"}}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            Create Master Merchant
          </h5>
 </div>
    
    
    <Offcanvas.Body >
    {/* new end */}

      {/* <div class="content-body"> */}
        {/* <div class="container-fluid"> */}
          {/* <div class="row page-titles">
            <o l class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">Create Master Merchant</a>
              </li>
            </o>
          </div> */}
          <div class="row">
            <div class="col-12">
              {/* <div className="card input-box-shadow"> */}
                <div className="card-header">
                  <h4 className="card-title">Fill Master Merchant Details</h4>
                </div>
                <div className="card-body py-4">
                  <div id="" className="form-wizard order-create">
                    <div id="" className="tab-pane" role="tabpanel">
                      <form className="form-section-form-group">
                        <div className="row">
                          {/* {merchantData && typeof merchantData === "object" ? (
                          keyOrder.map((key, index) => (
                            <div className="col-lg-6 mb-2" key={index}>
                              <div className="mb-3">
                                <label className="mb-1 text-dark">
                                  {keyLabels[key]}
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  value={merchantData[key] || ""}
                                  readOnly
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>Loading...</p>
                        )} */}
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark fs-4">
                                Master Merchant Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                name="AggregatorName"
                                value={formDataInputs.AggregatorName}
                                onChange={handleformDataInputs}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark fs-4">
                                e-Mail Id
                              </label>

                              <input
                                type="email"
                                className="form-control"
                                name="emailId"
                                value={formDataInputs.emailId}
                                onChange={handleformDataInputs}
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onBlur={handleValidations}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark fs-4">
                                {" "}
                                Merchant Mobile Number
                              </label>

                              <input
                                className="form-control"
                                maxLength={10}
                                minLength={10}
                                name="MobileNumber"
                                value={formDataInputs.MobileNumber}
                                onChange={handleformDataInputs}
                                required
                                pattern="[0-9]{10}"
                                title="Please enter a 10-digit mobile number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark fs-4"> Prefix</label>

                              <input
                               className="form-control"
                                name="Prifix"
                                value={formDataInputs.Prifix}
                                onChange={handleformDataInputs}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark fs-4">Password</label>

                              <input
                               className="form-control"
                                name="password"
                                value={formDataInputs.password}
                                onChange={handleformDataInputs}
                              />
                            </div>
                          </div>

                          <div className="card-header">
                            <h4 className="card-title">Privilege</h4>
                          </div>
                          <Row className="mt-3">
                            {/* <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                              Privilages
                            </lable> */}
                            <div className="d-flex flex-column gap-4">
                              {privilagesData &&
                                privilagesData.map((itemMain, i) => (
                                 
                                  <div key={i} className="d-flex flex-column">
                                    <div className="d-flex justify-content-start align-items-center gap-2">
                                    
                                      <div class="checkbox-wrapper-31">
                                        <input
                                          type="checkbox"
                                          name={itemMain.adminPrivilegeId}
                                          value={HeadPrivilages.includes(
                                            itemMain.adminPrivilegeId + ""
                                          )}
                                          onChange={handleHeadPrivilages}
                                        />
                                        <svg viewBox="0 0 35.6 35.6">
                                          <circle
                                            class="background"
                                            cx="17.8"
                                            cy="17.8"
                                            r="17.8"
                                          ></circle>
                                          <circle
                                            class="stroke"
                                            cx="17.8"
                                            cy="17.8"
                                            r="14.37"
                                          ></circle>
                                          <polyline
                                            class="check"
                                            points="11.78 18.12 15.55 22.23 25.17 12.87"
                                          ></polyline>
                                        </svg>
                                      </div>
                                      <h1 className="pt-2">
                                        {itemMain.privilegeName}
                                      </h1>
                                    </div>
                                    {/* </Card.Header> */}
                                    <div className="row fs-4 mt-2 text-dark">
                                      {itemMain.subTypes.map((item, i) => (
                                        <div
                                          key={i}
                                          class="checkbox-wrapper-27 col-lg-4 col-md-6 col-sm-12 d-flex "
                                        >
                                          <label class="checkbox">
                                            <input
                                              id="flexSwitchCheckDefault"
                                              type="checkbox"
                                              data-head={
                                                itemMain.adminPrivilegeId
                                              }
                                              name={
                                                item.adminPrivilegeSubTypeId
                                              }
                                              checked={privilages.includes(
                                                item.adminPrivilegeSubTypeId +
                                                  ""
                                              )}
                                              onChange={handlePrivilages}
                                            />
                                            <span class="checkbox__icon"></span>
                                            {item.subTypePrivilegeName}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </Row>
                          <div className="d-flex justify-content-end mt-4">
                            <button
                            className="badge badge-rounded badge-primary mx-2 px-3 py-3 "
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        {/* </div> */}
      {/* </div> */}
      </Offcanvas.Body>
  </Offcanvas>
    </>
  );
};

export default CreateMasterMerchant;
