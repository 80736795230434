import React, { useEffect, useState } from "react";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  CHECK_CALLBACK_STATUS,
  FETCH_ONBOARD_MERCHANT_DETAILS,
  FETCH_PAYMENT_SERVICES_PHASE1_API,
  UPDATE_BANK_DETAILS_STAGE2,
  UPDATE_ONBOARD_MERCHANT_DETAILS,
} from "../../../utils/constant";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import swal from "sweetalert";
import {
  getKeyPressed,
  isIfscRegexIsValid,
  isWebsiteURLRegexIsValid,
  stopAlphabetInput,
  stopCharacterInput,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../../../utils/helper";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import { useSelector } from "react-redux";
import useApiServices from "../../../utils/useApiServices";
import RemoveIcon from '@mui/icons-material/Remove';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

function OpenDetails({ mid, handleClose }) {
  const [merchantData, setmerchantData] = useState([]);
  const [getAgain, setGetAgain] = useState(false);
  const [again, setAgain] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [updatedDetails, setUpdatedDetails] = useState({ ...merchantData });
  const [showLoader, setShowLoader] = useState(false);
  const [isPhase2Editing, setIsPhase2Editing] = useState(false);
  const [showPaymentServicesList, setShowPaymentServicesList] = useState(false);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [paymentservice, setServices] = useState([]);
  const [phase2Details, setPhase2Details] = useState({ ...merchantData });
  const [payinCallbackStatus, setPayinCallbackStatus] = useState(false)
  const [chargebackCallbackStatus, setChargebackCallbackStatus] = useState(false)
  const [payoutCallbackStatus, setPayoutCallbackStatus] = useState(false)
  const { apiCallFnforGet, apiCallFnforPut, apiCallFnforPost } = useApiServices();

  useEffect(() => {
    fetchmerchantDetails(mid);
  }, [getAgain, again, mid]);

  const hasPrivilages2682 = useCheckPrivilages(2682);

  const reduxData = useSelector((state) => state?.privilages);

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const addressFieldLength = 200;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    // console.log(updatedDetails.companyAddress.length + pastedText.length);

    if (
      (name === "companyAddress" &&
        updatedDetails &&
        updatedDetails.companyAddress &&
        updatedDetails.companyAddress.length + pastedText.length >
        addressFieldLength) ||
      (name === "registeredOfficeAddress" &&
        updatedDetails &&
        updatedDetails.registeredOfficeAddress &&
        updatedDetails.registeredOfficeAddress.length + pastedText.length >
        addressFieldLength) ||
      (name === "communicationAddress" &&
        updatedDetails &&
        updatedDetails.communicationAddress &&
        updatedDetails.communicationAddress.length + pastedText.length >
        addressFieldLength)
    ) {
      swal({
        title: "Alert!",
        text: `Address must be lesser than ${addressFieldLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyAddressState" &&
        updatedDetails &&
        updatedDetails.companyAddressState &&
        updatedDetails.companyAddressState.length + pastedText.length >
        StateLength) ||
      (name === "registeredOfficeAddressState" &&
        updatedDetails &&
        updatedDetails.registeredOfficeAddressState &&
        updatedDetails.registeredOfficeAddressState.length + pastedText.length >
        StateLength) ||
      (name === "communicationAddressState" &&
        updatedDetails &&
        updatedDetails.communicationAddressState &&
        updatedDetails.communicationAddressState.length + pastedText.length >
        StateLength)
    ) {
      swal({
        title: "Alert!",
        text: `State must be lesser than ${StateLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyAddressCity" &&
        updatedDetails &&
        updatedDetails.companyAddressCity &&
        updatedDetails.companyAddressCity.length + pastedText.length >
        cityLength) ||
      (name === "registeredOfficeAddressCity" &&
        updatedDetails &&
        updatedDetails.registeredOfficeAddressCity &&
        updatedDetails.registeredOfficeAddressCity.length + pastedText.length >
        cityLength) ||
      (name === "communicationAddressCity" &&
        updatedDetails &&
        updatedDetails.communicationAddressCity &&
        updatedDetails.communicationAddressCity.length + pastedText.length >
        cityLength)
    ) {
      swal({
        title: "Alert!",
        text: `City must be lesser than ${cityLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  const fetchmerchantDetails = async (mid) => {
    try {
      const response = await apiCallFnforGet(
        FETCH_ONBOARD_MERCHANT_DETAILS + mid
      );

      if (response.statusCode === 200) {
        setmerchantData(response?.data);
        const uniqueServices = Array.from(
          new Set(
            response?.data?.paymentService?.map(
              (service) => service.paymentService
            )
          )
        );
        setSelectedServices(uniqueServices);
      } else if (response.statusCode === 304) {
        swal({
          title: "Failed",
          text: "Merchant not on-Boarded",
          icon: "error",
        });
      } else {
        swal({
          title: "Failed",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
  };

  useEffect(() => {
    apiCallFnforGet(FETCH_PAYMENT_SERVICES_PHASE1_API)
      .then((response) => {
        setServices(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching payment services:", error.message);
      });
  }, []);

  const handleServiceChange = (e) => {
    const found = selectedServices?.find((item) => item === e.target.value);
    if (found) {
      setSelectedServices(selectedServices?.filter((item) => item !== found));
    } else {
      setSelectedServices([...selectedServices, e.target.value]);
    }
  };
  const toggleEditing = () => {
    if (!isEditing) {
      const paymentTypeIds = selectedServices
        ?.map((service) => {
          const foundService = paymentservice?.find(
            (item) => item.paymentService === service
          );
          return foundService ? foundService.paymentTypeId : null;
        })
        .filter((id) => id !== null);

      const updatedDetailsCopy = { ...updatedDetails };
      updatedDetailsCopy.paymentTypeId = paymentTypeIds;
      setUpdatedDetails({
        authorizedPersonName: merchantData.authorizedPersonName,
        aapUrl: merchantData.aapUrl,
        businessTypeName: merchantData.businessTypeName,
        businessAs: merchantData.businessAs,
        commissionPercentage: 0,
        communicationAddress: merchantData.communicationAddress,
        communicationAddressAreaName: merchantData.communicationAddressAreaName,
        communicationAddressCity: merchantData.communicationAddressCity,
        communicationAddressPinCode: merchantData.communicationAddressPinCode,
        communicationAddressState: merchantData.communicationAddressState,
        companyAddress: merchantData.companyAddress,
        companyAddressAreaName: merchantData.companyAddressAreaName,
        companyAddressCity: merchantData.companyAddressCity,
        companyAddressPinCode: merchantData.companyAddressPinCode,
        companyAddressState: merchantData.companyAddressState,
        email: merchantData.email,
        mobile: merchantData.mobile,
        newCompanyAddress: merchantData.newCompanyAddress,
        registeredOfficeAddress: merchantData.registeredOfficeAddress,
        registeredOfficeAddressAreaName:
          merchantData.registeredOfficeAddressAreaName,
        registeredOfficeAddressCity: merchantData.registeredOfficeAddressCity,
        registeredOfficeAddressPinCode:
          merchantData.registeredOfficeAddressPinCode,
        registeredOfficeAddressState: merchantData.registeredOfficeAddressState,
        registrationId: merchantData.registrationId,
        websiteUrl: merchantData.websiteUrl,
        paymentTypeId: paymentTypeIds,
      });
    }
    setIsEditing(!isEditing);
    setIsPhase2Editing(false);
  };

  const handleSave = async () => {
    if (
      updatedDetails.authorizedPersonName === "" ||
      phase2Details.authorizedPersonName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorised person name !",
        icon: "error",
      });
      return;
    }
    if (updatedDetails.mobile === "" || phase2Details.mobile === null) {
      swal({
        title: "Alert!",
        text: "Please enter mobile number !",
        icon: "error",
      });
      return;
    }
    if (
      merchantData.businessType?.toLowerCase() === "others" &&
      updatedDetails.businessTypeName === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please enter other business category !",
        icon: "error",
      });
      return;
    }

    if (updatedDetails.email === "" || phase2Details.email === null) {
      swal({
        title: "Alert!",
        text: "Please enter email ID !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddress === "" ||
      phase2Details.companyAddress === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered address !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddressPinCode === "" ||
      phase2Details.companyAddressPinCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered pin-code !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddressCity === "" ||
      phase2Details.companyAddressCity === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered city !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddressState === "" ||
      phase2Details.companyAddressState === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered state !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddress === "" ||
      phase2Details.registeredOfficeAddress === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory address !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddressPinCode === "" ||
      phase2Details.registeredOfficeAddressPinCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory pincode !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddressCity === "" ||
      phase2Details.registeredOfficeAddressCity === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory city !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddressState === "" ||
      phase2Details.registeredOfficeAddressState === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory state !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddress === "" ||
      phase2Details.communicationAddress === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication address !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddressPinCode === "" ||
      phase2Details.communicationAddressPinCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication pin code !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddressCity === "" ||
      phase2Details.communicationAddressCity === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication city !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddressState === "" ||
      phase2Details.communicationAddressState === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication state !",
        icon: "error",
      });
      return;
    }

    try {
      setShowLoader(true);
      const paymentTypeIds = selectedServices?.map((service) => {
        const foundService = paymentservice?.find(
          (item) => item.paymentService === service
        );
        return foundService.paymentTypeId;
      });

      const updatedDetailsCopy = { ...updatedDetails };
      updatedDetailsCopy.paymentTypeId = paymentTypeIds;

      const payload = {
        authorizedPersonName: updatedDetails.authorizedPersonName,
        aapUrl: updatedDetails.aapUrl,
        businessTypeName: updatedDetails.businessTypeName,
        businessAs: updatedDetails.businessAs,
        commissionPercentage: 0,
        communicationAddress: updatedDetails.communicationAddress,
        communicationAddressAreaName:
          updatedDetails.communicationAddressAreaName,
        communicationAddressCity: updatedDetails.communicationAddressCity,
        communicationAddressPinCode: updatedDetails.communicationAddressPinCode,
        communicationAddressState: updatedDetails.communicationAddressState,
        companyAddress: updatedDetails.companyAddress,
        companyAddressAreaName: updatedDetails.companyAddressAreaName,
        companyAddressCity: updatedDetails.companyAddressCity,
        companyAddressPinCode: updatedDetails.companyAddressPinCode,
        companyAddressState: updatedDetails.companyAddressState,
        email: updatedDetails.email,
        mobile: updatedDetails.mobile,
        newCompanyAddress: updatedDetails.newCompanyAddress,
        registeredOfficeAddress: updatedDetails.registeredOfficeAddress,
        registeredOfficeAddressAreaName:
          updatedDetails.registeredOfficeAddressAreaName,
        registeredOfficeAddressCity: updatedDetails.registeredOfficeAddressCity,
        registeredOfficeAddressPinCode:
          updatedDetails.registeredOfficeAddressPinCode,
        registeredOfficeAddressState:
          updatedDetails.registeredOfficeAddressState,
        registrationId: updatedDetails.registrationId,
        websiteUrl: updatedDetails.websiteUrl,
        paymentTypeId: paymentTypeIds,
      };

      const response = await apiCallFnforPut(
        UPDATE_ONBOARD_MERCHANT_DETAILS,
        payload
      );

      if (response.statusCode === 200) {
        setmerchantData(updatedDetails);
        toggleEditing();
        swal({
          title: "Success",
          text: "Profile updated successfully",
          icon: "success",
        });
        setGetAgain(!getAgain);
      } else {
        // console.error("API response error:", response.data);
        swal({
          title: "Failed",
          text: "Profile updation failed",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error updating merchant details:", error);
    }
  };

  const togglePhase2Editing = () => {
    if (!isPhase2Editing) {
      setPhase2Details({
        websiteUrl: merchantData.websiteUrl,
        mid: merchantData.mid,
        merchantIfscCode: merchantData.merchantIfscCode,
        merchantBankName: merchantData.merchantBankName,
        merchantAccountNumber: merchantData.merchantAccountNumber,
        callBackUrl: merchantData.callBackUrl,
        bankCity: 0,
        bankBranchName: merchantData.bankBranchName,
        accoutBeneficiaryName: merchantData.companyName,
        chargebackCallbackUrl: merchantData.chargebackCallbackUrl,
        payoutCallbackUrl: merchantData.payoutCallbackUrl
      });
    }


    setIsPhase2Editing(!isPhase2Editing);
    setIsEditing(false);
  };

  const checkValidation = (name, e) => {
    if (
      pressedKeyName === "Backspace" &&
      (name === "companyAddress" ||
        name === "registeredOfficeAddress" ||
        name === "communicationAddress" ||
        name === "companyAddressPinCode" ||
        name === "registeredOfficeAddressPinCode" ||
        name === "communicationAddressPinCode" ||
        name === "companyAddressCity" ||
        name === "communicationAddressCity" ||
        name === "registeredOfficeAddressCity" ||
        name === "companyAddressState" ||
        name === "registeredOfficeAddressState" ||
        name === "communicationAddressState")
    ) {
      setUpdatedDetails({ ...updatedDetails, [name]: e.target.value });
    }

    // stop alphabets and special char input in pincode
    if (
      (stopAlphabetInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyAddressPinCode" ||
        name === "registeredOfficeAddressPinCode" ||
        name === "communicationAddressPinCode")
    ) {
      return;
    }

    // stop special char input and number input and allow alphabets INPUT in
    // LOCALITY - STATE - CITY
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyAddressCity" ||
        name === "registeredOfficeAddressCity" ||
        name === "communicationAddressCity" ||
        name === "companyAddressState" ||
        name === "registeredOfficeAddressState" ||
        name === "communicationAddressState")
    )
      return;

    const addressFieldLength = 200;
    const pincodeFieldLength = 6;
    const StateLength = 50;
    const cityLength = 50;

    if (
      (updatedDetails.companyAddress.length >= addressFieldLength &&
        name === "companyAddress") ||
      (updatedDetails.registeredOfficeAddress.length >= addressFieldLength &&
        name === "registeredOfficeAddress") ||
      (updatedDetails.communicationAddress.length >= addressFieldLength &&
        name === "communicationAddress") ||
      (updatedDetails.companyAddressPinCode.length >= pincodeFieldLength &&
        name === "companyAddressPinCode") ||
      (updatedDetails.registeredOfficeAddressPinCode.length >=
        pincodeFieldLength &&
        name === "registeredOfficeAddressPinCode") ||
      (updatedDetails.communicationAddressPinCode.length >=
        pincodeFieldLength &&
        name === "communicationAddressPinCode") ||
      (updatedDetails.companyAddressCity.length >= cityLength &&
        name === "companyAddressCity") ||
      (updatedDetails.communicationAddressCity.length >= cityLength &&
        name === "communicationAddressCity") ||
      (updatedDetails.registeredOfficeAddressCity.length >= cityLength &&
        name === "registeredOfficeAddressCity") ||
      (updatedDetails.companyAddressState.length >= StateLength &&
        name === "companyAddressState") ||
      (updatedDetails.registeredOfficeAddressState.length >= StateLength &&
        name === "registeredOfficeAddressState") ||
      (updatedDetails.communicationAddressState.length >= StateLength &&
        name === "communicationAddressState")
    ) {
      return;
    }

    setUpdatedDetails({
      ...updatedDetails,
      [name]: e.target.value,
    });
  };

  const checkPhase2Validation = (name, e) => {
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "accoutBeneficiaryName" ||
        name === "bankBranchName" ||
        name === "merchantBankName")
    )
      return;

    setPhase2Details({
      ...phase2Details,
      [name]: e.target.value,
    });
  };

  const handelValidations = (e) => {
    if (e.target.name === "merchantIfscCode" && e.target.value !== "") {
      if (!isIfscRegexIsValid(phase2Details.merchantIfscCode)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Code !",
          icon: "error",
        }).then(() => {
          setPhase2Details({ ...phase2Details, merchantIfscCode: "" });
        });
        return;
      }
    }

    if (e.target.name === "websiteUrl" && e.target.value !== "") {
      if (!isWebsiteURLRegexIsValid(phase2Details.websiteUrl)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Website URL!",
          icon: "error",
        }).then(() => {
          setPhase2Details({ ...phase2Details, websiteUrl: "" });
        });
        return;
      }
    }
  };

  const ShowAlert = () => {
    swal({
      title: "Alert!",
      text: "Merchant not onboarded.",
      icon: "error",
    });
  };
  const handlePhase2Save = async () => {
    if (
      phase2Details.accoutBeneficiaryName === "" ||
      phase2Details.accoutBeneficiaryName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter account beneficiary name !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.bankBranchName === "" ||
      phase2Details.bankBranchName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter bank branch name !",
        icon: "error",
      });
      return;
    }

    // if (
    //   phase2Details.callBackUrl === "" ||
    //   phase2Details.callBackUrl === null
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please enter callBack URL !",
    //     icon: "error",
    //   });
    //   return;
    // }
    // if (
    //   phase2Details.payoutCallbackUrl === "" ||
    //   phase2Details.payoutCallbackUrl === null
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please enter Payout callBack URL !",
    //     icon: "error",
    //   });
    //   return;
    // }
    // if (
    //   phase2Details.chargebackCallbackUrl === "" ||
    //   phase2Details.chargebackCallbackUrl === null
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please enter Chargeback callBack URL !",
    //     icon: "error",
    //   });
    //   return;
    // }
    if (
      phase2Details.merchantAccountNumber === "" ||
      phase2Details.merchantAccountNumber === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter merchant account number !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.merchantBankName === "" ||
      phase2Details.merchantBankName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Merchant Bank Name !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.merchantIfscCode === "" ||
      phase2Details.merchantIfscCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter IFSC code !",
        icon: "error",
      });
      return;
    }

    if (!isIfscRegexIsValid(phase2Details.merchantIfscCode)) {
      swal({
        title: "Alert!",
        text: "Please enter valid IFSC code !",
        icon: "error",
      }).then(() => {
        setPhase2Details({ ...phase2Details, merchantIfscCode: "" });
      });
      return;
    }

    try {
      const response = await apiCallFnforPut(
        UPDATE_BANK_DETAILS_STAGE2,
        phase2Details
      );

      if (response.statusCode === 200) {
        setmerchantData(phase2Details);

        togglePhase2Editing();
        swal({
          title: "Success",
          text: "Profile updated successfully",
          icon: "success",
        });
        setAgain(!again);
      } else {
        swal({
          title: "Faliure",
          text: "Profile not updated!",
          icon: "error",
        });
        console.error("API response error:", response.data);
      }
    } catch (error) {
      swal({
        title: "Faliure",
        text: error,
        icon: "error",
      });
      console.error("Error updating merchant details:", error);
    }
  };

  const handleVerifyUrl = async (url, type) => {
    try {
      const response = await apiCallFnforPost(CHECK_CALLBACK_STATUS, { url: url });
      console.log(response);
      if (type === "payin") {
        setPayinCallbackStatus(response);
        setPhase2Details({
          ...phase2Details,
          callBackUrlStatus: (response ? response : false),
        })
      }
      else if (type === "chargeback") {
        setChargebackCallbackStatus(response)
        setPhase2Details({
          ...phase2Details,
          chargebackCallbackUrlStatus: (response ? response : false),
        })
      }
      else if (type === "payout") {
        setPayoutCallbackStatus(response)
        setPhase2Details({
          ...phase2Details,
          payoutCallbackUrlStatus: (response ? response : false),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <>
        <div className="">
          <div className="">
            {isEditing || isPhase2Editing ? (
              ""
            ) : (
              <div className="">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="table-responsive">
                        <div className="table table-bordered">
                          <div className="row">
                            {[
                              {
                                label: "User Name",
                                value: merchantData.userName,
                              },
                              {
                                label: "Master Merchant",
                                value: merchantData.masterMerchant,
                              },
                              {
                                label: "MCC Code",
                                value: merchantData.mccCode,
                              },
                              {
                                label: "Password",
                                value:
                                  reduxData.prefix === "ALL"
                                    ? merchantData.password
                                    : "XXXXXXXXX",
                              },
                              {
                                label: "Payin Encryption Key",
                                value: merchantData.encKey,
                              },
                              {
                                label: "Payout API Key",
                                value: merchantData.apiKey,
                              },
                              {
                                label: "Payout Secret Key",
                                value: merchantData.secretKey,
                              },
                              { label: "MID", value: merchantData.mid },
                              {
                                label: "Company Name",
                                value: merchantData.companyName,
                              },
                              {
                                label: "Mobile Number",
                                value: merchantData.mobile,
                              },
                              {
                                label: "Business Type",
                                value: merchantData.companyType,
                              },
                              {
                                label: "Payment Services",
                                value: Array.from(
                                  new Set(
                                    merchantData?.paymentService?.map(
                                      (service) => service.paymentService
                                    )
                                  )
                                )
                                  .map((service) => `• ${service}`)
                                  .join(" "),
                              },
                              {
                                label: "Company PAN Number",
                                value: merchantData.companyPanNumber,
                              },
                              {
                                label: "GST Number",
                                value: merchantData.gstNumber,
                              },
                              {
                                label: "Authorized Person PAN",
                                value: merchantData.authorizedPersonPan,
                              },
                              {
                                label: "Aadhaar Number",
                                value: merchantData.aadharNumber,
                              },
                              {
                                label: "Mode of Registration",
                                value: merchantData.modeOfRegistration,
                              },
                              {
                                label: "Modification Date",
                                value: merchantData.modificationDate,
                              },
                              {
                                label: "Registration Type",
                                value: merchantData.registrationType,
                              },
                              {
                                label: "Business As",
                                value: merchantData.businessAs,
                              },
                              {
                                label: "Authorized Person Name",
                                value: merchantData.authorizedPersonName,
                              },
                              { label: "E-mail", value: merchantData.email },
                              {
                                label: "Website URL",
                                value: merchantData.websiteUrl,
                              },
                              {
                                label: "Application URL",
                                value: merchantData.aapUrl,
                              },
                              {
                                label: "Company Registered Address",
                                value: merchantData.companyAddress,
                              },
                              {
                                label: "Pin-Code",
                                value: merchantData.companyAddressPinCode,
                              },
                              {
                                label: "City",
                                value: merchantData.companyAddressCity,
                              },
                              {
                                label: "State",
                                value: merchantData.communicationAddressState,
                              },
                              {
                                label: "Account Beneficiary Name",
                                value: merchantData.companyName,
                              },
                              {
                                label: "Bank Branch Name",
                                value: merchantData.bankBranchName,
                              },
                              {
                                label: "Merchant Account Number",
                                value: merchantData.merchantAccountNumber,
                              },
                              {
                                label: "Merchant Bank Name",
                                value: merchantData.merchantBankName,
                              },
                              {
                                label: "Merchant IFSC Code",
                                value: merchantData.merchantIfscCode,
                              },
                              {
                                label: "Payin Callback URL",
                                value: merchantData?.callBackUrlStatus
                                  ? <>
                                    <span>{merchantData?.callBackUrl}</span>
                                    <VerifiedIcon className="fs-4 text-success" /><span style={{ fontSize: "12px" }} className="text-success">Verified</span>
                                  </>
                                  : <>
                                    <div>{merchantData?.callBackUrl}</div>
                                    <NewReleasesIcon style={{ cursor: 'pointer' }} className="fs-5 text-danger" /><span style={{ fontSize: "12px" }} className="text-danger"> Not Verified</span>
                                  </>
                              },
                              {
                                label: "Payout Callback URL",
                                value: merchantData?.payoutCallbackUrlStatus
                                ? <>
                                  <span>{merchantData?.payoutCallbackUrl}</span>
                                  <VerifiedIcon className="fs-4 text-success" /><span style={{ fontSize: "12px" }} className="text-success">Verified</span>
                                </>
                                : <>
                                  <div>{merchantData?.payoutCallbackUrl}</div>
                                  <NewReleasesIcon style={{ cursor: 'pointer' }} className="fs-5 text-danger" /><span style={{ fontSize: "12px" }} className="text-danger"> Not Verified</span>
                                </>
                              },
                              {
                                label: "Chargeback Callback URL",
                                value: merchantData?.chargebackCallbackUrlStatus
                                ? <>
                                  <span>{merchantData?.chargebackCallbackUrl}</span>
                                  <VerifiedIcon className="fs-4 text-success" /><span style={{ fontSize: "12px" }} className="text-success">Verified</span>
                                </>
                                : <>
                                  <div>{merchantData?.chargebackCallbackUrl}</div>
                                  <NewReleasesIcon style={{ cursor: 'pointer' }} className="fs-5 text-danger" /><span style={{ fontSize: "12px" }} className="text-danger"> Not Verified</span>
                                </>
                              },
                              {
                                label: "Submission Date",
                                value: merchantData.submitDate,
                              },
                              {
                                label: "Submission Time",
                                value: merchantData.submitTime,
                              },
                              {
                                label: "Referred By",
                                value: merchantData.referredBy,
                              },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className="col-6 border border-dark "
                                style={{ borderRight: "0.5px solid black", borderLeft: "0.5px solid black", paddingBottom: "0px" }}
                              >
                                <div className="d-flex justify-content-between align-items-center table table-bordered">

                                  <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">{item.label}   <RemoveIcon /></tr>{" "}

                                  <tr className="mt-2 w-100 text-end">{item.value}</tr>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* second table */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isEditing && !isPhase2Editing && (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="table-sm right-border">
                      <thead>
                        <div className="d-flex justify-content-between fs-4 border-bottom mb-4 fw-bold">
                          <div>Label</div>
                          <div>Value</div>
                        </div>
                      </thead>
                      <tbody>
                        {[
                          { label: "Business Category", value: merchantData.businessType },
                          {
                            label: "Other Business Category",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.businessTypeName}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    businessTypeName: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.businessTypeName
                            ),
                          },
                          {
                            label: "Business As",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.businessAs}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    businessAs: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.businessAs
                            ),
                          },
                          {
                            label: "Authorized Person Name",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.authorizedPersonName}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    authorizedPersonName: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.authorizedPersonName
                            ),
                          },
                          {
                            label: "e-Mail ID",
                            value: isEditing ? (
                              <input
                                type="email"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.email}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    email: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.email
                            ),
                          },
                          {
                            label: "Mobile Number",
                            value: isEditing ? (
                              <input
                                type="tel"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.mobile}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    mobile: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.mobile
                            ),
                          },
                          {
                            label: "Website URL",
                            value: isEditing ? (
                              <input
                                type="url"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.websiteUrl}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    websiteUrl: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.websiteUrl
                            ),
                          },
                          {
                            label: "Application URL",
                            value: isEditing ? (
                              <input
                                type="url"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.aapUrl}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    aapUrl: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.aapUrl
                            ),
                          },
                          {
                            label: "Company Registered Address",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.companyAddress}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    companyAddress: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.companyAddress
                            ),
                          },
                          {
                            label: "Pin Code",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2 mx-3"
                                value={updatedDetails.companyAddressPinCode}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    companyAddressPinCode: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.companyAddressPinCode
                            ),
                          },
                        ].map((item, index) => (
                          <tr key={index} className="col-6">
                            <div className="d-flex justify-content-between align-items-center  gap-4">
                              <strong className="mt-4 form-label">{item.label}</strong>
                              <div className="col-6 border-none">{item.value}</div>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  </div>
                  <div className="col">
                    <table className="table-sm">
                      <thead>
                        <div className="d-flex justify-content-between  fs-4 mb-4 fw-bold border-bottom">
                          <div>Label</div>
                          <div>Value</div>
                        </div>
                      </thead>
                      <tbody>
                        {[
                          {
                            label: "City",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.companyAddressCity}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    companyAddressCity: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.companyAddressCity
                            ),
                          },
                          {
                            label: "State",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.companyAddressState}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    companyAddressState: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.companyAddressState
                            ),
                          },
                          {
                            label: "Authorized Signatory Address",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.registeredOfficeAddress}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    registeredOfficeAddress: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.registeredOfficeAddress
                            ),
                          },
                          {
                            label: "Pin Code",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.registeredOfficeAddressPinCode}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    registeredOfficeAddressPinCode: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.registeredOfficeAddressPinCode
                            ),
                          },
                          {
                            label: "City",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.registeredOfficeAddressCity}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    registeredOfficeAddressCity: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.registeredOfficeAddressCity
                            ),
                          },
                          {
                            label: "State",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.registeredOfficeAddressState}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    registeredOfficeAddressState: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.registeredOfficeAddressState
                            ),
                          },
                          {
                            label: "Company Communication Address",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.communicationAddress}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    communicationAddress: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.communicationAddress
                            ),
                          },
                          {
                            label: "Pin Code",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.communicationAddressPinCode}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    communicationAddressPinCode: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.communicationAddressPinCode
                            ),
                          },
                          {
                            label: "City",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.communicationAddressCity}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    communicationAddressCity: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.communicationAddressCity
                            ),
                          },
                          {
                            label: "State",
                            value: isEditing ? (
                              <input
                                type="text"
                                className="form-control mb-2"
                                value={updatedDetails.communicationAddressState}
                                onChange={(e) =>
                                  setUpdatedDetails({
                                    ...updatedDetails,
                                    communicationAddressState: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              merchantData.communicationAddressState
                            ),
                          },
                        ].map((item, index) => (
                          <tr key={index} className="col-6">
                            <div className="d-flex justify-content-between gap-4">
                              <strong className="mt-4 form-label">{item.label}</strong>
                              <div>{item.value}</div>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowPaymentServicesList(!showPaymentServicesList)}
                  >
                    View Services
                  </button>
                  {showPaymentServicesList && (
                    <div className="row mt-3">
                      {paymentservice.map((service) => (
                        <div
                          className="col-6 d-flex align-items-center"
                          key={service.paymentTypeId}
                        >
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              value={service.paymentService}
                              checked={selectedServices.includes(service.paymentService)}
                              onChange={handleServiceChange}
                            />
                            {service.paymentService}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

            )}
            {isPhase2Editing && (
              <div className="">
                <div className="row">
                  <div className="col-6 ">
                    <div className="table-sm right-border">
                      <table className="table">
                        <thead>
                          <div className="fw-bold fs-4 border-bottom d-flex justify-content-between ">
                            <div>Label</div>
                            <div>Value</div>
                          </div>
                        </thead>
                        <tbody>
                          {[
                            {
                              label: "Account Beneficiary Name",
                              value: isPhase2Editing ? (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={phase2Details.accoutBeneficiaryName}
                                  onKeyDown={(e) => {
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  onChange={(e) =>
                                    checkPhase2Validation(
                                      "accoutBeneficiaryName",
                                      e
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.accoutBeneficiaryName}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Bank Branch Name",
                              value: isPhase2Editing ? (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={phase2Details.bankBranchName}
                                  onKeyDown={(e) => {
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  onChange={(e) =>
                                    checkPhase2Validation("bankBranchName", e)
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.bankBranchName}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Payin Callback URL",
                              value: isPhase2Editing ? (
                                <div className="form-group">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control p-3 text-center"
                                      value={phase2Details.callBackUrl}
                                      onChange={(e) =>
                                        setPhase2Details({
                                          ...phase2Details,
                                          callBackUrl: e.target.value,
                                        })
                                      }
                                    />
                                    <button type="button" className="btn btn-success" id="verifyButton"
                                      onClick={() => handleVerifyUrl(phase2Details.callBackUrl, "payin")}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                  <div className="d-flex justify-content-start">
                                    {payinCallbackStatus ? (<span
                                      id="callBackUrlErr"
                                      className="text-success"
                                    >
                                      <VerifiedIcon className="fs-4" /> Verified
                                    </span>) : (<span
                                      id="callBackUrlErr"
                                      className="text-danger"
                                    >
                                      <NewReleasesIcon className="fs-4" /> Not Verified
                                    </span>)}
                                  </div>

                                </div>
                                // <input
                                //   type="text"
                                //   className="form-control p-3 text-center bg-success mb-2"
                                //   value={phase2Details.callBackUrl}
                                //   onChange={(e) =>
                                //     setPhase2Details({
                                //       ...phase2Details,
                                //       callBackUrl: e.target.value,
                                //     })
                                //   }
                                // />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.callBackUrl}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Payout Callback URL",
                              value: isPhase2Editing ? (
                                <div className="form-group">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control p-3 text-center"
                                      value={phase2Details.payoutCallbackUrl}
                                      onChange={(e) =>
                                        setPhase2Details({
                                          ...phase2Details,
                                          payoutCallbackUrl: e.target.value,
                                        })
                                      }
                                    />
                                    <button type="button" className="btn btn-success" id="verifyButton"
                                      onClick={() => handleVerifyUrl(phase2Details.payoutCallbackUrl, "payout")}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                  <div className="d-flex justify-content-start">
                                    {payoutCallbackStatus ? (<span
                                      id="callBackUrlErr"
                                      className="text-success"
                                    >
                                      <VerifiedIcon className="fs-4" /> Verified
                                    </span>) : (<span
                                      id="callBackUrlErr"
                                      className="text-danger"
                                    >
                                      <NewReleasesIcon className="fs-4" /> Not Verified
                                    </span>)}
                                  </div>

                                </div>
                                // <input
                                //   type="text"
                                //   className="form-control p-3 text-center mb-2"
                                //   value={phase2Details.payoutCallbackUrl}
                                //   onChange={(e) =>
                                //     setPhase2Details({
                                //       ...phase2Details,
                                //       payoutCallbackUrl: e.target.value,
                                //     })
                                //   }
                                // />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.payoutCallbackUrl}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Chargeback Callback URL",
                              value: isPhase2Editing ? (
                                <div className="form-group">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control p-3 text-center"
                                      value={phase2Details.chargebackCallbackUrl}
                                      onChange={(e) =>
                                        setPhase2Details({
                                          ...phase2Details,
                                          chargebackCallbackUrl: e.target.value,
                                        })
                                      }
                                    />
                                    <button type="button" className="btn btn-success" id="verifyButton"
                                      onClick={() => handleVerifyUrl(phase2Details.chargebackCallbackUrl, "chargeback")}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                  <div className="d-flex justify-content-start">
                                    {chargebackCallbackStatus ? (<span
                                      id="callBackUrlErr"
                                      className="text-success"
                                    >
                                      <VerifiedIcon className="fs-4" /> Verified
                                    </span>) : (<span
                                      id="callBackUrlErr"
                                      className="text-danger"
                                    >
                                      <NewReleasesIcon className="fs-4" /> Not Verified
                                    </span>)}
                                  </div>

                                </div>
                                // <input
                                //   type="text"
                                //   className="form-control p-3 text-center mb-2"
                                //   value={phase2Details.chargebackCallbackUrl}
                                //   onChange={(e) =>
                                //     setPhase2Details({
                                //       ...phase2Details,
                                //       chargebackCallbackUrl: e.target.value,
                                //     })
                                //   }
                                // />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.chargebackCallbackUrl}
                                  disabled
                                />
                              ),
                            },

                            // Other rows...
                          ].map((item, index) => (
                            <tr key={index} className="col-6">
                              <div className="d-flex justify-content-between">
                                <strong className=" mt-4 form-label">
                                  {item.label}
                                </strong>
                                <div className="col-6 border-none">
                                  {item.value}
                                </div>
                              </div>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-6 ">
                    <div className="table-sm right-border">
                      <table className="table">
                        <thead>
                          <div className="fw-bold fs-4 border-bottom d-flex justify-content-between">
                            <div>Label</div>
                            <div>Value</div>
                          </div>
                        </thead>
                        <tbody>
                          {[
                            {
                              label: "Merchant Account Number",
                              value: isPhase2Editing ? (
                                <input
                                  type="number"
                                  className="form-control p-3 text-center mb-2"
                                  value={phase2Details.merchantAccountNumber}
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      merchantAccountNumber: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <input
                                  type="number"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.merchantAccountNumber}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Merchant Bank Name",
                              value: isPhase2Editing ? (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={phase2Details.merchantBankName}
                                  onKeyDown={(e) => {
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  onChange={(e) =>
                                    checkPhase2Validation("merchantBankName", e)
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.merchantBankName}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Merchant IFSC Code",
                              value: isPhase2Editing ? (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={phase2Details.merchantIfscCode}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  name="merchantIfscCode"
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      merchantIfscCode: e.target.value,
                                    })
                                  }
                                  onBlur={handelValidations}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.merchantIfscCode}
                                  disabled
                                />
                              ),
                            },
                            {
                              label: "Website URL",
                              value: isPhase2Editing ? (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={phase2Details.websiteUrl}
                                  name="websiteUrl"
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      websiteUrl: e.target.value,
                                    })
                                  }
                                  onBlur={handelValidations}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control p-3 text-center mb-2"
                                  value={merchantData.websiteUrl}
                                  disabled
                                />
                              ),
                            },
                            // Other rows...
                          ].map((item, index) => (
                            <tr key={index} className="col-6">
                              <div className="d-flex justify-content-between">
                                <strong className=" mt-4 form-label">
                                  {item.label}
                                </strong>
                                <div className="col-6 border-none">
                                  {item.value}
                                </div>
                              </div>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div id="" className="form-wizard order-create">
              <div id="" className="tab-pane" role="tabpanel">
                <div className="row">
                  {isEditing || isPhase2Editing ? (
                    ""
                  ) : (
                    <div className="row">
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            User Name
                          </label>
                          <div className="form-control p-3">
                            {merchantData.userName}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Master
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.masterMerchant}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Mcc Code
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.mccCode}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Password
                          </label>
                          <div className="form-control p-3 ">
                            {reduxData.prefix === "ALL"
                              ? merchantData.password
                              : "XXXXXXXXX"}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payin Encryption Key
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.encKey}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payout API Key
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.apiKey}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payout Secret Key
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.secretKey}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">Mid</label>
                          <div className="form-control p-3 ">
                            {merchantData.mid}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Company Name
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.companyName}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Mobile Number
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.mobile}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Business Type
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.companyType}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2"> */}
                      {/* <div className="mb-3"> */}
                      {/* <label className="mb-1 text-dark fs-4">
                            Payment Services
                          </label> */}
                      {/* <div className="form-control p-3 d-flex gap-2">
                            {Array.from(
                              new Set(
                                merchantData?.paymentService?.map(
                                  (service) => service.paymentService
                                )
                              )
                            ).map((uniqueService, index) => (
                              <li key={index}>{uniqueService}</li>
                            ))}
                          </div> */}
                      {/* </div> */}
                      {/* </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Company PAN Number
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.companyPanNumber}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            GST Number
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.gstNumber}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Authorized Person PAN
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.authorizedPersonPan}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Aadhaar Number
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.aadharNumber}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Mode of Registration
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.modeOfRegistration}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Modification Date
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.modificationDate}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Registration Type
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.registrationType}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Business As
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.businessAs}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Authorized Person Name
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.authorizedPersonName}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            e-Mail ID
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.email}
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Website URL
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.websiteUrl}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Application URL
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.aapUrl}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2 ">
                        <div className="mb-4">
                          <label className="mb-1 text-dark fs-4">
                            Company Registered Address
                          </label>
                          <div className="form-control p-3">
                            {merchantData.companyAddress}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Pin Code
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.companyAddressPinCode}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">City</label>
                          <div className="form-control p-3 ">
                            {merchantData.companyAddressCity}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">State</label>
                          <div className="form-control p-3 ">
                            {merchantData.companyAddressState}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Authorized Signatory Address
                          </label>
                          <div className=" form-control p-3">
                            {merchantData.registeredOfficeAddress}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Pin Code
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.registeredOfficeAddressPinCode}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">City</label>
                          <div className="form-control p-3 ">
                            {merchantData.registeredOfficeAddressCity}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">State</label>
                          <div className="form-control p-3 ">
                            {merchantData.registeredOfficeAddressState}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Company Communication Address
                          </label>
                          <div className="form-control p-3">
                            {merchantData.communicationAddress}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Pin Code
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.communicationAddressPinCode}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">City</label>
                          <div className="form-control p-3 ">
                            {merchantData.communicationAddressCity}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">State</label>
                          <div className="form-control p-3 ">
                            {merchantData.communicationAddressState}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Account Beneficiary Name
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.companyName}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Bank Branch Name
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.bankBranchName}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Account Number
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.merchantAccountNumber}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Bank Name
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.merchantBankName}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant IFSC Code
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.merchantIfscCode}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payin Callback URL
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.callBackUrl}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payout Callback URL
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.payoutCallbackUrl}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Chargeback Callback URL
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.chargebackCallbackUrl}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Submission Date
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.submitDate}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Submission Time
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.submitTime}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Referred By
                          </label>
                          <div className="form-control p-3 ">
                            {merchantData.referredBy}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )}

                  {/* editable column for phase 1:                */}

                  {isPhase2Editing && (
                    <div className="row">
                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Account Beneficiary Name
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.accoutBeneficiaryName}
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={(e) =>
                                checkPhase2Validation(
                                  "accoutBeneficiaryName",
                                  e
                                )
                              }
                            />
                          ) : (
                            merchantData.accoutBeneficiaryName
                          )}
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Bank Branch Name
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.bankBranchName}
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={(e) =>
                                checkPhase2Validation("bankBranchName", e)
                              }
                            />
                          ) : (
                            merchantData.bankBranchName
                          )}
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payin Callback URL
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.callBackUrl}
                              onChange={(e) =>
                                setPhase2Details({
                                  ...phase2Details,
                                  callBackUrl: e.target.value,
                                })
                              }
                            />
                          ) : (
                            merchantData.callBackUrl
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Payout Callback URL
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.payoutCallbackUrl}
                              onChange={(e) =>
                                setPhase2Details({
                                  ...phase2Details,
                                  payoutCallbackUrl: e.target.value,
                                })
                              }
                            />
                          ) : (
                            merchantData.payoutCallbackUrl
                          )}
                        </div>
                      </div> */}

                      {/* <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Chargeback Callback URL
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.chargebackCallbackUrl}
                              onChange={(e) =>
                                setPhase2Details({
                                  ...phase2Details,
                                  chargebackCallbackUrl: e.target.value,
                                })
                              }
                            />
                          ) : (
                            merchantData.chargebackCallbackUrl
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Account Number
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="number"
                              className="form-control p-3"
                              value={phase2Details.merchantAccountNumber}
                              onChange={(e) =>
                                setPhase2Details({
                                  ...phase2Details,
                                  merchantAccountNumber: e.target.value,
                                })
                              }
                            />
                          ) : (
                            merchantData.merchantAccountNumber
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Bank Name
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.merchantBankName}
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={(e) =>
                                checkPhase2Validation("merchantBankName", e)
                              }
                            />
                          ) : (
                            merchantData.merchantBankName
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant IFSC Code
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.merchantIfscCode}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              name="merchantIfscCode"
                              onChange={(e) =>
                                setPhase2Details({
                                  ...phase2Details,
                                  merchantIfscCode: e.target.value,
                                })
                              }
                              onBlur={handelValidations}
                            />
                          ) : (
                            merchantData.merchantIfscCode
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Website URL
                          </label>
                          {isPhase2Editing ? (
                            <input
                              type="text"
                              className="form-control p-3"
                              value={phase2Details.websiteUrl}
                              name="websiteUrl"
                              onChange={(e) =>
                                setPhase2Details({
                                  ...phase2Details,
                                  websiteUrl: e.target.value,
                                })
                              }
                              onBlur={handelValidations}
                            />
                          ) : (
                            merchantData.websiteUrl
                          )}
                        </div>
                      </div> */}
                    </div>
                  )}
                  {hasPrivilages2682 ? (
                    <div className="d-flex flex-end gap-2">
                      {isEditing && !isPhase2Editing ? (
                        <button
                          className="badge badge-rounded badge-success mx-3 px-3 py-2"
                          onClick={handleSave}
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          className="badge badge-rounded badge-primary mx-3 px-3 py-2"
                          onClick={
                            merchantData.applicationStatus === null
                              ? ShowAlert
                              : toggleEditing
                          }
                        >
                          Edit Phase 1
                        </button>
                      )}

                      {isPhase2Editing ? (
                        <button
                          className="badge badge-rounded badge-success mx-3 px-3 py-2"
                          onClick={handlePhase2Save}
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          className="badge badge-rounded badge-primary mx-3 px-3 py-2"
                          onClick={
                            merchantData.applicationStatus === null
                              ? ShowAlert
                              : togglePhase2Editing
                          }
                        >
                          Edit Phase 2
                        </button>
                      )}
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-end ">
                    <button
                      type="button"
                      class="badge badge-rounded badge-info mx-4 px-4 py-2 mb-25"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default OpenDetails;
