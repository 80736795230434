import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import useApiServices from '../../utils/useApiServices';
import { FETCH_VIEW_VENDOR_DETAILS_API, Vendor_Details } from '../../utils/constant';

const ViewVendor = ({isModalOpen,setIsModalOpen,vendorId,mid,companyName}) => {

    const [data, setData] = useState({
        accountNumber: "",
        bankName: "",
        branchName: "",
        category: "",
        email: "",
        fullName: "",
        ifscCode: "",
        mobile: "",
        parentMid: "",
        updateDate: "",
        upi: "",
        vendorId: "",
        vendorStatus: "",
      });
    
      const navigate = useNavigate();
    
      const { apiCallFnforGet } = useApiServices();
    
      const fetchVenderByVenderId = async (vendorId) => {
     
        if (!vendorId) {
          return;
        }
        let res = await apiCallFnforGet(
            FETCH_VIEW_VENDOR_DETAILS_API + vendorId
        );
        setData(res?.data);
      };
    
      useEffect(() => {
        fetchVenderByVenderId(vendorId);
      }, [vendorId]);

  return (
    <Modal show={isModalOpen} onHide={()=>setIsModalOpen(false)} backdrop="static"
    keyboard={false} size="xl">
    <Modal.Header closeButton>
      <Modal.Title className="fs-4  fw-bold">Vendor Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="  d-flex align-items-center flex-column">
    <div className='w-100 d-flex flex-column flex-start'>

    <h2 className="heading text-decoration-none">
          <span>Merchant Id : {mid}</span>
        </h2><h2 className="heading text-decoration-none">
          <span>Company Name : {companyName}</span>
        </h2>
    </div>
      <div className="excel-uploader mt-2">
        <h2 className="heading text-decoration-none">
          <span>Vendor Details</span>
        </h2>
      </div>
      <div className="w-75 text-black">
        <div className="d-flex px-5 border justify-content-between">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Vendor ID</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.vendorId === "" ? "N/A" : data.vendorId}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Full Name</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.fullName === "" ? "N/A" : data.fullName}
            </span>
          </div>
        </div>

        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>e-Mail ID</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.email === "" ? "N/A" : data.email}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Mobile</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.mobile === "" ? "N/A" : data.mobile}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Aadhaar Card</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.aadhaarNo === "" ? "N/A" : data.aadhaarNo}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>PAN Number</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.panNumber === "" ? "N/A" : data.panNumber}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Bank Name</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.bankName === "" ? "N/A" : data.bankName}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Account Number</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.accountNumber === "" ? "N/A" : data.accountNumber}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>IFSC Code</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.ifscCode === "" ? "N/A" : data.ifscCode}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Branch Name</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.branchName === "" ? "N/A" : data.branchName}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>UPI</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">{data.upi === "" ? "N/A" : data.upi}</span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Approval</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span
              className={`mx-5 ${
                data.vendorStatus === "Approved"
                  ? "text-success"
                  : data.vendorStatus === "Rejected"
                  ? "text-danger"
                  : "text-primary"
              }`}
            >
              {" "}
              <b>{data.vendorStatus === "" ? "N/A" : data.vendorStatus}</b>{" "}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Approve/Reject Date</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              N/A
              {/* {data?.updateDate === ""
                ? "N/A"
                : new Date(data.updateDate).getDate() +
                  "/" +
                  (new Date(data.updateDate).getMonth() + 1) +
                  "/" +
                  new Date(data.updateDate).getFullYear()} */}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Approve/Reject Time</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              N/A
              {/* {data?.updateDate === ""
                ? "N/A"
                : new Date(data.updateDate).getHours() +
                  ":" +
                  new Date(data.updateDate).getMinutes() +
                  ":" +
                  new Date(data.updateDate).getSeconds()} */}
            </span>
          </div>
        </div>
        <div className="d-flex px-5 border justify-content-between ">
          <div className="border-end  w-50 p-2">
            <b>
              <span>Rejection Remark</span>
            </b>
          </div>
          <div className=" w-50 p-2">
            <span className="mx-5">
              {data.remark === "" || data.remark === null ? "N/A" : data.remark}
            </span>
          </div>
        </div>

        {/* <div className="d-flex justify-content-end mt-3">
          <div className="Advanced-btn">
            <button
              className={`btn btn-sm btn-danger`}
              data-bs-toggle="modal"
              data-bs-target="#ViewVendor"
            >
              &#x2190; Close
            </button>
          </div>
        </div> */}
      </div>
    </div>
    </Modal.Body>
  </Modal>
  )
}

export default ViewVendor
