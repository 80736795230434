import React, { useEffect } from "react";
import InactiveServices from "../../common/InactiveServices"


const Settings = () => {

  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          {/* <div class="row page-titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">Settings</a> */}
                <InactiveServices location={"fdhjk"}/>
              {/* </li>
            </ol>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Settings;
