import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import {
  EKYC_SAVE,
  FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS,
  GET_ALL_MERCHANT_MASTER,
  GET_ALL_VEHICAL_MASTER_FORMS,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";

const EditEkycRouting = ({ ApplicationData, show, onHide }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(ApplicationData?.company || "");
  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [data, setData] = useState({
    vehicle: ApplicationData?.vehicleMasterId || "",
    merchantMasterId: ApplicationData?.masterMerchantId || "",
    merchantMasterName: ApplicationData?.merchantMaster || "",
  });

  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [formData, setFormData] = useState({
    mid: ApplicationData?.mid || "",
    merchantName: ApplicationData?.company || "",
  });

  const handleClose = () => {
    onHide();
  };

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const showConfirmationAlert = async () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application?",
        icon: "warning",
        buttons: true,
      }).then(async (willSubmit) => {
        if (willSubmit) {
          if (formData.merchantName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant",
              icon: "error",
            });
            return;
          }

          if (data.vehicle === "") {
            swal({
              title: "Alert!",
              text: "Please Select Vehicle",
              icon: "error",
            });
            return;
          }

          const payload = {
            company: formData?.merchantName.trim(),
            mid: formData.mid.trim(),
            vehicleMasterId: data?.vehicle.trim(),
            masterMerchantId: data?.merchantMasterId.trim(),
          };

          try {
            const response = await apiCallFnforPost(EKYC_SAVE, payload);
            if (response.statusCode === 200) {
              handleClose();
              swal({
                title: "Success!",
                text: "E-kyc Routing Updated Successfully.",
                icon: "success",
              });
              setFormData({
                mid: "",
                merchantName: "",
              });
              setData({
                vehicle: "",
                merchantMasterId: "",
                merchantMasterName: "",
              });
              setSelectedCompany("");
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          swal("Submission Cancelled!", {
            icon: "info",
          });
        }
      });
    };

    showConfirmationAlert();
  };

  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS);

    setMerchantData(res?.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const getAllVehicle = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICAL_MASTER_FORMS); // Add the await keyword here
      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedCompany(ApplicationData?.company);
  }, [ApplicationData]);

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
            <div className="merchant-report-view">
              <div className="report-table-view">
                <div className="containerflag d-flex justify-content-center">
                  <div className="row mt-4 ">
                    <div className="col-md-6 offset">
                      <div className="col-sm-12 col-md-8 offset-md-3">
                        <label htmlFor="email"className="fs-4 text-dark">Select A Merchant</label>
                        <select
                          id="companyDropdown"
                          className="form-control "
                          onChange={handleCompanyChange}
                          value={selectedCompany}
                        >
                          <option value="">Select a Merchant</option>
                          {merchantData?.map((merchant) => (
                            <option
                              key={merchant.mid}
                              value={merchant.merchantName}
                            >
                              {merchant.merchantName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="row mt-2">
                        <div className="col-sm-12 col-md-8 offset-md-3">
                          <div className="form-group mt-2">
                            <label htmlFor="email" className="fs-4 text-dark">Merchant MID</label>
                            <input
                              type="text"
                              className="form-control "
                              id="email"
                              placeholder=""
                              value={formData.mid}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-sm-12 col-md-8 offset-md-3">
                        <label htmlFor="email" className="fs-4 text-dark">Select A Vehicle</label>
                        <select
                          id="companyDropdown"
                          className="form-control "
                          onChange={handelDataChange}
                          name="vehicle"
                          value={data.vehicle}
                        >
                          <option value="">Select a Vehicle</option>
                          {vehicleData?.map((vehicle) => (
                            <option
                              key={vehicle.id}
                              data-master={vehicle.masterMerchantName}
                              data-masterId={vehicle.masterMerchantId}
                              value={vehicle.id}
                            >
                              {vehicle.vehicleName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-sm-12 col-md-8 offset-md-3 mt-3">
                        <label htmlFor="email" className=" d-flex fs-4 text-dark">Select Master Merchant</label>
                        <input
                          id="companyDropdown"
                          className="form-control "
                          readOnly
                          name="merchantMaster"
                          value={data.merchantMasterName}
                        />
                      </div>

                      <div className="row mt-2"></div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditEkycRouting;
