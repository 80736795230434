import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import {
  EKYC_SAVE,
  FETCH_ALL_MERCHANT_EKYC_ROUTING_ONBORDING,
  FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS,
  GET_ALL_MERCHANT_MASTER,
  GET_ALL_VEHICAL_MASTER_FORMS,
  SWIPELINC_API,
} from "../../utils/constant";

import { useSelector } from "react-redux";
import useApiServices from "../../utils/useApiServices";

const EkycRouting = ({ onHide, show, setRefresh, refresh }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [data, setData] = useState({
    vehicle: "",
    merchantMasterId: "",
    merchantMasterName: "",
  });

  const { privilagesData } = useSelector((state) => state?.privilages);
  const { apiCallFnforGet, apiCallFnforPut, apiCallFnforPost } =
    useApiServices();

  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId);
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS);
    setMerchantData(res?.data);
  };
  const getAllMerchantForOnBoardingRoutingEkyc = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_EKYC_ROUTING_ONBORDING);
    setMerchantData(res.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const showConfirmationAlert = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application?",
        icon: "warning",
        buttons: true,
      }).then(async (willSubmit) => {
        if (willSubmit) {
          if (formData.merchantName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant",
              icon: "error",
            });
            return;
          }

          if (data.vehicle === "") {
            swal({
              title: "Alert!",
              text: "Please Select Vehicle",
              icon: "error",
            });
            return;
          }

          const payload = {
            company: formData.merchantName.trim(),
            mid: formData.mid.trim(),
            vehicleMasterId: data.vehicle.trim(),
            masterMerchantId: data.merchantMasterId.trim(),
          };

          try {
            const response = await apiCallFnforPost(EKYC_SAVE, payload);
            console.log(response);

            if (response.statusCode === 200) {
              handleClose();
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });
              setFormData({
                mid: "",
                merchantName: "",
              });
              setData({
                vehicle: "",
                merchantMasterId: "",
                merchantMasterName: "",
              });
              setSelectedCompany("");

              getAllMerchantForApiTest();
              setRefresh(!refresh);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          swal("Submission Cancelled!", {
            icon: "info",
          });
        }
      });
    };

    showConfirmationAlert();
  };

  const getAllVehicle = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICAL_MASTER_FORMS); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

  const handleClose = () => {
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Routing</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid ">
            <div class="row d-flex justify-content-center gap-5">
              <div className="col-lg-4 mb-2 ">
                <div className="mb-3">
                  <label className="mb-1 text-dark fs-4">
                    Select A Merchant
                  </label>
                  <select
                    id="companyDropdown"
                    className="form-control  p-3 "
                    onChange={handleCompanyChange}
                    value={selectedCompany}
                    name="merchantSelect"
                  >
                    <option value="">Select a Merchant</option>
                    {merchantData?.map((merchant) => (
                      <option key={merchant.mid} value={merchant.merchantName}>
                        {merchant.merchantName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 mb-2">
                <label className="mb-1 text-dark fs-4">Merchant MID</label>
                <input
                  type="text"
                  className="form-control  p-3 "
                  id="email"
                  placeholder="Merchant MID"
                  value={formData.mid}
                  readOnly
                />
              </div>
            </div>

            <div class="row d-flex justify-content-center gap-5">
              <div className="col-lg-4 mb-2">
                <div className="mb-3">
                  <label className="mb-1 text-dark fs-4">
                    Select A Vehicle
                  </label>
                  <select
                    id="companyDropdown"
                    className="form-control  p-3 "
                    onChange={handelDataChange}
                    name="vehicle"
                    value={data.vehicle}
                  >
                    <option value="">Select a Vehicle</option>
                    {vehicleData?.map((vehicle) => (
                      <option
                        key={vehicle.id}
                        data-master={vehicle.masterMerchantName}
                        data-masterId={vehicle.masterMerchantId}
                        value={vehicle.id}
                      >
                        {vehicle.vehicleName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 mb-2">
                <label className="mb-1 text-dark fs-4">
                  Select Master Merchant
                </label>
                <input
                  type="text"
                  className="form-control  p-3 "
                  id="email"
                  readOnly
                  name="merchantMaster"
                  value={data.merchantMasterName}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <button
                className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EkycRouting;
