import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
// import InactiveServices from "../../common/InactiveServices";
// import Model from "./Model";
import { Button, Col, Modal, Row } from "react-bootstrap";
// import DownloadIcon from "@mui/icons-material/Download";
import useApiServices from "../../utils/useApiServices";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  DOWNLOAD_REPORTS_CSV_BY_TYPE,
} from "../../utils/constant";
import DownloadCsv from "../../common/DownloadCsv";
import swal from "sweetalert";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from "js-cookie";

import Loader from "../Loader/Loader";

const NewDowloadCSV = ({ type, show, setShow }) => {
  // const [type, setType] = useState("");
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState("");
  const [createStartDate, setCreateStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [createEndDate, setCreateEndDate] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [searchOption3, setSearchOption3] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [searchValue3, setSearchValue3] = useState("");
  const [mid, setMidState] = useState("");
  const [downloadData, setDownloadData] = useState(null);
  const [dropDownData, setDropDownData] = useState([]);
  const [formData, setFormData] = useState({
    mid: "",
  });

  const [csvRequest, setCsvRequest] = useState({
    mid: "",
    type: "",
    date: "",
    startDate: "",
    endDate: "",
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleShowRouting = () => setIsModalRoutingOpen(true);
  const handleCloseModalRouting = () => {
    // setShow(false);
    setSelectedCompany("");
    setStartDate("");
    setCreateStartDate("");
    setEndDate("");
    setCreateEndDate("");
    setSearchOption3("");
    setSearchValue3("");
    setFormData({ mid: "" });
    setDownloadData([]);
  };
  const reportstype = [
    "payin",
    "payout",
    "chargeback",
    "withdraw",
    "reconcilation",
    "settlement",
  ];
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handleFromToDateInputChange = (from, to) => {
    console.log(from, ":", to);

    const searchBtn = document.getElementById("searchBtn");

    // Parse the input dates
    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (Number(createStartDate) > Number(createEndDate)) {
      // searchBtn.classList.remove("d-none");
      searchBtn?.setAttribute("disabled", "true");
    } else {
      // searchBtn.classList.add("d-none");
      searchBtn?.removeAttribute("disabled");
    }

    // Check if dates are valid
    const isValidFrom = inputFromDateTime.isValid;
    const isValidTo = inputToDateTime.isValid;

    if (
      !isValidFrom ||
      !isValidTo ||
      from === to ||
      inputToDateTime < inputFromDateTime
    ) {
      // Disable the button if dates are invalid, the same, or the 'to' date is before the 'from' date
      searchBtn?.setAttribute("disabled", "true");
    } else {
      // Enable the button if dates are valid and 'to' date is after or equal to 'from' date
      searchBtn?.removeAttribute("disabled");
    }

    // Return whether the dates are valid and the 'to' date is after or equal to the 'from' date
    return isValidFrom && isValidTo && inputToDateTime >= inputFromDateTime;
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    if (selectedCompany === "") {
      setFormData({
        selectedCompany: "",
        createStartDate: "",
        createEndDate: "",
        searchValue3: "",
      });
      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);
  };
  // console.log("date--->", searchValue3);
  // console.log("----->", selectedCompany);
  // console.log("startdate-->", createStartDate);
  // console.log("enddate-->", createEndDate);
  const getMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

      if (response?.statusCode === 200) {
        setData(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };
  useEffect(() => {
    getMerchantData();
  }, []);

  const FetchDownloadData = async () => {
    setDownloadData(null);
    let payload = {
      mid: selectedCompany,
      type: type,
    };
    if (searchOption3 === "date") {
      payload = {
        ...payload,
        startDate: "",
        endDate: "",
        date: searchValue3,
      };
    } else if (searchOption3 === "dateInBetween") {
      payload = {
        ...payload,
        startDate: createStartDate,
        endDate: createEndDate,
        date: "",
      };
    }

    try {
      const response = await apiCallFnforPost(
        DOWNLOAD_REPORTS_CSV_BY_TYPE,
        payload
      );

      console.log("Downlod data-->", response);
      if (response) {
        setDownloadData(response);
      } else {
        swal({
          title: "Alert!",
          text: response?.message,
          icon: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        title: "Alert!",
        text: error,
        icon: "error",
      });
    }
  };
  console.log("data idhar h", downloadData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCsvRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setMessage("");
    e.preventDefault();
    setLoading(true);
    let payload = {
      mid: selectedCompany,
      type: type,
    };
    if (searchOption3 === "date") {
      payload = {
        ...payload,
        startDate: "",
        endDate: "",
        date: searchValue3,
      };
    } else if (searchOption3 === "dateInBetween") {
      payload = {
        ...payload,
        startDate: createStartDate,
        endDate: createEndDate,
        date: "",
      };
    }

    const access_token = await Cookies.get("access_token");

    try {
      setLoading(true);
      const response = await fetch(DOWNLOAD_REPORTS_CSV_BY_TYPE, {
        method: "POST",
        headers: { "Content-Type": "application/json",
              "Authorization" : `Bearer ${access_token}`
         },
        body: JSON.stringify(payload),
      });

      // const response = await apiCallFnforPost(DOWNLOAD_REPORTS_CSV_BY_TYPE,payload)
      console.log(response);
      if (response.ok) {
        const blob = await response.blob();
        if (blob.size > 0) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
          const fileName = `data_${timestamp}.zip`;
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          swal({
            title: "Success",
            text: "ZIP file downloaded successfully",
            icon: "success",
          });
          setLoading(false);
          // setMessage('ZIP file downloaded successfully.');
        } else {
          setLoading(false);
          swal({
            title: "Warning",
            text: "ZIP file is empty or not found",
            icon: "warning",
          });
          // setMessage('ZIP file is empty or not found.');
        }
      } else {
        const errorMessage = await response.text();
        // setMessage(errorMessage);
        setLoading(false);
        swal({
          title: "Alert",
          text: errorMessage,
          icon: "error",
        });
      }
    } catch (error) {
      swal({
        title: "Alert",
        text: "An error occurred while downloading the ZIP file.",
        icon: "error",
      });
      //     setMessage('An error occurred while downloading the ZIP file.');
      // } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <div className="content-body">
        <div className="container-fluid">
          <Button
            variant="outline-danger"
            size="sm"
            className="text-capitalize"
            onClick={handleShowRouting}
          >
            Download CSV
          </Button>
        </div>
      </div> */}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
      >
        {loading && <Loader />}
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">
            Download {type} zip
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="fs-3 text-dark fw-bold">Download {type} Zip</div> */}
            <div>
              <div className="filter-field-area">
                <fieldset className="rounded-0 mb-3">
                  <legend className="fs-5 fw-bold">Select Date</legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6} sm={12}>
                        <div>
                          <select
                            className="form-control"
                            value={searchOption3}
                            onChange={(e) => setSearchOption3(e.target.value)}
                          >
                            <option value="">Select Date</option>
                            <option value="date"> Date</option>
                            <option value="dateInBetween">
                              Date In Between
                            </option>
                            <i class="bi bi-caret-down-fill"></i>
                          </select>
                        </div>
                      </Col>

                      {searchOption3 === "date" ? (
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={searchValue3}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setSearchValue3("");

                                  document
                                    .getElementById("dateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("dateErr")
                                    .classList.add("d-none");
                                  setSearchValue3(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder={
                                searchOption === "Date" ? "Enter Date" : ""
                              }
                            />
                            <span id="dateErr" className=" text-danger d-none">
                              *Date must be lesser than current date.
                            </span>
                          </div>
                        </Col>
                      ) : searchOption3 === "dateInBetween" ? (
                        <>
                          <Col lg={6} sm={12}>
                            <div className="form-group gap-2">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={createStartDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setCreateStartDate("");
                                    document
                                      .getElementById("startDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    document
                                      .getElementById("startDateErr")
                                      .classList.add("d-none");
                                    setCreateStartDate(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Start Date"
                              />
                              <span
                                id="startDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                            </div>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={createEndDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setCreateEndDate("");
                                    document
                                      .getElementById("endDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    if (
                                      handleFromToDateInputChange(
                                        createStartDate,
                                        e.target.value
                                      ) &&
                                      createStartDate !== e.target.value
                                    ) {
                                      document
                                        .getElementById("endDateErr")
                                        .classList.add("d-none");
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.add("d-none");
                                      setCreateEndDate(e.target.value);
                                    } else {
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.remove("d-none");
                                    }
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="End Date"
                              />
                              <span
                                id="endDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                              <span
                                id="fromToDateErr"
                                className=" text-danger d-none"
                              >
                                *Start Date can't be greater than End Date!
                              </span>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </fieldset>

                <fieldset className="border-2 rounded-0 mt-3">
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6}>
                        <legend className="fs-5 fw-bold">
                          Select Merchant
                        </legend>
                        <div className="form-group">
                          <div className=" ">
                            <select
                              id="companyDropdown"
                              className="form-control"
                              onChange={handleCompanyChange}
                              value={selectedCompany}
                            >
                              <option value="">Select a Company</option>
                              {data?.map((merchant) => (
                                <option key={merchant.mid} value={merchant.mid}>
                                  {merchant.mid} - {merchant.merchantName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>

                      {/* <Col lg={6}>
                  <legend className="fs-5 fw-bold">Select Report Type</legend>
                    <div className="form-group">
                      <div className=" ">
                        <select
                          id="companyDropdown"
                          className="form-control"
                          onChange={(e)=>setType(e.target.value)}
                          value={type}
                        >
                          <option value="">Select Report</option>
                          {reportstype?.map((report) => (
                            <option key={report} value={report}>
                              {report.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col> */}
                    </Row>
                  </div>
                </fieldset>
              </div>
            </div>
            {/* <div className="d-flex justify-content-center gap-2 mt-4 py-1">
        <button
        className="fs-6 btn btn-sm  bg-success text-white text-center"
        onClick={handleSubmit}
      >
        Download Zip
        <DownloadIcon/>
      </button>
         
        </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center py-1">
          <button
            className="fs-6 btn btn-sm  bg-success text-white text-center"
            onClick={handleSubmit}
          >
            Download Zip
            <DownloadIcon />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewDowloadCSV;
