import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../utils/useApiServices";
import {
  GET_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE,
} from "../../utils/constant";
import swal from "sweetalert";
import useCheckPrivilages from "../../utils/checkPrivilages";

function WalletBalance({ toggleRightBar, type1 }) {
  const [walletBalance, setWalletBalance] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const hasPrivilage = useCheckPrivilages(2701);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const columns = [
    { headerName: "Merchant ID", field: "mid", minWidth: 150 },
    { headerName: "Company Name", field: "companyName", minWidth: 150 },
    { headerName: "Requested Amount", field: "actualAmount", minWidth: 150 },
    { headerName: "Amount", field: "amount", minWidth: 150 },
    { headerName: "Service Charge", field: "serviceCharge", minWidth: 150 },
    { headerName: "GST", field: "gstCharge", minWidth: 150 },
    { headerName: "Status", field: "status", minWidth: 150 },
    { headerName: "UTR", field: "utr", minWidth: 150 },
    { headerName: "Mode Of Transfer", field: "transferMode", minWidth: 150 },
    { headerName: "Created Date", field: "createDate", minWidth: 150 },
    { headerName: "Created Time", field: "createTime", minWidth: 150 },
    { headerName: "Action", field: "action", minWidth: 150 },
  ];
  // console.log("walllet-----------", walletBalance);
  const handleApproval = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this Wallet Balance?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          id: id,
          remark: "",
          status: "success",
        };

        apiCallFnforPost(UPDATE_WALLET_BALANCE, payload)
          .then((response) => {
            // console.log(response);
            if (response.statusCode === 200) {
              swal("Success", "Wallet Balance approved!", "success");

              setCallAgain(!callAgain);
            } else if (response.statusCode === 600) {
              swal("Failed", `${response.message}`, "error");
            } else {
              swal("Failed", `${response.message}`, "error");
            }
          })
          .catch((error) => {
            console.error("Error approving the balance:", error.message);
          });
      } else {
      }
    });
  };

  const handleReject = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this Wallet Balance Request?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const payload = {
          status: "failed",
          remark: remark,
          id: id,
        };

        apiCallFnforPost(UPDATE_WALLET_BALANCE, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              swal("Success", "Wallet Balance Rejected!", "success");

              setCallAgain(!callAgain);
            } else if (response.statusCode === 600) {
              swal("Failed", `${response.message}`, "error");
            } else {
              swal("Failed", `${response.message}`, "error");
            }
          })

          .catch((error) => {
            console.error("Error rejecting the application:", error.message);
          });
      } else {
      }
    });
  };

  useEffect(() => {
    apiCallFnforGet(GET_WALLET_BALANCE)
      .then((response) => {
        // console.log("Api===>",response)
        if (response.statusCode === 200) {
          const ApiResp = response?.data?.map((item, i) => ({
            id: i,
            actualAmount: item.actualAmount,
            amount: item.amount,
            serviceCharge: item.serviceCharge,
            gstCharge: item.gstCharge,
            status: item.status,
            companyName: item.companyName,
            mid: item.mid,
            utr: item.utr,
            transferMode: item.transferMode,
            createTime: item.createTime,
            createDate: item.createDate,

            action: (
              <div className="d-flex gap-2 align-items-center">
                <button
                  className="badge badge-success mt-2"
                  onClick={() => {
                    handleApproval(item.id);
                  }}
                >
                  Approve
                </button>
                <button
                  className="badge badge-danger mt-2"
                  onClick={() => {
                    handleReject(item.id);
                  }}
                >
                  Reject
                </button>
              </div>
            ),
          }));
          console.log("apiresponse--------------", ApiResp);
          setWalletBalance(ApiResp);
        } else {
          setWalletBalance([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching wallet data:", error);
      });
  }, [callAgain]);

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className=" d-flex justify-content-between bg-white">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Wallet Balance</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={walletBalance}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                     }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </div>  */}
    </>
  );
}

export default WalletBalance;
