import React, { useEffect, useState } from "react";
// import {
//   fetchAccountDetailsByMid,
//   fetchDocsByMidForaccounts,
// } from "../../utils/ApiServices";
import { Offcanvas } from "react-bootstrap";
import { Card, Row } from "react-bootstrap";
// import axios from "axios";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  FETCH_ACCOUNT_DETAILS_BY_MID,
  FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import swal from "sweetalert";

const offcanvasStyle = {
  width: "90vw", // Set width to 70vw
};

const ViewSingleSubmittedApplication = ({ mid, show, onHide }) => {
  const [responseData, setResponseData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { apiCallFnforGet } = useApiServices();

  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewDataClicked, setViewDataClicked] = useState(false);

  const FetchDataBymid = async () => {
    try {
      const apiResponse = await apiCallFnforGet(
        FETCH_ACCOUNT_DETAILS_BY_MID + mid
      );
      if (apiResponse.statusCode === 200) {
        setResponseData(apiResponse?.data);
        console.log(apiResponse);

        // setFinancialData(initializeFinancialData(responseData));
        console.log(apiResponse?.data);
      } else {
        swal({
          title: "ALERT!",
          text: "Something went wrong",
          icon: "warning",
        });
        // setOpenView(false);
      }
    } catch (error) {
      swal({
        title: "ALERT!",
        text: "Your Application Is Under Process!!",
        icon: "warning",
      });
      // setOpenView(false);
    }
  };

  useEffect(() => {
    FetchDataBymid();

    // setFinancialData(initializeFinancialData(responseData));
  }, []);

  useEffect(() => {
    // FetchDataBymid(mid, token);

    setFinancialData(initializeFinancialData(responseData));
  }, [responseData]);

  const initializeFinancialData = (responseData) => ({
    netSales: {
      firstYear: responseData.netSalesInFirstYear,
      secondYear: responseData.netSalesInSecondYear,
      thirdYear: responseData.netSalesInThirdYear,
    },
    netPurchase: {
      firstYear: responseData.netPurchaseInFirstYear,
      secondYear: responseData.netPurchaseInSecondYear,
      thirdYear: responseData.netPurchaseInThirdYear,
    },
    netProfit: {
      firstYear: responseData.netProfitInFirstYear,
      secondYear: responseData.netProfitInSecondYear,
      thirdYear: responseData.netProfitInthirdyear,
    },
    netExpenses: {
      firstYear: responseData.netExpensesInFirstyear,
      secondYear: responseData.netExpensesInSeconYear,
      thirdYear: responseData.netExpensesInThirdYear,
    },
  });

  const [financialData, setFinancialData] = useState(
    initializeFinancialData(responseData)
  );

  const handleViewData = async (mid, prev) => {
    try {
      const apiResponse = await apiCallFnforGet(
        FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT + mid
      );
      if (apiResponse.statusCode === 200) {
        setPdfFilenames(apiResponse.data);
        setViewDataClicked((prev) => !prev);
      } else {
      }
    } catch (error) {
      // console.error(error);
      console.log("Something went wrong. Please try again.");
    }
  };

  // const handleViewPDF = (filename) => {
  //   const encodedFilename = encodeURIComponent(filename);
  //   const headers = {
  //     Authentication: Bearer ${token},
  //   };
  //   axios
  //     .get(
  //       ${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename},
  //       {
  //         responseType: "arraybuffer",
  //         headers,
  //       }
  //     )
  //     .then((response) => {
  //       const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  //       window.open(pdfUrl, "_blank");
  //       // setSelectedFile(pdfUrl);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching PDF:", error.message);
  //     });
  // };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement="end"
        style={offcanvasStyle}
        backdrop="static" 
        keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          {/* <h5 class="offcanvas-title fw-bold" id="offcanvasLabel">
            Details
          </h5> */}
        <div className="fs-2 text-dark fw-bold">Details</div>

        </div>
        <Offcanvas.Body>

        <div className="p-4">
            <div className="table table-bordered">
              <div className="row">
                {[
                  { label: "Merchant Name", value: responseData.companyName },
                  { label: "MID", value: responseData.mid },
                  { label: "E-mail", value: responseData.email },
                  {
                    label: "Deposit In Lumpsum",
                    value: responseData.depositeLumpsum,
                  },
                  {
                    label: "Deposit in Percentage",
                    value: responseData.depositePercentage,
                  },
                  {
                    label: "Hold In Percentage",
                    value: responseData.holdPercentage,
                  },
                  {
                    label: "Guarantee Amount",
                    value: responseData.guarantyAmount,
                  },
                  {
                    label: "Chargeback Declaration In Percentage",
                    value: responseData.chargebackDeclarationPercentage,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="col-6 border border-dark border-bottom"
                    style={{
                      borderRight: "0.5px solid black",
                      borderLeft: "0.5px solid black",
                      paddingBottom: "0px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center table table-bordered">
                      <div className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                        {item.label} <RemoveIcon />
                      </div>
                      <div className="mt-2 w-100 text-end">{item.value}</div>
                    </div>
                  </div>
                ))}

                <div className="justify-content-center mt-4">
                  <div className="fs-3 text-dark">MDR Percentage</div>
                </div>

                {responseData?.mdrData?.map((item, index) => (
                  <div
                    key={index}
                    className="col-6 border border-dark border-bottom"
                    style={{
                      borderRight: "0.5px solid black",
                      borderLeft: "0.5px solid black",
                      paddingBottom: "0px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center table table-bordered">
                      <div className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                        {item.service} Percentage <RemoveIcon />
                      </div>
                      <div className="mt-2 w-100 text-end">
                        {item.mdrPercentage}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>


        
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewSingleSubmittedApplication;
