import React, { useState } from "react";
import { OnBoardingIcons } from "../../Assets/images/image";
import styles from "../Reports/report.module.css";
import SlideBox from "../../common/SlideBox/SlideBox";
import Phase1Applications from "./Phase1Applications";
import SubmittedRoutingPayin from "./PayinRoutingSubmitted";
import PayoutSubmitted from "./PayoutSubmitted";
import SubmittedRoutingEkyc from "./EkycRoutingSubmitted";
import MDRSubmittedAccountApplications from "./MDRSubmitted";
import FRMSubmitted from "./FRMSubmitted";
import useCheckPrivilages from "../../utils/checkPrivilages";
import SubmittedMobileApp from "./SubmittedMobileApp";
import SubmittedMobileApplications from "./SubmittedMobileApp";

const OnBoardingView = () => {
  const [flag, setFlag] = useState(false);
  const [report, setReport] = useState("");

  const reportType = {
    Phase1Applications: "Phase1Applications",
    SubmittedRoutingPayin: "SubmittedRoutingPayin",
    PayoutSubmitted: "PayoutSubmitted",
    SubmittedRoutingEkyc: "SubmittedRoutingEkyc",
    MDRSubmittedAccountApplications: "MDRSubmittedAccountApplications",
    FRMSubmitted: "FRMSubmitted",
    MobileAppOnboardingSubmitted:"MobileAppOnboardingSubmitted",
  };

  const toggleRightBar = (type) => {
    if (type) {
      setReport(type);
    }
    setFlag(!flag);
  };
  const hasPrivilages2693 = useCheckPrivilages(2693);
  const hasPrivilages2694 = useCheckPrivilages(2694);
  const hasPrivilages2695 = useCheckPrivilages(2695);
  const hasPrivilages2630 = useCheckPrivilages(2630);
  const hasPrivilages2142 = useCheckPrivilages(2142);
  const hasPrivilages2641 = useCheckPrivilages(2641);
  const hasPrivilages2677 = useCheckPrivilages(2677);
  const hasPrivilages2633 = useCheckPrivilages(2633);
  const hasPrivilages2634 = useCheckPrivilages(2634);
  const hasPrivilages2635 = useCheckPrivilages(2635);
  const hasPrivilages2636 = useCheckPrivilages(2636);
  const hasPrivilages2637 = useCheckPrivilages(2637);
  const hasPrivilages2638 = useCheckPrivilages(2638);
  const hasPrivilages2639 = useCheckPrivilages(2639);
  const hasPrivilages2640 = useCheckPrivilages(2640);
  const hasPrilage2595 = useCheckPrivilages(2595)

  return (
    <div className="content-body">
      <div className="container-fluid ">
        <div className="card card-inverse card-primary text-center">
          <div className="card-body pb-1" >
            <SlideBox flag={flag} toggleRightBar={toggleRightBar}>
              {reportType[report] === "Phase1Applications" && (
                <Phase1Applications
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "MobileAppOnboardingSubmitted" && (
                <SubmittedMobileApplications
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "SubmittedRoutingPayin" && (
                <SubmittedRoutingPayin
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "PayoutSubmitted" && (
                <PayoutSubmitted
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "SubmittedRoutingEkyc" && (
                <SubmittedRoutingEkyc
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "MDRSubmittedAccountApplications" && (
                <MDRSubmittedAccountApplications
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "FRMSubmitted" && (
                <FRMSubmitted type={report} toggleRightBar={toggleRightBar} />
              )}
            </SlideBox>

            <div class="row text-center text-lg-start">
              {hasPrivilages2693 ||
              hasPrivilages2694 ||
              hasPrilage2595||
              hasPrivilages2695 ||
              hasPrivilages2630 ||
              hasPrivilages2142 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("Phase1Applications")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.Application}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Applications
                    </h5>
                  </div>
                </div>
              ) : null}
              {hasPrivilages2633 ||
              hasPrivilages2634 
             ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("MobileAppOnboardingSubmitted")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.mobileAppOnboard}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Mobile App On-Boarding
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2633 ||
              hasPrivilages2634 
             ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("SubmittedRoutingPayin")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.payinrouting}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payin Routing
                    </h5>
                  </div>
                </div>
              ) : null}

              {
              hasPrivilages2635 ||
              hasPrivilages2636 
              ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("PayoutSubmitted")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.payout}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payout Routing
                    </h5>
                  </div>
                </div>
              ) : null}
              {
              hasPrivilages2637 ||
              hasPrivilages2638 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("SubmittedRoutingEkyc")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.ekyc}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      e-kYC Routing
                    </h5>
                  </div>
                </div>
              ) : null}
              {hasPrivilages2639 || hasPrivilages2640 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() =>
                      toggleRightBar("MDRSubmittedAccountApplications")
                    }
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.voucher}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">MDR</h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2641 || hasPrivilages2677 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("FRMSubmitted")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={OnBoardingIcons.trap}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Fraud & Risk Management
                    </h5>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingView;
