import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../../utils/useApiServices";
import { FETCH_MERCHANT_FOR_SID, SWIPELINC_API } from "../../../utils/constant";
// import { FaCheck, FaTimes } from 'react-icons/fa';
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Card, Modal } from "react-bootstrap";
import axios from "axios";

const SIDMerchantOnboarding = ({ type, toggleRightBar }) => {
  const { apiCallFnforGet } = useApiServices();
  const [merchants, setMerchants] = useState([]);
  const [selectedSidDetails, setSelectedSidDetails] = useState([]);
  const [selectedMerchantId, setSelectedMerchantId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCallFnforGet(FETCH_MERCHANT_FOR_SID);
        console.log("here---->", response.data);
        if (response.statusCode === 200) {
          setMerchants(response.data);
        } else {
          setMerchants([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  //   const renderButton = (status, id) => {
  //     console.log('Status:', status);
  //     console.log('ID:', id);

  //     return (
  //       <div className="d-inline-flex align-items-center">
  //         <div className={rounded-circle border border-2 ${status ? 'border-success' : 'border-danger'} p-2 mr-2}>
  //           {status ? <FaCheck style={{ color: 'green', fontSize: '20px' }} /> : <FaTimes style={{ color: 'red', fontSize: '20px' }} />}
  //         </div>
  //       </div>
  //     );
  //   };

  const handleSidClick = async (id) => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getSidByid/${id}?id=${id}`,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );

      if (response.data.statusCode === 200) {
        const data = Array.isArray(response.data.data)
          ? response.data.data
          : [response.data.data];
        const finalres = data.map((item, i) => ({
          id: i,
          company: item.company,
          masterMarchantName: item.masterMarchantName,
          vehicleName: item.vehicleName,
          sid: item.sid,
          maxTxnAmount: item.maxTxnAmount,
          remainingLimit: item.remainingLimit,
          createDateTime: item.createDateTime,
          updatedDateTime: item.updatedDateTime,
        }));

        setSelectedSidDetails(finalres);
        setSelectedMerchantId(id);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching SID details:", error);
    }
  };

  const column = [
    { headerName: "Company", field: "company", minWidth: 150 },
    {
      headerName: "Master Marchant Name",
      field: "masterMarchantName",
      minWidth: 150,
    },
    { headerName: "Vehicle Name", field: "vehicleName", minWidth: 150 },
    { headerName: "SID", field: "sid", minWidth: 150 },
    {
      headerName: "Max Transaction Amount",
      field: "maxTxnAmount",
      minWidth: 150,
    },
    { headerName: "Remaining Limit", field: "remainingLimit", minWidth: 150 },
    { headerName: "Create DateTime", field: "createDateTime", minWidth: 150 },
    { headerName: "Updated DateTime", field: "updatedDateTime", minWidth: 150 },
  ];

  const columns = [
    {
      headerName: "Merchant Name",
      field: "merchantName",
      minWidth: 400,
    },
    {
      headerName: "LINCPAY",
      field: "LINCPAY",
      minWidth: 150,
    },
    {
      headerName: "ICEPE",
      field: "ICEPE",
      minWidth: 150,
    },
    {
      headerName: "SILVERPAY",
      field: "SILVERPAY",
      minWidth: 150,
    },
    {
      headerName: "SWIPELINC",
      field: "SWIPELINC",
      minWidth: 150,
    },
  ];

  const renderButton = (status, id) => {
    const buttonColor = status ? "green" : "red";
    const icon = status ? (
      <TaskAltIcon
        style={{ color: buttonColor, width: "30px", height: "30px" }}
      />
    ) : (
      <HighlightOffIcon
        style={{ color: buttonColor, width: "30px", height: "30px" }}
      />
    );
    return (
      <div
        className="d-inline-flex align-items-center p-2"
        onClick={() => handleSidClick(id)}
      >
        {/* <div className={rounded-circle border border-2 ${status ? 'border-success' : 'border-danger'} p-2 mr-2}> */}
        {icon}
        {/* </div> */}
      </div>
    );
  };

  const mappedData = merchants.map((item, i) => ({
    ...item,
    id: i,
    LINCPAY: renderButton(item.data.LINCPAY, item.ids.LINCPAY),
    ICEPE: renderButton(item.data.ICEPE, item.ids.ICEPE),
    SILVERPAY: renderButton(item.data.SILVERPAY, item.ids.SILVERPAY),
    SWIPELINC: renderButton(item.data.SWIPELINC, item.ids.SWIPELINC),
  }));

  return (
    <>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>SID Details:</Modal.Title>
        </Modal.Header>
        {/* <button className="close-button" onClick={() => setIsModalOpen(false)}>Close</button> */}
        <Modal.Body>
          <div className="border-0 mb-4">
            <>
              <div className="merchant-report-view">
                <div className="row mt-4 ">
                  <DataGrid
                    rows={selectedSidDetails}
                    columns={column}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                  />
                </div>
              </div>
            </>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container-fluid">
        <div className="d-flex justify-content-start align-items-center">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">
              SID Merchant-Onboarding
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={mappedData}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "active" || column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SIDMerchantOnboarding;
