import { createSlice } from "@reduxjs/toolkit";

const utilSlice = createSlice({
  name: "utilsData",
  initialState: {
   loading:false,
  },
  reducers: {
    requestSent: (state) => {
         state.loading = true;
    },
    requestFailed: (state) => {
          state.loading = false;
    },
    responseReceived: (state) => {
          state.loading = false;
    }
  },
});

export const {
   requestSent,
   requestFailed,
   responseReceived
} = utilSlice.actions;

export default utilSlice.reducer;