import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";
import { config, getCompleteDomain, getServerEnvironment } from "../utils/helper";
import { CHANGE_PASSWORD, FORGOT_PASSWORD_API, OTP_VERIFY_API } from "../utils/constant";
import useApiServices from "../utils/useApiServices";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [value, setValue] = useState();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [generalError, setGeneralError] = useState(null);
    const [formError, setFormError] = useState({
        email: null,
        otp: null,
        password: null,
    });
       const navigate = useNavigate()
       const {apiCallFnforPostForOpenUrl} = useApiServices();
    const {logoPath,Name}=config(getServerEnvironment(getCompleteDomain()))
  
    const AppNameCapitalized=Name
    const AppName = AppNameCapitalized.toUpperCase()  

    const [success, setSuccess] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormError({ ...formError, email: null });

        try {
            const requestPayload = {
                emailId: email,
            };

            const apiResponse = await apiCallFnforPostForOpenUrl(FORGOT_PASSWORD_API, requestPayload);

            if (apiResponse.statusCode === 200) {
                // console.log(apiResponse.data);
                setSuccess(true);
              
            } else {
                setGeneralError(apiResponse.message);
            }
        } catch (error) {
            // console.error(error);
            setGeneralError("Something went wrong. Please try again.");
        }
         
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setFormError({ ...formError, otp: null });

        try {
            const otpPayload = {
                email:email,
                otp: otp,
            };

            const otpVerifyResponse = await apiCallFnforPostForOpenUrl(OTP_VERIFY_API, otpPayload);

            if (otpVerifyResponse.statusCode === 200) {
              
                setOtpVerified(true);
            } else {
                setFormError({ ...formError, otp: otpVerifyResponse.message });
            }
        } catch (error) {
            console.error(error);
            setGeneralError("Something went wrong. Please try again.");
        }
    };

    const handleNewPasswordSubmit = async (e) => {
        e.preventDefault();
        setFormError({ ...formError, password: null });

        try {
            if (newPassword !== confirmPassword) {
                setFormError({ ...formError, password: "Passwords do not match" });
                return;
            }

            const changePasswordPayload = {
                emailId: email,
                newPassword: newPassword,
            };

            const changePasswordResponse = await apiCallFnforPostForOpenUrl(CHANGE_PASSWORD, changePasswordPayload);

            if (changePasswordResponse.statusCode === 200) {
                // console.log(changePasswordResponse.data);
                setSuccess(true);
                setValue(changePasswordResponse.statusCode);
                setEmail("");
                setNewPassword("");
                setConfirmPassword("");
                navigate("/");
              
            } else {
                setGeneralError(changePasswordResponse.message);
            }
        } catch (error) {
            console.error(error);
            setGeneralError("Something went wrong. Please try again.");
        }

    };
    return(
        <>
        <div className="authincation h-100">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
            <div className="login-form">
              <div className="text-center">
                <h3 className="title">Forgot Password?</h3>
                <p>Please Enter Your Email</p>
              </div>

              {success && otpVerified ? (
                <form onSubmit={handleNewPasswordSubmit}>
                 {generalError && (
                <p className="alert alert-danger mb-4">
                  {generalError ?  generalError : ""}
                </p>
              )}
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="hello@example.com"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-1 text-dark">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-row d-flex justify-content-between mt-4 mb-2">
                    <div className="mb-4">
                    
                    </div>
                    <div className="mb-4">
                      <Link
                        href="page-forgot-password.html"
                        className="btn-link text-primary"
                      >
                        Need help?
                      </Link>
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Submit
                    </button>
                  </div>
                </form>
              ) : success ? (
                <form onSubmit={handleOtpSubmit}>
                {generalError && (
                <p className="alert alert-danger mb-4">
                  {generalError ? generalError : ""}
                </p>
              )}
                  <div className="mb-4">
                    <label className="mb-1 text-dark">OTP</label>
                    <input
                      type="text"
                      className="form-control form-control"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="please enter OTP"
                    />
                  </div>
                  <div className="form-row d-flex justify-content-between mt-4 mb-2">
                    <div className="mb-4">
                      
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Submit
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleFormSubmit}>
                {generalError && (
                <p className="alert alert-danger mb-4">
                  {generalError ? generalError : ""}
                </p>
              )}  
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Email</label>
                    <input
                      type="email"
                      className="form-control form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="hello@example.com"
                    />
                  </div>
                  <div className="form-row d-flex justify-content-between mt-4 mb-2">
                    <div className="mb-4">
                    
                    </div>
                    <div className="mb-4">
                      <Link
                        href=""
                        className="btn-link text-primary"
                      >
                        Need help?
                      </Link>
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Submit
                    </button>
                  </div>
                </form>
              )}
                
            </div>
          </div>
          
          <div className="col-xl-6 col-lg-6">
            <div className="pages-left h-100">
              <div className="login-content">
              <Link to="/">
                  <img src="images/logo-full.png" className="mb-3" alt="" />
                </Link>

                <p>
                  Your true value is determined by how much more you give in
                  value than you take in payment. ...
                </p>
              </div>
              <div className="login-media text-center">
                <img src="images/login-1.png" className="w-50" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}
export default ForgotPassword;