import React, { useState } from "react";
import swal from "sweetalert";
import { Card, Container, Offcanvas } from "react-bootstrap";
import frmTemplate from "../../Assets/templateFiles/frmTemplate.csv";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  FRM_BLOCK_UPLOAD,
  FRM_BLOCK_USER,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";

const parameter = [
  { id: 1, name: "VPA", payloadName: "vpa" },
  { id: 2, name: "IMEI Number / Device Id", payloadName: "imei" },
  { id: 3, name: "Email ID", payloadName: "email" },
  { id: 4, name: "Mobile Number", payloadName: "mobile" },
  { id: 5, name: "IFSC Code", payloadName: "ifscCode" },
  { id: 6, name: "Location", payloadName: "location" },
];

const FraudManagement = ({ onHide, show, refresh, setRefresh}) => {
  const [selectParameterData, setSelectParameterData] = useState(parameter);
  const [selectedParameter, setSelectedParameter] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [mid, setMid] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const offcanvasStyle = {
    width: "50vw", // Set width to 70vw
  };
  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setMid("");
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };
  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
    setMerchantData(res?.data);
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setFailureMessage("Please select a file to upload.");
      setShowMessage(true);

      return;
    }
    let formdata = new FormData();
    formdata.append("file", selectedFile);

    apiCallFnforPost(FRM_BLOCK_UPLOAD, formdata)
      .then((response) => {
        console.log(response);
        if (response.statusCode === 200) {
          console.log(response.data);

          swal({
            title: "Success",
            text: `${response.size} File Uploaded Successfully.`,
            icon: "success",
          });
        } else if (response.statusCode === 1001) {
          const errorData = response.data;
          const errorDublicateData = response.duplicateData;

          console.log(errorDublicateData);

          let errorMessage = " \n  File uploaded partially as:\n";
          let errorDublicateMessage = " \n  Data \n";
          let SuccessMsg = `${response.size} files uploaded Successfully. \n`;

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext =
            SuccessMsg + "\n" + errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.statusCode === 422) {
          const errorData = response.data;
          const errorDublicateData = response.duplicateData;

          console.log(errorDublicateData);

          let errorMessage = "File not uploaded as:\n";
          let errorDublicateMessage = " \n  Data \n";

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext = errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.statusCode === 600) {
          const errorMessage =
            response.message || "Error occurred while reading the file.";
          setFailureMessage(errorMessage);
          swal({
            title: `${response.status}`,
            text: errorMessage,
            icon: "error",
          });
        } else if (response.statusCode === 500) {
          swal({
            title: "Failed",
            text: "Please Upload CSV File",
            icon: "error",
          });
        } else if (response.statusCode === 101) {
          swal({
            title: "Failed",
            text: "File Not Uploaded Because Data Is Missing or InCorrect",
            icon: "error",
          });
        } else {
          setFailureMessage("File Not Uploaded, Please Upload Valid File.");
        }
        setShowMessage(true);

        document.getElementById("fileUpload").value = "";
        formdata = null;
        setSelectedFile(null);
      })

      .catch((error) => {
        setSuccessMessage("");
        setFailureMessage("File upload failed.");
        setShowMessage(true);

        console.error(error);
      });
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const handelParameterChange = (e) => {
    // setData({ ...data, [e.target.name]: e.target.value });

    // const selectedOption = e.target.options[e.target.selectedIndex];
    // const prifix = selectedOption.getAttribute("data-prifix");

    let val = e.target.value;

    // console.log(typeof val);
    // console.log(selectParameterData);

    let select = selectParameterData.filter(
      (data) => data.id === parseInt(val)
    );

    // console.log("select >>>>", select);

    const remaining = selectedParameter.filter((elem) => {
      return elem.id !== select[0].id;
    });

    // console.log("remaining >>>>>>", remaining);

    setSelectedParameter([...remaining, ...select]);

    // console.log(selectedParameter);

    setSelectParameterData(
      selectParameterData.filter((elem) => {
        return elem.id !== parseInt(val);
      })
    );
  };
  //   console.log("mid is :",mid)

  const removeParameter = (id, elem) => {
    setSelectedParameter(
      selectedParameter?.filter((elem) => {
        return elem.id !== id;
      })
    );
    setSelectParameterData([...selectParameterData, elem]);
  };

  const handelParameterInput = (e, id) => {
    if (id === 6) {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    } else {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      doBlock: true,
      email: "",
      ifscCode: "",
      imei: "",
      letitude: "",
      longitude: "",
      // mid: mid,
      mobile: "",
      vpa: "",
    };

    selectedParameter.forEach((elem) => {
      if (elem.id === 1) payload.vpa = elem.inputVal;
      if (elem.id === 2) payload.imei = elem.inputVal;
      if (elem.id === 3) payload.email = elem.inputVal;
      if (elem.id === 4) payload.mobile = elem.inputVal;
      if (elem.id === 5) payload.ifscCode = elem.inputVal;
      if (elem.id === 6) {
        payload.letitude = elem.Latitude;
        payload.longitude = elem.Longitude;
      }
    });

    // console.log("payload >>>", payload);

    let res = await apiCallFnforPost(FRM_BLOCK_USER, payload);

    if (res.statusCode === 200) {
      swal({
        title: "Success!",
        text: res.message,
        icon: "success",
      });
      setSelectParameterData(parameter);
      setSelectedParameter([]);
      setMerchantData([]);
      onHide();
      setRefresh(!refresh);
    } else {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      });
    }
  };

  const handleClose =()=>{
    onHide();
    setSelectParameterData(parameter);
    setSelectedParameter([]);
    setMerchantData([]);
    setRefresh(!refresh);
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
        keyboard={false}
      >
        {/* <Offcanvas.Header closeButton> */}
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton> </Offcanvas.Header>
          <h5
            class="offcanvas-title fw-bold fs-2 text-dark"
            id="offcanvasLabel"
          >
            Fraud Management
          </h5>
        </div>
        {/* </Offcanvas.Header> */}
        <Offcanvas.Body>
          <div className="d-flex justify-content-center flex-wrap gap-4 p-3">
            <div className="w-50 p-4 rounded">
              <div className="form-group upload-field d-flex align-items-end gap-3 mb-4">
                <div className="input-upload-file">
                  <label className="text-dark fs-5">FRM CSV</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileUpload}
                    id="fileUpload"
                  />
                </div>
                <div>
                  <button
                    className="badge badge-primary mb-3"
                    onClick={handleUpload}
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div className="mt-5">
                <h4 className="h5 mb-3">FRM CSV Template</h4>
                <a
                  className="btn btn-success"
                  title="Click here to download Chargeback file template"
                  href={frmTemplate}
                  download
                >
                  Download Template
                </a>
              </div>
            </div>
            <div className="w-100 mt-5">
              <div className="d-flex flex-column align-items-center">
                <div className="col-md-6 mb-3">
                  <label className="text-dark fs-5">Select A Parameter</label>
                  <select
                    id="companyDropdown"
                    className="form-control"
                    onChange={handelParameterChange}
                    name="merchantSelect"
                  >
                    <option value="">Select a Parameter</option>
                    {selectParameterData?.map((parameter) => (
                      <option key={parameter.id} value={parameter.id}>
                        {parameter.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  {selectedParameter?.map((elem, index) => {
                    if (elem.id === 6) {
                      return (
                        <div key={elem.sid} className="mb-3">
                          <div className="form-group">
                            <label className="text-dark fs-5">
                              {elem.name} (Latitude)
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              name="Latitude"
                              placeholder={`Enter ${elem.name}`}
                              value={elem.Latitude}
                              onChange={(e) => handelParameterInput(e, elem.id)}
                            />
                            <label className="text-dark fs-5">
                              {elem.name} (Longitude)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="Longitude"
                              placeholder={`Enter ${elem.name}`}
                              value={elem.Longitude}
                              onChange={(e) => handelParameterInput(e, elem.id)}
                            />
                            <div className="text-end mt-2">
                              <span
                                onClick={() => {
                                  removeParameter(elem.id, elem);
                                }}
                                style={{ cursor: "pointer" }}
                                className="text-danger fs-4"
                              >
                                {/* <i className="bi bi-trash-fill"></i> */}
                                <DeleteForeverIcon/>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={elem.sid} className="mb-3">
                          <div className="form-group">
                            <label htmlFor="email" className="text-dark fs-5">
                              {elem.name}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="txnAmt"
                              placeholder={`Enter ${elem.name}`}
                              value={elem.inputVal}
                              onChange={(e) => handelParameterInput(e, elem.id)}
                            />
                            <div className="text-end mt-2">
                              <span
                                onClick={() => {
                                  removeParameter(elem.id, elem);
                                }}
                                style={{ cursor: "pointer" }}
                                className="text-danger fs-4"
                              >
                                <DeleteForeverIcon/>
                                {/* <i className="bi bi-trash-fill"></i> */}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="badge badge-primary px-3 py-2"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FraudManagement;
