import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  SWIPELINC_API,
  YES_BANK_ONBOARD_WITH_MID,
  MOBILE_APP_ONBOARDING_APPROVED,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import swal from "sweetalert";
import MobileAppApprovedDetails from "./MobileAppApprovedDetails";

const OnbordingApproved = () => {
  const [applications, setApplications] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [data, setData] = useState({});
  const [openServices, setOpenServices] = useState({});
  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [showPaymentServices, setShowPaymentServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [dataApplication, setDataApplication] = useState([]);

  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const fetchSubmittedApplications = async () => {
    try {
      const response = await apiCallFnforGet(MOBILE_APP_ONBOARDING_APPROVED);

      if (response.statusCode === 200) {
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          ...item,
          paymentServices: item.paymentService.map((service) => ({
            serviceName: service.paymentService,
            mdrPercentage: service.mdrPercentage,
          })),
          ...item,
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSubmittedApplications();
  }, []);

  const handleOnBoard = (mid) => {
    axios
      .post(YES_BANK_ONBOARD_WITH_MID + mid, "", {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          swal({
            title: "SUCCESS",
            text: "Merchant On-Boarded Successfully.",
            icon: "success",
          });
        } else {
          swal({
            title: "Failed",
            text: "Merchant On-Boarding Failed.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
      });
  };

  const handleViewDetails = (data) => {
    setId(data?.registrationId);
    setDataApplication(data);
    setSelectedServices({
      ...selectedServices,
      [data.registrationId]: showPaymentServices[data.registrationId],
    });
    setOpenView(true);
  };

  const handleClose = () => {
    setId("");
    setOpenView(false);
  };

  const formatServices = (services) => {
    if (!services || services.length === 0) {
      return "No Services Available";
    }
    return services.map((service) => service.serviceName).join(", ");
  };

  const columns = [
    { headerName: "Company Name", field: "companyName", minWidth: 150 },
    {
      headerName: "Authorized Person Name",
      field: "authorizedPersonName",
      minWidth: 150,
    },
    { headerName: "e-Mail ID", field: "email", minWidth: 150 },
    { headerName: "Mobile Number", field: "mobile", minWidth: 150 },
    {
      headerName: "Company PAN Number",
      field: "companyPanNumber",
      minWidth: 150,
    },
    { headerName: "GST Number", field: "gstNumber", minWidth: 150 },
    {
      headerName: "Services",
      field: "paymentServices",
      minWidth: 150,
      renderCell: (params) => (
        <div>{formatServices(params.row.paymentServices)}</div>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 150,
      renderCell: (params) => (
        <button
          className="badge badge-rounded badge-warning"
          // className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleViewDetails(params.row)}
        >
          View Details
        </button>
      ),
    },
    {
      headerName: "On-Board",
      field: "onboard",
      minWidth: 150,
      renderCell: (params) => (
        <button
          className="badge badge-rounded badge-primary"
          type="button"
          onClick={() => handleOnBoard(params.row.mid)}
        >
          On-Board
        </button>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={applications}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        autoHeight
      />

      {openView && (
        <MobileAppApprovedDetails
          id={id}
          data={dataApplication}
          handleClose={handleClose}
          openView={openView}
        />
      )}
    </>
  );
};

export default OnbordingApproved;
