// import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { fetchAllFRMUsers } from "../../../utils/ApiServices";
import swal from "sweetalert";
import axios from "axios";
import { FETCH_FRM_USERS, SWIPELINC_API } from "../../../utils/constant";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../../utils/useApiServices";
import FraudManagement from "../FraudManagement";
import CustomTip from "../../../common/CustomToolTip/CustomTip";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const SubmittedFraudManagement = ({ toggleRightBar, type1 }) => {
  const [userData, setUserData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [show, setShow] = useState(false);
  const[refresh,setRefresh]=useState(false);
  const { apiCallFnforGet } = useApiServices();

  const disableAggregator = async (e, id) => {
    const EnableOrDisable = e.target.value;

    swal({
      title: "Alert",
      text: `Are you sure, you want to ${EnableOrDisable}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        axios
          .put(
            `${SWIPELINC_API}/MerchantAdminPanel/merchant/frm-block/${EnableOrDisable}/${id}`,
            "",
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.statusCode === 200) {
              swal({
                title: "Success",
                text: `Aggregator ${EnableOrDisable} Successfully.`,
                icon: "success",
              });
              setCallAgain((prevState) => !prevState);
              getAllFRMUsers(token);
            } else {
              swal({
                title: "Success",
                text: `Failed to ${EnableOrDisable} Aggregator.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
      }
    });
  };

  const columns = [
    { headerName: "VPA", field: "vpa", minWidth: 150 },

    { headerName: "IMEI Number", field: "imei", minWidth: 150 },
    { headerName: "Latitude", field: "letitude", minWidth: 150 },
    { headerName: "Longitude", field: "longitude", minWidth: 150 },
    { headerName: "e-Mail", field: "email", minWidth: 150 },
    { headerName: "Mobile", field: "mobile", minWidth: 150 },
    { headerName: "IFSC Code", field: "ifscCode", minWidth: 150 },
    { headerName: "Enable/Disable", field: "doBlock", minwidth: "150" },
  ];
  // const mappedData = userData?.map((item) => {
  //     const mappedItem = { ...item};
  //         mappedItem.doBlock = (
  //           <div>
  //             <button
  //               className={`btn ${!item.doBlock ? "btn-danger" : "btn-primary"}`}
  //               value={item.doBlock ? "enable" : "disable"}
  //               onClick={(e) => disableAggregator(e, item.id)}
  //             >
  //               {item.doBlock ? "Enable" : "Disable"}
  //             </button>
  //           </div>
  //         );
  //     return mappedItem;
  // })

  const getAllFRMUsers = async () => {
    let res = await apiCallFnforGet(FETCH_FRM_USERS);

    if (res.statusCode === 200) {
      const finalRes = res.data.map((item, i) => ({
        id: i,
        vpa: item.vpa,
        imei: item.imei,
        letitude: item.letitude,
        longitude: item.longitude,
        email: item.email,
        mobile: item.mobile,
        ifscCode: item.ifscCode,
        doBlock: (
          <div>
            <button
              className={`badge badge-rounded ${
                !item.doBlock ? "badge-danger" : "badge-success"
              }`}
              value={item.doBlock ? "enable" : "disable"}
              onClick={(e) => disableAggregator(e, item.id)}
            >
              {item.doBlock ? "Enable" : "Disable"}
            </button>
          </div>
        ),
      }));
      setUserData(finalRes);
    } else setUserData([]);
  };
  useEffect(() => {
    getAllFRMUsers();
  }, [refresh, setRefresh]);
  const handleClose = () => setShow(false);

  const handleOnClick = () => {
    setShow(true);
  };

  return (
    // <div class="content-body">
    <div class="container-fluid">
      <div className="d-flex justify-content-between bg-white  ">
        <div className="d-flex justify-content-start align-items-center ">
          <button
            type="button"
            class="btn-close m-3"
            onClick={toggleRightBar}
          ></button>

          <div className="fs-2 text-dark fw-bold">Fraud Management  </div>
        </div>
        <div className="d-flex gap-2 justify-content-end mt-4 me-4">
          <CustomTip size="18" placement="top" title=" Fraud Management">
            <div className="" onClick={handleOnClick}>
              <ManageAccountsIcon  fontSize="large"/>
            </div>
          </CustomTip>
        </div>
        {/* <div className="d-flex justify-content-end">
            <button
              className="badge badge-primary px-2 py-3 mb-3"
              
            >
              Fraud Management
            </button>
          </div> */}
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <div style={{ height: "500px", width: "100%" }}>
                  <DataGrid
                    rows={userData}
                    columns={columns?.map((column) => ({
                      ...column,
                      renderCell: (params) =>
                        column.field === "doBlock"
                          ? params.value
                          : params.value,
                    }))}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </div>
              </div>
              {/* </section> */}

              <div></div>
            </div>
          </div>
        </div>
      </div>
      <FraudManagement show={show} onHide={handleClose}  setRefresh={setRefresh} refresh={refresh}/>
    </div>
  );
};

export default SubmittedFraudManagement;
