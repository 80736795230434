import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import {
  getMechantForPayoutApiTest,
  getMerchantInfoByIdPayout,
  testCallbackURLPayout,
} from "../../utils/ApiServices";
import swal from "sweetalert";
import CallbackPayin from "./CallBackPayinTest";
import useApiServices from "../../utils/useApiServices";
import {
  CALLBACK_PAYOUT_BY_MID,
  FETCH_ALL_MERCHANT_VALIDATIONS,
} from "../../utils/constant";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const CallBackPayout = ({ toggleRightBar, type1 }) => {
  const [merchant, setMerchant] = useState([]);
  const token = localStorage.getItem("token");
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);

  const [parsedResponse, setParsedResponse] = useState(null);
  const { apiCallFnforGet } = useApiServices();

  const [merchantData, setMerchantData] = useState({
    callbackURL: "",
    jsonString: `{
      "attempt": 0,
      "bankReferenceNumber": "",
      "beneficiaryName": "",
      "callbackTxnId": "",
      "decentroTxnId": "",
      "errorKey": "",
      "originalCallbackTxnId": "",
      "providerMessage": "",
      "referenceId": "",
      "timestamp": "",
      "transactionAmount": 0,
      "transactionMessage": "",
      "transactionStatus": "",
      "transferType": ""
    }`,
  });

  const [formData, setFormData] = useState({
    merchantMid: "",
    customCallbackURL: "",
    jsonString: "",
  });

  const handelInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setMerchantData({ callbackURL: "" });
  };
  const getMerchantInfo = async (mid) => {
    if (mid === "") {
      swal({
        title: "Alert",
        text: "Please Select A Merchant",
        icon: "error",
      });
      return;
    }

    try {
      const res = await apiCallFnforGet(CALLBACK_PAYOUT_BY_MID + mid);

      if (res.statusCode === 200) {
        setMerchantData({
          callbackURL: res.data[0]?.callbackUrl || "Callback URL not found",
        });
      } else {
        swal({
          title: "Alert",
          text: "callback URL not found",
          icon: "error",
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  const getAllMerchantForApiTest = async () => {
    // setShowLoader(true);
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
    // setShowLoader(false);
    setMerchant(res?.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const submitCallbackURL = async () => {
    const callbackURL = formData.customCallbackURL || merchantData.callbackURL;

    if (formData.merchantMid === "") {
      swal({
        title: "Alert",
        text: "Please select a merchant",
        icon: "error",
      });
      return;
    }

    try {
      // setShowLoader(true);
      const jsonStringToSend =
        formData.customCallbackURL && formData.jsonString !== ""
          ? formData.jsonString
          : merchantData.jsonString;

      const res = await testCallbackURLPayout(
        token,
        callbackURL,
        jsonStringToSend || "{}"
      );
      // setShowLoader(false);

      if (res.status === "success") {
        const parsedData = {
          body: res.data.body,
          statusCode: res.data.statusCode,
          statusCodeValue: res.data.statusCodeValue,
        };
        setParsedResponse(parsedData);
        swal({
          title: "Success",
          text: "Callback URL Test Successful",
          icon: "success",
        });

        setMerchantData({
          callbackURL: "",
          jsonString: merchantData.jsonString,
        });
        setFormData({
          merchantMid: "",
          customCallbackURL: "",
          jsonString: merchantData.jsonString,
        });
      } else {
        swal({
          title: "Error",
          text: "Failed to test Callback URL",
          icon: "error",
        });
      }
    } catch (error) {
      // setShowLoader(false);
      console.error("API Error:", error);
      swal({
        title: "Error",
        text: "An error occurred while testing Callback URL",
        icon: "error",
      });
      console.error("Error:", error);
    }
  };
  const handleChange = (e) => {
    setMerchantData({ ...merchantData, jsonString: e.target.value });
  };
  const handleShowRouting = () => setIsModalRoutingOpen(true);
  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };
  return (
    <>
      {/* <div class="content-body"> */}
      <div class="container-fluid">
        <div className=" d-flex justify-content-between bg-white ">
          <div className="d-flex justify-content-start align-items-center ">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">
              CallBack Payout API Test
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-end mt-4 me-4">
            <CustomTip size="18" placement="top" title="  CallBack Payin">
              <div className="" onClick={handleShowRouting}>
                <CallMergeIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end ">
            <button
              className="badge badge-rounded badge-primary px-4 py-3 mb-3 "
              
            >
              Payin
            </button>
          </div> */}
        {/* <div class="row page-titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">CallBack TEST</a>
              </li>
            </ol>
          </div> */}
        {/* <Container> */}
        {/* <Card className="shadow border-0 mb-2"> */}
        <Card.Body>
          <div className="merchant-report-view">
            <div className="report-table-view">
              <div className="containerflag">
                <div className="row mt-1">
                  <div className="col-md-6 offset">
                    <div className="col-sm-12 col-md-6  offset-md-3">
                      <label className="d-flex text-dark fs-4">
                        Select A Merchant
                        <sup className="text-danger mt-3">*</sup>
                      </label>
                      <div className="d-flex gap-2">
                        <select
                          name="merchantMid"
                          value={formData.mid}
                          onChange={(e) => {
                            handelInput(e);
                            getMerchantInfo(e.target.value);
                          }}
                          className="form-control "
                        >
                          <option>Select A Merchant</option>
                          {merchant?.map((elem) => (
                            <option key={elem.mid} value={elem.mid}>
                              {elem.merchantName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                      <label className=" text-dark fs-4">Callback URL</label>
                      <div className="d-flex gap-2">
                        <input
                          type="text"
                          className="form-control "
                          value={
                            merchantData.callbackURL ||
                            formData.customCallbackURL
                          }
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              customCallbackURL: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="mx-4 text-dark fs-4">JSON Data</label>
                    <textarea
                      className="form-control"
                      value={merchantData.jsonString}
                      onChange={handleChange}
                      rows={15}
                      cols={30}
                    />
                  </div>
                  {parsedResponse && (
                    <div className="mt-4">
                      <h5>API Testing Response:</h5>
                      <pre>{JSON.stringify(parsedResponse, null, 2)}</pre>
                    </div>
                  )}

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                      onClick={submitCallbackURL}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
        {/* </Card> */}
        {/* </Container> */}
      </div>
      {/* </div> */}

      <Modal
        show={isModalRoutingOpen}
        onHide={handleCloseModalRouting}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">
            {" "}
            Callback Payin API Test{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <CallbackPayin />
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallBackPayout;
