import React, { useEffect, useState } from "react";
// import axios from 'axios';
// import { useNavigate, useParams } from 'react-router-dom';
import {
  APPROVE_VENDOR,
  SWIPELINC_API,
  VIEW_VENDOR_DETAILS,
} from "../../../utils/constant";
import swal from "sweetalert";
import { Container, Row, Col, Card } from "react-bootstrap";
import useApiServices from "../../../utils/useApiServices";
import RemoveIcon from "@mui/icons-material/Remove";
// import "../../../stylesheet/ViewDetails.css";

function ViewVendor({ setOpenView, id }) {
  const [vendorDetails, setvendorDetails] = useState([]);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  useEffect(() => {
    fetchViewVendorDetails();
  }, []);

  const fetchViewVendorDetails = async () => {
    try {
      const response = await apiCallFnforGet(VIEW_VENDOR_DETAILS + id);
      if (response.statusCode === 200) {
        setvendorDetails(response.data);
      } else {
        console.error("Api response error:", response.message);
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
  };

  // HANDLE APPROVE:

  const handleApproveConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          vendorStatus: "Approved",
          vendorId: id,
          remark: "NA",
        };

        apiCallFnforPost(APPROVE_VENDOR, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              swal("Success", "Application approved!", "success");
              setOpenView(false);
            } else {
              console.error(
                "Error approving the application:",
                response.message
              );
              swal("Failed", "Something Went Wrong", "error");
            }
          })
          .catch((error) => {
            console.error("Error approving the application:", error.message);
          });
      } else {
      }
    });
  };
  // HANDLE REJECT:
  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const payload = {
          vendorStatus: "Rejected",
          vendorId: id,
          remark: remark,
        };

        apiCallFnforPost(APPROVE_VENDOR, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              swal("Success", "Vendor rejected successfully!", "success");

              setOpenView(false);
            } else {
              swal("Failed", "Something Went Wrong", "error");
            }
          })
          .catch((error) => {
            console.error("Error rejecting the application:", error.message);
          });
      } else {
      }
    });
  };
  const handleBack = () => {
    setOpenView(false);
  };

  return (
    <>
      <div className="">
        <div className=" p-4 ">
          <div className="table-responsive">
            <div className="row">
              {[
                { label: "Vendor ID", value: vendorDetails?.vendorId },
                { label: "Vendor Name", value: vendorDetails?.fullName },
                { label: "Merchant Name", value: vendorDetails?.companyName },
                { label: "E-mail", value: vendorDetails?.email },
                { label: "Category", value: vendorDetails?.category },
                { label: "Mobile Number", value: vendorDetails?.mobile },
                { label: "Bank Name", value: vendorDetails?.bankName },
                {
                  label: "Account Number",
                  value: vendorDetails?.accountNumber,
                },
                {
                  label: "Mode Of Approval",
                  value: vendorDetails?.isvendorAutoApproved,
                },
                { label: "IFSC-Code", value: vendorDetails?.ifscCode },
                { label: "Branch Name", value: vendorDetails?.branchName },
                { label: "UPI", value: vendorDetails?.upi },
                { label: "Vendor Status", value: vendorDetails?.vendorStatus },
                { label: "Company Name", value: vendorDetails?.companyName },
                { label: "Merchant ID", value: vendorDetails?.parentMid },
                { label: "Pan Number", value: vendorDetails?.panNumber },
                { label: "Aadhaar Number", value: vendorDetails?.aadhaarNo },
              ].map((item, index) => (
                <div
                  key={index}
                  className="col-6 border border-dark border-bottom"
                  style={{
                    borderRight: "0.5px solid black",
                    borderLeft: "0.5px solid black",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center table table-bordered">
                    <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                      {item.label} <RemoveIcon />
                    </tr>{" "}
                    <tr className="mt-2 w-100 text-end">{item.value}</tr>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div class="d-flex justify-content-center gap-2 mt-4">
            <button
              class="badge badge-rounded badge-success py-2"
              onClick={() => handleApproveConfirm(id)}
            >
              Approve
              <span class="btn-icon-end">
                <i class="fa fa-check"></i>
              </span>
            </button>
            <button
              class="badge badge-rounded badge-danger py-2"
              onClick={() => handleReject()}
            >
              Reject
              <span class="btn-icon-end">
                <i class="fa fa-close"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewVendor;

