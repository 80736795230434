import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filterData",
  initialState: {
    amount: 0,
    startAmount: 0,
    endAmount: 0,
    date: "",
    startDate: "",
    endDate: "",
    bankLocation: "",
    customerLocation: "",
    ipAddress: "",
    mid: "",
    payerVpa: "",
    paymentType: "",
    sid: "",
    transactionStatus: "",
    isFilterUsed: false,
  },
  reducers: {
    setamount: (state, action) => {
      state.amount = action.payload;
    },
    setstartAmount: (state, action) => {
      state.startAmount = action.payload;
    },
    setendAmount: (state, action) => {
      state.endAmount = action.payload;
    },
    setdate: (state, action) => {
      state.date = action.payload;
    },
    setstartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setendDate: (state,action) => {
        console.log("endDate-------------",action.payload)
      state.endDate = action.payload;
    },
    setbankLocation: (state,action) => {
      state.bankLocation = action.payload;
    },
    setcustomerLocation: (state, action) => {
      state.customerLocation = action.payload;
    },
    setipAddress: (state, action) => {
      state.ipAddress = action.payload;
    },
    setmid: (state, action) => {
      state.mid = action.payload;
    },
    setpayerVPA: (state, action) => {
      state.payerVpa = action.payload;
    },
    setpaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setsid: (state, action) => {
      state.sid = action.payload;
    },
    settransactionStatus: (state, action) => {
        console.log(action.payload)
      state.transactionStatus = action.payload;
    },
    setisFilterUsed: (state, action) => {
      state.isFilterUsed = action.payload;
    },
    setfilterDataNull: (state, action) => {
      console.log(action.payload)
      state = action.payload;
    },
  },
});

export const {
    setamount,
    setstartAmount,
    setendAmount,
    setdate,
    setstartDate,
    setendDate,
    setbankLocation,
    setcustomerLocation,
    setipAddress,
    setmid,
    setpayerVPA,
    setpaymentType,
    setsid,
    settransactionStatus,
    setisFilterUsed,
    setfilterDataNull
  }  = filterSlice.actions;

export default filterSlice.reducer;
