import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_WALLET_BALANCE_BY_MID,
  SAVE_DROP_CREDIT,
} from "../../../../utils/constant";
import useApiServices from "../../../../utils/useApiServices";
import ManualUpload from "./ManualUpload";
import RequestedWithdraw from "./RequestedWithdraw";
import { Offcanvas } from "react-bootstrap";

const DropCredit = ({ onHide, show, setRefresh, refresh }) => {
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [merchantWalletBalance, setMerchantWalletBalance] = useState("");
  const [finalWalletBalance, setFinalWalletBalance] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [mid, setMid] = useState("");
  const [fullName, setFullName] = useState("");
  const offcanvasStyle = {
    width: "40vw", // Set width to 70vw
  };
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const getMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

      if (response?.statusCode === 200) {
        setData(response?.data);
        console.log(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };
  useState(() => {
    getMerchantData();
  }, []);

  // useEffect(() => {

  //     }, []);

  useEffect(() => {
    getWalletBalance(mid);
  }, [mid]);

  useEffect(() => {
    setFinalWalletBalance(parseInt(percentage(merchantWalletBalance)));
  }, [merchantWalletBalance]);
  console.log(merchantWalletBalance);
  const percentage = (num) => {
    return (num / 100) * 95;
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = data?.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
    }
  };

  const handleWithdrawAmountChange = (e) => {
    const sanitizedValue = e.target.value.replace(/^-|[^0-9]/g, "");
    if (/^0[0-9]*$/.test(sanitizedValue)) {
      setWithdrawAmount(sanitizedValue.slice(1));
    } else {
      setWithdrawAmount(sanitizedValue);
    }
  };

  const handleSubmit = () => {
    if (withdrawAmount > finalWalletBalance) {
      swal({
        title: "Failed",
        text: "Amount Can not be more than wallet balance.",
        icon: "warning",
      });
      return;
    }

    if (!mid.length || !withdrawAmount.length) {
      swal({
        title: "Warning",
        text: "Please fill the mandatory fields.",
        icon: "warning",
      });
      return;
    }

    const apiPayload = {
      mid: mid,
      amount: withdrawAmount,
    };

    apiCallFnforPost(SAVE_DROP_CREDIT, apiPayload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Saved",
            text: "Data saved successfully.",
            icon: "success",
          });
          setMid("");

          setWithdrawAmount("");

          setSelectedCompany("");
          onHide();
          setRefresh(!refresh);
        } else if (response.statusCode === 306) {
          swal({
            title: "error",
            text: response.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const getWalletBalance = (mid) => {
    const response = apiCallFnforGet(GET_WALLET_BALANCE_BY_MID + mid)
      .then((response) => {
        if (response.statusCode === 200) {
          setMerchantWalletBalance(response?.data?.totalwalletBalance);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const handleClose =()=>{
    onHide();
    setSelectedCompany("");
    setMid("");
    setWithdrawAmount("");
    setRefresh(!refresh);
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
          keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton> </Offcanvas.Header>

          <h5
            class="offcanvas-title fw-bold fs-2 text-dark"
            id="offcanvasLabel"
          >
            Drop Credit
          </h5>
        </div>

        <Offcanvas.Body>
          {/* <div className="content-body"> */}
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="form-group col-12">
                <label className=" text-dark fs-4">Select Merchant *</label>
                <select
                  id="companyDropdown"
                  className="form-control "
                  onChange={handleCompanyChange}
                  value={selectedCompany}
                >
                  <option value="">Select a Merchant</option>
                  {data?.map((merchant) => (
                    <option
                      key={merchant.mid}
                      value={`${merchant.mid} :- ${merchant.merchantName}`}
                    >
                      {merchant.mid} :- {merchant.merchantName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-12">
                <label className=" text-dark fs-4">Merchant ID</label>
                <input
                  required
                  type="email"
                  className="form-control "
                  id="email"
                  placeholder="Merchant ID"
                  value={mid}
                  readOnly
                />
              </div>

              <div className="form-group col-12">
                <label className=" text-dark fs-4">
                  Withdraw Amount (in Rupees)*
                </label>

                <input
                  required
                  type="text"
                  className="form-control "
                  id="holdAmount"
                  placeholder="Withdraw Amount"
                  value={withdrawAmount}
                  onChange={handleWithdrawAmountChange}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </div>

              <div className="d-flex justify-content-center mt-3 ">
                <button
                  className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* </div> */}
    </>
  );
};
export default DropCredit;
