import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Cookies from "js-cookie";
import { REPORTED_PAYIN_ISSUE_CONTROLLER_REPORT_API } from "../../utils/constant";
import Shimmer from "../../common/Shimmer/Shimmer";
import useApiServices from "../../utils/useApiServices";
import ViewResponse from "./ViewResponse";

const ViewUTR = ({ toggleRightBar }) => {
  const token = Cookies.get("access_token");
  const [ViewUTR, setViewUTR] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [submittedAggregator, setSubmittedAggregator] = useState([]);
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();
  const [alertData, setAlertData] = useState(null);
  const [showAlert, setShowAlert] = useState(false)
  const [company, setCompany] = useState(null);
  const [m, setM] = useState(null);

  const ViewDetailsCallbackResponse =(response, m, company)=>{
    setAlertData(response);
    setM(m);
    setCompany(company);
    setShowAlert(!showAlert);

  }

  const fetchData = async () => {
    try {
      const response = await apiCallFnforGet(
        REPORTED_PAYIN_ISSUE_CONTROLLER_REPORT_API
      );
      console.log(response);
      if (response.statusCode === 200) {
        const finalRes = response?.data?.map((item, i) => {
          // Ensure createDateTime is defined and split it into date and time
          const createDateTime = item?.createdDateTime || "";
          const [createdDate, createdTime] = createDateTime.split(" ");
          console.log(createDateTime,createdDate,createdTime)
        
          return {
            id: i,
            orderNumber: item.orderNumber,
            txnId: item.txnId,
            status: item.status,
            action: item.action,
            txnDate: item.txnDate ? item.txnDate.split(" ")[0] : "",
            amount: item.amount,
            utr: item.utr,
            vpa: item.vpa,
            createdDate: createdDate || "", // Default to empty string if not available
            createdTime: createdTime || "", // Default to empty string if not available
            response:(
              <button
                className=" badge badge-rounded badge-info mx-2 px-3 py-2"
                onClick={() =>
                  ViewDetailsCallbackResponse(item.response, item.mid, item.companyName)
                }
              >
                View
              </button>
            ),
            companyName: item.companyName,
            mid: item.mid
          };
        });
        
        console.log(finalRes);
        setSubmittedAggregator(finalRes);
      } else {
        setSubmittedAggregator([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const columns = [
    {
      headerName: "MID",
      field: "mid",
      minWidth: 200,
    },
    {
      headerName: "Company Name",
      field: "companyName",
      minWidth: 200,
    },
    {
      headerName: "Created Date",
      field: "createdDate",
      minWidth: 200,
    },
    // {
    //   headerName: "Created TIme",
    //   field: "createdTime",
    //   minWidth: 200,
    // },
    {
      headerName: "Order Number",
      field: "orderNumber",
      minWidth: 200,
    },
    {
      headerName: "Transaction ID",
      field: "txnId",
      minWidth: 250,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
    },
    {
      headerName: "Transaction Date",
      field: "txnDate",
      minWidth: 200,
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 150,
    },
    {
      headerName: "UTR",
      field: "utr",
      minWidth: 200,
    },
    {
      headerName: "VPA",
      field: "vpa",
      minWidth: 200,
    },
    {
      headerName: "Response",
      field: "response",
      minWidth: 200,
    },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   minWidth: 150,
    // },
  ];

 

  return (
  <>
    <div className="container-fluid">
      <div className="d-flex justify-content-between bg-white">
        <div className="d-flex justify-content-start align-items-center">
          <button
            type="button"
            className="btn-close m-3"
            onClick={toggleRightBar}
          ></button>
          <div className="fs-2 text-dark fw-bold">View UTR</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                {isLoading ? (
                  <Shimmer type="table" />
                ) : submittedAggregator.length > 0 ? (
                  <DataGrid
                    rows={submittedAggregator}
                    columns={columns.map((column) => ({
                      ...column,
                      renderCell: (params) =>
                        column.field === "response" ? params.value : params.value,
                    }))}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                ) : (
                  <div className="text-center p-3">No issues found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   {showAlert ? <ViewResponse
    setShowResponse={setShowAlert}
    alertData={alertData}
    m={m}
    company={company}
  />: null} 
   </>
  );
};

export default ViewUTR;
