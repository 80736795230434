import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  GET_MERCHANT_BY_MID,
  HANDLE_VIEW_DATA,
  SWIPELINC_API,
  GET_MERCHANT_BY_ID,
  UPDATE_STAGE2,
  VIEW_FILE_UPDATE_STATUS_API,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import PaymentsIcon from "@mui/icons-material/Payments";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useSelector } from "react-redux";
import { fetchAllMerchantMaster } from "../../utils/ApiServices";

const Phase1Approved = ({ id, handleClose, status }) => {
  const [merchantData, setMerchantData] = useState(null);
  const [open, setOpen] = useState(false);
  const [stage1, setStage1] = useState(null);
  const [mccCode, setMccCode] = useState("");
  const [merchantMaster, setMerchantMaster] = useState("");
  const [paymentservice, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showPaymentServicesList, setShowPaymentServicesList] = useState(false);
  const [commissionPercentageInput, setCommissionPercentageInput] =
    useState("");
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const token = localStorage.getItem("token");
  const { apiCallFnforPut } = useApiServices();
  const { privilagesData, prefix, type, masterId } = useSelector(
    (state) => state.privilages
  );

  const handleClosePDF = () => {
    window.close();
  };

  useEffect(() => {
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/${id}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMerchantData(response?.data?.data);
        setSelectedServices(
          response?.data?.data?.paymentService?.map(
            (service) => service.paymentService
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
      });

    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`
      )
      .then((response) => {
        setServices(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching payment services:", error.message);
      });
  }, [id]);

  useEffect(() => {
    const getAllMerchantMaster = async () => {
      try {
        const response = await fetchAllMerchantMaster(token);
        if (response?.statusCode === 200) {
          setMerchantMasterData(response?.data);
        } else {
          setMerchantMasterData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllMerchantMaster();
  }, []);

  const handleSave = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to save?",
      icon: "warning",
      buttons: ["Cancel", "Yes, Save it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const ids = [];
        for (let i = 0; i < selectedServices.length; i++) {
          const payId = paymentservice.find(
            (item) => item.paymentService === selectedServices[i]
          );
          ids.push(payId.paymentTypeId);
        }
        const payload = {
          paymentTypeId: ids,
          registrationId: id,
        };
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updatePaymentTypeService/${id}`,
            payload,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            swal("Success", "Services Saved Successfully", "success").then(
              () => {
                setOpen(false);
                handleClose();
              }
            );
          })
          .catch((error) => {
            console.error("Error updating payment services:", error.message);
          });
      } else {
        setOpen(false);
        handleClose();
      }
    });
  };

  const handleServiceChange = (e) => {
    const found = selectedServices?.find((item) => item === e.target.value);
    if (found) {
      setSelectedServices(selectedServices?.filter((item) => item !== found));
    } else {
      setSelectedServices([...selectedServices, e.target.value]);
    }
  };

  const handleApprove = () => {
    if (prefix === "ALL" && merchantMaster === "") {
      swal({
        title: "ALERT",
        text: "Please Select Merchant Master.",
        icon: "error",
      });
      return;
    }
    if (mccCode === "") {
      swal({
        title: "ALERT",
        text: "Please Select MCC Code.",
        icon: "error",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to approve this Application?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          applicationStatus: "Approved",
          bankVerified: true,
          registrationId: id,
          rejectionRemark: "NA",
          masterMerchantId: prefix === "ALL" ? merchantMaster : masterId,
          commissionPercentage: parseFloat(commissionPercentageInput),
          mccCode: mccCode,
        };
        apiCallFnforPut(VIEW_FILE_UPDATE_STATUS_API, payload)
          .then((response) => {
            swal("Success", "Application approved!", "success").then(() => {
              setOpen(false);
              handleClose();
            });
          })
          .catch((error) => {
            console.error("Error approving the application:", error.message);
            handleClose();
          });
      } else {
        setOpen(false);
        handleClose();
      }
    });
  };

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark === "") {
        swal("Warning", "Please fill in the rejection remark.", "warning");
      } else if (remark) {
        const payload = {
          applicationStatus: "rejected",
          bankVerified: true,
          registrationId: id,
          rejectionRemark: remark,
        };

        apiCallFnforPut(VIEW_FILE_UPDATE_STATUS_API, payload)
          .then((response) => {
            swal("Success", "Application Rejected!", "success").then(() => {
              setOpen(false);
              handleClose();
            });
          })
          .catch((error) => {
            swal("Failed", "Failed to Reject", "error").then(() => {
              setOpen(false);
              handleClose();
            });
            console.error("Error rejecting the application:", error.message);
          });
      } else {
        setOpen(false);
        handleClose();
      }
    });
  };

  const array = [
    { label: "Company Name", value: merchantData?.companyName },
    {
      label: "Registration Type",
      value: merchantData?.registrationType,
    },
    { label: "Business Category", value: merchantData?.businessType },
    {
      label: "Other Business Category",
      value: merchantData?.businessTypeName,
    },
    { label: "Business Type", value: merchantData?.companyType },
    { label: "Business As", value: merchantData?.businessAs },
    {
      label: "Company PAN Number",
      value: merchantData?.companyPanNumber,
    },
    { label: "GST Number", value: merchantData?.gstNumber },
    {
      label: "Authorized Person Name",
      value: merchantData?.authorizedPersonName,
    },
    { label: "e-Mail ID", value: merchantData?.email },
    { label: "Mobile Number", value: merchantData?.mobile },
    {
      label: "Authorized Person PAN",
      value: merchantData?.authorizedPersonPan,
    },
    { label: "Aadhaar Number", value: merchantData?.aadharNumber },
    { label: "Website URL", value: merchantData?.websiteUrl },
    { label: "Application URL", value: merchantData?.aapUrl },
    {
      label: "Payment Services",
      value:
        status === "Submitted" ? (
          <div>
            <button
              className="badge badge-warning bg-warning px-3"
              onClick={() =>
                setShowPaymentServicesList(!showPaymentServicesList)
              }
              style={{ marginRight: "10px" }} // Added marginRight for spacing
            >
              {showPaymentServicesList ? "Hide" : "View"}
            </button>
            {showPaymentServicesList && (
              <div className="payment-services-list row">
                {paymentservice?.map((service) => (
                  <div
                    key={service.paymentTypeId}
                    className="form-check col-4 mt-1"
                    style={{ marginRight: "18px", marginTop: "10px" }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        border: "1px solid grey",
                        scale: "1",
                      }}
                      className="form-check-input"
                      id={`service-${service.paymentTypeId}`}
                      value={service.paymentService}
                      checked={selectedServices.includes(
                        service.paymentService
                      )}
                      onChange={handleServiceChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`service-${service.paymentTypeId}`}
                    >
                      {service.paymentService}
                    </label>
                  </div>
                ))}
                <div className="col-12 mt-2">
                  <button
                    className="badge badge-rounded badge-success"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="horizontal-table-row">
            <div className="horizontal-table-cell text-left"></div>
            <div className="horizontal-table-cell-value text-left">
              {selectedServices.map((service, index) => (
                <div key={index} style={{ marginBottom: "5px" }}>
                  {service}
                </div>
              ))}
            </div>
          </div>
        ),
    },
    {
      label: "Company Registered Address",
      value: merchantData?.companyAddress,
    },
    { label: "Pin Code", value: merchantData?.companyAddressPinCode },
    { label: "City", value: merchantData?.companyAddressCity },
    { label: "State", value: merchantData?.companyAddressState },
    {
      label: "Authorized Signatory Address",
      value: merchantData?.registeredOfficeAddress,
    },
    {
      label: "Pin Code",
      value: merchantData?.registeredOfficeAddressPinCode,
    },
    {
      label: "City",
      value: merchantData?.registeredOfficeAddressCity,
    },
    {
      label: "State",
      value: merchantData?.registeredOfficeAddressState,
    },
    {
      label: "Company Communication Address",
      value: merchantData?.communicationAddress,
    },
    {
      label: "Pin Code",
      value: merchantData?.communicationAddressPinCode,
    },
    { label: "City", value: merchantData?.communicationAddressCity },
    {
      label: "State",
      value: merchantData?.communicationAddressState,
    },
    {
      label: "Mode of Registration",
      value: merchantData?.modeOfRegistration,
    },
    {
      label: "Modification Date",
      value: merchantData?.modificationDate,
    },
    { label: "Submission Date", value: merchantData?.submitDate },
    { label: "Submission Time", value: merchantData?.submitTime },
    { label: "Referred By", value: merchantData?.referredBy },
    {
      label: "Commission Percentage",
      value:
        merchantData?.referredBy !== null ? (
          <div className="vertical-table-row">
            <div className="vertical-table-cell text-left">
              Commission Percentage
            </div>
            <div className="vertical-table-cell-value">
              <input
                className="commision-per"
                type="text"
                value={commissionPercentageInput}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!inputValue || parseFloat(inputValue) >= 0) {
                    setCommissionPercentageInput(inputValue);
                  }
                }}
                placeholder="Enter commission percentage"
              />
              {parseFloat(commissionPercentageInput) < 0 && (
                <p className="error-message">
                  Commission percentage must be positive
                </p>
              )}
            </div>
          </div>
        ) : null,
    },

    {
      label: "Merchant Master",
      value:
        status === "Submitted" ? (
          <select
            onChange={(e) => {
              setMerchantMaster(e.target.value);
            }}
            value={merchantMaster}
            className="mt-3"
          >
            <option value="">Select a Merchant</option>
            {merchantMasterData?.map((merchant) => (
              <option
                key={merchant.masterMerchantId}
                value={merchant.masterMerchantId}
              >
                {merchant.name}
              </option>
            ))}
          </select>
        ) : (
          <div className="vertical-table-row">
            <div className="vertical-table-cell-value  text-left">
              {merchantData?.masterMerchant}
            </div>
          </div>
        ),
    },
    {
      label: "MCC Code",
      value:
        status === "Submitted" ? (
          <select
            onChange={(e) => {
              setMccCode(e.target.value);
            }}
            value={mccCode}
            className="mt-3"
          >
            <option value="">Select an MCC Code</option>
            <option value="gaming-101">Gaming 101</option>
            <option value="gaming-203">Gaming 203</option>
            <option value="gaming-204">Gaming 204</option>
            <option value="ecommerce-101">Ecommerce 101</option>
            <option value="ecommerce-103">Ecommerce 103</option>
            <option value="5262">5262</option>
            <option value="5816">5816</option>
            <option value="4900">4900</option>
            <option value="6300">6300</option>
          </select>
        ) : (
          <div className="vertical-table-row">
            <div className="vertical-table-cell-value  text-left">
              {merchantData?.mccCode}
            </div>
          </div>
        ),
    },
  ];

  return (
    <>
      <div className="container my-4">
        <div className="p-4">
          <div className="row">
            {array.map((item, index) => (
              <div
                key={index}
                className="col-6 border border-dark"
                style={{
                  borderRight: "0.5px solid black",
                  borderLeft: "0.5px solid black",
                  paddingBottom: "0px",
                }}
              >
                <div className="d-flex justify-content-between align-items-center table table-bordered">
                  <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                    {item.label} <RemoveIcon />
                  </tr>
                  <tr className="mt-2 w-100 text-end">{item.value}</tr>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="badge badge-rounded badge-info mx-4 px-4 py-2 mb-25"
            onClick={handleClose}
          >
            Close
          </button>

          {status === "Submitted" && (
            <>
              <button
                className="badge badge-rounded badge-success py-2 me-2"
                onClick={handleApprove}
              >
                Approve <i className="fa fa-check ms-2"></i>
              </button>
              <button
                className="badge badge-rounded badge-danger py-2"
                onClick={handleReject}
              >
                Reject <i className="fa fa-close ms-2"></i>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Phase1Approved;
