import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import useApiServices from "../../utils/useApiServices";
import { FETCH_FRM_SUBMITTED_FORM_BY_MID } from "../../utils/constant";
import RemoveIcon from "@mui/icons-material/Remove";

const ViewSingleFrmForm = ({ mid, setOpenView, handleClose }) => {
  const [responseData, setResponseData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewDataClicked, setViewDataClicked] = useState(false);
  const { apiCallFnforGet } = useApiServices();

  const FetchDataBymid = async (mid, token) => {
    try {
      const apiResponse = await apiCallFnforGet(
        FETCH_FRM_SUBMITTED_FORM_BY_MID + mid
      );
      //   const apiResponse = await fetchFrmsubmittedFormByMid(mid, token);
      if (apiResponse.statusCode === 200) {
        setResponseData(apiResponse?.data[0]);

        // setFinancialData(initializeFinancialData(responseData));
        console.log(apiResponse?.data[0]);
      } else {
        swal({
          title: "ALERT!",
          text: "Something went wrong",
          icon: "warning",
        });
        setOpenView(false);
      }
    } catch (error) {
      swal({
        title: "ALERT!",
        text: "Something went wrong",
        icon: "warning",
      });
      setOpenView(false);
    }
  };

  useEffect(() => {
    FetchDataBymid(mid, token);
  }, []);

  return (
    <>  
     <div className="p-4">
        <div className="table table-bordered">
          <div className="row">
            {[
              { label: "Merchant Name", value: responseData.companyName },
              { label: "MID", value: responseData.mid },
              { label: "e-Mail ID", value: responseData.email },
              { label: "Location", value: responseData.location },
              { label: "Type Of Business", value: responseData.typeOfBusiness },
              {
                label: "Website Analysis",
                value: responseData.websiteAnalysis,
              },
              { label: "App Analysis", value: responseData.appAnalysis },
              {
                label: "Director Profile",
                value: responseData.directorProfile,
              },
              { label: "Company Profile", value: responseData.companyProfile },
              {
                label: "Business Profile",
                value: responseData.businessProfile,
              },
              {
                label: "Chargeback Probability",
                value: responseData.chargebackProbability,
              },
              {
                label: "Bank Approval Probability",
                value: responseData.bankApprovalProbability,
              },
              { label: "Ticket Size", value: responseData.ticketSize },
              { label: "Volume", value: responseData.volume },
              {
                label: "Number Of Ticket",
                value: responseData.numberOfTickets,
              },
            ].map((item, index) => (
              <div
                key={index}
                className="col-6 border border-dark border-bottom"
                style={{
                  borderRight: "0.5px solid black",
                  borderLeft: "0.5px solid black",
                  paddingBottom: "0px",
                }}
              >
                <div className="d-flex justify-content-between align-items-center table table-bordered">
                  <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">
                    {item.label} <RemoveIcon />
                  </tr>{" "}
                  <tr className="mt-2 w-100 text-end">{item.value}</tr>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-end ">
          <button
            type="button"
            class="badge badge-rounded badge-info mt-4 px-4 py-2 "
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewSingleFrmForm;
