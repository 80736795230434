import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API, FETCH_FLAG_EDIT_FORM_API } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import { saveWithdrawlPercentage } from "../../utils/ApiServices";

function FlagEditForm({ mid, showModal, data, onCloseModal,closeModal }) {
  const [formData, setFormData] = useState({});
  const [form, setForm] = useState({
    IMPS: 0,
    UPI: 0,
    VIR: 0,
    MINIWALLETBALANCE: 0,
  });
  const [payoutForm, setPayoutForm] = useState({
    MaximumPayoutLimit: 0,
  });
  console.log(data)
  const [token] = useState(localStorage.getItem("token"));
  const [isWithdrawalAuto, setIsWithdrawalAuto] = useState(false);
  const [isPayoutTransactionAuto, setIsPayoutTransactionAuto] = useState(false);
  const [withdrawalPeriod, setWithdrawalPeriod] = useState("");
  const [userCreditType, setUserCreditType] = useState("");
  const { apiCallFnforPut } = useApiServices();

  useEffect(() => {
    const merchantData = data.find((item) => item.mid === mid);
    if (merchantData) {
      setFormData({
        isActive: merchantData.isActive === true ? "Enabled" : "Disabled",
        isAmountHold: merchantData.isAmountHold === true ? "Auto" : "Manual",
        isPayoutAutoApprovel:
          merchantData.isPayoutAutoApprovel === true ? "Auto" : "Manual",
        isProfileEdit:
          merchantData.isProfileEdit === true ? "Enabled" : "Disabled",
        isPayoutActive:
          merchantData.isPayoutActive === true ? "Enabled" : "Disabled",
        isTransactionAllow:
          merchantData.isTransactionAllow === true ? "Enabled" : "Disabled",
        isVendorAutoApproved:
          merchantData.isVendorAutoApproved === true ? "Auto" : "Manual",
        isWithdrawalAcountActive:
          merchantData.isWithdrawalAcountActive === true
            ? "Enabled"
            : "Disabled",
        isWithdrawalPayoutActive:
          merchantData.isWithdrawalPayoutActive === true
            ? "Enabled"
            : "Disabled",
        isWithdrawlAuto:
          merchantData.isWithdrawlAuto === true ? "Auto" : "Manual",
        mid,
      });
      console.log(merchantData.accountPercentage)
      setUserCreditType(merchantData.userCreditType ?? "")
      if (merchantData.isWithdrawlAuto === true) {
        setIsWithdrawalAuto(true);
        setForm({
          VIR:merchantData.accountPercentage  || 0 ,
          IMPS:merchantData.payoutPercentageImps || 0 ,
          UPI:merchantData.payoutPercentageUpi || 0,
          MINIWALLETBALANCE: merchantData.minimumWalletBalance || 0,
          MaximumPayoutLimit: merchantData.maximumPayoutLimit || 0,
          ManimumPayoutLimit: merchantData.manimumPayoutLimit || 0,
          PayoutChargeAfterLimitExceed: merchantData.payoutChargeAfterLimitExceed || 0,
          UserCreditType: merchantData.userCreditType || 0,
        })
        setWithdrawalPeriod(merchantData.withdrawalPeriod);
      }
      if (merchantData.isPayoutActive === true) {
        setIsPayoutTransactionAuto(true);
        setForm({
          VIR: merchantData.accountPercentage || 0,
          IMPS: merchantData.payoutPercentageImps || 0,
          UPI: merchantData.payoutPercentageUpi || 0,
          MINIWALLETBALANCE: merchantData.minimumWalletBalance || 0,
          MaximumPayoutLimit: merchantData.maximumPayoutLimit || 0,
          ManimumPayoutLimit: merchantData.manimumPayoutLimit || 0,
          PayoutChargeAfterLimitExceed: merchantData.payoutChargeAfterLimitExceed || 0,
        });
      }
      console.log(form)
    } 
  }, [data, mid]);

  const flagData = [
    {
      name: "Login",
      key: "isActive",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    { name: "Hold", key: "isAmountHold", enabled: "Auto", disabled: "Manual" },
    {
      name: "Profile Edit",
      key: "isProfileEdit",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Transaction",
      key: "isTransactionAllow",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Vendor",
      key: "isVendorAutoApproved",
      enabled: "Auto",
      disabled: "Manual",
    },
    {
      name: "Payout",
      key: "isPayoutAutoApprovel",
      enabled: "Auto",
      disabled: "Manual",
    },
    {
      name: "Payout Transactions",
      key: "isPayoutActive",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Payout Withdraw Manual",
      key: "isWithdrawalPayoutActive",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Account Withdraw Manual",
      key: "isWithdrawalAcountActive",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Withdraw",
      key: "isWithdrawlAuto",
      enabled: "Auto",
      disabled: "Manual",
    },
  ];

  const handlePercentageSave = async () => {
    console.log("percentageRunnnnnnnnnnnn-----------");
    const payload = {
      accountPercentage: form.VIR,
      mid: formData.mid,
      payoutPercentageImps: form.IMPS,
      payoutPercentageUpi: form.UPI,
      minimumWalletBalance: form.MINIWALLETBALANCE || 0,
      period: withdrawalPeriod,
    };
    try {
      const res = await saveWithdrawlPercentage(payload, token);
      console.log(res);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "Data saved successfully",
          icon: "success",
        });
        setForm({
          IMPS: 0,
          UPI: 0,
          VIR: 0,
        });
        setFormData({});
      } else {
        swal({
          title: "Failed",
          text: "Failed To Save Data.",
          icon: "error",
        });
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: "Something Went Wronggggggggggggggggggggg.",
        icon: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(form.VIR + form.IMPS + form.UPI);

    if (form.VIR + form.IMPS + form.UPI > 100) {
      swal({
        title: "error",
        text: "Cannot Save More Than 100%.",
        icon: "error",
      });
      return;
    }

    if( isWithdrawalAuto){
      if (form.VIR === 0 && form.IMPS === 0 && form.UPI === 0) {
        swal({
          title: "error",
          text: "Please Fill Atleast One Percentage field.",
          icon: "error",
        });
        return;
      }
    }
    if (isWithdrawalAuto) {
      if (parseInt(form.MINIWALLETBALANCE) < 101) {
        swal({
          title: "error",
          text: "Minimum wallet balance must be greater than 100",
          icon: "error",
        });
        return;
      }
    }

    try {
      const updatedData = { ...formData };

      updatedData.isActive = updatedData.isActive === "Enabled";
      updatedData.isPayoutActive = updatedData.isPayoutActive === "Enabled";
      updatedData.isAmountHold = updatedData.isAmountHold === "Auto";
      updatedData.isPayoutAutoApprovel =
        updatedData.isPayoutAutoApprovel === "Auto";
      updatedData.isWithdrawalAcountActive =
        updatedData.isWithdrawalAcountActive === "Enabled";
      updatedData.isWithdrawalPayoutActive =
        updatedData.isWithdrawalPayoutActive === "Enabled";
      updatedData.isProfileEdit = updatedData.isProfileEdit === "Enabled";
      updatedData.isTransactionAllow =
        updatedData.isTransactionAllow === "Enabled";
      updatedData.isVendorAutoApproved =
        updatedData.isVendorAutoApproved === "Auto";
      updatedData.isWithdrawlAuto = updatedData.isWithdrawlAuto === "Auto";
      updatedData.withdrawalPeriod = isWithdrawalAuto ? withdrawalPeriod : "";
      updatedData.payoutPercentageImps = isWithdrawalAuto ? form.IMPS : "";
      updatedData.payoutPercentageUpi = isWithdrawalAuto ? form.UPI : "";
      updatedData.accountPercentage = isWithdrawalAuto ? form.VIR : "";
      updatedData.minimumWalletBalance = isWithdrawalAuto
        ? form.MINIWALLETBALANCE
        : "";
      updatedData.maximumPayoutLimit = isPayoutTransactionAuto
        ? form.MaximumPayoutLimit
        : "";
      updatedData.minimumPayoutLimit = isPayoutTransactionAuto
        ? form.ManimumPayoutLimit
        : "";
      updatedData.payoutChargeAfterLimitExceed = isPayoutTransactionAuto
        ? form.payoutChargeAfterLimitExceed
        : "";

      const response = await apiCallFnforPut( FETCH_FLAG_EDIT_FORM_API,
        updatedData
      );

      if (response.statusCode === 200) {
        swal({
          title: "success",
          text: "Flag Data Updated Successfully.",
          icon: "success",
        });
        setForm({
          'IMPS':0,
          'UPI':0,
          'VIR':0,
        })
        setFormData({});
        onCloseModal();
      } else if (response.statusCode === 304) {
        swal({
          title: "error",
          text: "No Data Found",
          icon: "error",
        });
        onCloseModal();
      } else {
        swal({
          title: "error",
          text: "Failed To Update Flag Data.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error updating flags:", error);
      // swal(error)
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "isWithdrawlAuto" && value === "Auto") {
      setIsWithdrawalAuto(true);
    }
    if (name === "isWithdrawlAuto" && value === "Manual") {
      setIsWithdrawalAuto(false);
    }
    if (name === "isPayoutActive" && value === "Enabled") {
      setIsPayoutTransactionAuto(true);
    }
    if (name === "isPayoutActive" && value === "Disabled") {
      setIsPayoutTransactionAuto(false);
    }
  };

  const handlePercentageSubmit = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (!value) {
      setForm({ ...form, [name]: 0 });
      return;
    }
    if (name === "MINIWALLETBALANCE") {
      setForm({ ...form, [name]: parseInt(value) });
      return;
    }
    if (name === "MaximumPayoutLimit" || name === "ManimumPayoutLimit" || name === "payoutChargeAfterLimitExceed" ) {
      setForm({ ...form, [name]: parseInt(value) });
      return;
    }
    if (value <= 100) {
      setForm({ ...form, [name]: parseInt(value) });
    } else {
      setForm({ ...form, [name]: 100 }); 
    }
  };

  return (
    <div className={`flag-edit-form ${showModal ? "show" : ""}`}>
    <form onSubmit={handleSubmit} style={{ width: "80%", margin: "0 auto" }}>
      <div className="form-row  border border-1">
        {flagData.map((flag) => (
          <div className=" border border-1 align-content-center p-3">
            <div
              className="form-group w-100 d-flex justify-content-between   "
              key={flag.key}
            >
              <label className="w-50 " htmlFor={flag.key}>
                {flag.name}
              </label>
              <div className="d-flex w-75 justify-content-between ">
                <div className="form-check w-50 form-check-inline d-flex  flex-start gap-4">
                  <input
                    type="radio"
                    name={flag.key}
                    value={flag.enabled}
                    checked={formData[flag.key] === flag.enabled}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor={flag.key + "Enabled"}
                  >
                    {flag.enabled}
                  </label>
                </div>

                <div className="form-check w-50 form-check-inline d-flex flex-start gap-4">
                  <input
                    type="radio"
                    name={flag.key}
                    value={flag.disabled}
                    checked={formData[flag.key] === flag.disabled}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor={flag.key + "Disabled"}
                  >
                    {flag.disabled}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* <div className="mt-2 d-flex pb-3">
          <div className="col-5 d-flex align-items-center px-3 ">
            <label htmlFor="email">User Credit Type</label>
          </div>
          <div className="col-7">
            <select
              id=""
              className="form-control mt-1"
              onChange={(e)=>setUserCreditType(e.target.value)}
              value={userCreditType}
              name="UserCreditType"
            >
              <option value="">Select Credit Type</option>
              <option value="prepaid">prepaid</option>
              <option value="postpaid">postpaid</option>
             
            </select>
          </div>
        </div> */}

        {isWithdrawalAuto ? (
          <div className="mt-2 d-flex pb-3">
            <div className="col-5 d-flex align-items-center px-3 ">
              <label htmlFor="email">Update Withdrawal Period</label>
            </div>
            <div className="col-7">
              <select
                id=""
                className="form-control mt-1"
                onChange={(e) => setWithdrawalPeriod(e.target.value)}
                value={withdrawalPeriod}
                name="withdrawalPeriod"
              >
                <option value="">Select Withdrawal Period</option>
                <option value="T+1">T+1</option>
                <option value="T+3">T+3</option>
                <option value="T+7">T+7</option>
                <option value="T+15">T+15</option>
                <option value="1 HRS">1 Hour</option>
                <option value="3 HRS">3 Hours</option>
                <option value="6 HRS">6 Hours</option>
                <option value="12 HRS">12 Hours</option>
                <option value="24 HRS">24 Hours</option>
              </select>
            </div>
          </div>
        ) : (
          ""
        )}

        {isWithdrawalAuto ? (
          <>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Payout (IMPS)</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  // pattern="^(100|[1-9]?[0-9])$"
                  id=""
                  name="IMPS"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.IMPS}
                />
              </div>
            </div>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Payout (UPI)</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  // pattern="^(100|[1-9]?[0-9])$"
                  id=""
                  name="UPI"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.UPI}
                />
              </div>
            </div>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Account</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  // pattern="^(100|[1-9]?[0-9])$"
                  id=""
                  name="VIR"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.VIR}
                />
              </div>
            </div>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Minumum Wallet Balance</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  id=""
                  name="MINIWALLETBALANCE"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.MINIWALLETBALANCE}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {isPayoutTransactionAuto ? (
          <>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Maximum Payout Limit</label>
              </div>
              <div className="col-7">
                <input
                  type="number"
                  // pattern="^(100|[1-9]?[0-9])$"
                  id=""
                  name="MaximumPayoutLimit"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.MaximumPayoutLimit}
                />
              </div>
            </div>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Minimum Payout Limit</label>
              </div>
              <div className="col-7">
                <input
                  type="number"
                  // pattern="^(100|[1-9]?[0-9])$"
                  id=""
                  name="ManimumPayoutLimit"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.ManimumPayoutLimit}
                />
              </div>
            </div>
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Payout Charge After Limit Exceeds</label>
              </div>
              <div className="col-7">
                <input
                  type="number"
                  // pattern="^(100|[1-9]?[0-9])$"
                  id=""
                  name="payoutChargeAfterLimitExceed"
                  className="form-control mt-1"
                  onChange={handlePercentageSubmit}
                  value={form.payoutChargeAfterLimitExceed}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="d-flex justify-content-end mt-2 gap-2">
        <button className="badge badge-rounded badge-primary mx-2 px-3 py-3" type="submit">
          Submit
        </button>
        <button
          className="badge badge-rounded badge-info mx-2 px-3 py-3"
          type="button"
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </form>
  </div>
  );
}

export default FlagEditForm;