import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import IconWrapper from "../../common/IconWrapper/IconWrapper";
import { Container, Card, Row, Col, Offcanvas } from "react-bootstrap";
import ViewTxnStatus from "./ViewTxnStatus";
import DownloadCSV from "./DownloadCSV";
import CompanyNameAndMid from "../../common/CompanyNameAndMid";
import TransactionReportMultipleFilter from "./MerchantTransactionFilters/TransactionReportMultipleFilter";
import TxnReportAdvancedSearchPopup from "./MerchantTransactionFilters/TxnReportAdvancedSearchPopup";
import SettlementMultipleFilter from "./MerchantTransactionFilters/SettlementMultipleFilter";
import SettlementAdvanceSearchPopup from "./MerchantTransactionFilters/SettlementAdvanceSearchPopup";
import ChargebackMultipleFilter from "./MerchantTransactionFilters/ChargebackMultipleFilter";
import ChargebackAdvanceFilter from "./MerchantTransactionFilters/ChargebackAdvanceFilter";
import GuaranteeMultipleFilter from "./MerchantTransactionFilters/GuaranteeMultipleFilter";
import PayoutMultipleFilter from "./MerchantTransactionFilters/PayoutMultipleFilter";
import PayoutAdvanceFilter from "./MerchantTransactionFilters/PayoutAdvanceFilter";
import WithdrawMultipleFilter from "./MerchantTransactionFilters/WithdrawMultipleFilter";
import WithdrawAdvanceFilter from "./MerchantTransactionFilters/WithdrawAdvanceFilter";
import DailyTransactionBox from "./DailyTransactionBox";
import useApiServices from "../../utils/useApiServices";
import {
  SINGLE_PAYIN_REPORT,
  GET_BY_SETTLEMENT_MID,
  CHARGEBACK_UPDATE,
  HOLD_AMOUNT_REPORT,
  GET_DEPOSIT_REPORT,
  GET_PAYOUT_REPORT,
  GET_WITHDRAWAL_REPORT,
  GURANTEE_REPORT,
  SETTLEMENT_ADJUSTMENT_REPORT,
  VENDOR_REPORT,
  RELEASE_REPORT,
  WALLETBALANCE_REPORT,
  AVAILABLE_CREDIT_AMOUNT,
  CHARGEBACK_UPDATE_REPORT,
  UPDATE_WITHDRAWL,
  WITHDRAWL_DELETE,
} from "../../utils/constant";
import Filters from "./Filters";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function TransactionReport({
  show,
  type,
  mid,
  onHide,
  reportType,
  pageChange,
}) {
  // console.log("this is mid", mid);
  const [headerTitle, setHeaderTitle] = useState("");
  const [applications, setApplications] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [showEditChargebackModal, setShowEditChargebackModal] = useState(false);
  const [showEditWithdrawlModal, setShowEditWithdrawlModal] = useState(false);
  const [filterRecords, setFilterRecords] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showTxnStatusModal, setShowTxnStatusModal] = useState(false);
  const [modalOrderNo, setModalOrderNo] = useState(null);
  const [modalMid, setModalMid] = useState(null);
  const [zoomCount, setZoomCount] = useState(14);
  const [countData, setCountData] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [reset, setReset] = useState(false);
  const [condition, setCondition] = useState("this year");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [pageM, setPageM] = useState(0);
  const [sizeM, setSizeM] = useState(100);
  const [txnSize, setTxnSize] = useState("");
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [RRNNum, setRRNNum] = useState("");
  const [chargeBackAdjDate, setChargeBackAdjDate] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [utrNum, setUtrNum] = useState("");
  const [txnDate, setTxnDate] = useState("");
  const [ChargebackReportData, setChargebackReportData] = useState();
  const [isWithdrawalManual, setIswithdrawlmanual] = useState(Boolean);
  const [WithdrawlReportData, setWithdrawlReportData] = useState();
  const [PayoutReportData, setPayoutReportData] = useState();
  const [toggleFilterA, setToggleFilterA] = useState(true);
  const [isCreditAddedOpen, setIsCreditAddedOpen] = useState(false);

  const { apiCallFnforPost, apiCallFnforGet, apiCallFnforPut } =
    useApiServices();

  const navigate = useNavigate();
  // const { mid, orderNo } = useParams();

  const offcanvasStyle = {
    width: "95vw", // Set width to 70vw
  };

  function getSearchFunctionText(activeButton) {
    let searchFunctionText;

    if (activeButton === 1) {
      searchFunctionText = "getAllBy";
    } else if (activeButton === 2) {
      searchFunctionText = "getAllDebitBy";
    }

    return searchFunctionText;
  }

  const fetchTransactionReport = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforPost(SINGLE_PAYIN_REPORT, {
        mid: mid,
      });
      if (response.statusCode === 200) {
        // console.log("this is response", response);
        const finalRes = response.responseData.map((item, i) => ({
          id: i,
          mid: item.mid,
          paymentType: item.paymentType,
          txn_id: item.txn_id,
          txnDate: item.txnDate,
          txnTime: item.txnTime,
          amount: item.amount,
          sentToBankDate: item.sentToBankDate,
          ackFromBankDate: item.ackFromBankDate,
          qrSentToMerchantDate: item.qrSentToMerchantDate,
          callbackSentToMerchantDate: item.callbackSentToMerchantDate,
          ackReceivedFromMerchantDate: item.ackReceivedFromMerchantDate,
          terminalId: item.terminalId,
          currency: item.currency,
          txnStatus: item.txnStatus,
          evokTxn:
            item.evokTxnCode === "null" || item.evokTxnCode === null
              ? "0"
              : item.evokTxnCode,
          responseDate: item.responseDate,
          custRefNo: item.custRefNo,
          orderNo: item.orderNo,
          userVpa: item.userVpa,
          mobileNo: item.mobileNo,
          email: item.email,
          customerIp: item.customerIp,
          customerLocation: item.customerLocation,
          remark: item.remark,
          customerIfsc: item.customerIfsc,
          bankLocation: item.bankLocation,
          action: (
            <button
              className=" badge badge-rounded badge-warning"
              onClick={() => handelViewTxnBtn(item.orderNo)}
            >
              View TXN Status
            </button>
          ),
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchSettlementdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(GET_BY_SETTLEMENT_MID + mid);
      if (response?.statusCode === 200) {
        console.log("this is settlement response", response);
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          mid: item.mid,
          netAmount: item.netAmount,
          orderNo: item.orderNo,
          txnId: item.txnId,
          vpa: item.vpa,
          settlmentDate: item.settlmentDate,
          settlmentTime: item.settlmentTime,
          gst: item.gst,
          serviceChanrge: item.serviceChanrge,
          serviceChargeAmount: item.serviceChargeAmount,
          gstAmount: item.gstAmount,
          remark: item.remark,
          payinAmount: item.payinAmount,
          txnStatus: item.txnStatus,
          respCode: item.respCode,
          collectionMethod: item.collectionMethod,
          terminalId: item.terminalId,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response?.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchChargebackdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(CHARGEBACK_UPDATE + mid);
      if (response.statusCode === 200) {
        console.log("this is Chargeback response", response);
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          rrn: item.rrn,
          mid: item.mid,
          chargeBackAdjDate: item.chargeBackAdjDate,
          chargeBackAmount: item.chargeBackAmount,
          compensationPenalty: item.txnDate,
          transactionDate: item.transactionDate,
          transactionAmount: item.transactionAmount,
          payerVpa: item.payerVpa,
          payeeBank: item.payeeBank,
          mobileNumber: item.mobileNumber,
          emailId: item.emailId,
          ifscCode: item.ifscCode,
          bankLocation: item.bankLocation,
          payeeIpAddress: item.payeeIpAddress,
          payeeLocation: item.payeeLocation,
          createDate: item.createDate,
          createTime: item.createTime,
          action: (
            <div>
              <button
                className="badge badge-rounded badge-warning"
                onClick={() => {
                  EditChargeback(item.mid);
                  setRRNNum(item.rrn);
                  setChargeBackAdjDate(item.chargeBackAdjDate);
                  setEditItem(item);
                }}
              >
                Edit
              </button>
            </div>
          ),
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchHoldAmountdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(HOLD_AMOUNT_REPORT + mid);
      if (response.statusCode === 200) {
        console.log("this is hold amount response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          amount: item.amount,
          actualAmount: item.actualAmount,
          txnId: item.txnId,
          holdPercentage: item.holdPercentage,
          createDate: item.createDate,
          orderNo: item.orderNo,
          statusCode: item.statusCode,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchDepositdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(GET_DEPOSIT_REPORT + mid);
      if (response.statusCode === 200) {
        console.log("this is deposit response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          amount: item.amount,
          actualAmount: item.actualAmount,
          depositePercentage: item.depositePercentage,
          orderNo: item.orderNo,
          txnId: item.txnId,
          remark: item.remark,
          date: item.date,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchPayoutReportdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(GET_PAYOUT_REPORT + mid);
      if (response?.statusCode === 200) {
        console.log("this is payout response", response);
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          mid: item.mid,
          fullName: item.fullName,
          email: item.email,
          category: item.category,
          mobile: item.mobile,
          requestId: item.requestId,
          rrn: item.rrn,
          amount: item.amount,
          upi: item.upi,
          transferType: item.transferType,
          reference: item.reference,
          payoutStatus: item.payoutStatus,
          paymentBy: item.paymentBy,
          merchantCallbackRecived: item.merchantCallbackRecived,
          txnId: item.txnId,
          txnStatus: item.txnStatus,
          rrn: item.rrn,
          createDate: item.createDate,
          createTime: item.createTime,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchWithdrawldata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(GET_WITHDRAWAL_REPORT + mid);
      if (response?.statusCode === 200) {
        console.log("this is withdrawl response", response);
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          mid: item.mid,
          actualAmount: item.actualAmount,
          amount: item.amount,
          serviceCharge: item.serviceCharge,
          gstCharge: item.gstCharge,
          utr: item.utr,
          transferType: item.transferType,
          virtualAccountType: item.virtualAccountType,
          status: item.status,
          fullName: item.fullName,
          emailId: item.emailId,
          transactionDate: item.transactionDate,
          createDate: item.createDate,
          createTime: item.createTime,
          action: (
            <div>
              <button
                className="badge badge-rounded badge-warning"
                onClick={() => {
                  EditWithdrawl();
                  setEditItem(item);
                  setUtrNum(item.utr);
                  setTxnDate(item.transactionDate);
                  setIswithdrawlmanual(item.isWithdrawalManual);
                }}
              >
                Edit
              </button>

              {item.isWithdrawalManual && (
                <button
                  className="badge badge-rounded badge-danger"
                  onClick={() => handleDeleteRecord(item.withdrawalId)}
                >
                  Delete
                </button>
              )}
            </div>
          ),
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchGuaranteedata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(GURANTEE_REPORT + mid);
      if (response.statusCode === 200) {
        console.log("this is guarantee response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          guarantyAmount: item.guarantyAmount,
          transactionType: item.transactionType,
          netAmount: item.netAmount,
          transactionDate: item.transactionDate,
          utrNumber: item.utrNumber,
          createDate: item.createDate,
          createTime: item.createTime,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchCreditdata = async () => {
    try {
      const activeButton = 1; // Replace with your logic to determine activeButton
      let searchFunctionText = getSearchFunctionText(activeButton);

      if (activeButton === 1) {
        searchFunctionText = "getAllBy";
      } else if (activeButton === 2) {
        searchFunctionText = "getAllDebitBy";
      }
      // const payload = { mid };
      const response = await apiCallFnforGet(
        `${AVAILABLE_CREDIT_AMOUNT}${searchFunctionText}/${mid}`
      );
      if (response.statusCode === 200) {
        console.log(
          "URL:",
          `${AVAILABLE_CREDIT_AMOUNT}${searchFunctionText}/${mid}`
        );
        console.log("Active Button:", activeButton);
        console.log("Search Function Text:", searchFunctionText);
        console.log("Mid:", mid);
        console.log("this is credit balance response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          createDate: item.createDate,
          createTime: item.createTime,
          fullName: item.fullName,
          orignalAmount: item.orignalAmount,
          deductedAmount: item.deductedAmount,
          chargeInPercentage: item.chargeInPercentage,
          txnType: item.txnType,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchSettlementAdjustmentdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(
        SETTLEMENT_ADJUSTMENT_REPORT + mid
      );
      if (response.statusCode === 200) {
        console.log("this is settlement adjustment response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          txnStatus: item.txnStatus,
          netAmount: item.netAmount,
          settlmentDate: item.settlmentDate,
          remark: item.remark,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchVendordata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(VENDOR_REPORT + mid);
      if (response.statusCode === 200) {
        console.log("this is VENDOR response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          fullName: item.fullName,
          email: item.email,
          category: item.category,
          upi: item.upi,
          accountNumber: item.accountNumber,
          ifscCode: item.ifscCode,
          bankName: item.bankName,
          branchName: item.branchName,
          vendorStatus: item.vendorStatus,
          mobile: item.mobile,
          createDate: item.createDate,
          createTime: item.createTime,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchReleaseAmountdata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(RELEASE_REPORT + mid);
      if (response.statusCode === 200) {
        console.log("this is Release response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          amount: item.amount,
          releaseFrom: item.releaseFrom,
          createDate: item.createDate,
          txnDate: item.txnDate,
          rrn: item.rrn,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const fetchWalletBalancedata = async () => {
    try {
      // const payload = { mid };
      const response = await apiCallFnforGet(WALLETBALANCE_REPORT + mid);
      if (response.statusCode === 200) {
        console.log("this is wallet balance response", response);
        const finalRes = response.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          companyName: item.companyName,
          actualAmount: item.actualAmount,
          amount: item.amount,
          serviceCharge: item.serviceCharge,
          gstCharge: item.gstCharge,
          utr: item.utr,
          transferMode: item.transferMode,
          status: item.status,
          remark: item.remark,
          createDate: item.createDate,
        }));
        setApplications(finalRes);
      } else {
        console.error("Api response error:", response.data);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  useEffect(() => {
    if (reset === true) {
      if (reportType === "totalTransactionAmount") {
        fetchTransactionReport();
      } else if (reportType === "settlementAmount") {
        fetchSettlementdata();
      } else if (reportType === "chargeBackAmount") {
        fetchChargebackdata();
      } else if (reportType === "holdAmount") {
        fetchHoldAmountdata();
      } else if (reportType === "securityDeposit") {
        fetchDepositdata();
      } else if (reportType === "payoutAmount") {
        fetchPayoutReportdata();
      } else if (reportType === "withdrawalAmount") {
        fetchWithdrawldata();
      } else if (reportType === "guaranteeAmount") {
        fetchGuaranteedata();
      } else if (reportType === "creditBalance") {
        fetchCreditdata();
      } else if (reportType === "sumOfSettlemenetAdjustAmount") {
        fetchSettlementAdjustmentdata();
      } else if (reportType === "totalVendors") {
        fetchVendordata();
      } else if (reportType === "releaseAmount") {
        fetchReleaseAmountdata();
      } else if (reportType === "walletBalance") {
        fetchWalletBalancedata();
      }
    }
  }, [reset, reportType]);

  useEffect(() => {
    switch (reportType) {
      case "totalTransactionAmount":
        fetchTransactionReport()
          .then(() => {
            setHeaderTitle("Payin Report");
          })
          .catch((error) => {
            console.error("Error fetching transaction report:", error);
          });
        break;
      case "settlementAmount":
        fetchSettlementdata()
          .then(() => {
            setHeaderTitle("Settlement Report");
          })
          .catch((error) => {
            console.error("Error fetching settlement data:", error);
          });
        break;
      case "chargeBackAmount":
        fetchChargebackdata()
          .then(() => {
            setHeaderTitle("Chargeback Report");
          })
          .catch((error) => {
            console.error("Error fetching chargeback data:", error);
          });
        break;
      case "holdAmount":
        fetchHoldAmountdata()
          .then(() => {
            setHeaderTitle("Hold Amount Report");
          })
          .catch((error) => {
            console.error("Error fetching hold amount data:", error);
          });
        break;
      case "securityDeposit":
        fetchDepositdata()
          .then(() => {
            setHeaderTitle("Deposit Amount Report");
          })
          .catch((error) => {
            console.error("Error fetching security deposit data:", error);
          });
        break;
      case "payoutAmount":
        fetchPayoutReportdata()
          .then(() => {
            setHeaderTitle("Payout Amount Report");
          })
          .catch((error) => {
            console.error("Error fetching payout amount data:", error);
          });
        break;
      case "withdrawalAmount":
        fetchWithdrawldata()
          .then(() => {
            setHeaderTitle("Withdrawal Amount Report");
          })
          .catch((error) => {
            console.error("Error fetching withdrawal amount data:", error);
          });
        break;
      case "guaranteeAmount":
        fetchGuaranteedata()
          .then(() => {
            setHeaderTitle("Guarantee Amount Report");
          })
          .catch((error) => {
            console.error("Error fetching guarantee amount data:", error);
          });
        break;
      case "creditBalance":
        fetchCreditdata()
          .then(() => {
            setHeaderTitle("Credit Report");
          })
          .catch((error) => {
            console.error("Error fetching guarantee amount data:", error);
          });
        break;
      case "sumOfSettlemenetAdjustAmount":
        fetchSettlementAdjustmentdata()
          .then(() => {
            setHeaderTitle("Settlement Adjustment Report");
          })
          .catch((error) => {
            console.error("Error fetching settlement adjustment data:", error);
          });
        break;
      case "totalVendors":
        fetchVendordata()
          .then(() => {
            setHeaderTitle("Vendor Report");
          })
          .catch((error) => {
            console.error("Error fetching vendor data:", error);
          });
        break;
      // case "serviceCharge":
      //   setHeaderTitle("Service Charge Report");
      //   break;
      case "releaseAmount":
        fetchReleaseAmountdata()
          .then(() => {
            setHeaderTitle("Release Report");
          })
          .catch((error) => {
            console.error("Error fetching release amount data:", error);
          });
        break;
      case "walletBalance":
        fetchWalletBalancedata()
          .then(() => {
            setHeaderTitle("Wallet Balance Report");
          })
          .catch((error) => {
            console.error("Error fetching wallet balance data:", error);
          });
        break;
      // default:
      //   break;
    }
  }, [reportType, pageChange, mid, reset]);

  const columns1 = [
    //payin report columns
    { headerName: "Payment Type", field: "paymentType", minWidth: "150" },
    { headerName: "View Transaction Status", field: "action", minWidth: "200" },
    { headerName: "Transaction ID", field: "txn_id", minWidth: "150" },
    { headerName: "Transaction Date", field: "txnDate", minWidth: "150" },
    { headerName: "Transaction Time", field: "txnTime", minWidth: "150" },
    {
      headerName: "Sent To Bank Date",
      field: "sentToBankDate",
      minWidth: "150",
    },
    {
      headerName: "Ack. From Bank Date",
      field: "ackFromBankDate",
      minWidth: "150",
    },
    {
      headerName: "QR Sent To Merchant Date",
      field: "qrSentToMerchantDate",
      minWidth: "150",
    },
    {
      headerName: "Callback Sent To Merchant Date",
      field: "callbackSentToMerchantDate",
      minWidth: "150",
    },
    {
      headerName: "Ack. Received From Merchant Date",
      field: "ackReceivedFromMerchantDate",
      minWidth: "150",
    },
    { headerName: "Terminal ID", field: "terminalId", minWidth: "150" },
    { headerName: "Amount", field: "amount", minWidth: "150" },
    { headerName: "Currency", field: "currency", minWidth: "150" },
    { headerName: "Transaction Status", field: "txnStatus", minWidth: "150" },
    { headerName: "Bank Response Code ", field: "evokTxn", minWidth: "150" },
    { headerName: "Response Date", field: "responseDate", minWidth: "150" },
    { headerName: "UTR Number", field: "custRefNo", minWidth: "150" },
    { headerName: "Order No", field: "orderNo", minWidth: "150" },
    { headerName: "Payer VPA", field: "userVpa", minWidth: "150" },
    { headerName: "Mobile Number", field: "mobileNo", minWidth: "150" },
    { headerName: "Email", field: "email", minWidth: "150" },
    { headerName: "Customer IP Address", field: "customerIp", minWidth: "150" },
    {
      headerName: "Customer Location",
      field: "customerLocation",
      minWidth: "150",
    },
    { headerName: "Remark", field: "remark", minWidth: "150" },
    { headerName: " Customer IFSC", field: "customerIfsc", minWidth: "150" },
    { headerName: "Bank Location", field: "bankLocation", minWidth: "150" },
  ];

  const columns2 = [
    //settlement report columns
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "Net Amount", field: "netAmount", minWidth: "150" },
    { headerName: "Order No", field: "orderNo", minWidth: "150" },
    { headerName: "Transaction ID", field: "txnId", minWidth: "150" },
    { headerName: "VPA", field: "vpa", minWidth: "150" },
    { headerName: "Settlement Date", field: "settlmentDate", minWidth: "150" },
    { headerName: "Settlement Time", field: "settlmentTime", minWidth: "150" },
    { headerName: "GST", field: "gst", minWidth: "150" },
    { headerName: "Service Charge", field: "serviceChanrge", minWidth: "150" },
    {
      headerName: "Service Charge Amount",
      field: "serviceChargeAmount",
      minWidth: "150",
    },
    { headerName: "Remark", field: "remark", minWidth: "150" },
    { headerName: "PayIn Amount", field: "payinAmount", minWidth: "150" },
    { headerName: "Transaction Status", field: "txnStatus", minWidth: "150" },
    { headerName: "Response Code", field: "respCode", minWidth: "150" },
    {
      headerName: "Collection Method",
      field: "collectionMethod",
      minWidth: "150",
    },
    { headerName: "Terminal ID", field: "terminalId", minWidth: "150" },
  ];

  const columns3 = [
    //chargeback reports columns
    { headerName: "ID", field: "id", minWidth: "100" },
    { headerName: "RRN", field: "rrn", minWidth: "150" },
    {
      headerName: "Chargeback Adjustment Date",
      field: "chargeBackAdjDate",
      minWidth: "150",
    },
    {
      headerName: "Chargeback Amount",
      field: "chargeBackAmount",
      minWidth: "150",
    },
    {
      headerName: "Compensation Penalty",
      field: "compensationPenalty",
      minWidth: "150",
    },
    {
      headerName: "Transaction Date",
      field: "transactionDate",
      minWidth: "200",
    },
    {
      headerName: "Transaction Amount",
      field: "transactionAmount",
      minWidth: "150",
    },
    { headerName: "Payer-VPA", field: "payerVpa", minWidth: "200" },
    { headerName: "Payee Bank", field: "payeeBank", minWidth: "150" },
    { headerName: "Mobile Number", field: "mobileNumber", minWidth: "150" },
    { headerName: "e-Mail Id", field: "emailId", minWidth: "150" },
    { headerName: "IFSC Code", field: "ifscCode", minWidth: "150" },
    { headerName: "Bank Location", field: "bankLocation", minWidth: "150" },
    {
      headerName: "Payee IP Address",
      field: "payeeIpAddress",
      minWidth: "150",
    },
    { headerName: "Payee Location", field: "payeeLocation", minWidth: "150" },
    {
      headerName: "Chargeback Upload Date",
      field: "createDate",
      minWidth: "150",
    },
    {
      headerName: "Chargeback Upload Time",
      field: "createTime",
      minWidth: "150",
    },
    { headerName: "Action", field: "action", minWidth: "150" },
  ];

  const columns4 = [
    //hold columns
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "Amount", field: "amount", minWidth: "100" },
    { headerName: "Actual Amount", field: "actualAmount", minWidth: "130" },
    { headerName: "Transaction ID", field: "txnId", minWidth: "200" },
    { headerName: "Hold Percentage", field: "holdPercentage", minWidth: "150" },
    { headerName: "Created Date", field: "createDate", minWidth: "150" },
    { headerName: "Order Number", field: "orderNo", minWidth: "150" },
    { headerName: "Status Code", field: "statusCode", minWidth: "150" },
  ];

  const columns5 = [
    //deposit columns
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "Amount", field: "amount", minWidth: "150" },
    { headerName: "Actual Amount", field: "actualAmount", minWidth: "150" },
    {
      headerName: "Deposite Percentage",
      field: "depositePercentage",
      minWidth: "150",
    },
    { headerName: "Order Number", field: "orderNo", minWidth: "120" },
    { headerName: "Transaction ID", field: "txnId", minWidth: "200" },
    { headerName: "Remark", field: "remark", minWidth: "170" },
    { headerName: "Date", field: "date", minWidth: "120" },
  ];

  const columns6 = [
    //payout columns
    { headerName: "Full Name", field: "fullName", minWidth: "150" },
    { headerName: "e-Mail ID", field: "email", minWidth: "150" },
    { headerName: "Category", field: "category", minWidth: "150" },
    { headerName: "Mobile Number", field: "mobile", minWidth: "150" },
    { headerName: "VPA/UPI", field: "upi", minWidth: "150" },
    { headerName: "UTR Number", field: "rrn", minWidth: "150" },
    { headerName: "Amount", field: "amount", minWidth: "150" },
    { headerName: "Transfer type", field: "transferType", minWidth: "150" },
    { headerName: "Reference ID", field: "reference", minWidth: "200" },
    { headerName: "Payout Status", field: "payoutStatus", minWidth: "150" },
    { headerName: "Payment By", field: "paymentBy", minWidth: "150" },
    { headerName: "Callback Recieved", field: "Green", minWidth: "150" },
    { headerName: "Transaction ID", field: "txnId", minWidth: "200" },
    { headerName: "Transaction Status", field: "txnStatus", minWidth: "150" },
    // { headerName: "RRN", field: "rrn", minWidth: "150" },
    { headerName: "Create Date", field: "createDate", minWidth: "150" },
    { headerName: "Create Time", field: "createTime", minWidth: "150" },
  ];

  const columns7 = [
    //withdrawl columns
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "UTR Number", field: "utr", minWidth: "150" },
    { headerName: "Requested-Amount", field: "actualAmount", minWidth: "150" },
    { headerName: "Amount", field: "amount", minWidth: "150" },
    { headerName: "Service Charge", field: "serviceCharge", minWidth: "150" },
    { headerName: "GST", field: "gstCharge", minWidth: "150" },
    { headerName: "Transfer type", field: "transferType", minWidth: "150" },
    {
      headerName: "Virtual Account Type",
      field: "virtualAccountType",
      minWidth: "150",
    },
    { headerName: "Status", field: "status", minWidth: "150" },
    { headerName: "Full Name", field: "fullName", minWidth: "200" },
    { headerName: "e-Mail ID", field: "emailId", minWidth: "200" },
    {
      headerName: "Transaction Date",
      field: "transactionDate",
      minWidth: "150",
    },
    { headerName: "Create Date", field: "createDate", minWidth: "150" },
    { headerName: "Create Time", field: "createTime", minWidth: "150" },
    { headerName: "Action", field: "action", minWidth: "150" },
  ];

  const columns8 = [
    //guaranteeAmount columns
    {
      headerName: "Guarantee Amount",
      field: "guarantyAmount",
      minWidth: "150",
    },
    { headerName: "Net Amount", field: "netAmount", minWidth: "150" },
    {
      headerName: "Transaction Type",
      field: "transactionType",
      minWidth: "150",
    },
    {
      headerName: "Transaction Date",
      field: "transactionDate",
      minWidth: "150",
    },
    { headerName: "UTR Number", field: "utrNumber", minWidth: "150" },
    { headerName: "Create Date", field: "createDate", minWidth: "150" },
    { headerName: "Create Time", field: "createTime", minWidth: "150" },
  ];
  const columns9 = [
    //Credit balance columns
    { headerName: "Date", field: "createDate", minWidth: "150" },
    { headerName: "Time", field: "createTime", minWidth: "150" },
    { headerName: "Merchant Name", field: "fullName", minWidth: "200" },
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "Amount", field: "orignalAmount", minWidth: "150" },
    { headerName: "Service Charges", field: "deductedAmount", minWidth: "150" },
    {
      headerName: "Charges In Percentage",
      field: "chargeInPercentage",
      minWidth: "150",
    },
    { headerName: "Charges For Services", field: "txnType", minWidth: "150" },
  ];

  const columns10 = [
    //Settlement Adjustment columns
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "Transaction Status", field: "txnStatus", minWidth: "150" },
    { headerName: "Amount", field: "netAmount", minWidth: "100" },
    { headerName: "Create Date", field: "settlmentDate", minWidth: "150" },
    { headerName: "Remark", field: "remark", minWidth: "200" },
  ];

  const columns11 = [
    //vendor columns
    { headerName: "Name", field: "fullName", minWidth: "200" },
    { headerName: "e-Mail ID", field: "email", minWidth: "200" },
    { headerName: "Category", field: "category", minWidth: "150" },
    { headerName: "Account Number", field: "accountNumber", minWidth: "150" },
    { headerName: "VPA / UPI ID", field: "upi", minWidth: "150" },
    { headerName: "IFSC Code", field: "ifscCode", minWidth: "150" },
    { headerName: "Bank Name", field: "bankName", minWidth: "150" },
    { headerName: "Branch Name", field: "branchName", minWidth: "200" },
    { headerName: "Vendor Status", field: "vendorStatus", minWidth: "120" },
    { headerName: "Mobile", field: "mobile", minWidth: "120" },
    { headerName: "Create Date", field: "createDate", minWidth: "120" },
    { headerName: "Create Time", field: "createTime", minWidth: "120" },
  ];

  const columns12 = [
    //Release columns
    {
      headerName: "Date",
      field: "createDate",
      minWidth: 150,
    },
    {
      headerName: "Merchant ID",
      field: "mid",
      minWidth: 150,
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 150,
    },
    {
      headerName: "Transaction Date",
      field: "txnDate",
      minWidth: 150,
    },
    {
      headerName: "UTR Number",
      field: "rrn",
      minWidth: 150,
    },
    {
      headerName: "Release From",
      field: "releaseFrom",
      minWidth: 150,
    },
  ];

  const columns13 = [
    //wallet balance columns
    { headerName: "Merchant ID", field: "mid", minWidth: "150" },
    { headerName: "Create Date", field: "createDate", minWidth: "150" },
    { headerName: "Company Name", field: "companyName", minWidth: "200" },
    { headerName: "Request-Amount", field: "actualAmount", minWidth: "120" },
    { headerName: "Amount", field: "amount", minWidth: "120" },
    { headerName: "Service Charge", field: "serviceCharge", minWidth: "120" },
    { headerName: "GST", field: "gstCharge", minWidth: "120" },
    { headerName: "UTR Number", field: "utr", minWidth: "150" },
    { headerName: "Transfer Mode", field: "transferMode", minWidth: "150" },
    { headerName: "status", field: "status", minWidth: "150" },
    { headerName: "Remark", field: "remark", minWidth: "150" },
  ];
  const columnsForDebit = [
    { headerName: "Date", field: "createDate", minWidth: "150" },
    { headerName: "Time", field: "createTime", minWidth: "150" },
    { headerName: "Merchant Name", field: "fullName", minWidth: "300" },
    { headerName: "Merchant Id", field: "mid", minWidth: "150" },
    { headerName: "Amount", field: "orignalAmount", minWidth: "150" },
    { headerName: "Remark", field: "txnType", minWidth: "150" },
  ];

  const columns = {
    totalTransactionAmount: columns1,
    settlementAmount: columns2,
    chargeBackAmount: columns3,
    holdAmount: columns4,
    securityDeposit: columns5,
    payoutAmount: columns6,
    withdrawalAmount: columns7,
    guaranteeAmount: columns8,
    creditBalance: columns9,
    sumOfSettlemenetAdjustAmount: columns10,
    totalVendors: columns11,
    releaseAmount: columns12,
    walletBalance: columns13,
  };

  const EditChargeback = () => {
    setShowEditChargebackModal(true);
  };

  const EditWithdrawl = () => {
    setShowEditWithdrawlModal(!showEditWithdrawlModal);
  };

  function handleViewButtonClick(orderNo, mid) {
    setModalOrderNo(orderNo);
    setModalMid(mid);
    setShowResponseModal(true);
  }

  function handelViewTxnBtn(orderNo) {
    setModalOrderNo(orderNo);
    setShowTxnStatusModal(true);
  }
  const handleCloseResponseModal = () => {
    setShowResponseModal(false);
  };
  const onCloseModal = () => {
    setShowResponseModal(false);
  };
  const handleCloseTxnStatusModal = () => {
    setShowTxnStatusModal(false);
  };

  const handleChargebackModal = () => {
    setShowEditChargebackModal(false);
  };

  const handleWithdrawlkModal = () => {
    setShowEditWithdrawlModal(false);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    setIsMultipleFilterOpen(false);
  };
  const handleCreditAdded = () => {
    setIsCreditAddedOpen(!isCreditAddedOpen);
  };

  const handleCreditModal = () => {
    setIsCreditAddedOpen(false);
  };

  const handleEditChargebackSubmit = (e) => {
    e.preventDefault();
    const payload = {
      chargeBackAdjDate: chargeBackAdjDate,
      id: editItem.id,
      rrn: RRNNum,
    };

    apiCallFnforPut(CHARGEBACK_UPDATE_REPORT, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
          });
          setShowEditChargebackModal(false);
        } else {
          swal({
            title: "Alert",
            text: response.message,
            icon: "error",
          });
          setShowEditChargebackModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateInputChangeChargeback = (event) => {
    const newDate = event.target.value;
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setChargeBackAdjDate(inputDateTime.toISODate());
    }
  };

  const handleEditWithdrawlSubmit = (e) => {
    e.preventDefault();
    const payload = {
      remark: "",
      status: editItem.status,
      transactionDate: txnDate,
      isWithdrawalManual: isWithdrawalManual,
      utr: utrNum,
      withdrawalId: editItem.withdrawalId,
    };

    apiCallFnforPut(UPDATE_WITHDRAWL, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
          });
          setShowEditWithdrawlModal(false);
        } else {
          swal({
            title: "Alert",
            text: response.message,
            icon: "error",
          });
          setShowEditWithdrawlModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateInputChangeWithdrawl = (event) => {
    const newDate = event.target.value;
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime?.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime?.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setTxnDate(inputDateTime?.toISODate());
    }
  };

  const handleDeleteRecord = (withdrawalId) => {
    if (data) {
      const recordToDelete = data?.find(
        (item) => item.withdrawalId === withdrawalId
      );
      if (recordToDelete && recordToDelete?.isWithdrawalManual) {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this record!",
          icon: "warning",
          buttons: ["Cancel", "Yes, delete it"],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            apiCallFnforGet(`${WITHDRAWL_DELETE}/${withdrawalId}`)
              .then((response) => {
                if (response.statusCode === 200) {
                  swal({
                    title: "Success",
                    text: response.message,
                    icon: "success",
                  });

                  const updatedData = data.filter(
                    (item) => item.withdrawalId !== withdrawalId
                  );
                  setData(updatedData);
                } else {
                  swal({
                    title: "Alert",
                    text: response.message,
                    icon: "error",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    }
  };

  const value = Array?.isArray(applications) ? applications : [];

  const calculateTotalAmountForSuccess = (data) => {
    const successTransactions = applications?.filter(
      (item) => item.txnStatus === "success"
    );
    return successTransactions?.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(
    !value?.length ? filterRecords : value
  );

  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };

  const HandleReset = () => {
    setReset(!reset);
    setApplications([]);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  };

  const handleChangeFunc = () => {
    setReset(!reset);
    onHide();
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleChangeFunc}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
        keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton> </Offcanvas.Header>
          <h5
            class="offcanvas-title fs-2 text-dark fw-bold"
            id="offcanvasLabel"
          >
            {headerTitle}
          </h5>
        </div>

        <CompanyNameAndMid mid={mid} />

        <Offcanvas.Body>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex align-items-center">
              <div className="fs-2 text-dark fw-bold">{type}</div>
            </div>

            <div className="col-7 mb-4 mb-sm-0" style={{ textAlign: "right" }}>
              <IconWrapper title={"Zoomin"}>
                <span class="" onClick={incrementZoom}>
                  <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                </span>
              </IconWrapper>
              <IconWrapper title={"Zoomout"}>
                <span class="" onClick={decrementZoom}>
                  <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                </span>
              </IconWrapper>
              <IconWrapper title={"reset"}>
                <i className="fa fa-refresh" onClick={HandleReset}></i>
              </IconWrapper>

              {[
                "totalTransactionAmount",
                "settlementAmount",
                "chargeBackAmount",
                "payoutAmount",
                "withdrawalAmount",
              ].includes(reportType) && (
                <>
                  <IconWrapper title={"Multiple Filter"}>
                    <i
                      className="fa fa-filter"
                      onClick={handleMultipleFilter}
                    ></i>
                  </IconWrapper>
                  <IconWrapper title={"Multiple Filter"}>
                    <i
                      className="fa fa-filter"
                      onClick={handleAdvanceSearch}
                    ></i>
                  </IconWrapper>
                </>
              )}
              {["guaranteeAmount"].includes(reportType) && (
                <IconWrapper title={"Multiple Filter"}>
                  <i
                    className="fa fa-filter"
                    onClick={handleMultipleFilter}
                  ></i>
                </IconWrapper>
              )}
              {["creditBalance"].includes(reportType) && (
                <IconWrapper title={"Credit Added"}>
                  <i className="fa fa-flag" onClick={handleCreditAdded}></i>
                </IconWrapper>
              )}
            </div>
          </div>

          {/* Render appropriate components based on reportType */}
          {(() => {
            switch (reportType) {
              case "totalTransactionAmount":
                return (
                  <>
                    {isMultipleFilterOpen && (
                      <TransactionReportMultipleFilter
                        setTransactionReportData={setApplications}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setCountData={setCountData}
                        handelViewTxnBtn={handelViewTxnBtn}
                      />
                    )}
                    {isAdvancedSearchOpen && (
                      <TxnReportAdvancedSearchPopup
                        handelViewTxnBtn={handelViewTxnBtn}
                        setApplications={setApplications}
                        setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                        mid={mid}
                      />
                    )}
                  </>
                );
              case "settlementAmount":
                return (
                  <>
                    {isMultipleFilterOpen && (
                      <SettlementMultipleFilter
                        setApplications={setApplications}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setCountData={setCountData}
                      />
                    )}
                    {isAdvancedSearchOpen && (
                      <SettlementAdvanceSearchPopup
                        setApplications={setApplications}
                        setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                        mid={mid}
                      />
                    )}
                  </>
                );
              case "chargeBackAmount":
                return (
                  <>
                    {isMultipleFilterOpen && (
                      <ChargebackMultipleFilter
                        setChargeBackReportData={setApplications}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setCountData={setCountData}
                        setToggleFilterA={setToggleFilterA}
                        toggleFilterA={toggleFilterA}
                        EditChargeback={EditChargeback}
                        setRRNNum={setRRNNum}
                        setChargeBackAdjDate={setChargeBackAdjDate}
                        setEditItem={setEditItem}
                        // setCustomMultiApiResponse={setCustomMultiApiResponse}
                      />
                    )}
                    {isAdvancedSearchOpen && (
                      <ChargebackAdvanceFilter
                        setApplications={setApplications}
                        setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                        setChargebackReportData={setChargebackReportData}
                        EditChargeback={EditChargeback}
                        setRRNNum={setRRNNum}
                        setChargeBackAdjDate={setChargeBackAdjDate}
                        setEditItem={setEditItem}
                        // setCustomMultiApiResponse={setCustomMultiApiResponse}
                      />
                    )}
                  </>
                );
              case "payoutAmount":
                return (
                  <>
                    {isMultipleFilterOpen && (
                      <PayoutMultipleFilter
                        setApplications={setApplications}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setCountData={setCountData}
                        setToggleFilterA={setToggleFilterA}
                        toggleFilterA={toggleFilterA}
                      />
                    )}
                    {isAdvancedSearchOpen && (
                      <PayoutAdvanceFilter
                        setApplications={setApplications}
                        setShowLoader={setShowLoader}
                        setPayoutReportData={setPayoutReportData}
                        setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                        mid={mid}
                      />
                    )}
                  </>
                );
              case "creditBalance":
                return (
                  <>
                    {isCreditAddedOpen && (
                      <Modal
                        show={isCreditAddedOpen}
                        onHide={handleCreditModal}
                        size="xl"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="fs-4 fw-bold">
                            Credit Added
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div style={{ height: "500px", width: "100%" }}>
                            <DataGrid
                              rows={applications}
                              columns={columnsForDebit.map((column) => ({
                                ...column,
                                renderCell: (params) =>
                                  column.field === "createDate" ||
                                  column.field === "createTime"
                                    ? `${params.row[column.field]}`
                                    : `${params.value}`,
                              }))}
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </div>
                        </Modal.Body>
                      </Modal>
                    )}
                  </>
                );

              case "withdrawalAmount":
                return (
                  <>
                    {isMultipleFilterOpen && (
                      <WithdrawMultipleFilter
                        setWithdrawlReportData={setApplications}
                        setShowMultipleFilter={setIsMultipleFilterOpen}
                        mid={mid}
                        setCountData={setCountData}
                        setShowLoader={setShowLoader}
                        EditWithdrawl={EditWithdrawl}
                        setEditItem={setEditItem}
                        setUtrNum={setUtrNum}
                        setTxnDate={setTxnDate}
                        setIswithdrawlmanual={setIswithdrawlmanual}
                        handleDeleteRecord={handleDeleteRecord}
                        // setShowMultipleFilter={setShowMultipleFilter}
                      />
                    )}
                    {isAdvancedSearchOpen && (
                      <WithdrawAdvanceFilter
                        setApplications={setApplications}
                        setWithdrawlReportData={setWithdrawlReportData}
                        setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                        mid={mid}
                        EditWithdrawl={EditWithdrawl}
                        setEditItem={setEditItem}
                        setUtrNum={setUtrNum}
                        setTxnDate={setTxnDate}
                        setIswithdrawlmanual={setIswithdrawlmanual}
                      />
                    )}
                  </>
                );
              case "securityDeposit":
              case "guaranteeAmount":
              case "walletBalance":
                return (
                  <>
                    {isMultipleFilterOpen ? (
                      <GuaranteeMultipleFilter
                        setApplications={setApplications}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        // setShowLoader={setShowLoader}
                        setCountData={setCountData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              default:
                return null;
            }
          })()}

          <div className="report-grid-view mt-3">
            <div
              className="material-table-records mb-3"
              style={{ height: "400px", width: "100%" }}
            >
              <DataGrid
                rows={applications}
                columns={columns[reportType]?.map((column) => ({
                  ...column,
                  renderCell: (params) =>
                    column.field === "active" || column.field === "action"
                      ? params.value
                      : params.value,
                }))}
                reset={reset}
                slots={{
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                sx={{
                  fontSize: `${zoomCount}px`,
                  "& .css-1rtad1": {
                    display: "block",
                  },
                }}
              />
            </div>

            {/* <DownloadCSV />  */}
            <div className="d-flex justify-content-between align-items-center">
              {/* <div className="d-flex align-items-center justify-content-between w-25">
            {transactionReportData.length > 0 && (
              <DownloadCSV />
            )}
          </div> */}
              {reportType === "totalTransactionAmount" && (
                <div className="d-flex align-items-center justify-content-between w-25 gap-4 border border-1 p-2">
                  <label className="fs-4 w-50">Total Amount</label>
                  <span className="fs-4 fw-bold w-50 border border-2 rounded-2 p-1">
                    {totalAmountForSuccess}
                  </span>
                </div>
              )}
            </div>
          </div>

          <Modal show={showTxnStatusModal} onHide={handleCloseTxnStatusModal} backdrop="static"
          keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>View TXN Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ViewTxnStatus
                mid={modalMid}
                orderNo={modalOrderNo}
                showModal={showTxnStatusModal}
                closeModal={handleCloseTxnStatusModal}
                onCloseModal={onCloseModal}
              />
            </Modal.Body>
          </Modal>

          <Modal show={showEditChargebackModal} onHide={handleChargebackModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Chargeback:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="w-100">
                <div className="form-group">
                  <div>
                    <label>Reference Number *</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Reference Number"
                      value={RRNNum}
                      onChange={(e) => setRRNNum(e.target.value)}
                    />
                    <label className="mt-3">Transaction Date *</label>
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Enter Transaction Date"
                      value={chargeBackAdjDate}
                      onChange={handleDateInputChangeChargeback}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <button
                    className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                    onClick={(e) => handleEditChargebackSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <Modal show={showEditWithdrawlModal} onHide={handleWithdrawlkModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Withdrawal:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="w-100">
                <div className="form-group">
                  <div>
                    <label>Reference Number *</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Reference Number"
                      value={utrNum}
                      onChange={(e) => setUtrNum(e.target.value)}
                    />
                    <label className="mt-3">Transaction Date *</label>
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Enter Transaction Date"
                      value={txnDate}
                      onChange={handleDateInputChangeWithdrawl}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <button
                    className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                    onClick={(e) => handleEditWithdrawlSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default TransactionReport;
