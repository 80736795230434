import axios from "axios";
import { SWIPELINC_API } from "./constant";
import { redirectIfNoToken } from "./redirect";
import swal from "sweetalert";
import { getTokenFromLocalStorage } from "./tokenService";

const axiosInstance = axios.create({
  baseURL: SWIPELINC_API,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getTokenFromLocalStorage()
    // const token = localStorage.getItem('token');
    // console.log("TOKEN >>>>> ", token);

    if (!token) {
      swal({
        title: "Alert!",
        text: " Session Expired ! Please LogIn Again",
        icon: "warning",
      }).then(() => {
        redirectIfNoToken();
      });
      return Promise.reject(new Error("No token found"));
    }
    config.headers["Authentication"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
