import { useLocation } from "react-router-dom";

export default function Header()
{

    const  type = useLocation();
    // console.log(type?.pathname.split("/")[1])

return<>
<div className="header">
<div className="header-content">
    <nav className="navbar navbar-expand">
        <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
                <div className="dashboard_bar">
                    {type?.pathname.split("/")[1]}
                </div>
            </div>
        </div>
    </nav>
</div>
</div>
</>
}