import React, { useState } from "react";
import MerchantDropdown from "./merchantDropdown";


const MerchantIcon = ({merchantData, setMid, reset}) => {
  const [isOpen, setIsOpen] = useState(false);
  

  const handleMouseEnter = () => {
  
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
   
    setIsOpen(false);
  };

  return (
    <div
      
      style={{
        position: "fixed",
        bottom: "70px",
        right: "5px",
        zIndex: "9999",
        // width: isOpen ? "350px" :,
        transition: "width 0.3s ease",
        overflow: "visible",
      }}
    >
      <div
        className="rounded rounde-5 m-1"
        style={{
          height: "50px",
          width: "auto",
          // color: "white",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          cursor: "pointer",
        //   padding: "0px 10px"
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        
        <MerchantDropdown   isOpen={isOpen} merchantData={merchantData} setMid={setMid} reset={reset}/>

        
      </div>
    </div>
  );
};

export default MerchantIcon;
