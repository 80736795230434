import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import { DateTime } from "luxon";

const MyModal = ({ id, showModal, setShowModal, type, performApprovalOrRejection }) => {
  const [data, setData] = useState("");
  const [date, setDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remark, setRemark] = useState("");

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setDate(inputDateTime.toISODate());
    }
  };

  const handleSubmit = () => {
    if (!type) {
      if (isSubmitting || data.length === 0 || date.length === 0) {
        swal({
          title: "Incomplete Information",
          text: "Please fill in all the required fields.",
          icon: "warning",
        });
        return;
      }
    } else if (isSubmitting || remark.length === 0) {
      swal({
        title: "Incomplete Information",
        text: "Please fill in the rejection remark.",
        icon: "warning",
      });
      return;
    }

    setIsSubmitting(true);

    performApprovalOrRejection(id, type, data, date, remark);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="sm">
      <Modal.Header closeButton>
        <Modal.Title className="fs-4 fw-bold">Enter Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center ">
          <div>
            {!type ? (
              <div>
               <label className="mx-4 text-dark fs-4">
                  Reference Number *

                </label>
                <input className="form-control"
                  type="text"
                  placeholder="Enter Reference Number"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                />
                <label className="mx-4 text-dark fs-4">
                  Transaction Date *</label>
                <input className="form-control "
                  type="date"
                  placeholder="Enter Transaction Date"
                  value={date}
                  onChange={handleDateInputChange}
                />
              </div>
            ) : (
              <div>
                 <label className="mx-4 text-dark fs-4">
                  Rejection Remark *</label>
                <input className="form-control input-22"
                  type="text"
                  placeholder="Enter rejection remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="badge badge-rounded badge-primary mx-2 px-3 py-3" onClick={handleSubmit} disabled={isSubmitting}>
          Submit
        </button>
        <button className="badge badge-rounded badge-info mx-2 px-3 py-3" onClick={() => setShowModal(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyModal;
