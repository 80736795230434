import React from "react";
import { config, getCompleteDomain, getServerEnvironment } from "../utils/helper";
import { useSelector } from "react-redux";

const InactiveServices = ({ location }) => {
  const { sectionName } = useSelector((state) => state.privilages);
  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));


  return (
    <>
      <div className="noservice">
        <div className="card text-center">
          <div className="card-header">Not Available</div>
          <div className="card-body">
            <img src={logoPath} className="inactive-header" alt="logo" />
            <h5 className="card-title">
              { location !== "privilageskkkkkkkkkkkkkkkkkkkkkkkkkkkk"
                ? "This Service Is Not Activated yet."
                : `You Do Not Have Privilage To Access ${sectionName} section`}
            </h5>
            <p className="card-text"> </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveServices;