import React, { useEffect, useState } from "react";
import { FETCH_ALL_ACCOUNT_DETAILS } from "../../utils/constant";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, Modal } from "react-bootstrap";
import useApiServices from "../../utils/useApiServices";
import FeedIcon from "@mui/icons-material/Feed";
import ViewSingleSubmittedApplication from "./ViewSingleSubmittedApplication";
import MDRForm from "./MDRForm";
import CustomTip from "../../common/CustomToolTip/CustomTip";

const MDRSubmittedAccountApplications = ({ toggleRightBar }) => {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [applications, setApplications] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [mid, setMid] = useState("");
  // const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { apiCallFnforGet } = useApiServices();
  const[refresh,setRefresh]=useState(false)

  useEffect(() => {
    FetchData();
  }, [isModalOpen,refresh,setRefresh]);

  const FetchData = async () => {
    try {
      const apiResponse = await apiCallFnforGet(FETCH_ALL_ACCOUNT_DETAILS);
      if (apiResponse.statusCode === 200) {
        const mappedData = apiResponse?.data?.map((item, i) => ({
          id: i,
          companyName: item.companyName,
          email: item.email,
          mid: item.mid,
          action: (
            <div>
              <button
                className="badge badge-rounded badge-warning"
                onClick={() => ViewSubmittedApplicationsDetails(item.mid)}
              >
                View
              </button>
            </div>
          ),
        }));
        mappedData?.sort((a, b) => a?.companyName?.localeCompare(b?.companyName));
        setApplications(mappedData);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleShowMDRForm = () => setIsModalOpen(true);

  const columns = [
    { headerName: "Merchant Name", field: "companyName", minWidth: 350 },
    { headerName: "e-Mail", field: "email", minWidth: 350 },
    { headerName: "Merchant ID", field: "mid", minWidth: 250 },
    { headerName: "Action", field: "action", minWidth: 250 },
  ];

  const ViewSubmittedApplicationsDetails = (mid) => {
    setShow(true);
    setMid(mid);
    setShowDetails(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center me-4">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Merchant Discount Rate</div>
          </div>

          <div className="">
            <CustomTip size="18" placement="top" title="MDR Form ">
              <div className="" onClick={handleShowMDRForm}>
                <FeedIcon />
              </div>
            </CustomTip>
          </div>
        </div>

        <div style={{ height: "500px", width: "100%" }}>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {show ? (
                      <ViewSingleSubmittedApplication
                        show={show}
                        onHide={handleClose}
                        mid={mid}
                      />
                    ) : (
                      <DataGrid
                        rows={applications}
                        columns={columns.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Modal Routing */}

      {/* <Modal show={isModalOpen} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">MDR Form</Modal.Title>
        </Modal.Header>

        <Modal.Body > */}
      <MDRForm show={isModalOpen} onHide={handleCloseModal} 
      setRefresh={setRefresh}
      refresh={refresh}
      />
      {/* </Modal.Body>
      </Modal> */}
    </>
  );
};

export default MDRSubmittedAccountApplications;
