 
import { useSelector } from "react-redux";
import { setSectionName } from "../store/slices/privilagesSlice";

const useCheckPrivilages = (privilageId) => {
  const { allPrivilagesData, sectionName, privilagesData } = useSelector(
    (state) => state.privilages
  );

  return privilagesData.includes(privilageId);
};

export default useCheckPrivilages;
