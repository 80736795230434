import React, { useEffect, useState } from "react";
import { Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {
  // updateMerchantBankDetails,
  // uploadMerchantDocs,
} from "../../../utils/ApiServices";
import swal from "sweetalert";
import {
  getKeyPressed,
  isIfscRegexIsValid,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../../../utils/helper";
// import styles from "./CreateMerchantPhase2.module.css";
import {
  SWIPELINC_API,
  UPDATE_MERCHANT_BANK_DETAILS,
  fIRST_STAGE_APPROVE_SECOND_NOT_SUBMITTED,
  CHECK_CALLBACK_STATUS
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";

const CreateMerchantPhase2 = ({ show, onHide, refresh, setRefresh }) => {
  const initialValues = {
    accountNumber: "",
    ifsc: "",
    bankName: "",
    branchName: "",
    bankCity: "",
    callbackUrl: "",
    websiteUrl: "",
    docs: "",
    payoutCallbackUrl: "",
    chargebackCallbackUrl: "",
  };

  const [companyRegFile, setCompanyRegFile] = useState(null);
  // const [accountName, setAccountName] = useState("");
  const [formData, setFormData] = useState(initialValues);
  const [supportingFileList, setSupportingFileList] = useState([]);
  const [docsFile, setDocsFile] = useState([]);
  const [docsName, setDocsName] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [isIfscVerified, setIsIfscVerified] = useState(false);
  // const [showLoader, setShowLoader] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const { apiCallFnforPut, apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [payinCallbackStatus, setPayinCallbackStatus] = useState(false)
  const [chargebackCallbackStatus, setChargebackCallbackStatus] = useState(false)
  const [payoutCallbackStatus, setPayoutCallbackStatus] = useState(false)
  // const [callbacksVerifyOrNot, setCallbacksVerifyOrNot] = useState({
  //   callBackUrl: false,
  //   payoutCallbackUrl: false,
  //   chargebackCallbackUrl: false,
  // });
  const token = localStorage.getItem("token");

  const offcanvasStyle = {
    width: "90vw", // Set width to 70vw
  };


  const updateMerchantBankDetails = async (payload, token) => {
    try {
      const response = await apiCallFnforPut(UPDATE_MERCHANT_BANK_DETAILS, payload);
      console.log(response);
      return response;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };


  const uploadMerchantDocs = async (mid, docsName, docsFile) => {
    console.log("Worked here 1");
    // console.log(token)
    try {
      const formData = new FormData();
      formData.append("mid", mid);

      docsFile.forEach((file) => {
        formData.append(`pdfFiles`, file);
      });
      docsName.forEach((name) => {
        formData.append(`fileName`, name);
      });
      console.log("Worked here 2");
      const response = await apiCallFnforPost(`${SWIPELINC_API}MerchantAdminPanel/merchant/Account/savemerchantdocuments`, formData);
      console.log("Worked here 3", response);
      return response?.statusCode;
    } catch (error) {
      console.error(error);
    }
  };

  const handelFormData = (e) => {
    // console.log("filesssssss", e.target.files);
    const { name, value } = e.target;

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      (name === "bankCity" || name === "bankName" || name === "branchName")
    ) {
      return;
    }

    // if (isIfscVerified && (name === "ifsc" || name === "accountNumber")) {
    //   // setAccountName("");
    //   setIsIfscVerified(false);
    // }

    // if (
    //   isIfscVerified &&
    //   (name === "bankName" || name === "branchName" || name === "bankCity")
    // ) {
    //   return;
    // }

    // if (isIfscVerified === "NotVerified") {
    //   setIsIfscVerified(false);
    // }
    if (
      name === "payoutCallbackUrl" ||
      name === "callbackUrl" ||
      name === "chargebackCallbackUrl"
    ) {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }
  };

  const handelValidations = (e) => {
    if (e.target.name === "ifsc" && e.target.value !== "") {
      if (!isIfscRegexIsValid(formData.ifsc)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, ifsc: "" });
        });
        return;
      }
    }
  };

  const setFilesToState = () => {
    let nameArr = [];
    let docsArr = [];

    supportingFileList.forEach((element, indx) => {
      let name = element.name;
      let file = element.file;
      nameArr.push(name);
      docsArr.push(file);
    });

    setDocsName(nameArr);
    setDocsFile(docsArr);
  };
  // console.log(token)
  const saveDataToServer = async (payload) => {
    try {
      let response = await updateMerchantBankDetails(payload);
      console.log("Response", response)
      let merchantDocsRes;
      console.log("Company-type", companyType)
      if (response.statusCode === 200 && companyType) {
        console.log("inside IFF")
        merchantDocsRes = await uploadMerchantDocs(
          selectedMerchant.mid,
          [companyType],
          [formData.docs],
        );

        if (merchantDocsRes === 200) {
          document.getElementById("docs").value = "";
        } else {
          swal({
            title: "Alert!",
            text: "Error in Uploading Documents",
            icon: "error",
          });
        }
      }

      // console.log("response", response);
      // console.log("merchantDocsRes", merchantDocsRes);

      if (response.statusCode === 200 && supportingFileList.length > 0) {
        let merchantSupportingDocs = await uploadMerchantDocs(
          selectedMerchant.mid,
          docsName,
          docsFile,
        );

        // console.log("merchantSupportingDocs",merchantSupportingDocs);

        if (merchantSupportingDocs === 200) {
          setSupportingFileList([]);
          setSelectedMerchant("");
        } else {
          swal({
            title: "Alert!",
            text: "Error in Uploading Documents",
            icon: "error",
          });
        }
      }

      if (response.statusCode === 200 && merchantDocsRes === 200) {
        setFormData(initialValues);
        setSelectedMerchant("");
        setIsIfscVerified(false);
        swal({
          title: "Success!",
          text: "Data Saved Successfully !",
          icon: "success",
        });
        setCompanyRegFile(null);
        onHide();
        setRefresh(!refresh)
      } else if (response.statusCode === 306) {
        swal({
          title: "Alert!",
          text: "Beneficiary Account Name and Company Name Must be Similiar !",
          icon: "error",
        });
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong !",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedMerchant === "" || selectedMerchant === undefined) {
      swal({
        title: "ALERT!",
        text: "Please Select A Merchant*",
        icon: "error",
      });
      return;
    }

    if (companyType !== "") {
      if (document.getElementById("docs").value === "") {
        swal({
          title: "ALERT!",
          text: "Please Select your document*",
          icon: "error",
        });
        return;
      }
    }

    if (formData.accountNumber === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Account number*",
        icon: "error",
      });
      return;
    }

    if (formData.ifsc === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter IFSC Code*",
        icon: "error",
      });
      return;
    }

    if (formData.bankName === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Bank Name*",
        icon: "error",
      });
      return;
    }

    if (formData.branchName === "") {
      swal({
        title: "ALERT!",
        text: " Please Enter Branch Name*",
        icon: "error",
      });
      return;
    }

    if (formData.bankCity === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Bank City*",
        icon: "error",
      });
      return;
    }

    // if (accountName === "") {
    //   swal({
    //     title: "ALERT!",
    //     text: "Please Verify your Bank*",
    //     icon: "error",
    //   });
    //   return;
    // }

    // supportingFileList.forEach((docsRow) => {
    //   if (docsRow.name === "") {
    //     swal({
    //       title: "Alert!",
    //       text: "Please Enter Supporting Documents Labels",
    //       icon: "error",
    //     });
    //     return;
    //   }
    // });

    for (let docsRow of supportingFileList) {
      if (docsRow.name === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Supporting Documents Labels",
          icon: "error",
        });
        return;
      }
    }

    // supportingFileList.forEach((docsRow) => {
    //   if (docsRow.file === "") {
    //     swal({
    //       title: "Alert!",
    //       text: "Please Select Supporting Documents",
    //       icon: "error",
    //     });
    //     return;
    //   }
    // });

    for (let docsRow of supportingFileList) {
      if (docsRow.file === "") {
        swal({
          title: "Alert!",
          text: `Please Select ${docsRow.name} Document`,
          icon: "error",
        });
        return;
        // break;
      }
    }

    const payload = {
      bankBranchName: formData.branchName,
      callBackUrl: formData.callbackUrl,
      merchantAccountNumber: formData.accountNumber,
      merchantBankName: formData.bankName,
      merchantIfscCode: formData.ifsc,
      mid: selectedMerchant.mid,
      websiteUrl: formData.websiteUrl,
      payoutCallBackUrl: formData.payoutCallbackUrl,
      chargebackCallbackUrl: formData.chargebackCallbackUrl,
      callBackUrlStatus: payinCallbackStatus,
      payoutCallbackUrlStatus:payoutCallbackStatus,
      chargebackCallbackUrlStatus:chargebackCallbackStatus,
    };

    saveDataToServer(payload);
  };

  const handleViewFile = (file) => {
    if (!file) {
      return; // If no file is selected, do nothing
    }
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  // const verifyIfsc = async () => {
  //   if (formData.accountNumber === "") {
  //     swal({
  //       title: "ALERT!",
  //       text: "*Please Enter Account number",
  //       icon: "error",
  //     });
  //     return;
  //   }
  //   if (formData.ifsc === "") {
  //     swal({
  //       title: "ALERT!",
  //       text: "*Please Enter IFSC Code",
  //       icon: "error",
  //     });
  //     return;
  //   }
  //   setShowLoader(true);
  //   const isAccVerified = await accountNumberIfscCodeVerifier(
  //     formData.ifsc,
  //     formData.accountNumber
  //   );

  //   // setAccountName(isAccVerified.data.AccoutBeneficiaryName);

  //   const res = await ifscCodeVerifier(formData.ifsc);
  //   setShowLoader(false);

  //   if (res.status === 200 && isAccVerified.statusCode === 200) {
  //     setFormData({
  //       ...formData,
  //       branchName: res.data.branch,
  //       bankName: res.data.bank,
  //       bankCity: res.data.city,
  //     });
  //     setIsIfscVerified(true);
  //   } else {
  //     setIsIfscVerified("NotVerified");
  //     swal({
  //       title: "Alert!",
  //       text: "Invalid Bank Details !",
  //       icon: "error",
  //     });
  //   }
  // };

  const addSupportingDocumentField = () => {
    let newEntry = {
      id: new Date().getTime().toLocaleString(),
      name: "",
      file: "",
    };
    setSupportingFileList([...supportingFileList, newEntry]);
  };

  const removeSupportingDocumentField = (id) => {
    let newData = supportingFileList?.filter((elem) => {
      console.log(elem);
      return elem.id !== id;
    });

    let nameArray = newData?.map((elem) => {
      return elem.name;
    });

    let fileArray = newData?.map((elem) => {
      return elem.file;
    });

    setDocsName(nameArray);
    setDocsFile(fileArray);

    setSupportingFileList(newData);
  };

  const handelDocumentsFileName = (event, id) => {
    let newArr = supportingFileList?.map((elem) => {
      if (elem.id === id) {
        elem.name = event.target.value?.toUpperCase();
        return elem;
      }
      return elem;
    });

    setSupportingFileList(newArr);
    setFilesToState();
  };

  const handelDocumentsFileData = async (file, id) => {
    let newArr = supportingFileList?.map((elem) => {
      if (elem.id === id) {
        elem.file = file;
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);
    setFilesToState();
  };

  const isPdfFile = async (event, id) => {
    // clear state of pdf seleted
    let newArr = supportingFileList?.map((elem) => {
      if (elem.id === id) {
        elem.file = "";
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);

    let file = event.target.files[0];
    // setShowLoader(true);
    let res = 200;
    // let res = await checkIsFileIsPDF(file);

    if (res === 200) {
      handelDocumentsFileData(file, id);
      // setShowLoader(false);
      return;
    } else {
      swal({
        title: "Alert!",
        text: "Kindly Upload PDF FIles Only",
        icon: "error",
      });
      setCompanyRegFile(null);
      document.getElementById(id).value = "";
      return false;
    }
  };

  const getCompanyType = (companyType) => {
    if (
      companyType?.trim() === "PRIVATE LIMITED" ||
      companyType?.trim() === "PUBLIC LIMITED" ||
      companyType?.trim() === "OPC"
    ) {
      setCompanyType("MOA");
    } else if (
      companyType?.trim() === "LLP" ||
      companyType?.trim() === "PARTNERSHIP"
    ) {
      setCompanyType("Partnership Deal");
    } else if (companyType?.trim() === "PROPERITARY") {
      setCompanyType("Gumasta");
    } else if (companyType?.trim() === "NGO/TRUST") {
      setCompanyType("Bye-laws");
    } else {
      setCompanyType("");
    }
  };

  const getAllMerchantList = async () => {
    let res = await apiCallFnforGet(fIRST_STAGE_APPROVE_SECOND_NOT_SUBMITTED);
    setMerchantList(res?.data ? res?.data : []);
  };

  const handelMerchantSelect = (e) => {
    let mid = e.target.value;
    let selectedData = merchantList?.filter((elem) => elem.mid === mid);
    // console.log("selectedData", selectedData[0]);
    setSelectedMerchant(selectedData[0]);
    setCompanyType(selectedData[0].companyType);
    getCompanyType(selectedData[0].companyType);
  };

  useEffect(() => {
    getAllMerchantList();
  }, []);
  const handleClose = () => {
    setFormData(initialValues);
    setSelectedMerchant("");
    setCompanyRegFile(null);
    onHide();
    setChargebackCallbackStatus(false);
    setPayinCallbackStatus(false);
    setPayoutCallbackStatus(false);
    setRefresh(!refresh);
  };

  const handleVerifyUrl = async (url, type) => {
    try {
      const response = await apiCallFnforPost(CHECK_CALLBACK_STATUS, { url: url });
      console.log(response);
      if (type === "payin") {
        setPayinCallbackStatus(response);
      }
      else if (type === "chargeback") {
        setChargebackCallbackStatus(response)
      }
      else if (type === "payout") {
        setPayoutCallbackStatus(response)
      }
    } catch (error) {
      console.log(error)
    }
  }
console.log("Payin callback",payinCallbackStatus)
console.log("Payout callback",payoutCallbackStatus)
console.log("chargeback callback",chargebackCallbackStatus)
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static" 
        keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title  fs-2 text-dark fw-bold" id="offcanvasLabel">
            Create Merchant Phase2
          </h5>
        </div>
        <Offcanvas.Body>
          <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
            <div className="cart-view-sec mt-4">
              <Row>
                <Col xs={12} lg={12} md={12}>
                  {/* Tab content start here */}
                  {/* <Card className=" border-0 mb-5 chart-boxes merchant-info-form-sec"> */}
                  {/* <Card.Body> */}
                  <Card.Title className="h3 fw-bold text-center">
                    Account Details
                  </Card.Title>
                  <div className="merchant-form-info">
                    <form
                      onSubmit={handleSubmit}
                      className="form-section-form-group"
                    >
                      <Row>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="mx-4 text-dark fs-4 mt-4 ">
                              Select Merchant{" "}
                              <sup className="text-danger">*</sup>
                            </label>
                            <select
                              id="companyDropdown"
                              className="form-control "
                              onChange={handelMerchantSelect}
                              value={selectedMerchant?.mid}
                            >
                              <option value="">Select a Merchant</option>
                              {merchantList?.map((merchant) => (
                                <option
                                  key={merchant.mid}
                                  value={merchant.mid}
                                >
                                  {merchant.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* ./form-group */}
                        </Col>
                      </Row>

                      <Row>
                        {companyType !== "" ? (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="accNum"
                                className="mx-4 text-dark fs-4 mt-2"
                              >
                                {companyType}
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="file"
                                accept=".pdf"
                                id="docs"
                                name="docs"
                                className="form-control "
                                // value={formData.docs}
                                // onChange={handelFormData}
                                onChange={(event) => {
                                  // Handle file upload here
                                  setCompanyRegFile(event.target.files[0]);
                                  if (true) {
                                    setFormData({
                                      ...formData,
                                      docs: event.target.files[0],
                                    });
                                  }
                                }}
                              />

                              {companyRegFile && (
                                <div className="d-flex mt-2 justify-content-end">
                                  <button
                                    type="button"
                                    className="badge badge-rounded badge-warning"
                                    //   className={styles.viewButton_first_stage}
                                    onClick={() =>
                                      handleViewFile(companyRegFile)
                                    }
                                  >
                                    View
                                  </button>
                                </div>
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="accNum"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              Account Number
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="accountNumber"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="accountNumber"
                              value={formData.accountNumber}
                              onChange={handelFormData}
                              className="form-control "
                            />
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="ifsc"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              IFSC Code
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="ifscCode"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onBlur={handelValidations}
                              name="ifsc"
                              value={formData.ifsc}
                              onChange={handelFormData}
                              className="form-control "
                            />

                            {/*  */}
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="bankName"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              Bank Name
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="bankName"
                              onKeyDown={(e) => {
                                // if (e.key === " ") {
                                //   e.preventDefault();
                                // }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="bankName"
                              value={formData.bankName}
                              onChange={handelFormData}
                              className="form-control "
                            />
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="branchName"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              Branch Name
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="branchName"
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              name="branchName"
                              value={formData.branchName}
                              onChange={handelFormData}
                              className="form-control "
                            />
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              City
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="city"
                              onKeyDown={(e) => {
                                // if (e.key === " ") {
                                //   e.preventDefault();
                                // }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="bankCity"
                              value={formData.bankCity}
                              onChange={handelFormData}
                              className="form-control "
                            />
                          </div>
                          {/* ./form-group */}
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group mt-3">
                            <label htmlFor="callBackUrl" className="text-dark fs-4">
                              Payin Callback URL
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="callBackUrl"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  // setPressedKeyName(getKeyPressed(e));
                                }}
                                name="callbackUrl"
                                value={formData.callbackUrl}
                                onChange={handelFormData}
                                className="form-control"
                              />
                              <button type="button" className="btn btn-success" id="verifyButton"
                                onClick={() => handleVerifyUrl(formData.callbackUrl, "payin")}
                              >
                                Verify
                              </button>
                            </div>
                            {payinCallbackStatus ?(<span
                              id="callBackUrlErr"
                              className="text-success"
                            >
                              <VerifiedIcon className="fs-4"/> Verified
                            </span>):( <span
                              id="callBackUrlErr"
                              className="text-danger"
                            >
                             <NewReleasesIcon className="fs-4"/> Not Verified
                            </span>)}
                           
                          </div>
                          {/* ./form-group */}
                        </Col>


                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="callBackUrl"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              Payout Callback URL
                            </label>
                            <div className="input-group">
                            <input
                              type="text"
                              // id="firstName"
                              id="callBackUrl"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                // setPressedKeyName(getKeyPressed(e));
                              }}
                              name="payoutCallbackUrl"
                              value={formData.payoutCallbackUrl}
                              onChange={handelFormData}
                              className="form-control "
                            />
                            <button type="button" className="btn btn-success" id="verifyButton"
                                onClick={() => handleVerifyUrl(formData.payoutCallbackUrl, "payout")}
                              >
                                Verify
                              </button>
                            </div>
                            {payoutCallbackStatus ?(<span
                              id="callBackUrlErr"
                              className="text-success"
                            >
                              <VerifiedIcon className="fs-4"/> Verified
                            </span>):( <span
                              id="callBackUrlErr"
                              className="text-danger"
                            >
                               <NewReleasesIcon className="fs-4"/> Not Verified
                            </span>)}
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="callBackUrl"
                              className="mx-4 text-dark fs-4 mt-3"
                            >
                              Chargeback Callback URL
                            </label>
                            <div className="input-group">
                            <input
                              type="text"
                              // id="firstName"
                              id="chargebackCallbackUrl"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                // setPressedKeyName(getKeyPressed(e));
                              }}
                              name="chargebackCallbackUrl"
                              value={formData.chargebackCallbackUrl}
                              onChange={handelFormData}
                              className="form-control "
                            />
                            <button type="button" className="btn btn-success" id="verifyButton"
                                onClick={() => handleVerifyUrl(formData.chargebackCallbackUrl, "chargeback")}
                              >
                                Verify
                              </button>
                            </div>
                           
                            {chargebackCallbackStatus ?(<span
                              id="callBackUrlErr"
                              className="text-success"
                            >
                              <VerifiedIcon className="fs-4"/> Verified
                            </span>):( <span
                              id="callBackUrlErr"
                              className="text-danger"
                            >
                               <NewReleasesIcon className="fs-4"/> Not Verified
                            </span>)}
                          </div>
                          {/* ./form-group */}

                        </Col>

                        <div className="form-group mt-3">
                          <label
                            htmlFor="callbackUrl"
                            className="mx-4 text-dark fs-4 mt-3"
                          >
                            Other Supporting Documents{" "}
                            <span
                              onClick={addSupportingDocumentField}
                              style={{ cursor: "pointer" }}
                              className="text-primary fs-5 mb-1"
                            >
                              {/* <i className="bi bi-plus-circle-fill"></i> */}
                              <ControlPointIcon />
                            </span>{" "}
                          </label>
                          {supportingFileList?.map((elem) => {
                            return (
                              <div className="mt-3" key={elem.id}>
                                <div className="d-flex">
                                  <div className=" w-100">
                                    <div className="form-group">
                                      <label
                                        htmlFor="callBackUrl"
                                        className="mx-4 text-dark fs-4"
                                      >
                                        Label Name
                                        <sup className="text-danger">*</sup>
                                      </label>
                                      <input
                                        type="text"
                                        id="callBackUrl"
                                        name="name"
                                        value={elem.name}
                                        required="true"
                                        onChange={(event) =>
                                          handelDocumentsFileName(
                                            event,
                                            elem.id
                                          )
                                        }
                                        className="form-control "
                                      />

                                      {/* <span
                                          id="callBackUrlErr"
                                          className=" text-danger d-none"
                                        >
                                          Please Enter CallBack Url*
                                        </span> */}
                                    </div>
                                  </div>

                                  <input
                                    type="file"
                                    accept=".pdf"
                                    required="true"

                                    onChange={(event) =>
                                      isPdfFile(event, elem.id)
                                    }
                                    id={elem.id}
                                    className="form-control  mx-3"
                                    style={{ marginTop: "29px" }}
                                  />
                                </div>
                                <div className="d-flex justify-content-end mt-1 mx-2">
                                  <span
                                    onClick={() =>
                                      removeSupportingDocumentField(elem.id)
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="text-danger fs-5"
                                  >
                                    <DeleteForeverIcon />
                                    {/* <i className="bi bi-trash-fill"></i> */}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Row>
                      <Col xs={12} lg={12} md={12}>
                        <div className="d-flex justify-content-end">
                          <div className="form-group  tool-bar-btn mb-0 mt-3">
                            <button
                              type="submit"
                              className="badge badge-rounded badge-primary mx-2 px-5 py-3"
                              // onClick={handleSubmit}
                              id="submitDetailsBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Col>
                    </form>
                  </div>

                  <div className="merchant-form-info"></div>
                  {/* <Col xs={12} lg={12} md={12}>
                    <div className="d-flex justify-content-end">
                      <div className="form-group  tool-bar-btn mb-0 mt-3">
                        <button
                          className="badge badge-rounded badge-primary mx-2 px-5 py-3"
                          onClick={handleSubmit}
                          id="submitDetailsBtn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Col> */}
                  {/* </Card.Body> */}
                  {/* </Card> */}
                  {/* Tab content end here */}
                </Col>
                {/* ./col */}
              </Row>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreateMerchantPhase2;
