import CustomTip from "../common/CustomToolTip/CustomTip";
import IbtnWrapper from "../common/CustomToolTip/IButtonWrraper";
import MiniLoader from "../common/MiniLoader/MiniLoader";
import { formatCompactNumber } from "../utils/helper";

export default function TotalTransaction({ title,data }) {
  return (
    <>
    <div className="col-sm-6">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mt-2">{title}</h4>
          <div className="d-flex align-items-center mt-3 mb-2">
          <CustomTip size={20} title={data??0} placement="top">
          {data === null || data === undefined ? (
             <MiniLoader size={15} color={"primary"} />
          ):(

            <h2 className="fs-38 mb-0 me-3">{formatCompactNumber(data??0)}</h2>
          )}

          </CustomTip>
            
          </div>
        </div>
      <IbtnWrapper color={"text-black"} desc={title}/>
      </div>
    </div>
  </>
  );
}
