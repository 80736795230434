import { combineReducers, configureStore } from "@reduxjs/toolkit";
import reportSlice from "./slices/reportSlice";
import privilagesSlice from "./slices/privilagesSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import utilSlice from "./slices/utilSlice";
import userSlice from "./slices/userSlice";


// Define slices
const reportReducer = reportSlice ;
const privilagesReducer = privilagesSlice ;
const utilsReducer = utilSlice;
const userReducer = userSlice;


// Combine reducers
const rootReducer = combineReducers({
  report: reportReducer,
  privilages: privilagesReducer,
  utils: utilsReducer,
  userData:userReducer,
});
// Create persistConfig
const persistConfig = {
  key: "root", 
  storage, 
  whitelist: ["userData", "privilages"]
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
